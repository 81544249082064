import { memo } from 'react';

import { FILE_INPUT_ACCEPTED_MIME_TYPES_AND_EXTENSIONS } from '$app-web/constants';
import { useTranslator } from '$app-web/hooks/use-translator';

import MediaBase from '../media-base';

interface Props {
    value?: string | File | null;
    onChange: (value: File | string | null) => void;
}

const TypeVideo = ({ value = null, onChange }: Props) => {
    const t = useTranslator();

    return (
        <MediaBase
            type="video"
            mediaViewerProps={{ type: 'video', autoPlay: true }}
            roundFrameProps={value ? { variant: 'video-playing', type: 'icon-text' } : { variant: 'video' }}
            viewButtonText={t('webActivities:survey_audio_recorder_play')}
            captureButtonText={t('webActivities:survey_video_record_button')}
            fileInputProps={{
                accept: FILE_INPUT_ACCEPTED_MIME_TYPES_AND_EXTENSIONS.VIDEO,
            }}
            value={value}
            onChange={onChange}
        />
    );
};

export default memo(TypeVideo);
