import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { uniqueId } from 'lodash-es';
import * as PropTypes from 'prop-types';

import Badge from '$app-web/ui-kit/components/badge';
import ChipsGroup from '$app-web/ui-kit/components/chips-group';
import Icon from '$app-web/ui-kit/components/icon';
import InputAdornment from '$app-web/ui-kit/components/input-adornment';
import Label from '$app-web/ui-kit/components/label';
import OptionItems from '$app-web/ui-kit/components/option-items';
import Popover from '$app-web/ui-kit/components/popover';
import TextField from '$app-web/ui-kit/components/text-field';
import useOutsideClick from '$app-web/ui-kit/hooks/use-outside-click';

import classes from './styles.module.scss';

const CHIPS_END_OFFSET = 60;

const getIsASearchResultCandidate = (itemLabel, searchInputValue, { disable }) =>
    disable ? true : itemLabel.toLowerCase().includes(searchInputValue.toLowerCase());

const Select = (props) => {
    const {
        badge,
        chips,
        className,
        disabled,
        error,
        noOptionsText,
        errorMessage,
        customValue,
        fetchingNextPage,
        fetchNextPage,
        freeInput,
        fullWidth,
        hasNextPage,
        id,
        label,
        labelHelperText,
        lazy,
        loading,
        loadingText,
        multiple,
        onBlur,
        onChange,
        options,
        placeholder,
        required,
        searchable,
        disabledLocalSearch,
        onInputChange,
        selectAll,
        total,
        value,
        variant,
        clearable,
        icon,
        searchInputRef: propsSearchInputRef,
        warning,
        warningTooltip,
        hideDropdown,
    } = props;

    const [innerLabel, setInnerLabel] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    const selectUniqueId = useMemo(() => id || uniqueId('select-'), [id]);

    const baseOptions = useMemo(
        () => (customValue !== undefined ? [...options, { label: customValue, value: customValue }] : options),
        [customValue, options]
    );

    const normalizedOptions = useMemo(
        () => baseOptions.map((option) => (option.header && option.items.length ? option.items : option)).flat(),
        [baseOptions]
    );

    const selectAllLabel = typeof selectAll === 'string' ? selectAll : 'All';
    const shouldChipsBeShown = chips && multiple && (!searchable || !isPopoverOpen);
    const optionItemsRef = useRef(null);

    const updateLabel = useCallback(
        (newSelectedOptions) => {
            let newLabel = '';
            if (!chips) {
                if (optionItemsRef.current?.allSelected === true) {
                    newLabel = selectAllLabel;
                } else {
                    newLabel = newSelectedOptions.map((option) => option?.label).join(', ');
                }
            }
            setInnerLabel(newLabel || '');
        },
        [chips, selectAllLabel]
    );

    const ref = useRef(null);
    const innerSearchInputRef = useRef(null);
    const searchInputRef = propsSearchInputRef || innerSearchInputRef;

    const optionItems = useMemo(
        () =>
            baseOptions.map((option) => {
                if (option.header) {
                    const mappedGroupItems = option.items.map((optionItem) => ({
                        ...optionItem,
                        hidden:
                            searchInputValue !== '' &&
                            !getIsASearchResultCandidate(optionItem.label, searchInputValue, { disable: disabledLocalSearch }),
                    }));
                    const isAllGroupItemsHidden = !mappedGroupItems.some((item) => !item.hidden);
                    return {
                        ...option,
                        items: mappedGroupItems,
                        hidden: isAllGroupItemsHidden,
                    };
                }
                return {
                    ...option,
                    hidden:
                        searchInputValue !== '' &&
                        !getIsASearchResultCandidate(option.label, searchInputValue, { disable: disabledLocalSearch }),
                };
            }),
        [baseOptions, disabledLocalSearch, searchInputValue]
    );

    const closePopover = useCallback(() => {
        setIsPopoverOpen(false);
    }, []);

    const openPopover = useCallback(
        (event) => {
            if (!disabled) {
                if (event) {
                    event.stopPropagation();
                }
                setIsPopoverOpen(true);
                setSearchInputValue('');
            }
        },
        [disabled]
    );

    const togglePopover = useCallback(
        () => (isPopoverOpen ? closePopover() : openPopover()),
        [closePopover, isPopoverOpen, openPopover]
    );

    const handleSearchInputChange = useCallback(
        (newValue) => {
            setSearchInputValue(newValue);
            if (!isPopoverOpen) {
                openPopover();
            }
        },
        [isPopoverOpen, openPopover]
    );

    const handleOptionClick = useCallback(() => {
        if (!multiple) {
            closePopover();
        }
    }, [closePopover, multiple]);

    const handleOptionDeselect = useCallback(
        (targetOption) => {
            const selectedItems = value.filter((item) => item !== targetOption.value);
            onChange(selectedItems);
        },
        [onChange, value]
    );

    const handleOutsideClick = useCallback(() => {
        closePopover();
        if (isPopoverOpen) {
            setIsTouched(true);
            onBlur?.();
        }
    }, [closePopover, isPopoverOpen, onBlur]);

    useOutsideClick(ref, handleOutsideClick);

    useEffect(() => {
        if (isPopoverOpen && searchInputRef.current) {
            searchInputRef.current.focus();
        }

        if (!isPopoverOpen) {
            setSearchInputValue(chips ? '' : innerLabel);
        }
    }, [chips, isPopoverOpen, innerLabel, searchInputRef]);

    useEffect(() => {
        let newSelectedOptions = [];

        if (multiple) {
            newSelectedOptions = normalizedOptions.filter((option) => value.includes(option.value));
        } else if (value !== undefined) {
            newSelectedOptions = [normalizedOptions.find((option) => option.value === value)];
        }

        setSelectedOptions(newSelectedOptions);
        updateLabel(newSelectedOptions);
    }, [chips, multiple, normalizedOptions, updateLabel, value]);

    const getChevronIconProps = () => ({
        color: 'gray-400',
        disabled,
        icon: variant === 'header-bold' ? ['far', 'angle-up'] : ['fal', 'chevron-up'],
        rotation: isPopoverOpen ? 0 : 180,
        size: variant === 'header-bold' ? 'lg' : 'sm',
        ...(variant === 'header-bold' && { className: classes.fixChevronIconHeight }),
        ...(variant === 'header' && { className: classes.chevronMargin }),
    });

    const isErrorShown = error && isTouched;
    const isItemWithWarningSelected = optionItems.find((optionItem) => optionItem.value === value)?.warning;

    return (
        <div
            className={clsx(classes.root, className, {
                [classes.chips]: chips,
                [classes.fullWidth]: fullWidth,
                [classes.error]: isErrorShown,
                [classes.variantHeaderBase]: variant === 'header' || variant === 'header-bold',
                [classes.variantHeaderBold]: variant === 'header-bold',
            })}
            ref={ref}
        >
            {label && (
                <Label
                    disabled={disabled}
                    htmlFor={selectUniqueId}
                    label={label}
                    helperText={labelHelperText}
                    required={required}
                    warning={warning}
                    warningTooltip={warningTooltip}
                />
            )}
            <Popover
                open={isPopoverOpen && !hideDropdown}
                align="start"
                content={
                    <OptionItems
                        ref={optionItemsRef}
                        customValue={customValue}
                        fetchingNextPage={fetchingNextPage}
                        fetchNextPage={fetchNextPage}
                        freeInput={freeInput}
                        hasNextPage={hasNextPage}
                        lazy={lazy}
                        total={total}
                        loading={loading}
                        loadingText={loadingText}
                        noOptionsText={noOptionsText}
                        multiple={multiple}
                        options={optionItems}
                        onChange={onChange}
                        onOptionClick={handleOptionClick}
                        searchInputValue={searchInputValue}
                        selectAll={selectAll}
                        selectAllLabel={selectAllLabel}
                        value={value}
                    />
                }
            >
                <div className={classes.headerWrapper}>
                    {searchable ? (
                        <TextField
                            startAdornment={
                                icon != null ? (
                                    <InputAdornment position="start">
                                        <Icon color="gray-400" size="sm" icon={icon} />
                                    </InputAdornment>
                                ) : undefined
                            }
                            id={selectUniqueId}
                            autoComplete="off"
                            error={isErrorShown}
                            disabled={disabled}
                            ellipsis
                            endAdornment={
                                <InputAdornment position="end" tabIndex={-1} onClick={togglePopover}>
                                    <Icon {...getChevronIconProps()} />
                                </InputAdornment>
                            }
                            fullWidth
                            inputRef={searchInputRef}
                            placeholder={shouldChipsBeShown && value.length ? '' : placeholder}
                            tabIndex={disabled ? -1 : 0}
                            value={searchInputValue}
                            warning={!isPopoverOpen && isItemWithWarningSelected}
                            onChange={(newValue) => {
                                onInputChange?.(newValue);
                                handleSearchInputChange(newValue);
                            }}
                            onClick={togglePopover}
                            onClear={clearable ? () => onChange(undefined) : undefined}
                        />
                    ) : (
                        <div
                            // TODO: take care of semantic aspects of this (id & label) later
                            id={selectUniqueId}
                            className={clsx(classes.selectBoxHeader, 'flex justify-between items-center nowrap', {
                                [classes.disabledSelectBoxHeader]: disabled,
                                [classes.active]: isPopoverOpen,
                            })}
                            onClick={togglePopover}
                            onKeyPress={togglePopover}
                            role="button"
                            tabIndex={disabled ? -1 : 0}
                        >
                            <div
                                className={clsx('item', classes.selectBoxHeaderText, {
                                    [classes.placeholder]: placeholder,
                                })}
                            >
                                {innerLabel || placeholder}
                            </div>
                            <div className="item xs-fill" />
                            <Icon {...getChevronIconProps()} />
                            {badge && <Badge border color="red" variant="dot" />}
                        </div>
                    )}
                    {shouldChipsBeShown && (
                        <ChipsGroup
                            absolutePositioned
                            endOffset={CHIPS_END_OFFSET}
                            items={selectedOptions}
                            truncate
                            onClick={openPopover}
                            onDelete={handleOptionDeselect}
                        />
                    )}
                </div>
            </Popover>
            {isErrorShown && errorMessage && !disabled && (
                <div className={clsx('flex items-center', classes.errorMessageWrapper)}>
                    <Icon size="sm" icon={['fas', 'exclamation-circle']} color="red-500" />
                    <span className={classes.error}>{errorMessage}</span>
                </div>
            )}
        </div>
    );
};

Select.defaultProps = {
    badge: false,
    chips: false,
    className: undefined,
    disabled: false,
    error: false,
    errorMessage: undefined,
    customValue: undefined,
    fetchingNextPage: false,
    fetchNextPage: undefined,
    freeInput: false,
    fullWidth: true,
    hasNextPage: false,
    id: undefined,
    lazy: false,
    label: undefined,
    labelHelperText: undefined,
    loading: false,
    multiple: false,
    onBlur: undefined,
    placeholder: undefined,
    required: false,
    searchable: true,
    selectAll: undefined,
    total: undefined,
    value: undefined,
    variant: 'default',
    clearable: false,
    icon: undefined,
    warning: false,
    warningTooltip: undefined,
    searchInputRef: undefined,
    disabledLocalSearch: false,
    hideDropdown: false,
    onInputChange: undefined,
    loadingText: undefined,
    noOptionsText: undefined,
};

const getOptionPropTypes = (isRequired = false) => {
    const isRequiredType = (type) => (isRequired ? type.isRequired : type);

    return {
        label: isRequiredType(PropTypes.node),
        value: isRequiredType(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])),
        badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        disabled: PropTypes.bool,
        divider: PropTypes.bool,
        statusColor: PropTypes.string,
        icon: PropTypes.arrayOf(PropTypes.string),
    };
};

Select.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            ...getOptionPropTypes(),
            header: PropTypes.string,
            items: PropTypes.arrayOf(PropTypes.shape(getOptionPropTypes(true))),
        })
    ).isRequired,
    badge: PropTypes.bool,
    chips: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    customValue: PropTypes.string,
    fetchingNextPage: PropTypes.bool,
    fetchNextPage: PropTypes.func,
    freeInput: PropTypes.bool,
    fullWidth: PropTypes.bool,
    hasNextPage: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    labelHelperText: PropTypes.string,
    lazy: PropTypes.bool,
    loading: PropTypes.bool,
    multiple: PropTypes.bool,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    searchable: PropTypes.bool,
    selectAll: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    total: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])),
    ]),
    variant: PropTypes.oneOf(['default', 'header-bold', 'header']),
    clearable: PropTypes.bool,
    icon: PropTypes.arrayOf(PropTypes.string),
    warning: PropTypes.bool,
    warningTooltip: PropTypes.string,
    searchInputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
    disabledLocalSearch: PropTypes.bool,
    hideDropdown: PropTypes.bool,
    onInputChange: PropTypes.func,
    loadingText: PropTypes.string,
    noOptionsText: PropTypes.string,
};

export default Select;
