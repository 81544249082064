import { useCallback, useLayoutEffect, useRef, useState } from 'react';

function useCountdown(interval = 1, initialTime = 0) {
    const intervalId = useRef(-1);
    const secondsToCount = useRef(initialTime);
    const [started, setStared] = useState(false);
    const [secondsCounted, setSecondsCounted] = useState(0);

    const reset = useCallback(() => {
        setStared(false);
        secondsToCount.current = initialTime;
        setSecondsCounted(0);
        clearInterval(intervalId.current);
        intervalId.current = -1;
    }, [initialTime]);

    const start = useCallback(
        (seconds = initialTime) => {
            reset();
            secondsToCount.current = seconds;
            setStared(true);
        },
        [initialTime, reset]
    );

    useLayoutEffect(() => {
        if (secondsToCount.current - secondsCounted === 0) {
            reset();
        }
    }, [reset, secondsCounted]);

    useLayoutEffect(() => {
        if (started && intervalId.current === -1) {
            intervalId.current = setInterval(() => {
                setSecondsCounted((currentSecondsCounter) => currentSecondsCounter + 1);
            }, interval * 1000);
        }

        return () => {
            clearInterval(intervalId.current);
        };
    }, [interval, started]);

    return [secondsToCount.current - secondsCounted, start, reset];
}

export default useCountdown;
