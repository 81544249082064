import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import useStyles from './styles';

const Layout = ({ labels, slider, sliderHint, vertical, spacing, variant }) => {
    const classes = useStyles();

    if (vertical) {
        return (
            <Grid container direction="column" className={classes.root} spacing={spacing} wrap="nowrap">
                <Grid
                    className={clsx(classes.verticalControlWrapper, { [classes.topMargin]: variant === 'primary' })}
                    item
                    xs
                    container
                    direction="row"
                    wrap="nowrap"
                    spacing={4}
                >
                    {variant === 'secondary' && vertical ? (
                        <>
                            <Grid item>{slider}</Grid>
                            <Grid item className={classes.verticalLabelWrapper}>
                                {labels}
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item className={classes.verticalLabelWrapper}>
                                {labels}
                            </Grid>
                            <Grid item>{slider}</Grid>
                        </>
                    )}
                </Grid>
                {sliderHint && <Grid item>{sliderHint}</Grid>}
            </Grid>
        );
    }

    return (
        <Grid
            className={classes.root}
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={spacing}
            wrap="nowrap"
        >
            <Grid item>{labels}</Grid>
            <Grid item>
                <div className={clsx(classes.sliderWrapper, { [classes.sliderPadding]: variant === 'primary' })}>{slider}</div>
            </Grid>
            {sliderHint && <Grid item>{sliderHint}</Grid>}
        </Grid>
    );
};

Layout.defaultProps = {
    vertical: false,
    sliderHint: undefined,
    spacing: 5,
    variant: 'primary',
};

Layout.propTypes = {
    labels: PropTypes.node.isRequired,
    slider: PropTypes.node.isRequired,
    sliderHint: PropTypes.node,
    vertical: PropTypes.bool,
    variant: PropTypes.oneOf(['primary', 'secondary']),
    spacing: PropTypes.number,
};

export default Layout;
