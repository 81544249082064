import { forwardRef } from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import styles from './styles';

const ScrollableView = forwardRef((props, ref) => {
    const { classes, children, hideX, hideY, className } = props;
    // Note that for vertical scroll:
    // the child should not have a fixed '100%' height so
    // it can not have height==='100%' or maxHeight==='100%'
    // just you can set minHeight==='100%' if your component wants
    // to fill whole vertical space

    // Note that for horizontal scroll:
    // the child should not have a fixed '100%' width so
    // it can not have width==='100%' or maxWidth==='100%'
    // just you can set minWidth==='100%' if your component wants
    // to fill whole vertical space
    return (
        <div
            ref={ref}
            className={clsx(
                classes.root,
                {
                    [classes.xHidden]: hideX,
                    [classes.yHidden]: hideY,
                },
                className
            )}
        >
            {children}
        </div>
    );
});

ScrollableView.defaultProps = {
    className: undefined,
    hideX: false,
    hideY: false,
};

ScrollableView.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    hideX: PropTypes.bool,
    hideY: PropTypes.bool,
};

export default withStyles(styles)(ScrollableView);
