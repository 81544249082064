import { core as appClientCore } from '@edf-pkg/app-client';

import { SESSION_STATUSES } from '$app-web/constants';
import { SessionResponse, SurveyHierarchy } from '$app-web/types';
import { SuperObject } from '$app-web/utils';

export interface SaveSessionRequestParams {
    deviceId: string;
    sTimeMs: number;
    pTimeMs: number;
    tlTypeId: number;
    uuid: string;
    activityId: number;
    studyId: number;
    tlId: number;
    version: number;
    responses: Array<Partial<SessionResponse>>;
    initialSurveyHierarchy: SurveyHierarchy;
    statusId: number;
    duration?: number;
    inProgressInfo: {
        questionId?: number;
        iteration?: number | null;
    };
}

export async function submitSurveyAsInProgress(requestData: SaveSessionRequestParams) {
    try {
        const rTimeMs = null; // Response time will be null for in-progress sessions
        const statusId = SESSION_STATUSES.IN_PROGRESS; // Valid Option: any status except DELETED
        const data = SuperObject.toSnakeCase({ ...requestData, rTimeMs, statusId });

        await appClientCore.axiosClientsManager.getClient('api').put('/v1/activity/session/submit/', data);

        return true;
    } catch (error) {
        return false;
    }
}

export async function submitSurveyAsCompleted(requestData: SaveSessionRequestParams) {
    try {
        const isInProgressSession = requestData.statusId === SESSION_STATUSES.IN_PROGRESS;
        const rTimeMs = Date.now();
        const statusId = SESSION_STATUSES.SUBMITTED; // Valid Option: SUBMITTED
        const data = SuperObject.toSnakeCase({ ...requestData, rTimeMs, statusId });

        if (isInProgressSession) {
            await submitSurveyAsInProgress(requestData);
        }
        await appClientCore.axiosClientsManager.getClient('api').post('/v1/activity/session/submit/', data);

        return true;
    } catch (error) {
        return false;
    }
}

// Valid Status Ids: UNKNOWN / IN_PROGRESS
export async function saveSessionDraft(requestData: SaveSessionRequestParams, statusId = SESSION_STATUSES.IN_PROGRESS) {
    try {
        const rTimeMs = null; // Response time will be null for in-progress sessions
        const data = SuperObject.toSnakeCase({ ...requestData, rTimeMs, statusId });

        await appClientCore.axiosClientsManager.getClient('api').post('/v1/activity/session/submit/draft/', data);

        return true;
    } catch (error) {
        return false;
    }
}

export async function submitSession(
    requestData: SaveSessionRequestParams,
    options: { onSubmitSessionCallback?: (data: SaveSessionRequestParams) => void } = {}
) {
    const { statusId } = requestData;
    const { onSubmitSessionCallback } = options;

    // Valid Options: SUBMITTED | EXPIRED | CANCELED | BLOCKED
    const VALID_STATUSES: Array<number> = [
        SESSION_STATUSES.SUBMITTED,
        SESSION_STATUSES.BLOCKED,
        SESSION_STATUSES.CANCELED,
        SESSION_STATUSES.EXPIRED,
    ];

    if (!VALID_STATUSES.includes(statusId)) {
        return false;
    }

    try {
        const rTimeMs = Date.now();
        const data = SuperObject.toSnakeCase({ ...requestData, rTimeMs });
        const client = appClientCore.axiosClientsManager.getClient('api');

        if (statusId === SESSION_STATUSES.SUBMITTED) {
            await client.post('/v1/activity/session/submit/', data);
        } else {
            await client.put('/v1/activity/session/submit/', data);
        }

        onSubmitSessionCallback?.(requestData);

        return true;
    } catch {
        return false;
    }
}

export async function deleteSessionAsParticipant(sessionUuid: string) {
    try {
        await appClientCore.axiosClientsManager
            .getClient('api')
            .post('/study/survey/session/delete/', { session_uuid: sessionUuid });

        return true;
    } catch {
        return false;
    }
}
