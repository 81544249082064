import appDateTime from '@edf-pkg/app-date-time';

export const DIALOG_IDS = {
    GET_LOCATION_PERMISSION: 'GET_LOCATION_PERMISSION',
    CLOSE: 'CLOSE',
    DELETE: 'DELETE',
};

export const CREATE_SESSION_STATUS = {
    UNKNOWN: 'UNKNOWN',
    STARTED: 'STARTED',
    FINISHED: 'FINISHED',
    FAILED: 'FAILED',
};

export const GO_TO_STATUS = {
    UNKNOWN: 'UNKNOWN',
    STARTED: 'STARTED',
    FINISHED: 'FINISHED',
    FAILED: 'FAILED',
};

export const CLOSE_STATUS = {
    UNKNOWN: 'UNKNOWN',
    STARTED: 'STARTED',
    FINISHED: 'FINISHED',
    FAILED: 'FAILED',
};

export const DELETE_STATUS = {
    UNKNOWN: 'UNKNOWN',
    STARTED: 'STARTED',
    FINISHED: 'FINISHED',
    FAILED: 'FAILED',
};

export const SURVEY_SECTION_INITIAL_QUESTION_ID = -999;

export const GO_TO_DIRECTION = {
    PREVIOUS: 'PREVIOUS',
    NEXT: 'NEXT',
    FINISH: 'FINISH',
};

export const SECTION_STATUS = {
    UNKNOWN: 'UNKNOWN',
    PREPARING: 'PREPARING',
    PREPARED: 'PREPARED',
    FAILED: 'FAILED',
};

export const SURVEY_INITIAL_SECTION_INDEX = -999;
export const SECTION_END_SECTION_ID = -1;
export const QUESTION_CHOICE_NEUTRAL_NEXT_SECTION_ID = -2;
export const QUESTION_CHOICE_NO_NEXT_SECTION_ID = -1;

export const SURVEY_INITIAL_SECTION = {
    index: SURVEY_INITIAL_SECTION_INDEX,
    questionsCreated: true,
    questionsOrder: [],
    questionsPosition: [],
    questionsCount: 0,
    cumulativeQuestionsCount: 0,
    currentQuestionId: SURVEY_SECTION_INITIAL_QUESTION_ID,
    loop: {
        isLoop: false,
        sourceQuestionId: -1,
        sourceValue: 0,
        count: 1,
        currentLoopIndex: 0,
    },
    nextSection: 0,
    hasCriteria: false,
    previousSection: -1,
};

export const QUESTION_TYPES = {
    INFORMATION: 'information',
    SINGLE_CHOICE: 'single_choice',
    MULTIPLE_CHOICE: 'multiple_choice',
    FREE_FORM_TEXT: 'free_form_text',
    NUMBER: 'number',
    MASS: 'mass',
    LENGTH: 'length',
    VAS: 'vas',
    CALENDAR: 'calendar',
    IMAGE: 'image',
    VIDEO: 'video',
    AUDIO: 'audio',
    AUDIO_TEXT: 'audio_text',
    BARCODE: 'barcode',
};

export const AUDIO_TEXT_INPUT_TYPES = {
    AUDIO: 'audio',
    TEXT: 'text',
};

export const CHOICES_LAYOUT_TYPES = {
    RADIO_BUTTON: 'radio_button',
    CHECKBOX: 'checkbox',
    BUTTON: 'button',
    DROPDOWN: 'dropdown',
};

export const CHOICE_TYPES = {
    TEXT: 'text',
    URL: 'url',
};

export const CALENDAR_SELECTOR_TYPES = {
    DATE_TIME: 'date_time',
    DATE: 'date',
    TIME: 'time',
};

export const CALENDAR_SELECTOR_TYPES_FORMAT = {
    [CALENDAR_SELECTOR_TYPES.DATE_TIME]: appDateTime.FORMATS.DATE_TIME,
    [CALENDAR_SELECTOR_TYPES.DATE]: appDateTime.FORMATS.DATE,
    [CALENDAR_SELECTOR_TYPES.TIME]: appDateTime.FORMATS.TIME,
};

export const LENGTH_UNITS = {
    CM: 'cm',
    FT_IN: 'ft_in',
};

export const MASS_UNITS = {
    KG: 'kg',
    LBS: 'lbs',
};

export const TRIGGERING_LOGIC_TYPE_IDS = {
    UNKNOWN: -1,
    RESEARCHER: 0,
    ELIGIBILITY: 1,
    TIME: 2,
    PROXIMITY: 3,
    USER: 4,
    DROPOUT: 5,
    TIME_USER_DEF: 6,
    PUBLIC: 9,
};

export const SESSION_STATUSES_IDS = {
    UNKNOWN: 0,
    SUBMITTED: 1,
    CANCELED: 2,
    EXPIRED: 3,
    BLOCKED: 4,
    INVALID_CRITERIA: 5,
    IN_PROGRESS: 6,
};

export const TIME_USE_QUESTION_IDS = {
    PERIOD: 1,
    WHAT: 2,
    WHERE: 3,
    WHO: 4,
};

export const TIME_USE_DIARY_START_TIME_UPPER_BOUND = 2;

export const MAX_LOCATION_ACCURACY = 65000;
