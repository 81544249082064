import { Grid, Typography } from '@material-ui/core';

import Frame from './frame';
import Icon from './icon';
import IconText from './icon-text';
import Image from './image';
import Text from './text';

const componentsByType = {
    icon: Icon,
    image: Image,
    'icon-text': IconText,
    text: Text,
};

interface Props {
    title?: string;
    size?: 'small' | 'medium';
    iconSize?: 'small' | 'medium' | 'large';
    type?: 'icon' | 'text' | 'icon-text' | 'image';
}

const RoundFrame = ({ type = 'icon', title, size = 'medium', ...restProps }: Props) => {
    const VariantComponent = componentsByType[type];

    const frame = (
        <Frame size={size}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error */}
            <VariantComponent size={size} {...restProps} />
        </Frame>
    );

    const frameWithTitle = title && (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="subtitle2" align="center">
                    {title}
                </Typography>
            </Grid>
            <Grid item>{frame}</Grid>
            {/* mock spacing to centralize the children above */}
            <Grid item style={{ height: 36 }} />
        </Grid>
    );

    return frameWithTitle || frame;
};

export default RoundFrame;
