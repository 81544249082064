import React, { Component, useEffect, useState } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { useStore } from '@edf-pkg/app-main/src/store/app.zustand';
import utils from '@edf-pkg/app-utils';

import FullLoadingIndicator from '$app-web/components/full-loading-indicator';

// HOC to inject Zustand store to class components
// This would be removed later when we refactor these HOC class components, for now we're deprecating some Redux Sagas while keeping some other ones (gradual refactoring). This below wrapper component allows us to make the new Zustand store work with the Old Saga codes (Since we still haven't refactored all the Sagas)
const withZustandStore = (WrappedComponent) => {
    const Wrapper = (props) => {
        const { initializeApp, isLoading, isError } = useStore();

        const [unsubscribe, setUnsubscribe] = useState(() => () => {});

        useEffect(() => {
            const unsubscribeFunction = initializeApp();
            setUnsubscribe(() => unsubscribeFunction);

            return () => {
                if (typeof unsubscribe === 'function') {
                    unsubscribe();
                }
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const additionalProps = { ...props, isLoading, isError };

        return <WrappedComponent {...additionalProps} />;
    };

    return Wrapper;
};

const AsSpaceHOC =
    (showSplash = true) =>
    (WrappedComponent) => {
        class AsSpace extends Component {
            static WrappedComponent = WrappedComponent;

            render() {
                const { isLoading, isError } = this.props;
                const isAppStoreInitializedSuccessfully = !isLoading && !isError;

                return (
                    <>
                        {showSplash && isLoading && <FullLoadingIndicator />}
                        {!isLoading && isError && <FullLoadingIndicator hasError />}
                        {isAppStoreInitializedSuccessfully && <WrappedComponent />}
                    </>
                );
            }
        }

        AsSpace.displayName = `AsSpace(${utils.component.getDisplayName(WrappedComponent)})`;
        AsSpace.propTypes = {
            isLoading: PropTypes.bool.isRequired,
            isError: PropTypes.bool.isRequired,
        };

        return withRouter(hoistNonReactStatics(withZustandStore(AsSpace), WrappedComponent));
    };

export default AsSpaceHOC;
