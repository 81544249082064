/* eslint-disable */
// Generated from criteria.g4 by ANTLR 4.8
var antlr4 = require('antlr4/index');

var serializedATN = [
    '\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964',
    '\u0002\r\u00a9\b\u0001\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004',
    '\u0004\t\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t',
    '\u0007\u0004\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004\u000b\t\u000b\u0004',
    '\f\t\f\u0004\r\t\r\u0004\u000e\t\u000e\u0004\u000f\t\u000f\u0003\u0002',
    '\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0002',
    '\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0002',
    '\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0003\u0003\u0003\u0003\u0003',
    '\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003',
    '\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003',
    '\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003',
    '\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003',
    '\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003',
    '\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003',
    '\u0005\u0003W\n\u0003\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004',
    '\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004\u0005\u0004a\n\u0004',
    '\u0003\u0005\u0003\u0005\u0006\u0005e\n\u0005\r\u0005\u000e\u0005f\u0003',
    '\u0005\u0003\u0005\u0006\u0005k\n\u0005\r\u0005\u000e\u0005l\u0005\u0005',
    'o\n\u0005\u0003\u0006\u0005\u0006r\n\u0006\u0003\u0006\u0006\u0006u',
    '\n\u0006\r\u0006\u000e\u0006v\u0003\u0007\u0005\u0007z\n\u0007\u0003',
    '\u0007\u0006\u0007}\n\u0007\r\u0007\u000e\u0007~\u0003\u0007\u0003\u0007',
    '\u0006\u0007\u0083\n\u0007\r\u0007\u000e\u0007\u0084\u0003\b\u0003\b',
    '\u0003\t\u0003\t\u0003\t\u0003\t\u0003\n\u0003\n\u0003\n\u0003\n\u0003',
    '\u000b\u0003\u000b\u0003\u000b\u0003\f\u0003\f\u0003\f\u0003\f\u0003',
    '\f\u0003\f\u0003\f\u0003\f\u0003\f\u0005\f\u009d\n\f\u0003\r\u0003\r',
    '\u0003\u000e\u0003\u000e\u0003\u000f\u0006\u000f\u00a4\n\u000f\r\u000f',
    '\u000e\u000f\u00a5\u0003\u000f\u0003\u000f\u0002\u0002\u0010\u0003\u0003',
    '\u0005\u0002\u0007\u0002\t\u0004\u000b\u0005\r\u0006\u000f\u0002\u0011',
    '\u0007\u0013\b\u0015\t\u0017\n\u0019\u000b\u001b\f\u001d\r\u0003\u0002',
    '\u0005\u0003\u00022;\u0004\u0002>>@@\u0005\u0002\u000b\f\u000f\u000f',
    '""\u0002\u00b9\u0002\u0003\u0003\u0002\u0002\u0002\u0002\t\u0003\u0002',
    '\u0002\u0002\u0002\u000b\u0003\u0002\u0002\u0002\u0002\r\u0003\u0002',
    '\u0002\u0002\u0002\u0011\u0003\u0002\u0002\u0002\u0002\u0013\u0003\u0002',
    '\u0002\u0002\u0002\u0015\u0003\u0002\u0002\u0002\u0002\u0017\u0003\u0002',
    '\u0002\u0002\u0002\u0019\u0003\u0002\u0002\u0002\u0002\u001b\u0003\u0002',
    '\u0002\u0002\u0002\u001d\u0003\u0002\u0002\u0002\u0003\u001f\u0003\u0002',
    '\u0002\u0002\u0005V\u0003\u0002\u0002\u0002\u0007`\u0003\u0002\u0002',
    '\u0002\tb\u0003\u0002\u0002\u0002\u000bq\u0003\u0002\u0002\u0002\ry',
    '\u0003\u0002\u0002\u0002\u000f\u0086\u0003\u0002\u0002\u0002\u0011\u0088',
    '\u0003\u0002\u0002\u0002\u0013\u008c\u0003\u0002\u0002\u0002\u0015\u0090',
    '\u0003\u0002\u0002\u0002\u0017\u009c\u0003\u0002\u0002\u0002\u0019\u009e',
    '\u0003\u0002\u0002\u0002\u001b\u00a0\u0003\u0002\u0002\u0002\u001d\u00a3',
    '\u0003\u0002\u0002\u0002\u001f \u0007a\u0002\u0002 !\u0005\u0005\u0003',
    '\u0002!"\u0007a\u0002\u0002"#\u0007u\u0002\u0002#$\u0007k\u0002\u0002',
    "$%\u0007p\u0002\u0002%&\u0007e\u0002\u0002&'\u0007g\u0002\u0002'(",
    '\u0007a\u0002\u0002()\u0007t\u0002\u0002)*\u0007g\u0002\u0002*+\u0007',
    'i\u0002\u0002+,\u0007a\u0002\u0002,-\u0003\u0002\u0002\u0002-.\u0005',
    '\u0007\u0004\u0002.\u0004\u0003\u0002\u0002\u0002/0\u0007u\u0002\u0002',
    '01\u0007g\u0002\u000212\u0007e\u0002\u000223\u0007q\u0002\u000234\u0007',
    'p\u0002\u000245\u0007f\u0002\u00025W\u0007u\u0002\u000267\u0007o\u0002',
    '\u000278\u0007k\u0002\u000289\u0007p\u0002\u00029:\u0007w\u0002\u0002',
    ':;\u0007v\u0002\u0002;<\u0007g\u0002\u0002<W\u0007u\u0002\u0002=>\u0007',
    'j\u0002\u0002>?\u0007q\u0002\u0002?@\u0007w\u0002\u0002@A\u0007t\u0002',
    '\u0002AW\u0007u\u0002\u0002BC\u0007f\u0002\u0002CD\u0007c\u0002\u0002',
    'DE\u0007{\u0002\u0002EW\u0007u\u0002\u0002FG\u0007y\u0002\u0002GH\u0007',
    'g\u0002\u0002HI\u0007g\u0002\u0002IJ\u0007m\u0002\u0002JW\u0007u\u0002',
    '\u0002KL\u0007o\u0002\u0002LM\u0007q\u0002\u0002MN\u0007p\u0002\u0002',
    'NO\u0007v\u0002\u0002OP\u0007j\u0002\u0002PW\u0007u\u0002\u0002QR\u0007',
    '{\u0002\u0002RS\u0007g\u0002\u0002ST\u0007c\u0002\u0002TU\u0007t\u0002',
    '\u0002UW\u0007u\u0002\u0002V/\u0003\u0002\u0002\u0002V6\u0003\u0002',
    '\u0002\u0002V=\u0003\u0002\u0002\u0002VB\u0003\u0002\u0002\u0002VF\u0003',
    '\u0002\u0002\u0002VK\u0003\u0002\u0002\u0002VQ\u0003\u0002\u0002\u0002',
    'W\u0006\u0003\u0002\u0002\u0002XY\u0007f\u0002\u0002YZ\u0007c\u0002',
    '\u0002Z[\u0007v\u0002\u0002[a\u0007g\u0002\u0002\\]\u0007v\u0002\u0002',
    ']^\u0007k\u0002\u0002^_\u0007o\u0002\u0002_a\u0007g\u0002\u0002`X\u0003',
    '\u0002\u0002\u0002`\\\u0003\u0002\u0002\u0002a\b\u0003\u0002\u0002\u0002',
    'bd\u0007S\u0002\u0002ce\u0005\u000f\b\u0002dc\u0003\u0002\u0002\u0002',
    'ef\u0003\u0002\u0002\u0002fd\u0003\u0002\u0002\u0002fg\u0003\u0002\u0002',
    '\u0002gn\u0003\u0002\u0002\u0002hj\u0007a\u0002\u0002ik\u0005\u000f',
    '\b\u0002ji\u0003\u0002\u0002\u0002kl\u0003\u0002\u0002\u0002lj\u0003',
    '\u0002\u0002\u0002lm\u0003\u0002\u0002\u0002mo\u0003\u0002\u0002\u0002',
    'nh\u0003\u0002\u0002\u0002no\u0003\u0002\u0002\u0002o\n\u0003\u0002',
    '\u0002\u0002pr\u0007/\u0002\u0002qp\u0003\u0002\u0002\u0002qr\u0003',
    '\u0002\u0002\u0002rt\u0003\u0002\u0002\u0002su\u0005\u000f\b\u0002t',
    's\u0003\u0002\u0002\u0002uv\u0003\u0002\u0002\u0002vt\u0003\u0002\u0002',
    '\u0002vw\u0003\u0002\u0002\u0002w\f\u0003\u0002\u0002\u0002xz\u0007',
    '/\u0002\u0002yx\u0003\u0002\u0002\u0002yz\u0003\u0002\u0002\u0002z|',
    '\u0003\u0002\u0002\u0002{}\u0005\u000f\b\u0002|{\u0003\u0002\u0002\u0002',
    '}~\u0003\u0002\u0002\u0002~|\u0003\u0002\u0002\u0002~\u007f\u0003\u0002',
    '\u0002\u0002\u007f\u0080\u0003\u0002\u0002\u0002\u0080\u0082\u00070',
    '\u0002\u0002\u0081\u0083\u0005\u000f\b\u0002\u0082\u0081\u0003\u0002',
    '\u0002\u0002\u0083\u0084\u0003\u0002\u0002\u0002\u0084\u0082\u0003\u0002',
    '\u0002\u0002\u0084\u0085\u0003\u0002\u0002\u0002\u0085\u000e\u0003\u0002',
    '\u0002\u0002\u0086\u0087\t\u0002\u0002\u0002\u0087\u0010\u0003\u0002',
    '\u0002\u0002\u0088\u0089\u0007P\u0002\u0002\u0089\u008a\u0007Q\u0002',
    '\u0002\u008a\u008b\u0007V\u0002\u0002\u008b\u0012\u0003\u0002\u0002',
    '\u0002\u008c\u008d\u0007C\u0002\u0002\u008d\u008e\u0007P\u0002\u0002',
    '\u008e\u008f\u0007F\u0002\u0002\u008f\u0014\u0003\u0002\u0002\u0002',
    '\u0090\u0091\u0007Q\u0002\u0002\u0091\u0092\u0007T\u0002\u0002\u0092',
    '\u0016\u0003\u0002\u0002\u0002\u0093\u009d\t\u0003\u0002\u0002\u0094',
    '\u0095\u0007>\u0002\u0002\u0095\u009d\u0007?\u0002\u0002\u0096\u0097',
    '\u0007@\u0002\u0002\u0097\u009d\u0007?\u0002\u0002\u0098\u0099\u0007',
    '?\u0002\u0002\u0099\u009d\u0007?\u0002\u0002\u009a\u009b\u0007#\u0002',
    '\u0002\u009b\u009d\u0007?\u0002\u0002\u009c\u0093\u0003\u0002\u0002',
    '\u0002\u009c\u0094\u0003\u0002\u0002\u0002\u009c\u0096\u0003\u0002\u0002',
    '\u0002\u009c\u0098\u0003\u0002\u0002\u0002\u009c\u009a\u0003\u0002\u0002',
    '\u0002\u009d\u0018\u0003\u0002\u0002\u0002\u009e\u009f\u0007*\u0002',
    '\u0002\u009f\u001a\u0003\u0002\u0002\u0002\u00a0\u00a1\u0007+\u0002',
    '\u0002\u00a1\u001c\u0003\u0002\u0002\u0002\u00a2\u00a4\t\u0004\u0002',
    '\u0002\u00a3\u00a2\u0003\u0002\u0002\u0002\u00a4\u00a5\u0003\u0002\u0002',
    '\u0002\u00a5\u00a3\u0003\u0002\u0002\u0002\u00a5\u00a6\u0003\u0002\u0002',
    '\u0002\u00a6\u00a7\u0003\u0002\u0002\u0002\u00a7\u00a8\b\u000f\u0002',
    '\u0002\u00a8\u001e\u0003\u0002\u0002\u0002\u000f\u0002V`flnqvy~\u0084',
    '\u009c\u00a5\u0003\b\u0002\u0002',
].join('');

var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map(function (ds, index) {
    return new antlr4.dfa.DFA(ds, index);
});

function criteriaLexer(input) {
    antlr4.Lexer.call(this, input);
    this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache());
    return this;
}

criteriaLexer.prototype = Object.create(antlr4.Lexer.prototype);
criteriaLexer.prototype.constructor = criteriaLexer;

Object.defineProperty(criteriaLexer.prototype, 'atn', {
    get: function () {
        return atn;
    },
});

criteriaLexer.EOF = antlr4.Token.EOF;
criteriaLexer.REG_DT = 1;
criteriaLexer.QID = 2;
criteriaLexer.INT = 3;
criteriaLexer.DOUBLE = 4;
criteriaLexer.NOT = 5;
criteriaLexer.AND = 6;
criteriaLexer.OR = 7;
criteriaLexer.COMPARATOR = 8;
criteriaLexer.LPAREN = 9;
criteriaLexer.RPAREN = 10;
criteriaLexer.WS = 11;

criteriaLexer.prototype.channelNames = ['DEFAULT_TOKEN_CHANNEL', 'HIDDEN'];

criteriaLexer.prototype.modeNames = ['DEFAULT_MODE'];

criteriaLexer.prototype.literalNames = [null, null, null, null, null, "'NOT'", "'AND'", "'OR'", null, "'('", "')'"];

criteriaLexer.prototype.symbolicNames = [
    null,
    'REG_DT',
    'QID',
    'INT',
    'DOUBLE',
    'NOT',
    'AND',
    'OR',
    'COMPARATOR',
    'LPAREN',
    'RPAREN',
    'WS',
];

criteriaLexer.prototype.ruleNames = [
    'REG_DT',
    'UNIT',
    'BASE',
    'QID',
    'INT',
    'DOUBLE',
    'DIGIT',
    'NOT',
    'AND',
    'OR',
    'COMPARATOR',
    'LPAREN',
    'RPAREN',
    'WS',
];

criteriaLexer.prototype.grammarFileName = 'criteria.g4';

exports.criteriaLexer = criteriaLexer;
