import { memo, ReactNode } from 'react';
import clsx from 'clsx';
import type { ButtonProps } from '@material-ui/core';
import { Grid } from '@material-ui/core';

import Button from '$app-web/activities/survey/components/button';
import RoundFrame from '$app-web/activities/survey/components/round-frame';

import Period from './period';
import classes from './style.module.scss';

interface Props {
    roundFrameProps?: Record<string, unknown>;
    periodProps?: { variant: 'date-time' | 'date' | 'time' } & Record<string, unknown>;
    mainSlot?: ReactNode;
    secondSlot?: ReactNode;
    mainSlotButtonProps?: ButtonProps;
    secondSlotButtonProps?: ButtonProps;
}

const RoundFrameBase = ({
    roundFrameProps,
    periodProps,
    mainSlot,
    secondSlot,
    mainSlotButtonProps,
    secondSlotButtonProps,
}: Props) => {
    return (
        <Grid container spacing={2} direction="column" alignItems="center">
            {roundFrameProps && (
                <Grid className={classes.roundFrameWrapper} item>
                    <RoundFrame {...roundFrameProps} />
                </Grid>
            )}
            {periodProps && (
                <Grid className={classes.roundFrameWrapper} item>
                    <Period {...periodProps} />
                </Grid>
            )}
            {mainSlot && <Grid item>{mainSlot}</Grid>}
            {!mainSlot && mainSlotButtonProps && (
                <Grid item>
                    <Button
                        data-testid="mainSlotButton"
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        size="small"
                        round
                        {...mainSlotButtonProps}
                    />
                </Grid>
            )}
            {secondSlot && <Grid item>{secondSlot}</Grid>}
            {!secondSlot && secondSlotButtonProps && (
                <Grid item>
                    <Button
                        data-testid="secondSlotButton"
                        className={clsx(classes.button, classes.secondSlotButton)}
                        color="primary"
                        {...secondSlotButtonProps}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default memo(RoundFrameBase);
