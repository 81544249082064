import { fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        width: 80,
        height: 80,
        maxWidth: 80,
        maxHeight: 80,
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
            '& $button': {
                '&:not(:disabled)': {
                    bottom: 0,
                },
            },
        },
    },
    button: {
        position: 'absolute',
        width: '100%',
        bottom: -20,
        transition: 'bottom 0.3s ease-out',
        backgroundColor: fade(theme.palette.background.default, 0.5),
        fontSize: theme.typography.pxToRem(10),
        color: theme.palette.primary.dark,
        fontWeight: 600,
        padding: theme.spacing(0.55, 0),
        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    },
}));
