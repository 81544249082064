import { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { utils as appClientUtils } from '@edf-pkg/app-client';
import { store } from '@edf-pkg/app-main';
import utils from '@edf-pkg/app-utils';

import Loading from '$app-web/components/loading';
import AppErrorBoundary from '$app-web/containers/app-error-boundary';

const { userDuck } = store;

const AsModuleHOC = (shouldRender, name, moduleDidMount, moduleWillUnmount) => (WrappedComponent) => {
    // eslint-disable-next-line react/prefer-stateless-function
    class AsModule extends Component {
        static WrappedComponent = WrappedComponent;

        componentDidMount() {
            if (typeof moduleDidMount === 'function') {
                moduleDidMount();
            }
        }

        componentWillUnmount() {
            if (typeof moduleWillUnmount === 'function') {
                moduleWillUnmount();
            }
        }

        render() {
            const { role, history, location, match, userInitializationStatusSucceeded, userInitializationStatusStarted } =
                this.props;

            const user = {
                isAuthenticated: appClientUtils.auth.isUserAuthenticated(),
                role,
                initializationStatusSucceeded: userInitializationStatusSucceeded,
                initializationStatusStarted: userInitializationStatusStarted,
            };

            const router = {
                history,
                location,
                match,
            };

            return (
                <AppErrorBoundary name={`app-web.module.${name}`}>
                    <Loading loading={userInitializationStatusStarted} />
                    {shouldRender({ user, router }) ? <WrappedComponent {...{ user, router }} /> : null}
                </AppErrorBoundary>
            );
        }
    }

    AsModule.displayName = `AsModule(${utils.component.getDisplayName(WrappedComponent)})`;

    AsModule.propTypes = {
        role: PropTypes.string.isRequired,
        history: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.number, PropTypes.object]))
            .isRequired,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        match: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool])).isRequired,
        userInitializationStatusSucceeded: PropTypes.bool.isRequired,
        userInitializationStatusStarted: PropTypes.bool.isRequired,
    };

    const mapStateToProps = (state) => {
        return {
            role: userDuck.duckSelectors.roleSelector(state),
            userInitializationStatusSucceeded: userDuck.duckSelectors.initializationStatusSucceededSelector(state),
            userInitializationStatusStarted: userDuck.duckSelectors.initializationStatusStartedSelector(state),
        };
    };

    return withRouter(connect(mapStateToProps)(hoistNonReactStatics(AsModule, WrappedComponent)));
};

export default AsModuleHOC;
