export const REGISTRATION_TIME_FILTERS = Object.freeze({
    SECONDS_SINCE_REG_DATE: '_seconds_since_reg_date',
    SECONDS_SINCE_REG_TIME: '_seconds_since_reg_time',
    MINUTES_SINCE_REG_DATE: '_minutes_since_reg_date',
    MINUTES_SINCE_REG_TIME: '_minutes_since_reg_time',
    HOURS_SINCE_REG_DATE: '_hours_since_reg_date',
    HOURS_SINCE_REG_TIME: '_hours_since_reg_time',
    DAYS_SINCE_REG_DATE: '_days_since_reg_date',
    DAYS_SINCE_REG_TIME: '_days_since_reg_time',
    WEEKS_SINCE_REG_DATE: '_weeks_since_reg_date',
    WEEKS_SINCE_REG_TIME: '_weeks_since_reg_time',
    MONTHS_SINCE_REG_DATE: '_months_since_reg_date',
    MONTHS_SINCE_REG_TIME: '_months_since_reg_time',
    YEARS_SINCE_REG_DATE: '_years_since_reg_date',
    YEARS_SINCE_REG_TIME: '_years_since_reg_time',
});

export const CRITERIA_OPERAND_TYPES = Object.freeze({
    LAST_RESPONSE: 'last_response',
    NUMBER: 'number',
    REGISTRATION_DATE_TIME: 'registration_date_time',
});

export const CRITERIA_LOGICAL_OPERATORS = Object.freeze({
    AND: 'AND',
    OR: 'OR',
});

export const CRITERIA_COMPARISON_OPERATORS = Object.freeze({
    EQUAL_TO: '==',
    NOT_EQUAL_TO: '!=',
    GREATER_THAN: '>',
    GREATER_THAN_OR_EQUAL_TO: '>=',
    LESS_THAN: '<',
    LESS_THAN_OR_EQUAL_TO: '<=',
});

export const CRITERIA_DATE_AND_TIME_FILTERS = Object.freeze({
    SECONDS_SINCE_REG_DATE: '_seconds_since_reg_date',
    SECONDS_SINCE_REG_TIME: '_seconds_since_reg_time',
    MINUTES_SINCE_REG_DATE: '_minutes_since_reg_date',
    MINUTES_SINCE_REG_TIME: '_minutes_since_reg_time',
    HOURS_SINCE_REG_DATE: '_hours_since_reg_date',
    HOURS_SINCE_REG_TIME: '_hours_since_reg_time',
    DAYS_SINCE_REG_DATE: '_days_since_reg_date',
    DAYS_SINCE_REG_TIME: '_days_since_reg_time',
    WEEKS_SINCE_REG_DATE: '_weeks_since_reg_date',
    WEEKS_SINCE_REG_TIME: '_weeks_since_reg_time',
    MONTHS_SINCE_REG_DATE: '_months_since_reg_date',
    MONTHS_SINCE_REG_TIME: '_months_since_reg_time',
    YEARS_SINCE_REG_DATE: '_years_since_reg_date',
    YEARS_SINCE_REG_TIME: '_years_since_reg_time',
});

export const UNSUPPORTED_EDITOR_STATES = Object.freeze({
    INVALID: 'invalid',
    UNSUPPORTED: 'unsupported',
    MAX_LEVEL: 'max_level',
});

export const CRITERIA_TYPES = Object.freeze({
    NOTIFICATION_TEMPLATE: 'notification_template',
    DISPLAY: 'display',
    ELIGIBILITY: 'eligibility',
});
