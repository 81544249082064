import dayjs from 'dayjs';
import caES from 'dayjs/locale/ca';
import deDe from 'dayjs/locale/de';
// en-ca is identical to en-us. But the exported dayjs/locale/en cannot be used for dayjs.locale('en-US', enUS); call.
import enUS from 'dayjs/locale/en-ca';
import esES from 'dayjs/locale/es';
import esUS from 'dayjs/locale/es-us';
import frCA from 'dayjs/locale/fr-ca';
import itIT from 'dayjs/locale/it';
import nlNL from 'dayjs/locale/nl';
import slSI from 'dayjs/locale/sl';
import urPK from 'dayjs/locale/ur';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { FatalError } from '@edf-pkg/app-error';

import faIR from './locales/fa-ir.locale';
import zuZU from './locales/zu-zu.locale';

let initialized = false;

export function initialize() {
    if (initialized) {
        return;
    }
    dayjs.extend(LocalizedFormat);
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(customParseFormat);
    dayjs.extend(relativeTime);
    dayjs.extend(advancedFormat);
    dayjs.locale('de-DE', deDe);
    dayjs.locale('es-ES', esES);
    dayjs.locale('ca-ES', caES);
    dayjs.locale('sl-SI', slSI);
    dayjs.locale('es-US', esUS);
    dayjs.locale('es-CO', esUS);
    dayjs.locale('fa-IR', faIR);
    dayjs.locale('fr-CA', frCA);
    dayjs.locale('it-IT', itIT);
    dayjs.locale('nl-NL', nlNL);
    dayjs.locale('ur-PK', urPK);
    dayjs.locale('en-US', enUS);
    dayjs.locale('zu-ZU', zuZU);
    initialized = true;
}

export function changeLocale(code) {
    if (!initialized) {
        throw new FatalError('You should initialize dateTime.core before calling changeLocale.');
    }
    dayjs.locale(code);
}
