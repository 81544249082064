import client from '$app-web/core/client';
import { KeysToSnakeCase, Survey } from '$app-web/types';
import { SuperObject } from '$app-web/utils';

interface Params {
    surveyId: number;
    versionNumber: number;
    shouldCall?: boolean;
    onSuccess?: (response: Survey) => void;
    onError?: (error: Error) => void;
}

interface ReturnValue {
    data: Survey;
    isLoading: boolean;
    isFetching: boolean;
    isSuccess: boolean;
    isIdle: boolean;
    isError: boolean;
    refetch: () => void;
}

export const useFetchSurveyContent = ({ surveyId, versionNumber, shouldCall, onSuccess, onError }: Params) => {
    const result = client.registerEndpoint(
        'GET api/read-survey-content',
        `v1/survey/version/${versionNumber}/`,
        {}
    )(
        { activityId: `${surveyId}` },
        {
            enabled: shouldCall,
            onError: (error) => {
                onError?.(error as Error);
            },
            onSuccess: (response) => {
                const normalizedResponse = SuperObject.toCamelCase(response as KeysToSnakeCase<Survey>) as Survey;
                onSuccess?.(normalizedResponse);
            },
        }
    ) as ReturnValue;

    return result;
};
