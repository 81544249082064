import { LinearProgress, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        padding: theme.spacing(1),
        width: '100%',
    },
    loading: {
        maxWidth: '70%',
    },
    button: {
        margin: theme.spacing(3, 0),
        // please don't change width of button, causes overflow
    },
}));

const loadingIndicator = (theme) => ({
    root: {
        height: theme.spacing(0.5),
        borderRadius: 5,
        width: 80,
        display: 'inline-block',
    },
    indeterminate: {
        backgroundColor: theme.palette.logo.blue.main,
    },
    bar1Indeterminate: {
        backgroundColor: theme.palette.logo.yellow.main,
    },
    bar2Indeterminate: {
        backgroundColor: theme.palette.logo.green.main,
    },
});

export const LoadingProgress = withStyles(loadingIndicator)(LinearProgress);
