import { ChangeEvent, memo, useCallback, useState } from 'react';
import { Grid, Input } from '@material-ui/core';

import Button from '$app-web/activities/survey/components/button';
import { useTranslator } from '$app-web/hooks/use-translator';
import { SuperNumber } from '$app-web/utils';

import classes from './styles.module.scss';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

interface Props {
    value: number | null;
    onChange: (value: number | null) => void;
    min?: number;
    max?: number;
    step?: number;
    defaultValue?: number;
    unit?: string;
}

const TypeNumber = ({
    min = Number.MIN_VALUE,
    max = Number.MAX_VALUE,
    step = 1,
    value = null,
    onChange,
    defaultValue = Math.max(0, min),
    unit = '',
}: Props) => {
    const t = useTranslator();
    const [inputText, setInputText] = useState(value == null ? '' : value.toString());
    const [error, setError] = useState<'min' | 'max' | null>(null);

    const handleError = useCallback(
        (enteredValue: number | null) => {
            if (enteredValue !== null && enteredValue < min) {
                setError('min');
            } else if (enteredValue !== null && enteredValue > max) {
                setError('max');
            } else {
                setError(null);
            }
        },
        [min, max]
    );

    const handleIncreaseOrDecrease = useCallback(
        (value: number) => {
            const roundedResult = SuperNumber.round(value, 2);
            setInputText(roundedResult.toString());
            onChange(roundedResult);
            handleError(roundedResult);
        },
        [handleError, onChange]
    );

    const handleIncrease = useCallback(() => {
        handleIncreaseOrDecrease((value ?? defaultValue) + step);
    }, [value, defaultValue, step, handleIncreaseOrDecrease]);

    const handleDecrease = useCallback(() => {
        handleIncreaseOrDecrease((value ?? defaultValue) - step);
    }, [value, defaultValue, step, handleIncreaseOrDecrease]);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const inputValue = event.currentTarget.value
                .replace(/[^0-9.-]/g, '')
                .replaceAll('..', '.')
                .replaceAll('--', '-')
                .replaceAll('-.', '-')
                .replaceAll('.-', '.')
                .replace('-', min >= 0 ? '' : '-');

            const startsWithDot = inputValue.startsWith('.');
            const endsWithDot = inputValue.endsWith('.');

            const numericValue = Number(inputValue);
            const isNumericValueValid = !Number.isNaN(numericValue);

            if (inputValue === '' || inputValue === '-' || inputValue === '.') {
                setInputText(inputValue);
                onChange(null);
                setError(null);
            } else if (startsWithDot) {
                if (isNumericValueValid) {
                    setInputText(numericValue.toString());
                    onChange(numericValue);
                    handleError(numericValue);
                } else {
                    setInputText(inputValue);
                }
            } else if (endsWithDot) {
                setInputText(inputValue);
            } else if (isNumericValueValid) {
                setInputText(inputValue);
                onChange(numericValue);
                handleError(numericValue);
            }
        },
        [min, onChange, handleError]
    );

    return (
        <Grid container direction="column" justify="flex-start" alignItems="center">
            <Button
                data-testid="plus"
                className={classes.button}
                variant="triangle"
                color="primary"
                onClick={handleIncrease}
                disabled={value != null && value >= max}
            >
                <FontAwesomeIcon icon={['fal', 'plus']} />
            </Button>
            <Input
                className={classes.input}
                value={inputText}
                onChange={handleChange}
                inputProps={{ className: classes.input }}
                error={error != null}
            />
            {unit === '' ? null : <h4>{unit}</h4>}
            {error && (
                <p className={classes.errorText}>
                    {error === 'max'
                        ? `${t('webActivities:value_must_be_at_most')} ${max}`
                        : `${t('webActivities:value_must_be_at_least')} ${min}`}
                </p>
            )}
            <Button
                data-testid="minus"
                className={classes.button}
                variant="triangle"
                color="primary"
                onClick={handleDecrease}
                reverse
                disabled={(value != null && value <= min) || (value == null && defaultValue === min)}
            >
                <FontAwesomeIcon icon={['fal', 'minus']} />
            </Button>
        </Grid>
    );
};

export default memo(TypeNumber);
