import Language from '../core/language';

const pdashNamespace = import('../namespaces/zh-hans.pdash.namespace');
const webActivitiesNamespace = import('../namespaces/zh-hans.web-activities.namespace');
const rdashTranslatablesNamespace = import('../namespaces/zh-hans.rdash-translatables.namespace');

class ZhHansLanguage extends Language {
    code = 'zh-Hans';

    locale = 'zh';

    localName = '汉语';

    englishName = 'Simplified Chinese';

    territory = 'Hans';

    notLoadedNamespaces = {
        pdash: pdashNamespace,
        webActivities: webActivitiesNamespace,
        rdashTranslatables: rdashTranslatablesNamespace,
    };
}

const instance = new ZhHansLanguage();
export default instance;
