import React from 'react';
import importedComponent from 'react-imported-component';

import { errorHandle, FatalError } from '@edf-pkg/app-error';

import FullLoadingIndicator from '$app-web/components/full-loading-indicator';
import LoadingBar from '$app-web/components/loading-bar';
import LoadingIndicatorError from '$app-web/components/loading-indicator-error';
import { callPromiseWithTimeout, getDelayedPromise } from '$app-web/utils/promise-timeout';

export default function lazyLoad(importer, noLoading) {
    return importedComponent(importer, {
        LoadingComponent: () => !noLoading && <FullLoadingIndicator hasError={false} />,
        ErrorComponent: () => <FullLoadingIndicator hasError />,
        onError: (error) => {
            errorHandle.anError(new FatalError('Error occurred during lazy-load component.', error));
        },
    });
}

export function lazyLoadInline(importer) {
    return importedComponent(importer, {
        LoadingComponent: () => <LoadingBar />,
        ErrorComponent: () => <LoadingIndicatorError errorMessage="Error occurred during lazy-load component." />,
        onError: (error) => {
            errorHandle.anError(new FatalError('Error occurred during lazy-load component.', error));
        },
    });
}

export function withTimeoutAndDelay(callback, timeoutTime = 60000, delayTime = 0) {
    return callPromiseWithTimeout(getDelayedPromise(callback(), delayTime), timeoutTime);
}
