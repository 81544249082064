import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';

import useStyles from './styles';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

const FontAwesomeButton = (props) => {
    const { size, disabled, onClick, ...restProps } = props;
    const classes = useStyles();
    return (
        <IconButton onClick={onClick} size="small" disabled={disabled}>
            <div className={classes.root}>
                <FontAwesomeIcon {...restProps} />
            </div>
        </IconButton>
    );
};

FontAwesomeButton.defaultProps = {
    onClick: undefined,
    size: 36,
    disabled: false,
};

FontAwesomeButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.number,
};
export default FontAwesomeButton;
