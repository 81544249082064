import produce from 'immer';
import { createSelector } from 'reselect';
import { getSelector, namespaceReducerActions } from 'retrack';

export const DUCK_NAME = 'snackbar';

const initialState = {
    snackbarsQueue: [],
    toShowSnackbar: {},
    showingSnackbarId: '',
    shouldOpen: false,
};

export const duckActions = {
    ENQUEUE: 'ENQUEUE',
    DEQUEUE: 'DEQUEUE',
    SET_TO_SHOW_SNACKBAR: 'SET_TO_SHOW_SNACKBAR',
    SET_SHOWING_SNACKBAR_ID: 'SET_SHOWING_SNACKBAR_ID',
    SET_SHOULD_OPEN: 'SET_SHOULD_OPEN',
    SHOW: 'SHOW',
    SNACKBAR_OPENED: 'SNACKBAR_OPENED',
    SNACKBAR_CLOSED: 'SNACKBAR_CLOSED',
    SNACKBAR_EXITED: 'SNACKBAR_EXITED',
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case duckActions.ENQUEUE:
            draft.snackbarsQueue.push(action.payload.snackbar);
            break;
        case duckActions.DEQUEUE:
            draft.snackbarsQueue.shift();
            break;
        case duckActions.SET_TO_SHOW_SNACKBAR:
            draft.toShowSnackbar = action.payload.snackbar;
            break;
        case duckActions.SET_SHOWING_SNACKBAR_ID:
            draft.showingSnackbarId = action.payload.snackbarId;
            break;
        case duckActions.SET_SHOULD_OPEN:
            draft.shouldOpen = action.payload.shouldOpen;
            break;
        // no default
    }
}, initialState);

namespaceReducerActions(reducer, duckActions);

export default reducer;

export const duckActionCreators = {
    enqueue: function enqueue(snackbar) {
        return { type: duckActions.ENQUEUE, payload: { snackbar } };
    },
    dequeue: function dequeue() {
        return { type: duckActions.DEQUEUE };
    },
    setToShowSnackbar: function setToShowSnackbar(snackbar) {
        return {
            type: duckActions.SET_TO_SHOW_SNACKBAR,
            payload: { snackbar },
        };
    },
    setShowingSnackbarId: function setShowingSnackbarId(snackbarId) {
        return {
            type: duckActions.SET_SHOWING_SNACKBAR_ID,
            payload: { snackbarId },
        };
    },
    setShouldOpen: function setShouldOpen(shouldOpen) {
        return { type: duckActions.SET_SHOULD_OPEN, payload: { shouldOpen } };
    },
    show: function show(snackbar) {
        return { type: duckActions.SHOW, payload: { snackbar } };
    },
    opened: function opened(snackbarId) {
        return { type: duckActions.SNACKBAR_OPENED, payload: { snackbarId } };
    },
    closed: function closed() {
        return { type: duckActions.SNACKBAR_CLOSED };
    },
    exited: function exited() {
        return { type: duckActions.SNACKBAR_EXITED };
    },
};

const snackbarSelector = getSelector(reducer);

const toShowSnackbarSelector = createSelector(snackbarSelector, (snackbarState) => snackbarState.toShowSnackbar);
const showingSnackbarIdSelector = createSelector(snackbarSelector, (snackbarState) => snackbarState.showingSnackbarId);

const shouldOpenSelector = createSelector(snackbarSelector, (snackbarState) => snackbarState.shouldOpen);

export const duckSelectors = {
    snackbarSelector,
    toShowSnackbarSelector,
    showingSnackbarIdSelector,
    shouldOpenSelector,
};
