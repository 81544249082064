import Language from '../core/language';

const pdashNamespace = import('../namespaces/it-it.pdash.namespace');
const webActivitiesNamespace = import('../namespaces/it-it.web-activities.namespace');
const rdashTranslatablesNamespace = import('../namespaces/it-it.rdash-translatables.namespace');

class ItItLanguage extends Language {
    code = 'it-IT';

    locale = 'it';

    localName = 'Italiano';

    englishName = 'Italian';

    territory = 'IT';

    timezones = ['Europe/Rome'];

    notLoadedNamespaces = {
        pdash: pdashNamespace,
        webActivities: webActivitiesNamespace,
        rdashTranslatables: rdashTranslatablesNamespace,
    };
}

const instance = new ItItLanguage();
export default instance;
