import { fullStack } from 'make-error-cause';
import * as Sentry from '@sentry/browser';

import appLogger from '@edf-pkg/app-logger';

import APIError from '../errors/api.error';
import APIMappedError from '../errors/api.mapped.error';
import APIUnAuthorizedError from '../errors/api.unauthorized.error';
import ConnectionError from '../errors/connection.error';
import EDFError from '../errors/edf.error';
import FatalError from '../errors/fatal.error';
import UnknownError from '../errors/unknown.error';
import ValidationError from '../errors/validation.error';

function anError(error) {
    const errorTypesToHandle = [FatalError, APIError, UnknownError, EDFError, ValidationError, TypeError];
    const errorTypesToExclude = [APIMappedError, APIUnAuthorizedError, ConnectionError];

    const isErrorOfInterest =
        errorTypesToHandle.some((type) => error instanceof type) && !errorTypesToExclude.some((type) => error instanceof type);

    appLogger.error(fullStack(error));

    if (isErrorOfInterest) {
        Sentry.withScope((scope) => {
            scope.setTag('catch_in', 'errorHandler');
            scope.setExtras({
                stackTrace: fullStack(error),
                name: error.constructor.name || 'externalLog',
            });
            Sentry.captureException(error);
        });
    }

    return error;
}

export { anError };
