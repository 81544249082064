import { makeStyles } from '@material-ui/styles';

function createDashBackgroundImage(color) {
    return {
        backgroundImage: [
            `linear-gradient(to right, ${color} 50%, rgba(255,255,255,0) 0%)`,
            `linear-gradient(${color} 50%, rgba(255,255,255,0) 0%)`,
            `linear-gradient(to right, ${color} 50%, rgba(255,255,255,0) 0%)`,
            `linear-gradient(${color} 50%, rgba(255,255,255,0) 0%)`,
        ],
    };
}

export default makeStyles((theme) => ({
    root: {},
    dropZoneWrapper: {
        textAlign: 'center',
        padding: theme.spacing(3.75),
        outline: 'none',
        transition: 'border .5s ease-in-out',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.dark,
        borderRadius: 4,
        backgroundPosition: ['top', 'left', 'bottom', 'right'],
        backgroundSize: ['22px 1px', '1px 22px', '22px 1px', '1px 22px'],
        backgroundRepeat: ['repeat-x', 'repeat-y', 'repeat-x', 'repeat-y'],
        ...createDashBackgroundImage(theme.palette.primary.dark),
    },
    dropZoneWrapperActive: {
        ...createDashBackgroundImage(theme.palette.info.main),
    },
    dropZoneWrapperAccept: {
        ...createDashBackgroundImage(theme.palette.success.main),
    },
    dropZoneWrapperReject: {
        ...createDashBackgroundImage(theme.palette.error.main),
    },
    fileSelectorText: {
        color: '#7c8df6',
    },
    error: {
        color: theme.palette.error.main,
        fontSize: theme.typography.pxToRem(16),
        marginTop: theme.spacing(1.25),
        textAlign: 'center',
    },
    mainText: {},
    inputBox: {},
    disabled: { userSelect: 'none', cursor: 'not-allowed' },
    acceptablePropertiesVariantButton: {
        display: 'block',
        marginTop: theme.spacing(1),
        textAlign: 'center',
    },
}));
