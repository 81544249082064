import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import LazyImage from '$app-web/components/lazy-image';
import MediaViewer from '$app-web/components/media-viewer';
import { QUESTION_VAS_ANCHOR_TYPES } from '$app-web/modules/activity-editor/constants';
import { getImageOriginalAndThumbUrl } from '$app-web/utils/image';

import ImageAnchor from './image-anchor';
import useStyles from './styles';
import TextAnchor from './text-anchor';

const Labels = ({ anchors, showSelectionLabel, labels, vertical, value, variant }) => {
    const [imageViewerOpened, setImageViewerOpened] = useState(false);

    const classes = useStyles();

    const anchorType = anchors[0].type;
    const orderedAnchors = vertical ? [...anchors].reverse() : anchors;
    const currentLabel =
        value !== null && showSelectionLabel && labels
            ? labels.map // exclude all labels with the lowerBound greater than the value
                  .filter(({ lowerBound }) => lowerBound <= value)
                  // select the label with the nearest lowerBand to the value
                  .sort((firstLabel, secondLabel) => secondLabel.lowerBound - firstLabel.lowerBound)[0]
            : undefined;

    const imagesUrl = getImageOriginalAndThumbUrl(currentLabel && currentLabel.url);

    const imageLabel = currentLabel && (labels.type === 'url' || labels.type === 'image') && imagesUrl && (
        <>
            <MediaViewer
                open={imageViewerOpened}
                onCloseClick={() => setImageViewerOpened(false)}
                url={imagesUrl.original}
                type="image"
            />
            <LazyImage
                className={classes.image}
                src={imagesUrl.thumb}
                small
                shadow={false}
                onClick={() => setImageViewerOpened(true)}
            />
        </>
    );

    const textLabel = currentLabel && labels.type === 'text' && (
        <Typography align="center" className={clsx(classes.text, { [classes.smallerText]: variant === 'secondary' })}>
            {currentLabel.text}
        </Typography>
    );

    const labelElement = imageLabel || textLabel;
    return (
        <Grid
            className={clsx({
                [classes.horizontalRootSecondary]: !vertical && variant === 'secondary',
                [classes.horizontalRoot]: !vertical && variant === 'primary',
                [classes.verticalRoot]: vertical,
            })}
            direction={vertical ? 'column' : 'row'}
            container
            wrap="nowrap"
            alignItems={variant === 'secondary' && vertical ? 'flex-start' : 'flex-end'}
            justify="space-between"
            data-vas-element="label-root"
        >
            {anchorType === QUESTION_VAS_ANCHOR_TYPES.IMAGE ? (
                <ImageAnchor url={orderedAnchors[0].url} />
            ) : (
                <TextAnchor variant={variant} text={orderedAnchors[0].text} vertical={vertical} dataTestId="left-anchor-text" />
            )}
            <Grid item data-testid="vas-label">
                {labelElement && (
                    <div
                        className={clsx({
                            [classes.secondary]: variant === 'secondary',
                        })}
                    >
                        {labelElement}
                    </div>
                )}
            </Grid>
            {anchorType === QUESTION_VAS_ANCHOR_TYPES.IMAGE ? (
                <ImageAnchor url={orderedAnchors[1].url} />
            ) : (
                <TextAnchor variant={variant} text={orderedAnchors[1].text} vertical={vertical} dataTestId="right-anchor-text" />
            )}
        </Grid>
    );
};

Labels.defaultProps = {
    value: null,
    labels: undefined,
    vertical: false,
    variant: 'primary',
};

Labels.propTypes = {
    anchors: PropTypes.arrayOf(PropTypes.object).isRequired,
    showSelectionLabel: PropTypes.bool.isRequired,
    value: PropTypes.number,
    labels: PropTypes.objectOf(PropTypes.any),
    vertical: PropTypes.bool,
    variant: PropTypes.oneOf(['primary', 'secondary']),
};

export default Labels;
