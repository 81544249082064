import { useCallback, useEffect, useMemo } from 'react';
import { groupBy } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import { dataSourceOptionsDuck } from '../../store';

const BEACON_NAME = 'beacon';
const GPS_NAME = 'gps';

function useDataSourceOptions({ enabled = true } = {}) {
    const dispatch = useDispatch();

    const dataSourceOptions = useSelector(dataSourceOptionsDuck.duckSelectors.dataSourceOptionsDataSelector);
    const isLoading = useSelector(dataSourceOptionsDuck.duckSelectors.dataSourceOptionsLoadingSelector);
    const isSucceeded = useSelector(dataSourceOptionsDuck.duckSelectors.dataSourceOptionsSucceededSelector);
    const isFailed = useSelector(dataSourceOptionsDuck.duckSelectors.dataSourceOptionsFailedSelector);

    const fetch = useCallback(() => {
        dispatch(dataSourceOptionsDuck.duckActionCreators.fetchDataSourceOptions());
    }, [dispatch]);

    useEffect(() => {
        if (enabled) {
            fetch();
        }
    }, [enabled, fetch]);

    const mappedDataSourceOptions = useMemo(
        () =>
            (dataSourceOptions || []).map((category) => ({
                ...category,
                dataSources: category.dataSources.map((dataSource) => ({
                    ...dataSource,
                    icon: ['far', dataSource.icon],
                    solidIcon: ['fas', dataSource.icon],
                    category,
                })),
            })),
        [dataSourceOptions]
    );

    const DATA_SOURCES = useMemo(
        () => mappedDataSourceOptions.flatMap((category) => category.dataSources),
        [mappedDataSourceOptions]
    );

    const DATA_SOURCES_BY_ID = useMemo(
        () => Object.fromEntries(Object.values(DATA_SOURCES).map((dataSource) => [dataSource.id, dataSource])),
        [DATA_SOURCES]
    );

    const DATA_SOURCES_BY_CATEGORY_ID = useMemo(
        () => groupBy(DATA_SOURCES, (dataSource) => dataSource.category.id),
        [DATA_SOURCES]
    );

    const BEACON_ID = useMemo(
        () => DATA_SOURCES.find((dataSource) => dataSource.name === BEACON_NAME)?.id || null,
        [DATA_SOURCES]
    );

    const GPS_ID = useMemo(() => DATA_SOURCES.find((dataSource) => dataSource.name === GPS_NAME)?.id || null, [DATA_SOURCES]);

    const SUPPORTED_DATA_SOURCE_IDS = useMemo(() => DATA_SOURCES.map((dataSource) => dataSource.id), [DATA_SOURCES]);

    return {
        BEACON_ID,
        GPS_ID,
        DATA_SOURCES,
        DATA_SOURCES_BY_CATEGORY_ID,
        DATA_SOURCES_BY_ID,
        SUPPORTED_DATA_SOURCE_IDS,
        isLoading,
        isSucceeded,
        isFailed,
        refetch: fetch,
    };
}

export default useDataSourceOptions;
