import { memo } from 'react';

import { FILE_INPUT_ACCEPTED_MIME_TYPES_AND_EXTENSIONS } from '$app-web/constants';
import { useTranslator } from '$app-web/hooks/use-translator';

import MediaBase from '../media-base';

interface Props {
    value: File | string;
    onChange: (value: File | string | null) => void;
}

const TypeImage = ({ value, onChange }: Props) => {
    const t = useTranslator();

    return (
        <MediaBase
            type="image"
            mediaViewerProps={{ type: 'image' }}
            roundFrameProps={value ? { type: 'image' } : { variant: 'camera' }}
            viewButtonText={t('webActivities:view')}
            captureButtonText={t('webActivities:survey_image_capture_button')}
            fileInputProps={{
                accept: FILE_INPUT_ACCEPTED_MIME_TYPES_AND_EXTENSIONS.IMAGE,
            }}
            value={value}
            onChange={onChange}
        />
    );
};

export default memo(TypeImage);
