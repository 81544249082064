import { Context, createContext, ReactElement, ReactNode, useContext } from 'react';

const isDevMode = process.env.NODE_ENV !== 'production';

type UseValueFn<P extends object, T> = (props: P) => T;
type SelectorFn<T, R> = (state: T) => R;

export type ProviderType = (props: { children: ReactNode } & Record<string, unknown>) => ReactElement;

const EMPTY_OBJECT = {} as const;

function createUseContext<T>(context: Context<T>) {
    return () => {
        const value = useContext(context);
        if (isDevMode && value === EMPTY_OBJECT) {
            const warnMessage = context.displayName
                ? `The context consumer of ${context.displayName} must be wrapped with its corresponding Provider`
                : 'Component must be wrapped with Provider.';
            // eslint-disable-next-line no-console
            console.warn(warnMessage);
        }
        return value;
    };
}

export function contextStore<P extends object, T>(useValue: UseValueFn<P, T>, ...selectors: Array<SelectorFn<T, unknown>>) {
    const allSelectors = selectors.length === 0 ? [useValue] : selectors;

    const contexts = allSelectors.reduce(
        (all, selector) => {
            const newContext = createContext<unknown>(EMPTY_OBJECT);
            if (isDevMode && typeof selector === 'function' && selector.name !== '') {
                newContext.displayName = selector.name;
            }
            return [...all, newContext];
        },
        [] as Array<Context<unknown>>
    );
    const hooks = contexts.map((context) => createUseContext(context));

    const StoreProvider = ({ children, ...props }: { children: ReactNode } & P) => {
        const store = useValue(props as P);

        return contexts.reduceRight((element, Context, index) => {
            const selector = (selectors[index] as SelectorFn<T, unknown>) ?? ((state: T) => state);

            return <Context.Provider value={selector(store)}>{element}</Context.Provider>;
        }, children);
    };

    return [StoreProvider, ...hooks] as [ProviderType, () => T, ...Array<() => unknown>];
}
