import React from 'react';
import clsx from 'clsx';
import { camelCase } from 'lodash-es';
import * as PropTypes from 'prop-types';

import useStyles from './styles';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

const Icon = (props) => {
    const { icon, size, variant, className, ...restProps } = props;

    const classes = useStyles();

    // we may have custom (imported) icon components which are not present on FA
    const IconComponent = Array.isArray(icon) || (icon.prefix && icon.iconName) ? FontAwesomeIcon : icon;

    return (
        <IconComponent
            className={clsx(
                classes[camelCase(size)],
                {
                    [classes[variant]]: variant !== 'default',
                    [classes[camelCase(`${size}_${variant}`)]]: variant !== 'default',
                },
                className
            )}
            {...((Array.isArray(icon) || (icon.prefix && icon.iconName)) && { icon })}
            {...restProps}
        />
    );
};

Icon.defaultProps = {
    className: undefined,
    size: 'medium',
    variant: 'default',
};

Icon.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object, PropTypes.arrayOf(PropTypes.string)]).isRequired,
    className: PropTypes.string,
    size: PropTypes.oneOf(['extra-small', 'small', 'medium', 'large', 'extra-large']),
    variant: PropTypes.oneOf(['default', 'contained']),
};

export default Icon;
