import { auth as appClientAuth, core as appClientCore, utils as appClientUtils } from '@edf-pkg/app-client';

export default async function userLogoutEndpoint() {
    try {
        const { refreshToken } = appClientAuth.useAuthenticationStore.getState();
        await appClientCore.axiosClientsManager
            .getClient('api')
            .post('/v1/auth/revoke/', appClientUtils.object.toFormData({ refresh_token: refreshToken }));
        return true;
    } catch (error) {
        appClientCore.errorMapper(error);
    }
    return null;
}
