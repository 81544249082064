import produce from 'immer';
import { createSelector } from 'reselect';
import { getSelector, namespaceReducerActions } from 'retrack';

export const DUCK_NAME = 'dashboard';

const initialState = {
    authAction: {},
};

export const duckActions = {
    RESET: 'RESET',
    SET_AUTH_ACTION: 'SET_AUTH_ACTION',
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case duckActions.RESET:
            return initialState;
        case duckActions.SET_AUTH_ACTION:
            draft.authAction = action.payload.authAction;
            break;
        // no default
    }
    return draft;
}, initialState);

namespaceReducerActions(reducer, duckActions);

export default reducer;

export const duckActionCreators = {
    reset: () => {
        return {
            type: duckActions.RESET,
        };
    },
    setAuthAction: (authAction) => {
        return {
            type: duckActions.SET_AUTH_ACTION,
            payload: { authAction },
        };
    },
};

const dashboardSelector = getSelector(reducer);

const authActionSelector = createSelector(dashboardSelector, (dashboardState) => {
    return dashboardState.authAction;
});

const hasAuthActionSelector = createSelector(authActionSelector, (authAction) => {
    return Object.keys(authAction).length > 0;
});

export const duckSelectors = {
    dashboardSelector,
    authActionSelector,
    hasAuthActionSelector,
};
