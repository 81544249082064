const styles = (theme) => ({
    root: {
        padding: theme.spacing(1, 2),
    },
    rootError: {
        color: '#fff',
        background: theme.palette.error.main,
        '&:hover': {
            background: theme.palette.error.main,
        },
    },
    rootSuccess: {
        color: '#fff',
        background: theme.palette.success.main,
        '&:hover': {
            background: theme.palette.success.main,
        },
    },
    rootInfo: {
        color: '#fff',
        background: theme.palette.info.main,
        '&:hover': {
            background: theme.palette.info.main,
        },
    },
    label: {},
});

export default styles;
