import React from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import ButtonContained from '$app-web/components/button-contained';

import useStyles, { LoadingProgress } from './styles';

const LoadingBar = ({ className, variant, errorText, showErrorText, errorButtonText, onErrorButtonClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <div className={clsx(classes.root, className)}>
            {variant === 'loading' && <LoadingProgress className={classes.loading} />}
            {(variant === 'error' || variant === 'error-with-button') && (
                <>
                    {showErrorText && (
                        <Typography variant="body2" color="error">
                            {errorText || t('generic_error_msg')}
                        </Typography>
                    )}
                    {variant === 'error-with-button' &&
                        (onErrorButtonClick ? (
                            <ButtonContained
                                text={errorButtonText}
                                color="error"
                                handleClick={onErrorButtonClick}
                                className={classes.button}
                            />
                        ) : (
                            <ButtonContained
                                text={t('refresh')}
                                color="error"
                                className={classes.button}
                                component="a"
                                // do not use "." for href,
                                // look the comments of https://stackoverflow.com/a/12108553/5117141
                                href={window.location.href}
                            />
                        ))}
                </>
            )}
        </div>
    );
};

LoadingBar.defaultProps = {
    className: undefined,
    variant: 'loading',
    errorText: undefined,
    showErrorText: true,
    errorButtonText: undefined,
    onErrorButtonClick: undefined,
};

LoadingBar.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(['loading', 'error', 'error-with-button']),
    errorText: PropTypes.string,
    errorButtonText: PropTypes.node, // should be defined when `onErrorButtonClick` specified
    onErrorButtonClick: PropTypes.func,
    showErrorText: PropTypes.bool,
};

export default LoadingBar;
