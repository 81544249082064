import { useEffect } from 'react';

function useStopPlayingAudios() {
    useEffect(() => {
        function stopAllAudios() {
            const allAudioTags = [...document.getElementsByTagName('audio')];

            if (document.visibilityState === 'hidden') {
                allAudioTags.forEach((audioTag) => {
                    audioTag.pause();
                    // eslint-disable-next-line no-param-reassign
                    audioTag.currentTime = 0;
                });
            }
        }

        document.addEventListener('visibilitychange', stopAllAudios);

        return () => {
            document.removeEventListener('visibilitychange', stopAllAudios);
        };
    }, []);
}

export default useStopPlayingAudios;
