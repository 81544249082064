import { core as appClientCore } from '@edf-pkg/app-client';

import { SuperObject } from '$app-web/utils';

interface Response {
    result: boolean;
}

interface Parameters {
    activityId: number;
    sessionUuid: string;
    expression: string;
}

export async function evaluateCriteria({ activityId, sessionUuid, expression }: Parameters) {
    try {
        const urlParams = SuperObject.toSnakeCase({ sessionUuid, activityId, exp: expression });

        const response = await appClientCore.axiosClientsManager
            .getClient('api')
            .get<Response>('/study/activity/criteria/evaluate/', { params: urlParams });

        return { result: response.data.result };
    } catch (error) {
        appClientCore.errorMapper(error as Error);
        return { result: false };
    }
}
