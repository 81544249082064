import { fade, lighten } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

const surveyTheme = (outerTheme) => {
    const theme = createMuiTheme(outerTheme, {
        palette: {
            default: {
                light: '#EFEFEF',
                main: '#707070',
                dark: '#000000',
                contrastText: '#FFFFFF',
            },
            primary: {
                light: '#F5F5F5',
                main: '#000000',
                dark: '#3C3C3C',
                contrastText: '#FFFFFF',
            },
            green: {
                light: lighten('#30D158', 0.3),
                main: '#30D158',
                dark: '#1FA841',
                contrastText: '#FFFFFF',
            },
            action: {
                disabled: 'rgba(0, 0, 0, 0.2)',
                disabledOpacity: 0.2,
            },
        },
    });

    theme.shadows.custom = {
        ...(theme.shadows.custom ? theme.shadows.custom : {}),
        1: '0 3px 16px 0 rgba(0, 0, 0, 0.16)',
        2: '0 3px 6px 0 rgba(0, 0, 0, 0.04)',
    };

    theme.overrides = {
        ...outerTheme.overrides,
        MuiPickersToolbar: {
            ...outerTheme.overrides.MuiPickersToolbar,
            toolbar: {
                backgroundColor: theme.palette.secondary.main,
            },
        },
        MuiPickersDay: {
            ...outerTheme.overrides.MuiPickersDay,
            day: {
                '&:hover': {
                    color: theme.palette.background.default,
                    backgroundColor: theme.palette.secondary.dark,
                },
            },
            daySelected: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.background.default,
                '&:hover': {
                    willChange: 'background-color',
                    backgroundColor: theme.palette.secondary.dark,
                },
            },
            current: {
                color: theme.palette.secondary.main,
            },
        },
        MuiPickersYear: {
            ...outerTheme.overrides.MuiPickersYear,
            yearSelected: {
                color: theme.palette.secondary.main,
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: fade(theme.palette.default.main, 0.9),
            },
        },
        MuiFormControlLabel: {
            root: {
                marginLeft: undefined,
                marginRight: undefined,
            },
        },
    };

    return theme;
};

export default surveyTheme;
