import { memo } from 'react';

import RoundFrame from '$app-web/activities/survey/components/round-frame';
import { useTranslator } from '$app-web/hooks/use-translator';

import Mediator from './mediator';

interface Props extends Record<string, unknown> {
    variant: 'date-time' | 'date' | 'time';
    type?: 'icon' | 'text';
    items?: Array<Record<string, unknown> | number>;
}

const Period = ({ type = 'icon', items = [0, 1, 2], variant, ...restProps }: Props) => {
    const t = useTranslator();

    const titles = [t('webActivities:from_label').toLocaleUpperCase(), t('webActivities:to_label').toLocaleUpperCase()];

    return (
        <Mediator>
            {items.map((roundFrameProps, index) => (
                <RoundFrame
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                    title={titles[index % titles.length]}
                    size="small"
                    iconSize="medium"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    variant={variant}
                    type={type}
                    {...(typeof roundFrameProps === 'object' ? roundFrameProps : {})}
                    {...restProps}
                />
            ))}
        </Mediator>
    );
};

export default memo(Period);
