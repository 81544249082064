import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { Grid } from '@material-ui/core';

import Loading from '$app-web/components/loading';

import Controller from '../controller';
import useStyles, { PlayerSlider } from './styles';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

const Player = ({ type, autoPlay, url, icon }) => {
    const classes = useStyles();
    const [isPlaying, setPlaying] = useState(autoPlay);
    const [played, setPlayed] = useState(0); // a value between 0 and 1 indicates the percent of played time.
    const [playedSecond, setPlayedSecond] = useState(0); // the elapsed time of video (in sec)
    const [duration, setDuration] = useState(0); // the total time of video (in sec)
    const [isBuffering, setBuffering] = useState(true);

    const isAudio = type === 'audio';

    const videoRef = useRef(null);
    return (
        <Grid className={classes.root} container direction="column" spacing={2} wrap="nowrap">
            <Grid className={classes.videoContainer} item xs>
                {isAudio && (
                    <span className={classes.audioIcon}>
                        {icon || <FontAwesomeIcon icon={['fad', 'headphones-alt']} size="10x" />}
                    </span>
                )}
                <Loading
                    className={clsx({
                        [classes.audioLoading]: isAudio,
                    })}
                    variant={isAudio ? 'inline' : 'parent-overlay'}
                    backdropVariant="dark"
                    loading={isBuffering}
                />
                <ReactPlayer
                    ref={videoRef}
                    url={url}
                    width="100%" // it doesn't accept `className`
                    height="100%"
                    style={{ maxWidth: '100%', maxHeight: '100%', backgroundColor: type === 'video' ? 'black' : undefined }}
                    playing={isPlaying}
                    onPlay={() => setPlaying(true)}
                    onPause={() => setPlaying(false)}
                    onEnded={() => setPlaying(false)}
                    onProgress={(state) => {
                        setPlayed(state.played);
                        setPlayedSecond(state.playedSeconds);
                    }}
                    onDuration={(d) => setDuration(d)}
                    onBuffer={() => setBuffering(true)}
                    onBufferEnd={() => setBuffering(false)}
                />
            </Grid>
            <Grid container item direction="column" spacing={1} wrap="nowrap">
                <Grid item>
                    <PlayerSlider
                        defaultValue={0}
                        min={0}
                        max={1}
                        step={0.001}
                        value={played}
                        onChange={(e, v) => setPlayed(parseFloat(v))}
                        onChangeCommitted={() => videoRef.current.seekTo(played)}
                    />
                </Grid>
                <Grid item>
                    <Controller duration={duration} isPlaying={isPlaying} playedSecond={playedSecond} setPlaying={setPlaying} />
                </Grid>
            </Grid>
        </Grid>
    );
};

Player.defaultProps = {
    autoPlay: false,
    type: 'video',
    icon: undefined,
};

Player.propTypes = {
    autoPlay: PropTypes.bool,
    url: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['audio', 'video']),
    icon: PropTypes.node,
};

export default Player;
