import queryString from 'query-string';
import URLParse from 'url-parse';

import { EDFError, FatalError } from '@edf-pkg/app-error';
import appUtils from '@edf-pkg/app-utils';

import { SuperObject } from '../object';
import { SpaceURLsManager } from './space-urls-manager';

export abstract class URLsManager {
    static readonly baseURL = URLsManager.getBaseUrl();

    static readonly filesBaseURL = process.env.REACT_APP_FILE_SERVER_BASE_URL ?? '';

    static readonly forumBaseURL = process.env.REACT_APP_FORUM_BASE_URL ?? '';

    static relativeUrls: Record<string, string> = {
        base: '',
        app: process.env.PUBLIC_URL ?? '/',
        api: '/api',
        graphql: '/graphql',
        media: '/media',
    };

    private static spaces: Record<string, SpaceURLsManager> = {};

    private static getBaseUrl() {
        if (appUtils.env.isDev()) {
            return process.env.REACT_APP_SERVER_BASE_URL || '';
        }
        if (appUtils.env.shouldLoadBaseUrlFromLocalStorage() && localStorage.getItem('baseURL')) {
            return localStorage.getItem('baseURL') || '';
        }
        const parsedURL = new URL(window.location.href);
        return parsedURL.origin;
    }

    static getEndpointURL(endpoint: string) {
        if (SuperObject.hasKey(URLsManager.relativeUrls, endpoint)) {
            return `${URLsManager.baseURL}${URLsManager.relativeUrls[endpoint]}`;
        }
        return `${URLsManager.baseURL}/${endpoint}`;
    }

    static addSpace(spaceId: string) {
        if (!SuperObject.hasKey(URLsManager.spaces, spaceId)) {
            URLsManager.spaces[spaceId] = new SpaceURLsManager(spaceId);
        } else {
            throw new FatalError(`Trying to add space which exists.`, new EDFError(`Space with Id: ${spaceId} already exists.`));
        }
    }

    static space(spaceId: string) {
        if (!SuperObject.hasKey(URLsManager.spaces, spaceId)) {
            URLsManager.addSpace(spaceId);
        }
        return URLsManager.spaces[spaceId];
    }

    static getParsedURL() {
        return new URLParse(window.location.href, true);
    }

    static to(path: string, params: Record<string, string> = {}, includeModulePath = true) {
        const splitPath = path.split(':');
        const spaceId = splitPath[0];
        let moduleId = splitPath[1];
        const pathId = splitPath[2];
        if (splitPath.length !== 3) {
            throw new FatalError(
                `Trying to get url with bad path. Path should have spaceId, moduleId and pathId. Given Path: ${path}`
            );
        }
        if (!SuperObject.hasKey(URLsManager.spaces, spaceId)) {
            throw new FatalError(`Trying to get missing space.`, new EDFError(`Space with Id: ${spaceId} is missing.`));
        }
        const space = URLsManager.spaces[spaceId];

        let subModuleId;
        if (moduleId.includes('.')) {
            [moduleId, subModuleId] = moduleId.split('.');
        }

        if (!space.hasModule(moduleId)) {
            throw new FatalError(`Trying to get missing module.`, new EDFError(`Module with Id: ${moduleId} is missing.`));
        }

        return space.module(moduleId).to(pathId, params, includeModulePath, subModuleId);
    }

    static replaceParamsInLink(URL: string, params: Record<string, string>) {
        return Object.keys(params).reduce((finalURL, paramKey) => finalURL.replace(`:${paramKey}`, params[paramKey]), URL);
    }

    static getMediaURLFromRelativePath(relativePath: string) {
        return `${URLsManager.filesBaseURL}${relativePath}`;
    }

    static getUploadedMediaURL(params: { userId: number; fileType: string; fileName: string }) {
        const { userId, fileType, fileName } = params;

        if (!['video', 'image', 'audio'].includes(fileType)) {
            throw new Error('Entered file type is not valid for uploaded media url.');
        }

        const path = `resp_files/${userId}/${fileType}/${fileName}`;

        return { url: `${URLsManager.filesBaseURL}/media/${path}`, path };
    }

    // TODO: Should be refactored in future
    static getURLTo(address: string, endpointId?: string, queryParams?: Record<string, string>): string {
        const needInterpreting = appUtils.env.isDev() || Boolean(endpointId);

        if (!needInterpreting) {
            // backend sends interpreted URL so no need to more inspection
            if (queryParams) {
                return queryString.stringifyUrl({
                    url: address,
                    query: queryParams,
                });
            }
            return address;
        }

        const addressWithoutLeadingSlash = address.replaceAll(/^\/+/g, '');
        const location = endpointId ? `${URLsManager.getEndpointURL(endpointId)}/` : URLsManager.baseURL;
        const parsedUrl = new URLParse(addressWithoutLeadingSlash, location, true);

        if (queryParams) {
            Object.assign(parsedUrl.query, queryParams);
        }

        return parsedUrl.toString();
    }

    static redirectTo(url: string) {
        setTimeout(() => {
            window.location.href = url;
        }, 200);
    }
}
