import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import useLazyImage from '$app-web/components/lazy-image/hook';
import Loading from '$app-web/components/loading';

import useStyles from './styles';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

// TODO: Refactor the usages to replace with the UIKit Image component with the new `lazy` prop
// TODO: Add round corner variant. Currently because of the backdrop issue that is used by loading, this cannot be added.
const LazyImage = (props) => {
    const { className, style, src, alt, small, loadingProps, shadow, translucentImage, ...restProps } = props;
    const classes = useStyles(props);
    const { t } = useTranslation();

    const { loading, setLoading, failed, setFailed, retry, setRetry } = useLazyImage(src);

    const isImageLoaded = !loading && !failed;

    return (
        <Grid
            container
            className={clsx(
                classes.root,
                {
                    [classes.shadow]: !isImageLoaded && shadow,
                },
                className
            )}
            style={style}
            alignItems="center"
            justify="center"
        >
            <Loading
                variant="parent-overlay"
                loading={loading}
                error={failed}
                showErrorText={false}
                errorHasButton
                onErrorButtonClick={() => setRetry((currentRetry) => currentRetry + 1)}
                errorButtonText={small ? <FontAwesomeIcon icon={['fas', 'redo-alt']} /> : t('reload')}
                {...loadingProps}
            />
            <img
                data-testid="lazyImage"
                key={`${retry}`}
                className={clsx(classes.image, {
                    [classes.shadow]: isImageLoaded && shadow,
                    [classes.translucentImage]: translucentImage,
                })}
                src={src}
                alt={alt}
                onLoad={() => {
                    setLoading(false);
                    setFailed(false);
                }}
                onError={() => {
                    setFailed(true);
                    setLoading(false);
                }}
                {...restProps}
            />
        </Grid>
    );
};

LazyImage.defaultProps = {
    className: '',
    small: false,
    loadingProps: {},
    alt: '',
    style: {},
    shadow: true,
    translucentImage: false,
    onClick: undefined,
};

LazyImage.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    small: PropTypes.bool,
    loadingProps: PropTypes.objectOf(PropTypes.any),
    style: PropTypes.objectOf(PropTypes.any),
    shadow: PropTypes.bool,
    translucentImage: PropTypes.bool,
    onClick: PropTypes.func,
};

export default LazyImage;
