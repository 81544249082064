import { useMemo, useState } from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';

import useSnackbar from '@edf-pkg/app-main/src/hooks/use-snackbar';

import { FILE_INPUT_MAX_SIZE } from '$app-web/constants';
import { getReadableFileSize } from '$app-web/utils/file-size';

import useStyles from './styles';

// TODO: Replace the usages of this component with the DropZone and remove this.
// TODO: Usage of mainText can be removed when survey-editor typography fixed.
const FileInput = (props) => {
    const {
        variant,
        onChange,
        accept,
        maxSize,
        minSize,
        multiple,
        disabled,
        ButtonComponent,
        ButtonProps,
        children,
        showError,
        errorMessage,
        showAcceptableProperties,
    } = props;

    const [internalErrorMessage, setInternalErrorMessage] = useState('');

    const snackbar = useSnackbar();

    const acceptedExtensions = useMemo(
        () => Array.from(new Set(Object.values(accept).flatMap((extension) => extension))).join(', '),
        [accept]
    );

    const classes = useStyles(props);
    const { t } = useTranslation();
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        noDrag: variant === 'button',
        noKeyboard: true,
        accept,
        onDropAccepted: (acceptedFiles) => {
            setInternalErrorMessage('');
            onChange(acceptedFiles);
        },
        onDropRejected: (fileRejections) => {
            const allErrorCodes = fileRejections
                .map((fileRejection) => fileRejection.errors)
                .flat()
                .map((error) => error.code);

            let finalErrorMessage = '';
            if (allErrorCodes.includes('too-many-files')) {
                finalErrorMessage = t('invalid_files_count_more');
            } else if (allErrorCodes.includes('file-too-large')) {
                finalErrorMessage = t('invalid_file_size');
            } else {
                finalErrorMessage = t('invalid_file_type');
            }

            if (variant === 'dropzone' && Boolean(finalErrorMessage)) {
                setInternalErrorMessage(finalErrorMessage);
            } else {
                snackbar.showError(finalErrorMessage);
            }
        },
        validator: (file) => {
            const { name: fileName } = file;
            // Extension validator
            const extension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
            if (acceptedExtensions && acceptedExtensions.indexOf(extension) === -1) {
                return {
                    code: 'file-invalid-extension',
                };
            }

            return null;
        },
        maxSize,
        minSize,
        multiple,
        disabled,
    });

    const inlineClassName = clsx(classes.dropZoneWrapper, {
        [classes.dropZoneWrapperActive]: isDragActive,
        [classes.dropZoneWrapperAccept]: isDragAccept,
        [classes.dropZoneWrapperReject]: isDragReject,
    });

    return (
        <div className={classes.root}>
            <Box
                display={variant === 'button' ? 'inline-block' : 'block'}
                {...getRootProps({
                    className: clsx(classes.inputBox, {
                        [inlineClassName]: variant === 'dropzone',
                        [classes.disabled]: disabled,
                    }),
                })}
            >
                <input {...getInputProps()} />
                {variant === 'dropzone' && (
                    <>
                        <Typography variant="subtitle1" className={classes.mainText}>
                            {t('drop_file')}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.mainText} fontWeight="fontWeightBold">
                            <Box fontWeight="fontWeightBold" m={1}>
                                {t('or')}
                            </Box>
                        </Typography>
                        <Typography variant="subtitle1" className={clsx(classes.fileSelectorText, classes.mainText)}>
                            {t('choose_from_computer')}
                        </Typography>
                        <br />
                        {showAcceptableProperties && (
                            <Typography variant="caption">
                                {t('file_acceptable_properties', [
                                    acceptedExtensions || t('not_limited'),
                                    (Number.isFinite(maxSize) && getReadableFileSize(maxSize)) || t('not_limited'),
                                ])}
                            </Typography>
                        )}
                    </>
                )}
                {variant === 'button' && <ButtonComponent {...ButtonProps}>{children}</ButtonComponent>}
                {variant === 'button' && showAcceptableProperties && (
                    <Typography variant="caption" className={classes.acceptablePropertiesVariantButton}>
                        {t('file_acceptable_properties', [
                            acceptedExtensions || t('not_limited'),
                            (Number.isFinite(maxSize) && getReadableFileSize(maxSize)) || t('not_limited'),
                        ])}
                    </Typography>
                )}
                {variant === 'dropzone' && showError && (errorMessage || internalErrorMessage) && (
                    <Typography variant="body1" className={classes.error}>
                        {errorMessage || internalErrorMessage}
                    </Typography>
                )}
            </Box>
        </div>
    );
};

FileInput.defaultProps = {
    accept: undefined,
    ButtonComponent: Button,
    ButtonProps: {},
    children: undefined,
    disabled: false,
    errorMessage: '',
    maxSize: FILE_INPUT_MAX_SIZE,
    minSize: undefined,
    multiple: false,
    showError: true,
    variant: 'dropzone',
    showAcceptableProperties: true,
};

FileInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    accept: PropTypes.object,
    ButtonComponent: PropTypes.elementType,
    ButtonProps: PropTypes.oneOfType([PropTypes.object]),
    children: PropTypes.node,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    multiple: PropTypes.bool,
    showError: PropTypes.bool,
    variant: PropTypes.oneOf(['button', 'dropzone']),
    showAcceptableProperties: PropTypes.bool,
};

export default FileInput;
