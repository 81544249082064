import Language from '../core/language';

const pdashNamespace = import('../namespaces/es-es.pdash.namespace');
const webActivitiesNamespace = import('../namespaces/es-es.web-activities.namespace');
const rdashTranslatablesNamespace = import('../namespaces/es-es.rdash-translatables.namespace');

class EsESLanguage extends Language {
    code = 'es-US';

    locale = 'es';

    localName = 'Español';

    englishName = 'Spanish';

    territory = 'US';

    notLoadedNamespaces = {
        pdash: pdashNamespace,
        webActivities: webActivitiesNamespace,
        rdashTranslatables: rdashTranslatablesNamespace,
    };
}

const instance = new EsESLanguage();
export default instance;
