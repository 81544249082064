import Language from '../core/language';

const pdashNamespace = import('../namespaces/ur-pk.pdash.namespace');
const webActivitiesNamespace = import('../namespaces/ur-pk.web-activities.namespace');
const rdashTranslatablesNamespace = import('../namespaces/ur-pk.rdash-translatables.namespace');

class UrPkLanguage extends Language {
    code = 'ur-PK';

    locale = 'ur';

    localName = 'اردو';

    englishName = 'Urdu';

    territory = 'PK';

    timezones = ['Asia/Karachi'];

    notLoadedNamespaces = {
        pdash: pdashNamespace,
        webActivities: webActivitiesNamespace,
        rdashTranslatables: rdashTranslatablesNamespace,
    };
}

const instance = new UrPkLanguage();
export default instance;
