import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';

import WebApp from '$app-web/components/web-app';
import ClientProvider from '$app-web/core/client/provider';
import * as fontAwesomeLibrary from '$app-web/utils/font-awesome-library';

import { jss, theme } from './theme';

fontAwesomeLibrary.initialize();

// eslint-disable-next-line react/prefer-stateless-function
class WebAppContainer extends Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <StylesProvider jss={jss}>
                    <ClientProvider>
                        <WebApp />
                    </ClientProvider>
                </StylesProvider>
            </MuiThemeProvider>
        );
    }
}

WebAppContainer.propTypes = {};

export default WebAppContainer;
