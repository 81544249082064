import * as logLevel from 'loglevel';
import snakecaseKeys from 'snakecase-keys';

import appDateTime from '@edf-pkg/app-date-time';
import * as appMain from '@edf-pkg/app-main';

import client from '../client';
import { getDeviceOs } from '../utils';

interface LogPayload {
    source: string;
    name: string;
    timestamp: string | number;
    osVersion?: string | null;
    deviceId?: string;
    appVersion: number;
    userId: number;
    message: string;
    level: string;
    stackTrace: string;
    extraInfo: Record<string, unknown>;
}

class Logger {
    private logLevelInstance: logLevel.Logger;

    static isProd: boolean = process.env.NODE_ENV === 'production';

    static basePayload: LogPayload = {
        source: 'frontend',
        name: 'frontend',
        timestamp: '',
        osVersion: '',
        deviceId: '',
        appVersion: 1,
        userId: -1,
        message: '',
        level: '',
        stackTrace: '',
        extraInfo: {},
    };

    constructor() {
        this.logLevelInstance = logLevel.getLogger('@edf-logger');
        if (Logger.isProd) {
            this.logLevelInstance.setLevel('warn', false);
        } else {
            this.logLevelInstance.setLevel('trace', false);
        }
    }

    trace(message: string): void {
        this.logLevelInstance.trace(message);
    }

    debug(message: string): void {
        this.logLevelInstance.debug(message);
    }

    info(message: string): void {
        this.logLevelInstance.info(message);
    }

    warn(message: string): void {
        this.logLevelInstance.warn(message);
    }

    error(message: string): void {
        this.logLevelInstance.error(message);
    }

    logToBackend(payload: Partial<LogPayload>): void {
        const finalPayload: LogPayload = {
            ...Logger.basePayload,
            timestamp: appDateTime().toMillis(),
            osVersion: getDeviceOs(),
            ...payload,
        };

        if (!finalPayload.deviceId) {
            finalPayload.deviceId = appMain.utils.user.getDeviceId();
        }

        client.api
            .createLog(snakecaseKeys(finalPayload))
            .then()
            .catch((error: unknown) => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }
}

const instance = new Logger();
export default instance;
