import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createStore } from 'zustand';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import * as appMain from '@edf-pkg/app-main';
import useSnackbar from '@edf-pkg/app-main/src/hooks/use-snackbar';

import { TRIGGERING_LOGIC_TYPE_IDS } from '$app-web/activities/survey/constants';
import { SESSION_STATUSES } from '$app-web/constants';
import {
    deleteSessionAsParticipant,
    evaluateCriteria,
    quitStudy,
    saveSessionDraft,
    SaveSessionRequestParams,
    submitSession,
    useFetchActivitySession,
    useFetchLastResponses,
    useFetchSurveyContent,
} from '$app-web/endpoints';
import useErrorHandler from '$app-web/hooks/use-error-handler';
import { useTranslator } from '$app-web/hooks/use-translator';
import { TRIGGERING_LOGICS } from '$app-web/modules/rdash/constants';
import { ObjectType, Session, Survey } from '$app-web/types';
import { pipe, SuperDate, SuperNumber, SuperObject, URLsManager } from '$app-web/utils';

import {
    applyEnablersAnswers,
    applyNextSectionsOfAnswer,
    applySurveyCriteria,
    applySurveyFlow,
    applySurveyLoopSections,
    applySurveyRandomization,
    extractPlaceholderByResponse,
    extractTokensAndPlaceholders,
    generateBackendResponses,
    generateSurveyHierarchy,
    getAnswerOfQuestion,
    getDefaultValue,
    getQuestionTokens,
    normalizeQuestionsForStore,
    processQuestionsAnswersForBackend,
    shouldPresentQuestion,
} from '../utils';
import { storeInitialValue } from './constant';
import { PureStore, Store, StoreProviderProps, StoreQuestion } from './types';

const StoreContext = createContext<Store>({} as Store);

export const StoreProvider = ({
    children,
    isSandboxMode,
    sessionUUID = '',
    surveyVersion: surveyVersionProp = -1,
    sessionInfo,
    isTimeUse = false,
    isEligibilitySurvey = false,
    isPublicSurvey,
    onSubmitSurveySession,
    participantRegistrationTime = Date.now(),
    onClose,
}: PropsWithChildren<StoreProviderProps>) => {
    const t = useTranslator();
    const [surveyVersion, setSurveyVersion] = useState(surveyVersionProp);
    const [tokensAndPlaceholders, setTokensAndPlaceholders] = useState<Array<{ activityId: number; questionId: number }>>([]);
    const urlParams = useParams<ObjectType<string>>();
    const activityIdParam = urlParams.activityId != null ? Number(urlParams.activityId) : null;
    const { handleError } = useErrorHandler();
    const history = useHistory();
    const { showError } = useSnackbar();

    const deviceId = appMain.utils.user.getDeviceId() ?? '';
    const userLanguage = appMain.utils.user.getUserLanguage();
    const translationId = userLanguage.replace('-', '');

    const activityType = useMemo(() => {
        if (isTimeUse) return 'time-use';
        else if (isEligibilitySurvey) return 'eligibility';
        else return 'survey';
    }, [isEligibilitySurvey, isTimeUse]);

    const store = useMemo(
        () =>
            createStore<PureStore>((setStore, getStore) => ({
                ...storeInitialValue,
                session: { ...storeInitialValue.session, ...(sessionInfo ?? {}) },
                isSandboxMode,
                isPublicSurvey,
                type: activityType,
                participantRegistrationTime,
                updateCurrentQuestion: (answer) => {
                    const { questions, currentQuestion, tokens, session, participantRegistrationTime } = getStore();

                    const updatedQuestions = questions.map((question) => {
                        if (SuperObject.isSuperset(question, currentQuestion)) {
                            return { ...question, answer };
                        }
                        return question;
                    });

                    const targetQuestion = questions.find((question) => SuperObject.isSuperset(question, currentQuestion));

                    if (targetQuestion == null) {
                        return questions;
                    }

                    const updatedQuestionsWithCriteria = applySurveyCriteria({
                        questions: updatedQuestions,
                        tokens,
                        registrationTime: participantRegistrationTime,
                        activityId: session.activityId,
                    });

                    const finalQuestions = pipe(
                        applySurveyLoopSections,
                        applySurveyFlow,
                        applyNextSectionsOfAnswer,
                        applyEnablersAnswers
                    )(updatedQuestionsWithCriteria);

                    setStore({ questions: finalQuestions });
                },
                goNextQuestion: async ({ isLastQuestion } = { isLastQuestion: false }) => {
                    const {
                        currentQuestion,
                        session,
                        surveyHierarchy,
                        mediaInteractions,
                        eligibleCriteria,
                        location,
                        type,
                        questions,
                    } = getStore();

                    const questionIndex = questions.findIndex((question) => SuperObject.isSuperset(question, currentQuestion));
                    const nextQuestion = questions.slice(questionIndex + 1).find((question) => shouldPresentQuestion(question))!;

                    if (nextQuestion == null && !isLastQuestion) {
                        return;
                    }

                    const updatedCurrentQuestion =
                        nextQuestion == null
                            ? { questionId: -1, iteration: null }
                            : SuperObject.pick(nextQuestion, ['questionId', 'iteration']);
                    let updatedQuestions: Array<StoreQuestion> = questions;

                    if (!isSandboxMode && !isPublicSurvey && type !== 'time-use') {
                        try {
                            setStore({ isLoading: true });

                            updatedQuestions = await processQuestionsAnswersForBackend({
                                questions,
                                sessionUuid: session.sessionUuid,
                                userId: session.userId,
                            });

                            if (updatedQuestions.length === 0) {
                                setStore({ isError: true, isLoading: false });
                                return;
                            }

                            const backendData = generateBackendResponses({
                                session,
                                questions: updatedQuestions,
                                mediaInteractions,
                                location,
                                answeringQuestion: {
                                    questionId: updatedCurrentQuestion.questionId,
                                    iteration: updatedCurrentQuestion.iteration,
                                },
                                translationId,
                                newSessionStatus: SESSION_STATUSES.SUBMITTED,
                            });
                            const data = { ...backendData, deviceId, initialSurveyHierarchy: surveyHierarchy };

                            if (isLastQuestion) {
                                const isSurveySubmitted = await submitSession(data, {
                                    onSubmitSessionCallback: onSubmitSurveySession,
                                });

                                if (isSurveySubmitted && isEligibilitySurvey) {
                                    const { result: isEligibilityCriteriaValid } = await evaluateCriteria({
                                        activityId: session.activityId,
                                        sessionUuid: session.uuid,
                                        expression: eligibleCriteria,
                                    });

                                    if (isEligibilityCriteriaValid) {
                                        history.push(
                                            URLsManager.to('dashboard:pdash:JOIN_STUDY_INFO', {
                                                studyId: session.studyId.toString(),
                                            })
                                        );
                                    } else {
                                        history.push(URLsManager.to('dashboard:pdash:INELIGIBILITY'));
                                    }
                                } else if (isSurveySubmitted && session.tlTypeId === TRIGGERING_LOGIC_TYPE_IDS.DROPOUT) {
                                    const hasBeenQuitted = await quitStudy(session.studyId);

                                    if (hasBeenQuitted) {
                                        history.push(URLsManager.to('dashboard:pdash:JOIN_STUDY_CHECK'));
                                        window.location.reload();
                                    }
                                    throw Error();
                                } else if (isSurveySubmitted && !isEligibilitySurvey) {
                                    history.push(URLsManager.to('dashboard:pdash:HOME'));
                                    return;
                                }
                                throw Error();
                            } else {
                                const isSessionSaved = await saveSessionDraft(data);
                                if (isSessionSaved) {
                                    setStore({ isLoading: false });
                                } else {
                                    throw Error();
                                }
                            }
                        } catch (error) {
                            setStore({ isLoading: false, isError: true });
                            return;
                        }
                    }

                    if (!isSandboxMode && isTimeUse && isLastQuestion) {
                        setStore({ isLoading: true });

                        try {
                            const backendData = generateBackendResponses({
                                session,
                                questions: updatedQuestions,
                                location,
                                mediaInteractions,
                                translationId,
                                answeringQuestion: updatedCurrentQuestion,
                                newSessionStatus: SESSION_STATUSES.SUBMITTED,
                            });

                            const data = {
                                ...backendData,
                                deviceId,
                                initialSurveyHierarchy: surveyHierarchy,
                            };
                            const isSurveySubmitted = await submitSession(data, {
                                onSubmitSessionCallback: onSubmitSurveySession,
                            });

                            if (isSurveySubmitted) {
                                setStore({ isLoading: false });
                                onClose?.(true);
                                return;
                            }
                            throw Error();
                        } catch (error) {
                            setStore({ isLoading: false, isError: true });
                            return;
                        }
                    }

                    if (isPublicSurvey && isLastQuestion) {
                        try {
                            setStore({ isLoading: true });

                            updatedQuestions = await processQuestionsAnswersForBackend({
                                questions,
                                sessionUuid: session.sessionUuid,
                                userId: session.userId,
                            });

                            if (updatedQuestions.length === 0) {
                                setStore({ isError: true, isLoading: false });
                                return;
                            }

                            const backendData = generateBackendResponses({
                                session,
                                questions: updatedQuestions,
                                mediaInteractions,
                                location,
                                translationId,
                                answeringQuestion: {
                                    questionId: updatedCurrentQuestion.questionId,
                                    iteration: updatedCurrentQuestion.iteration,
                                },
                                newSessionStatus: SESSION_STATUSES.SUBMITTED,
                            });

                            const data = {
                                ...backendData,
                                deviceId,
                                initialSurveyHierarchy: surveyHierarchy,
                            };
                            const isSurveySubmitted = await submitSession(data, {
                                onSubmitSessionCallback: onSubmitSurveySession,
                            });

                            if (isSurveySubmitted) {
                                return;
                            }
                            throw Error();
                        } catch (error) {
                            setStore({ isLoading: false, isError: true });
                            return;
                        }
                    }

                    setStore({ currentQuestion: updatedCurrentQuestion, questions: updatedQuestions });
                },
                goToPreviousQuestion: async () => {
                    const { currentQuestion, questions } = getStore();

                    const questionIndex = questions.findIndex((question) => SuperObject.isSuperset(question, currentQuestion));
                    const previousQuestion = questions
                        .slice(0, questionIndex)
                        .findLast((question) => shouldPresentQuestion(question));

                    if (previousQuestion == null) {
                        return;
                    }

                    setStore({ questions, currentQuestion: SuperObject.pick(previousQuestion, ['questionId', 'iteration']) });
                },
                skipQuestion: async () => {
                    const {
                        currentQuestion,
                        mediaInteractions,
                        surveyHierarchy,
                        location,
                        session: storeSession,
                        questions,
                    } = getStore();

                    const questionIndex = questions.findIndex((question) => SuperObject.isSuperset(question, currentQuestion));
                    const question = questions[questionIndex];

                    getStore().updateCurrentQuestion(getDefaultValue(question.questionType));

                    const nextQuestion = questions.slice(questionIndex + 1).find((question) => shouldPresentQuestion(question));

                    if (nextQuestion == null) {
                        return;
                    }

                    const updatedCurrentQuestion = SuperObject.pick(nextQuestion, ['questionId', 'iteration']);

                    if (!isSandboxMode && !isPublicSurvey) {
                        try {
                            setStore({ isLoading: true });

                            const backendData = generateBackendResponses({
                                session: storeSession,
                                questions,
                                translationId,
                                location,
                                mediaInteractions,
                                answeringQuestion: updatedCurrentQuestion,
                            });

                            const data = {
                                ...backendData,
                                deviceId,
                                initialSurveyHierarchy: surveyHierarchy,
                            };
                            const isSessionSkipped = await saveSessionDraft(data);

                            if (isSessionSkipped) {
                                setStore({ isLoading: false });
                            } else {
                                throw Error();
                            }
                        } catch {
                            setStore({ isLoading: false, isError: true });
                            return;
                        }
                    }

                    setStore({ questions, currentQuestion: updatedCurrentQuestion });
                },
                cancelSession: async () => {
                    const { session, surveyHierarchy, location } = getStore();

                    if (!isSandboxMode) {
                        const backendData = generateBackendResponses({
                            mediaInteractions: [],
                            questions: [],
                            location,
                            translationId,
                            session,
                            newSessionStatus: SESSION_STATUSES.CANCELED,
                        });

                        const data: SaveSessionRequestParams = {
                            ...backendData,
                            deviceId,
                            initialSurveyHierarchy: surveyHierarchy,
                        };

                        setStore({ isLoading: true });
                        const isSessionCanceled = await submitSession(data, {
                            onSubmitSessionCallback: onSubmitSurveySession,
                        });
                        setStore({ isLoading: false });

                        if (isSessionCanceled) {
                            // Public survey handles its cancel callback UI in its own module.
                            if (!isPublicSurvey) {
                                history.push(URLsManager.to('dashboard:pdash:HOME'));
                            }
                        } else {
                            setStore({ isError: true });
                        }
                    }
                },
                expireSession: async () => {
                    const { session, location, surveyHierarchy, questions, mediaInteractions } = getStore();

                    const updatedQuestions = await processQuestionsAnswersForBackend({
                        questions,
                        sessionUuid: session.sessionUuid,
                        userId: session.userId,
                    });

                    if (!isSandboxMode) {
                        setStore({ isLoading: true });

                        const backendData = generateBackendResponses({
                            mediaInteractions,
                            questions: updatedQuestions,
                            location,
                            translationId,
                            session,
                            newSessionStatus: SESSION_STATUSES.EXPIRED,
                        });

                        const data = {
                            ...backendData,
                            deviceId,
                            initialSurveyHierarchy: surveyHierarchy,
                        };
                        const isSessionExpired = await submitSession(data, {
                            onSubmitSessionCallback: onSubmitSurveySession,
                        });

                        if (isSessionExpired) {
                            // Public survey handles its cancel callback UI in its own module.
                            if (!isPublicSurvey) {
                                showError(t('webActivities:survey_is_expired'));
                                history.replace(URLsManager.to('dashboard:pdash:HOME'));
                            }
                        } else {
                            setStore({ isError: true });
                        }

                        setStore({ isLoading: false });
                    }
                },
                deleteSession: async () => {
                    const { session } = getStore();

                    if (!isSandboxMode) {
                        setStore({ isLoading: true });
                        const isSessionDeleted = await deleteSessionAsParticipant(session.uuid);
                        setStore({ isLoading: false });

                        if (isSessionDeleted) {
                            history.push(URLsManager.to('dashboard:pdash:HOME'));
                        } else {
                            setStore({ isError: true });
                        }
                    }
                },
                setLocation: (coords) => {
                    const accuracy = Math.min(10 ** 6 - 1, Math.floor(coords.accuracy ?? 0));

                    setStore({
                        location: {
                            lat: SuperNumber.round(coords.latitude, 3),
                            lng: SuperNumber.round(coords.longitude, 3),
                            speed: Number(SuperNumber.round(coords.speed ?? 0, 2).toPrecision(9)),
                            accu: Number(SuperNumber.round(accuracy, 2).toPrecision(9)),
                        },
                    });
                },
                addMediaInteraction: (interaction) => {
                    const { mediaInteractions } = getStore();
                    const { playPeriod, ...commonProperties } = interaction;
                    const playingDuration = Math.round(interaction.playPeriod[1] - interaction.playPeriod[0]);

                    const previousMatchedInteraction = mediaInteractions.find(
                        (item) => interaction.questionId === item.questionId && interaction.mediaUrl === item.mediaUrl
                    );

                    if (playingDuration === 0) {
                        return;
                    }

                    if (previousMatchedInteraction == null) {
                        setStore({
                            mediaInteractions: [
                                ...mediaInteractions,
                                { ...commonProperties, playPeriods: [playPeriod], totalDuration: playingDuration },
                            ],
                        });
                    } else {
                        const otherInteractions = mediaInteractions.filter(
                            (item) => item.questionId !== interaction.questionId || item.mediaUrl !== interaction.mediaUrl
                        );
                        const newPlayPeriods = [...previousMatchedInteraction.playPeriods, playPeriod];
                        const newTotalDuration = previousMatchedInteraction.totalDuration + playingDuration;
                        const newInteraction = {
                            ...commonProperties,
                            playPeriods: newPlayPeriods,
                            totalDuration: newTotalDuration,
                        };

                        setStore({ mediaInteractions: [...otherInteractions, newInteraction] });
                    }
                },
            })),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const { data: sessionData = { session: sessionInfo as Session } } = useFetchActivitySession({
        sessionUUID: sessionUUID ?? '',
        shouldCall:
            !isSandboxMode &&
            !isPublicSurvey &&
            sessionUUID != null &&
            !isEligibilitySurvey &&
            sessionInfo?.tlTypeId !== TRIGGERING_LOGIC_TYPE_IDS.DROPOUT,
        onSuccess: (response) => {
            const { currentQuestion, questions } = store.getState();
            const { showProgress } = response.activity;
            const updatedCurrentQuestion = {
                questionId: response.session.inProgressInfo?.questionId ?? currentQuestion.questionId,
                iteration: response.session.inProgressInfo?.iteration ?? currentQuestion.iteration,
            };
            const responses = response.responses ?? [];
            const updatedQuestions = questions.map((question) => {
                const relatedResponse = responses.find(
                    (resp) => resp.qId === question.questionId && resp.iteration === question.iteration
                );

                if (relatedResponse == null) {
                    return question;
                } else {
                    const answer = getAnswerOfQuestion({
                        questionId: question.questionId,
                        questionType: question.questionType,
                        iteration: question.iteration,
                        resp: relatedResponse.resp,
                    });

                    return { ...question, location: answer.location, answer: answer.value };
                }
            });
            setSurveyVersion(response.activity.version);
            store.setState({
                questions: updatedQuestions,
                session: response.session,
                isSurveyProgressVisible: showProgress,
                currentQuestion: updatedCurrentQuestion,
            });
        },
        onError: () => store.setState({ isError: true }),
    });

    const session = useMemo(
        () => SuperObject.toCamelCase(sessionData?.session ?? sessionInfo ?? {}) as Session,
        [sessionData?.session, sessionInfo]
    );
    const activityId = sessionInfo?.activityId ?? store.getState().session?.activityId ?? activityIdParam;
    const { userId = 0 } = session;

    const handleSurveyContent = useCallback(
        (survey: Survey) => {
            const { session: storeSession, participantRegistrationTime } = store.getState();
            const { initialSurveyHierarchy = [] } = storeSession;
            const targetSurvey = survey.subSurveys[0];
            const { subSurveyContent, sectionRandomOrder, sectionRandomSelection } = targetSurvey;
            const sections = subSurveyContent.map((section, index) => ({ ...section, id: index }));
            const surveyHierarchy =
                initialSurveyHierarchy.length === 0
                    ? generateSurveyHierarchy({ sections: sections, sectionRandomOrder, sectionRandomSelection })
                    : initialSurveyHierarchy;
            const storeQuestions = normalizeQuestionsForStore(sections);
            const inFlowQuestions = applySurveyFlow(storeQuestions);
            const randomizedQuestions = applySurveyRandomization(inFlowQuestions, surveyHierarchy);

            let finalQuestions = randomizedQuestions;

            const questionTokensAndPlaceholders = extractTokensAndPlaceholders(sections, activityId);
            if (questionTokensAndPlaceholders.length > 0) {
                const normalizedTokensAndPlaceholders = questionTokensAndPlaceholders.map((token) => {
                    const [surveyId, questionId] = token.replace('Q', '').split('_');
                    return { activityId: Number(surveyId), questionId: Number(questionId) };
                });

                setTokensAndPlaceholders(normalizedTokensAndPlaceholders);
            } else {
                // Apply criteria settings only if there are no question references from other surveys,
                // Otherwise apply criteria settings after fetching last responses of those questions
                finalQuestions = applySurveyCriteria({
                    questions: finalQuestions,
                    activityId: storeSession.activityId,
                    tokens: {},
                    registrationTime: participantRegistrationTime,
                });
            }

            const firstQuestion = finalQuestions.find((question) => shouldPresentQuestion(question)) ?? {
                questionId: 0,
                iteration: null,
            };

            const { eligibilityCriteria = '' } =
                survey.triggeringLogics.find((item) => item.type === TRIGGERING_LOGICS.ELIGIBILITY.name) ?? {};

            store.setState({
                eligibleCriteria: eligibilityCriteria,
                questions: finalQuestions,
                surveyHierarchy,
                isLoading: !isSandboxMode && questionTokensAndPlaceholders.length > 0,
                currentQuestion: { questionId: firstQuestion.questionId, iteration: firstQuestion.iteration },
                captureLocation: survey.captureLocation,
                session: { ...storeSession, version: survey.version },
                isPreviousButtonEnabled: survey.previousButtonEnabled ?? true,
            });
        },
        [activityId, isSandboxMode, store]
    );

    useEffect(() => {
        store.setState({ session });
    }, [store, session]);

    const isStoreEmpty = store.getState().questions.length === 0;
    const isSurveyVersionValid = isSandboxMode || surveyVersion !== -1;
    const isActivityIdValid = Boolean(activityId);

    useFetchSurveyContent({
        surveyId: activityId,
        versionNumber: isTimeUse ? 0 : surveyVersion,
        shouldCall: isStoreEmpty && isSurveyVersionValid && isActivityIdValid,
        onSuccess: handleSurveyContent,
        onError: handleError,
    });

    useFetchLastResponses({
        userId,
        atTime: SuperDate.stringify(Date.now()),
        questions: tokensAndPlaceholders,
        shouldCall:
            (userId !== 0 || session.tlTypeId === TRIGGERING_LOGIC_TYPE_IDS.PUBLIC) &&
            tokensAndPlaceholders.length > 0 &&
            !isSandboxMode,
        onSuccess: (data) => {
            const { session: storeSession, participantRegistrationTime, questions, tokens: storeTokens } = store.getState();

            const tokens = getQuestionTokens(data);
            const placeholders = data.reduce(
                (acc, lastResponse) => {
                    const key = `{{Q${lastResponse.activityId}_${lastResponse.response.qId}}}`;

                    return { ...acc, [key]: extractPlaceholderByResponse(lastResponse, t) };
                },
                {} as Record<string, string>
            );

            const updatedTokens = Object.fromEntries(
                Object.entries(tokens).flatMap(([key, value]) => {
                    if (key.startsWith(`Q${storeSession.activityId}_`)) {
                        const newKey = key.replace(/Q\d+_/g, 'Q');
                        return [
                            [key, value],
                            [newKey, value],
                        ];
                    }
                    return [[key, value]];
                })
            );

            const newTokens = { ...storeTokens, ...updatedTokens };

            const questionsWithAppliedCriteriaSettings = applySurveyCriteria({
                questions,
                activityId: storeSession.activityId,
                tokens: newTokens,
                registrationTime: participantRegistrationTime,
            });

            const firstQuestion = questionsWithAppliedCriteriaSettings.find((question) => shouldPresentQuestion(question)) ?? {
                questionId: 0,
                iteration: null,
            };

            store.setState({
                questions: questionsWithAppliedCriteriaSettings,
                currentQuestion: { questionId: firstQuestion.questionId, iteration: firstQuestion.iteration },
                tokens: newTokens,
                placeholders: placeholders,
                isLoading: false,
            });
        },
        onError: () => store.setState({ isError: true }),
    });

    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export function useStore<T>(selector: (store: PureStore) => T, equalityFn?: (a: T, b: T) => boolean) {
    const store = useContext(StoreContext);
    return useStoreWithEqualityFn<Store, T>(store, selector, equalityFn);
}
