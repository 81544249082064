import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';
import URLParse from 'url-parse';

import { utils as appClientUtils } from '@edf-pkg/app-client';
import { store as appMainStore } from '@edf-pkg/app-main';

import Loading from '$app-web/components/loading';
import { AUTH_ACTIONS } from '$app-web/spaces/dashboard/constants';
import { dashboardDuck } from '$app-web/spaces/dashboard/store';
import { URLsManager } from '$app-web/utils';

const AuthActionProvider = (props) => {
    const { children } = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const hasAction = useSelector(dashboardDuck.duckSelectors.hasAuthActionSelector);
    const action = useSelector(dashboardDuck.duckSelectors.authActionSelector);
    const username = useSelector(appMainStore.userDuck.duckSelectors.usernameSelector);
    const apiKey = useSelector(appMainStore.userDuck.duckSelectors.apiKeySelector);
    const isAuthenticated = appClientUtils.auth.isUserAuthenticated();

    useEffect(() => {
        if (isAuthenticated && hasAction) {
            switch (action.type) {
                case AUTH_ACTIONS.REDIRECT:
                    {
                        const parsedNextURL = new URLParse(action.params.next, true);
                        if (!parsedNextURL.host.includes('avicennaresearch.com')) {
                            parsedNextURL.set('host', 'avicennaresearch.dev');
                        }
                        parsedNextURL.set('query', {
                            ...parsedNextURL.query,
                            ...snakecaseKeys({
                                username,
                                apiKey,
                            }),
                        });
                        URLsManager.redirectTo(parsedNextURL.toString());
                    }
                    break;
                case AUTH_ACTIONS.JOIN_STUDY:
                    dispatch(dashboardDuck.duckActionCreators.reset());
                    history.replace(
                        `${URLsManager.to('dashboard:pdash:SETTINGS_JOIN_STUDY_CHECK')}?studyId=${action.params.studyId}`
                    );
                    break;
                // no default
            }
        }
    }, [action, apiKey, dispatch, hasAction, history, isAuthenticated, username]);

    if (!isAuthenticated) {
        return null;
    }

    if (hasAction) {
        return <Loading variant="parent-overlay" loading />;
    }

    return children;
};

AuthActionProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthActionProvider;
