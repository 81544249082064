import React from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import * as RUITooltip from '@radix-ui/react-tooltip';

import classes from './styles.module.scss';

const Tooltip = (props) => {
    const { children, content, disabled, side, align, variant, open, onOpenChange, hideArrow, delayDuration, maxWidth } = props;

    if (disabled) {
        return children;
    } else {
        return (
            <RUITooltip.Provider delayDuration={delayDuration}>
                <RUITooltip.Root {...(open !== undefined ? { open, onOpenChange } : {})}>
                    <RUITooltip.Trigger asChild>{children}</RUITooltip.Trigger>
                    <RUITooltip.Portal>
                        <RUITooltip.Content sideOffset={5} side={side} align={align}>
                            {content && (
                                <>
                                    <div
                                        className={clsx(classes.contentWrapper, {
                                            [classes.lightContentWrapper]: variant === 'light',
                                        })}
                                        style={{
                                            maxWidth,
                                        }}
                                    >
                                        {content}
                                    </div>
                                    {!hideArrow && (
                                        <RUITooltip.Arrow
                                            className={clsx(classes.arrow, { [classes.lightArrow]: variant === 'light' })}
                                        />
                                    )}
                                </>
                            )}
                        </RUITooltip.Content>
                    </RUITooltip.Portal>
                </RUITooltip.Root>
            </RUITooltip.Provider>
        );
    }
};

Tooltip.defaultProps = {
    disabled: false,
    side: 'bottom',
    align: 'center',
    variant: 'dark',
    content: undefined,
    hideArrow: false,
    delayDuration: 0,
    open: undefined,
    maxWidth: undefined,
    onOpenChange: () => {},
};

Tooltip.propTypes = {
    children: PropTypes.element.isRequired,
    disabled: PropTypes.bool,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    side: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
    variant: PropTypes.oneOf(['light', 'dark']),
    align: PropTypes.oneOf(['center', 'start', 'end']),
    hideArrow: PropTypes.bool,
    delayDuration: PropTypes.number,
    open: PropTypes.bool,
    maxWidth: PropTypes.number,
    onOpenChange: PropTypes.func,
};

export default Tooltip;
