import { ObjectType } from '$app-web/types';

import { SuperObject } from './object';

export abstract class SuperArray {
    static shuffle<T>(array: Array<T>): Array<T> {
        return [...array].sort(() => Math.random() - 0.5);
    }

    static getRandomElement<T>(array: Array<T>): T {
        return array[Math.floor(Math.random() * array.length)];
    }

    static getRandomElements<T>(array: Array<T>, count: number): Array<T> {
        const shuffled = SuperArray.shuffle(array);
        return shuffled.slice(0, count);
    }

    static getDifference<T extends string | number>(firstArray: Array<T>, secondArray: Array<T>): Array<T> {
        const dataSet = new Set([...firstArray, ...secondArray]);
        return Array.from(dataSet).filter((item) => !firstArray.includes(item) || !secondArray.includes(item));
    }

    static isSuperset<T extends string | number>(superset: Array<T>, subset: Array<T>): boolean {
        return subset.every((value) => superset.includes(value));
    }

    static removeDuplicates<T>(array: Array<T>): Array<T> {
        const uniqueArray: Array<T> = [];

        array.forEach((item) => {
            const isItemExist = uniqueArray.some((uniqueItem) => SuperObject.isEqual(uniqueItem, item));

            if (!isItemExist) {
                uniqueArray.push(item);
            }
        });

        return uniqueArray;
    }

    static removeNullAndUndefined<T>(array: Array<T | null | undefined>): Array<T> {
        return array.filter((item) => item != null) as Array<T>;
    }

    static sortBy<T extends ObjectType>(array: Array<T>, key: keyof T, order: 'asc' | 'desc' = 'asc'): Array<T> {
        return array.sort((a, b) => {
            if (order === 'asc') {
                return a[key] > b[key] ? 1 : -1;
            }

            return a[key] < b[key] ? 1 : -1;
        });
    }

    static omit<T>(array: Array<T>, subArray: Array<T>): Array<T> {
        const newArray = array.filter((item) => !subArray.some((subItem) => SuperObject.isEqual(item, subItem)));

        return newArray;
    }

    static resizeFromEnd<T>(array: Array<T>, targetLength: number, defaultValue: T) {
        if (array.length >= targetLength) {
            return array.slice(0, targetLength);
        } else {
            const endingElements = Array.from({ length: targetLength - array.length }, () => defaultValue);

            return [...array, ...endingElements];
        }
    }
}
