import { makeStyles } from '@material-ui/styles';

export default makeStyles({
    image: {
        height: 80,
        width: 80,
        margin: '0 auto',
        cursor: 'pointer',
    },
});
