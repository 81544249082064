import { useLayoutEffect, useRef, useState } from 'react';

function useLazyImage(src) {
    const previousSrcRef = useRef(null);
    const previousRetryRef = useRef(-1);

    const [loading, setLoading] = useState(true);
    const [failed, setFailed] = useState(false);
    const [retry, setRetry] = useState(0);

    useLayoutEffect(() => {
        if (src === previousSrcRef.current && previousRetryRef.current === retry) {
            return;
        }
        setLoading(true);
        setFailed(false);
        previousSrcRef.current = src;
        previousRetryRef.current = retry;
    }, [retry, src]);
    return { loading, setLoading, failed, setFailed, retry, setRetry };
}

export default useLazyImage;
