import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

import { withActivity } from '@edf-pkg/app-activities';
import utils from '@edf-pkg/app-utils';

import AppErrorBoundary from '$app-web/containers/app-error-boundary';

const WithActivityErrorBoundaryHOC = (name) => (WrappedComponent) => {
    // eslint-disable-next-line react/prefer-stateless-function
    class WithActivityErrorBoundary extends Component {
        static WrappedComponent = WrappedComponent;

        render() {
            const { props } = this;
            return (
                <AppErrorBoundary name={`app-web.activity.${name}`}>
                    <WrappedComponent {...props} />
                </AppErrorBoundary>
            );
        }
    }

    WithActivityErrorBoundary.displayName = `WithActivityErrorBoundary(${utils.component.getDisplayName(WrappedComponent)})`;

    return withActivity(hoistNonReactStatics(WithActivityErrorBoundary, WrappedComponent));
};

export default WithActivityErrorBoundaryHOC;
