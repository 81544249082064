import { Children, Fragment, memo, ReactNode } from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';

interface Props {
    children: ReactNode;
}

const Mediator = ({ children }: Props) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Grid container justify="center" alignItems="center" spacing={isXs ? 0 : 2}>
            {Children.map(children, (child, index) => (
                <Fragment key={index!}>
                    {index > 0 && !isXs && (
                        // TODO: this should be visible in mobile size. but it caused issues on Time-use editor preview
                        <Grid item>
                            <Typography variant="h5">.....</Typography>
                        </Grid>
                    )}
                    <Grid item>{child}</Grid>
                </Fragment>
            ))}
        </Grid>
    );
};

export default memo(Mediator);
