const styles = (theme) => ({
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'relative',
    },
    hiddenActivity: {
        display: 'none',
        opacity: 0,
        visibility: 'hidden',
    },
    dialogActionsWrapper: {
        paddingBottom: theme.spacing(4),
    },
});

export default styles;
