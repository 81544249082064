import React, { useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { isNumber, range } from 'lodash-es';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, List, ListItem, ListItemText, ListSubheader, Toolbar, Typography } from '@material-ui/core';

import appDateTime from '@edf-pkg/app-date-time';

import ScrollableView from '$app-web/components/scrollable-view';

import useStyles from './styles';

const TimePicker = ({ value, onChange, orientation, minuteInterval, minTime, maxTime }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const selectCallback = useCallback(
        (selection) => {
            if (selection.hour) {
                onChange(value.setHour(selection.value));
            }
            if (selection.minute) {
                onChange(value.setMinute(selection.value));
            }
        },
        [onChange, value]
    );

    const valueDate = appDateTime(value);

    const { hour, minute } = useMemo(() => {
        if (!value) {
            return {};
        }
        return { hour: appDateTime(valueDate).getHour(), minute: appDateTime(valueDate).getMinute() };
    }, [value, valueDate]);

    const minTimeDate = useMemo(
        () => (minTime ? appDateTime(minTime) : appDateTime(valueDate).startOfDay()),
        [minTime, valueDate]
    );
    const maxTimeDate = useMemo(() => (maxTime ? appDateTime(maxTime) : appDateTime(valueDate).endOfDay()), [maxTime, valueDate]);

    const [hourRange, minuteRange] = useMemo(() => {
        let finalHourRange = range(0, 24);
        let finalMinuteRange = range(0, 60, minuteInterval);

        if (valueDate.startOfDay().isSame(minTimeDate.startOfDay())) {
            finalHourRange = finalHourRange.filter((hourValue) => hourValue >= minTimeDate.getHour());
            finalMinuteRange = finalMinuteRange.filter((minuteValue) =>
                valueDate.getHour() === minTimeDate.getHour() ? minuteValue >= minTimeDate.getMinute() : true
            );
        }

        if (valueDate.startOfDay().isSame(maxTimeDate.startOfDay())) {
            finalHourRange = finalHourRange.filter((hourValue) => hourValue <= maxTimeDate.getHour());
            finalMinuteRange = finalMinuteRange.filter((minuteValue) =>
                valueDate.getHour() === maxTimeDate.getHour() ? minuteValue <= maxTimeDate.getMinute() : true
            );
        }

        return [finalHourRange, finalMinuteRange];
    }, [maxTimeDate, minTimeDate, minuteInterval, valueDate]);

    const hourElements = useMemo(() => {
        return hourRange.map((h) => (
            <ListItem
                key={h}
                selected={isNumber(hour) && h === hour}
                onClick={() => selectCallback({ hour: true, value: h })}
                button
                data-testid="hour-item"
            >
                <ListItemText>{`${h}`.padStart(2, '0')}</ListItemText>
            </ListItem>
        ));
    }, [hour, hourRange, selectCallback]);

    const minuteElements = useMemo(() => {
        return minuteRange.map((min) => (
            <ListItem
                key={min}
                selected={isNumber(minute) && min === minute}
                onClick={() => selectCallback({ minute: true, value: min })}
                button
                data-testid="minute-item"
            >
                <ListItemText>{`${min}`.padStart(2, '0')}</ListItemText>
            </ListItem>
        ));
    }, [minute, minuteRange, selectCallback]);

    useEffect(() => {
        if (valueDate.isBefore(minTimeDate)) {
            onChange(appDateTime(minTimeDate));
        }

        if (valueDate.isAfter(maxTimeDate)) {
            onChange(appDateTime(maxTimeDate));
        }
    }, [maxTimeDate, minTimeDate, onChange, valueDate]);

    return (
        <Grid container direction={orientation === 'landscape' ? 'row' : 'column'} wrap="nowrap" className={classes.root}>
            <Grid item>
                <Toolbar
                    className={clsx(classes.toolbar, {
                        [classes.toolbarLandscape]: orientation === 'landscape',
                    })}
                >
                    {value && (
                        <Typography color="inherit" variant="h4">
                            {valueDate.format(appDateTime.FORMATS.HOUR_MINUTE)}
                        </Typography>
                    )}
                </Toolbar>
            </Grid>
            <Grid item className={classes.timeSelectorContainer} xs>
                <Grid container wrap="nowrap" className={classes.timeSelectorWrapper}>
                    <Grid xs item className={classes.timeListWrapper}>
                        <ScrollableView hideX>
                            <List disablePadding>
                                <ListSubheader className={classes.listSubheader}>{t('hour')}</ListSubheader>
                                {hourElements}
                            </List>
                        </ScrollableView>
                    </Grid>
                    <Grid item xs className={classes.timeListWrapper}>
                        <ScrollableView hideX>
                            <List disablePadding>
                                <ListSubheader className={classes.listSubheader}>{t('minute')}</ListSubheader>
                                {minuteElements}
                            </List>
                        </ScrollableView>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

TimePicker.defaultProps = {
    value: null,
    orientation: 'portrait',
    minuteInterval: 1,
    minTime: undefined,
    maxTime: undefined,
};

TimePicker.propTypes = {
    value: PropTypes.oneOfType([PropTypes.any]),
    onChange: PropTypes.func.isRequired,
    orientation: PropTypes.oneOf(['landscape', 'portrait']),
    minuteInterval: PropTypes.number,
    minTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TimePicker;
