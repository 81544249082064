import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as store from '../../store';

const { snackbarDuck } = store;

// eslint-disable-next-line react/prefer-stateless-function
class SnackbarProvider extends Component {
    render() {
        const { render, ...others } = this.props;
        return <>{render(others)}</>;
    }
}

const mapStateToProps = function mapStateToProps(state) {
    return {
        toShowSnackbar: snackbarDuck.duckSelectors.toShowSnackbarSelector(state),
        showingSnackbarId: snackbarDuck.duckSelectors.showingSnackbarIdSelector(state),
        shouldOpen: snackbarDuck.duckSelectors.shouldOpenSelector(state),
    };
};

SnackbarProvider.propTypes = {
    render: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
        snackbarOpened: (snackbarId) => dispatch(snackbarDuck.duckActionCreators.opened(snackbarId)),
        snackbarClosed: () => dispatch(snackbarDuck.duckActionCreators.closed()),
        snackbarExited: () => dispatch(snackbarDuck.duckActionCreators.exited()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarProvider);
