import autocompleteTheme from './autocomplete.theme';
import buttonTheme from './button.theme';
import inputTheme from './input.theme';
import selectTheme from './select.theme';
import sliderTheme from './slider.theme';
import surveyTheme from './survey.theme';

export default function themeSetter(theme) {
    let result = null;
    result = surveyTheme(theme);
    result = autocompleteTheme(result);
    result = buttonTheme(result);
    result = inputTheme(result);
    result = sliderTheme(result);
    result = selectTheme(result);

    return result;
}
