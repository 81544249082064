import { ChangeEvent, memo } from 'react';
import clsx from 'clsx';
import { FormControl, FormGroup } from '@material-ui/core';

import Choice from '$app-web/activities/survey/components/choice';
import { CHOICE_TYPES, CHOICES_LAYOUT_TYPES } from '$app-web/activities/survey/constants';

import classes from './styles.module.scss';

interface Props {
    options: Array<{ label: string; value: string }>;
    onChange: (value: Array<string>) => void;
    value: Array<string>;
    type: 'text' | 'url';
    layout: string;
    maxChoices?: number;
    exclusiveAnswerId?: number;
}

const TypeMultipleChoice = ({
    maxChoices = Number.MAX_SAFE_INTEGER,
    layout,
    options,
    type,
    value,
    exclusiveAnswerId,
    onChange,
}: Props) => {
    const correctMax = maxChoices > 0 ? maxChoices : Number.MAX_SAFE_INTEGER;
    const isTypeImage = type === CHOICE_TYPES.URL;

    return (
        <FormControl fullWidth>
            <FormGroup
                row={isTypeImage || layout === CHOICES_LAYOUT_TYPES.BUTTON}
                className={clsx({ [classes.imageRadioGroup]: isTypeImage })}
            >
                {options.map((option) => {
                    const sharedProps = {
                        key: option.value,
                        type: 'multiple' as const,
                        onChange: (event: ChangeEvent<HTMLInputElement>) => {
                            const { checked } = event.target;

                            const newValue = checked ? [...value, option.value] : value.filter((id) => id !== option.value);

                            if (exclusiveAnswerId != null) {
                                if (option.value === exclusiveAnswerId.toString()) {
                                    onChange(checked ? [option.value] : []);
                                } else {
                                    onChange(newValue.filter((id) => id !== exclusiveAnswerId.toString()));
                                }
                            } else {
                                onChange(newValue);
                            }
                        },
                        checked: value.includes(option.value),
                        disabled: value.length >= correctMax && !value.includes(option.value),
                    };

                    return isTypeImage ? (
                        <Choice
                            className={clsx(classes.choice, classes.imageChoice)}
                            variant="image"
                            imageSrc={option.label}
                            {...sharedProps}
                        />
                    ) : (
                        <Choice
                            className={classes.choice}
                            variant={layout === CHOICES_LAYOUT_TYPES.BUTTON ? 'button' : 'text'}
                            label={option.label}
                            {...sharedProps}
                        />
                    );
                })}
                {/* This s a fix for odd number of only image choices to make the last choice stick to left */}
                {isTypeImage && options.length % 2 !== 0 && <div style={{ width: '136px', display: 'flex' }} />}
            </FormGroup>
        </FormControl>
    );
};

export default memo(TypeMultipleChoice);
