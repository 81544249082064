import { Slider, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        height: '100%',
        padding: theme.spacing(2),
        color: theme.palette.background.default,
    },
    videoContainer: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
    },
    video: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    audioIcon: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
    },
    audioLoading: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
    },
}));

export const PlayerSlider = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.dark,
        height: 6,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: [['2px solid', theme.palette.primary.dark]],
        marginTop: -8,
        marginLeft: -12,
        '&:focus,&:hover,&$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 6,
        borderRadius: 3,
    },
    rail: {
        height: 6,
        borderRadius: 3,
        backgroundColor: theme.palette.primary.dark,
    },
}))(Slider);
