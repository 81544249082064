import { memo, MouseEvent, useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import TypeAudio from '$app-web/activities/survey/components/question/audio';
import TypeFreeFormText from '$app-web/activities/survey/components/question/free-form-text';
import { useTranslator } from '$app-web/hooks/use-translator';

import classes from './styles.module.scss';

interface Props {
    onChange: (value: { audioUrl: File | string | null; text: string }) => void;
    value: { audioUrl: File | string | null; text: string } | null;
    hint?: string;
    multiLine?: boolean;
}

const TypeAudioText = ({ value, onChange, hint, multiLine = false, ...restProps }: Props) => {
    const t = useTranslator();

    const [tab, setTab] = useState('audio');
    const validValue = value ?? { audioUrl: null, text: '' };

    const handleChangeTab = useCallback((_event: MouseEvent<HTMLElement>, selectedTab: unknown) => {
        if (typeof selectedTab === 'string' && selectedTab !== '') {
            setTab(selectedTab);
        }
    }, []);

    return (
        <Grid container direction="column" alignItems="center" spacing={4}>
            <Grid item>
                <ToggleButtonGroup value={tab} onChange={handleChangeTab} exclusive>
                    <ToggleButton data-testid="audio" value="audio">
                        {t('webActivities:survey_audio_text_options_audio')}
                    </ToggleButton>
                    <ToggleButton data-testid="text" value="text">
                        {t('webActivities:survey_audio_text_options_text')}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item className={classes.controlWrapper}>
                {tab === 'audio' && (
                    <TypeAudio
                        value={validValue.audioUrl}
                        onChange={(file) => onChange({ ...validValue, audioUrl: file })}
                        {...restProps}
                    />
                )}
                {tab === 'text' && (
                    <TypeFreeFormText
                        value={validValue.text}
                        onChange={(text) => onChange({ ...validValue, text })}
                        hint={hint}
                        multiLine={multiLine}
                        {...restProps}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default memo(TypeAudioText);
