import { createMuiTheme } from '@material-ui/core/styles';

const sliderTheme = (theme) =>
    createMuiTheme(theme, {
        props: {
            MuiSlider: {
                valueLabelFormat: (value) => (Number.isNaN(Number(value)) ? '' : value),
            },
        },
        overrides: {
            MuiSlider: {
                root: {
                    color: theme.palette.default.dark,
                    height: 4,
                },
                thumb: {
                    height: 20,
                    width: 20,
                    border: '2px solid currentColor',
                    marginTop: -8,
                    marginLeft: -12,
                    '&:focus, &:hover, &$active': {
                        boxShadow: 'inherit',
                    },
                    '&:after': {
                        boxShadow: theme.shadows.custom[2],
                        top: 'unset',
                        left: 'unset',
                        bottom: 'unset',
                        right: 'unset',
                        width: 20,
                        height: 20,
                    },
                    '$vertical &': {
                        marginLeft: -8,
                    },
                    '$vertical &$disabled': undefined,
                    '&$disabled': undefined,
                },
                valueLabel: {
                    fontSize: 14,
                    left: '-50%',
                    fontWeight: 600,
                    top: -22,
                    '& *': {
                        background: 'transparent',
                        color: theme.palette.default.main,
                    },
                    '$vertical &': {
                        top: 2,
                        left: -32,
                    },
                },
                track: {
                    height: 4,
                    borderRadius: 2,
                    '$vertical &': {
                        width: 4,
                    },
                },
                rail: {
                    height: 4,
                    borderRadius: 2,
                    '$vertical &': {
                        width: 4,
                    },
                },
                vertical: {},
                disabled: {},
            },
        },
    });

export default sliderTheme;
