interface DateParts {
    years: number;
    months: number;
    weeks: number;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

type DateInput = Date | number | string;

export abstract class SuperDate {
    static validate(date: DateInput): boolean {
        const standardDate = new Date(date);

        return standardDate.toString() !== 'Invalid Date';
    }

    static getParts(date: DateInput) {
        const standardDate = new Date(date);

        if (SuperDate.validate(date)) {
            return {
                year: standardDate.getFullYear(),
                month: standardDate.getMonth() + 1,
                day: standardDate.getDate(),
                hours: standardDate.getHours(),
                minutes: standardDate.getMinutes(),
                seconds: standardDate.getSeconds(),
            };
        }

        throw new Error('Invalid Date');
    }

    static getDifference(firstDate: DateInput, secondDate: DateInput): DateParts {
        const firstStandardDate = new Date(firstDate);
        const secondStandardDate = new Date(secondDate);

        if (SuperDate.validate(firstDate) && SuperDate.validate(secondDate)) {
            const diffInMilliSeconds = Math.abs(firstStandardDate.getTime() - secondStandardDate.getTime());
            const diffInSeconds = diffInMilliSeconds / 1000;
            const diffInMinutes = diffInSeconds / 60;
            const diffInHours = diffInMinutes / 60;
            const diffInDays = diffInHours / 24;
            const diffInWeeks = diffInDays / 7;
            const diffInMonths = diffInDays / 30;
            const diffInYears = diffInDays / 365;

            return {
                years: diffInYears,
                months: diffInMonths,
                weeks: diffInWeeks,
                days: diffInDays,
                hours: diffInHours,
                minutes: diffInMinutes,
                seconds: diffInSeconds,
            };
        }

        throw new Error('Invalid Date');
    }

    static ignoreTodayChanges(date: DateInput): Date {
        const standardDate = new Date(date);

        if (SuperDate.validate(date)) {
            const { year, month, day } = SuperDate.getParts(standardDate);

            return new Date(year, month, day, 0, 0, 0, 0);
        }

        throw new Error('Invalid Date');
    }

    static toMilliseconds(date: DateInput): number {
        const standardDate = new Date(date);

        if (SuperDate.validate(date)) {
            return standardDate.getTime();
        }

        throw new Error('Invalid Date');
    }

    static toSeconds(date: DateInput): number {
        const standardDate = new Date(date);

        if (SuperDate.validate(date)) {
            return standardDate.getTime() / 1000;
        }

        throw new Error('Invalid Date');
    }

    static toMinutes(date: DateInput): number {
        const standardDate = new Date(date);

        if (SuperDate.validate(date)) {
            return standardDate.getTime() / 1000 / 60;
        }

        throw new Error('Invalid Date');
    }

    static toHours(date: DateInput): number {
        const standardDate = new Date(date);

        if (SuperDate.validate(date)) {
            return standardDate.getTime() / 1000 / 60 / 60;
        }

        throw new Error('Invalid Date');
    }

    static toWeeks(date: DateInput): number {
        const standardDate = new Date(date);

        if (SuperDate.validate(date)) {
            return standardDate.getTime() / 1000 / 60 / 60 / 24 / 7;
        }

        throw new Error('Invalid Date');
    }

    static toMonths(date: DateInput): number {
        const standardDate = new Date(date);

        if (SuperDate.validate(date)) {
            return standardDate.getTime() / 1000 / 60 / 60 / 24 / 30;
        }

        throw new Error('Invalid Date');
    }

    static toYears(date: DateInput): number {
        const standardDate = new Date(date);

        if (SuperDate.validate(date)) {
            return standardDate.getTime() / 1000 / 60 / 60 / 24 / 365;
        }

        throw new Error('Invalid Date');
    }

    static getCurrentTimeAsId(): string {
        const dateString = new Date().toISOString();

        return dateString.replaceAll(/[^\d]/g, '');
    }

    static stringify(date: DateInput): string {
        const standardDate = new Date(date);

        if (SuperDate.validate(date)) {
            return standardDate.toISOString();
        }

        throw new Error('Invalid Date');
    }

    static humanizeDateTime(
        date: DateInput,
        options?: {
            dateStyle?: 'short' | 'medium' | 'long' | 'full';
            timeStyle?: 'short' | 'medium' | 'long' | 'full' | 'none';
            hour12?: boolean;
        }
    ): string {
        const standardDate = new Date(date);

        if (SuperDate.validate(date)) {
            const { dateStyle = 'medium', timeStyle = 'short', hour12 = false } = options ?? {};

            return standardDate.toLocaleString('en-US', {
                dateStyle,
                timeStyle: timeStyle === 'none' ? undefined : timeStyle,
                hour12,
            });
        }

        throw new Error(`Invalid Date: ${date} / Typeof input: ${typeof date}`);
    }

    static getHumanizedWeekRange(startDate: DateInput): string {
        const today = new Date(startDate);

        if (SuperDate.validate(startDate)) {
            const intl = new Intl.DateTimeFormat('en', { month: 'long', day: '2-digit', year: 'numeric' });
            const nextWeek = new Date(today.getTime() + 6 * 24 * 60 * 60 * 1000);
            const weekPeriod = intl.formatRange(today, nextWeek);

            return weekPeriod;
        } else {
            return '-';
        }
    }
}
