import APIError from './api.error';

export default class APIMappedError extends APIError {
    constructor(message, code, status, target, details, innerError, cause) {
        const theCode = code || 'EDF:APIMappedError';
        super(message, theCode, cause);
        this.status = status;
        this.target = target;
        this.details = details;
        this.innerError = innerError;
    }

    toJSON() {
        return {
            message: this.message,
            code: this.code,
            status: this.status,
            target: this.target,
            details: this.details,
            innerError: this.innerError,
        };
    }
}
