import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';

import styles from './styles';

const LoadingIndicatorError = ({ classes, errorMessage, errorButton }) => {
    return (
        <div className={classes.errorPaper}>
            <Typography variant="body1" className={classes.errorMessage}>
                {errorMessage}
            </Typography>
            {errorButton && errorButton}
        </div>
    );
};

LoadingIndicatorError.defaultProps = {
    errorButton: null,
};

LoadingIndicatorError.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    errorMessage: PropTypes.string.isRequired,
    errorButton: PropTypes.node,
};

export default withStyles(styles)(LoadingIndicatorError);
