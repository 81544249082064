import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { store as appI18nStore } from '@edf-pkg/app-i18n';
import appUtils from '@edf-pkg/app-utils';

import { activityDuck, inject } from '../../store';

inject();

const AsActivityHOC = (WrappedComponent) => {
    class AsActivity extends Component {
        static WrappedComponent = WrappedComponent;

        componentDidMount() {
            const { loadActivityData, type, options, sessionUUID, sessionInfo, i18nNamespace, changeNamespace, ensurePHRSent } =
                this.props;
            loadActivityData(type, sessionUUID, options, sessionInfo, ensurePHRSent);
            /*
                TODO: It's a temporary fix for loading activities texts as they are in web activities name space.
                 It'll not broke anything. But we can change the changeNamespace to loadNamespace.
             */
            if (i18nNamespace !== 'webActivities') {
                changeNamespace('webActivities');
            }
        }

        componentDidUpdate(prevProps) {
            const { open, onClose, activityFinished, options } = this.props;
            if (options.isSandbox && !options.timeUseSurvey) {
                return;
            }
            if (prevProps.open !== open && open === false) {
                onClose && onClose(activityFinished);
            }
        }

        componentWillUnmount() {
            const { resetActivity } = this.props;
            resetActivity();
        }

        render() {
            const { props } = this;
            return <WrappedComponent {...props} />;
        }
    }

    AsActivity.displayName = `AsActivity(${appUtils.component.getDisplayName(WrappedComponent)})`;

    AsActivity.defaultProps = {
        options: {},
        sessionInfo: {},
        onClose: () => {},
        sessionUUID: '',
        ensurePHRSent: false,
    };

    AsActivity.propTypes = {
        loadActivityData: PropTypes.func.isRequired,
        type: PropTypes.string.isRequired,
        i18nNamespace: PropTypes.string.isRequired,
        changeNamespace: PropTypes.func.isRequired,
        resetActivity: PropTypes.func.isRequired,
        closingInProgress: PropTypes.bool.isRequired,
        open: PropTypes.bool.isRequired,
        activityFinished: PropTypes.bool.isRequired,
        onClose: PropTypes.func,
        options: PropTypes.oneOfType([PropTypes.object]),
        sessionInfo: PropTypes.oneOfType([PropTypes.object]),
        sessionUUID: PropTypes.string,
        activityUUID: PropTypes.string.isRequired,
        ensurePHRSent: PropTypes.bool,
    };

    const mapStateToProps = (state, ownProps) => {
        return {
            isActivityLoadDataStatusLoading: activityDuck.duckSelectors.isLoadDataStatusLoadingSelector(state)(
                ownProps.activityUUID
            ),
            isActivityLoadDataStatusSucceeded: activityDuck.duckSelectors.isLoadDataStatusSucceededSelector(state)(
                ownProps.activityUUID
            ),
            isActivityLoadDataStatusFailed: activityDuck.duckSelectors.isLoadDataStatusFailedSelector(state)(
                ownProps.activityUUID
            ),
            isActivityStatusAnswerable: activityDuck.duckSelectors.isActivityStatusAnswerable(state)(ownProps.activityUUID),
            isActivityStatusUpcoming: activityDuck.duckSelectors.isActivityStatusUpcoming(state)(ownProps.activityUUID),
            isActivityStatusPast: activityDuck.duckSelectors.isActivityStatusPast(state)(ownProps.activityUUID),
            isActivityStatusFinished: activityDuck.duckSelectors.isActivityStatusFinished(state)(ownProps.activityUUID),
            closingInProgress: activityDuck.duckSelectors.closingInProgressSelector(state)(ownProps.activityUUID),
            open: activityDuck.duckSelectors.openSelector(state)(ownProps.activityUUID),
            activityFinished: activityDuck.duckSelectors.activityFinishedSelector(state)(ownProps.activityUUID),
            i18nNamespace: appI18nStore.i18nDuck.duckSelectors.namespaceSelector(state),
        };
    };

    const mapDispatchToProps = (dispatch, ownProps) => {
        return {
            loadActivityData: (activityType, sessionUUID, activityOption, activitySessionInfo, ensurePHRSent) =>
                dispatch(
                    activityDuck.duckActionCreators.loadData(
                        ownProps.activityUUID,
                        activityType,
                        sessionUUID,
                        activityOption,
                        activitySessionInfo,
                        ensurePHRSent
                    )
                ),
            resetActivity: () => dispatch(activityDuck.duckActionCreators.reset(ownProps.activityUUID)),
            changeNamespace: (namespace) => dispatch(appI18nStore.i18nDuck.duckActionCreators.changeNamespace(namespace)),
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(hoistNonReactStatics(AsActivity, WrappedComponent));
};

export default AsActivityHOC;
