import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import {
    Button,
    Dialog as MUIDialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
} from '@material-ui/core';

import { asDialog } from '@edf-pkg/app-main';

const Dialog = (props) => {
    const { id, isOpen, title, content, divider, disableOutClickClose, actions, dialogProps, close, onOpen, onClose, children } =
        props;

    const onCloseCallback = () => {
        close();
        if (onClose) {
            onClose(id);
        }
    };

    useEffect(() => {
        if (isOpen) {
            if (onOpen) {
                onOpen(id);
            }
        } else if (onClose) {
            onClose(id);
        }
    }, [id, isOpen, onClose, onOpen]);

    let contentElement = null;
    if (React.isValidElement(content)) {
        contentElement = content;
    } else {
        switch (typeof content) {
            case 'string':
                contentElement = content;
                break;
            case 'function':
                contentElement = content();
                break;
            // no default
        }
    }
    let actionsElement = null;
    if (actions !== null) {
        switch (typeof actions) {
            case 'object':
                actionsElement = Object.keys(actions).map((actionId) => {
                    const action = actions[actionId];
                    return (
                        <Button
                            key={actionId}
                            onClick={() => {
                                if (typeof action.onClick === 'function') {
                                    action.onClick();
                                }
                                if (action.autoClose) {
                                    onCloseCallback();
                                }
                            }}
                            color="secondary"
                        >
                            {action.text}
                        </Button>
                    );
                });
                break;
            case 'function':
                actionsElement = actions();
                break;
            // no default
        }
    }

    return (
        <div>
            <MUIDialog
                {...dialogProps}
                open={isOpen}
                onClose={onCloseCallback}
                disableBackdropClick={disableOutClickClose === true}
                disableEscapeKeyDown={disableOutClickClose === true}
            >
                {title && (
                    <DialogTitle>
                        {title}
                        {divider && <Divider light />}
                    </DialogTitle>
                )}
                {content && (
                    <DialogContent>
                        <DialogContentText component="div">{contentElement && contentElement}</DialogContentText>
                    </DialogContent>
                )}
                {actionsElement !== null && <DialogActions data-testid="dialogActions">{actionsElement}</DialogActions>}
                {children}
            </MUIDialog>
        </div>
    );
};

Dialog.defaultProps = {
    title: null,
    content: null,
    actions: null,
    divider: false,
    disableOutClickClose: false,
    dialogProps: {},
    onClose: null,
    onOpen: null,
    children: undefined,
};

Dialog.propTypes = {
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    title: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
    dialogProps: PropTypes.oneOfType([PropTypes.object]),
    actions: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.object]),
    divider: PropTypes.bool,
    disableOutClickClose: PropTypes.bool,
    open: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    children: PropTypes.node,
};

export default asDialog(Dialog);
