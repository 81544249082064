import { ReactElement, useMemo } from 'react';
import clsx from 'clsx';
import { ReactComponent as KibanaIcon } from '@assets/images/rdash/kibana.svg';
import { ReactComponent as AudioTextQuestionTypeIcon } from '@assets/images/survey/audio-text-question.svg';
import { ReactComponent as VasQuestionTypeIcon } from '@assets/images/survey/vas-question.svg';

import classes from './styles.module.scss';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

interface Props {
    icon?: string | Array<string> | { iconName: string; prefix: string };
    customIcon?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    color?: string;
    disabled?: boolean;
    className?: string;
    iconProps?: Record<string, unknown>;
}

const Icon = (props: Props): ReactElement => {
    const { size = 'md', color = 'gray-500', disabled = false, className, icon, customIcon, ...restProps } = props;

    const CustomIconComponent = useMemo(() => {
        switch (customIcon) {
            case 'kibana':
                return KibanaIcon;
            case 'audio-text':
                return AudioTextQuestionTypeIcon;
            case 'vas':
                return VasQuestionTypeIcon;
            default:
                return null;
        }
    }, [customIcon]);

    const getCommonIconProps = () => ({
        className: clsx('color', {
            [classes['space-200']]: disabled,
            [color]: !disabled,
            // TODO: Get rid of the following line after making the color system more consistent:
            [classes[color]]: !disabled,
        }),
        ...restProps,
    });

    return (
        <div className={clsx(classes.root, classes[size], className)}>
            {customIcon && CustomIconComponent !== null ? (
                <CustomIconComponent {...getCommonIconProps()} />
            ) : (
                <FontAwesomeIcon fixedWidth icon={icon} {...getCommonIconProps()} />
            )}
        </div>
    );
};

export default Icon;
