import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import FontAwesomeButton from '$app-web/components/font-awesome-button';

import useStyles from './styles';

const Header = ({ onCloseClick, alignCloseStart }) => {
    const classes = useStyles();
    return (
        <Grid container direction="row" justify={alignCloseStart ? 'flex-start' : 'flex-end'} wrap="nowrap">
            <Grid item>
                <FontAwesomeButton
                    data-testid="close"
                    icon={['fal', 'times']}
                    className={classes.iconButton}
                    onClick={onCloseClick}
                />
            </Grid>
        </Grid>
    );
};

Header.propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    alignCloseStart: PropTypes.bool.isRequired,
};

export default Header;
