import { ORDINAL_NUMBERS_SUFFIX_MAP } from '$app-web/constants';

export abstract class SuperNumber {
    private static readonly BASE_POUND = 2.20462262185 as const;

    private static readonly BASE_INCH = 2.54 as const;

    static round(value: number, precision: number) {
        const multiplier = 10 ** precision;

        return Math.round(value * multiplier) / multiplier;
    }

    static centimetersToFeetAndInches(length: number) {
        const totalInches = SuperNumber.round(length / SuperNumber.BASE_INCH, 4);
        const feet = Math.floor(totalInches / 12);
        const inches = Math.floor(totalInches % 12);

        return { feet, inches };
    }

    static feetAndInchesToCentimeters(feet: number, inches: number) {
        const totalInches = feet * 12 + inches;
        const centimeters = SuperNumber.round(totalInches * SuperNumber.BASE_INCH, 4);

        return centimeters;
    }

    static kilogramToPound(kg: number) {
        const convertedValue = kg * SuperNumber.BASE_POUND;

        return SuperNumber.round(convertedValue, 4);
    }

    static poundToKilogram(pound: number) {
        const convertedValue = pound / SuperNumber.BASE_POUND;

        return SuperNumber.round(convertedValue, 4);
    }

    static getOrdinalNumber(number: number, locale = 'en-US') {
        const availableLocales = Object.keys(ORDINAL_NUMBERS_SUFFIX_MAP);
        const targetLocale = availableLocales.includes(locale) ? locale : 'en-US';
        const ordinalRules = new Intl.PluralRules(targetLocale, { type: 'ordinal' });
        const pluralRule = ordinalRules.select(number);
        const ordinalSuffixMap = ORDINAL_NUMBERS_SUFFIX_MAP[targetLocale];
        const suffix = ordinalSuffixMap[pluralRule];

        return `${number}${suffix}`;
    }

    static getPercentage(value: number, total: number, precision = 0) {
        return SuperNumber.round((value / total) * 100, precision);
    }
}
