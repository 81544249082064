import produce from 'immer';
import { getSelector, namespaceReducerActions } from 'retrack';

import { HTTP_CALL_STATUS, PHR_EVENT_TYPES } from '../constants';

export const DUCK_NAME = 'phr';

const initialState = {
    sendStatus: HTTP_CALL_STATUS.UNKNOWN,
};

export const duckActions = {
    SET_SEND_STATUS: 'SET_SEND_STATUS',
    APPLICATION_OPENED: 'APPLICATION_OPENED',
    USER_SIGN_ACTION: 'USER_SIGN_ACTION',
    USER_LOGOUT: 'USER_LOGOUT',
    ACTIVITY_OPENED: 'ACTIVITY_OPENED',
    ACTIVITY_CLOSED: 'ACTIVITY_CLOSED',
    ACTIVITY_FINISHED: 'ACTIVITY_FINISHED',
    ACTIVITY_RESPONSES_UPLOAD_STARTED: 'RESPONSES_UPLOAD_STARTED',
    ACTIVITY_RESPONSES_UPLOAD_FINISHED: 'RESPONSES_UPLOAD_FINISHED',
    ACTIVITY_RESPONSES_UPLOAD_FAILED: 'RESPONSES_UPLOAD_FAILED',
    ROUTE_VISITED: 'ROUTE_VISITED',
    EDEN_PHR_EVENTS: 'EDEN_PHR_EVENTS',
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case duckActions.SET_SEND_STATUS:
            draft.sendStatus = action.payload.status;
            break;
        // no default
    }
}, initialState);

namespaceReducerActions(reducer, duckActions);

export default reducer;

export const duckActionCreators = {
    setSendStatusSucceeded() {
        return {
            type: duckActions.SET_SEND_STATUS,
            payload: {
                status: HTTP_CALL_STATUS.SUCCEEDED,
            },
        };
    },
    setSendStatusFailed() {
        return {
            type: duckActions.SET_SEND_STATUS,
            payload: {
                status: HTTP_CALL_STATUS.FAILED,
            },
        };
    },
    sendApplicationOpened(studies) {
        return {
            type: duckActions.APPLICATION_OPENED,
            payload: { studies },
        };
    },
    sendUserSignAction(studies) {
        return {
            type: duckActions.USER_SIGN_ACTION,
            payload: { studies },
        };
    },
    sendUserLogout(studies) {
        return {
            type: duckActions.USER_LOGOUT,
            payload: { studies },
        };
    },
    sendActivityOpened: function sendActivityOpened(studyId, activityId, sessionUuid) {
        return {
            type: duckActions.ACTIVITY_OPENED,
            payload: { eventType: PHR_EVENT_TYPES.ACTIVITY_OPENED, studyId, activityId, sessionUuid },
        };
    },
    sendActivityClosed: function sendActivityClosed(studyId, activityId, sessionUuid) {
        return {
            type: duckActions.ACTIVITY_CLOSED,
            payload: { eventType: PHR_EVENT_TYPES.ACTIVITY_CLOSED, studyId, activityId, sessionUuid },
        };
    },
    sendActivityFinished: function sendActivityFinished(
        studyId,
        activityId,
        sessionUuid,
        sTimeMs,
        pTimeMs,
        rTimeMs,
        statusId,
        tlTypeId
    ) {
        return {
            type: duckActions.ACTIVITY_FINISHED,
            payload: {
                eventType: PHR_EVENT_TYPES.ACTIVITY_FINISHED,
                studyId,
                activityId,
                sessionUuid,
                sTimeMs,
                pTimeMs,
                rTimeMs,
                statusId,
                tlTypeId,
            },
        };
    },
    sendActivityResponseUploadStarted: function sendResponseUploadStarted(sessionUuid, studyId) {
        return {
            type: duckActions.ACTIVITY_RESPONSES_UPLOAD_STARTED,
            payload: { eventType: PHR_EVENT_TYPES.ACTIVITY_RESPONSES_UPLOAD_STARTED, studyId, sessionUuid },
        };
    },
    sendActivityResponseUploadFinished: function sendResponseUploadFinished(
        sessionUuid,
        studyId,
        startTimeMs,
        sessionsSubmitted
    ) {
        return {
            type: duckActions.ACTIVITY_RESPONSES_UPLOAD_FINISHED,
            payload: { eventType: PHR_EVENT_TYPES.ACTIVITY_RESPONSES_UPLOAD_FINISHED, studyId, startTimeMs, sessionsSubmitted },
        };
    },
    sendActivityResponseUploadFailed: function sendResponseUploadFailed(sessionUuid, studyId, startTimeMs, error) {
        return {
            type: duckActions.ACTIVITY_RESPONSES_UPLOAD_FAILED,
            payload: { eventType: PHR_EVENT_TYPES.ACTIVITY_RESPONSES_UPLOAD_FAILED, studyId, startTimeMs, error },
        };
    },
    sendRouteVisited: function sendRouteVisited(event, studyId) {
        return {
            type: duckActions.ROUTE_VISITED,
            payload: { eventType: event, studyId },
        };
    },
    sendEdenEvent: function sendEdenEvent(eventType, ethicaId, studyId, consentType = null, actionType = null) {
        return {
            type: duckActions.EDEN_PHR_EVENTS,
            payload: {
                eventType,
                studyId,
                ethicaId,
                actionType,
                consentType,
            },
        };
    },
};

const PHRSelector = getSelector(reducer);

export const duckSelectors = {
    PHRSelector,
};
