import { FatalError } from '@edf-pkg/app-error';

import { MobileInjectedFunctions } from '../types';
import NativeToJsFunctionHelperAndroid from './/native-to-js-function-helper.android';
import { NativeToJavaScriptFunctionHelper } from './native-to-js-function';
import NativeToJsFunctionHelperIos from './native-to-js-function-helper.ios';
import NativeToJsFunctionHelperWeb from './native-to-js-function-helper.web';
import platformHelper from './platform-helper';

class NativeToJsHelper {
    injectedBaseObject: MobileInjectedFunctions | null = null;
    functionsHelper: NativeToJavaScriptFunctionHelper | null = null;

    initialize() {
        if (!platformHelper.isPlatformIos()) {
            this.getInjectedBaseObject();
            this.validateBaseObject();
            this.checkNeededFunctionsInjected();
        }
        this.createFunctionsHelperForPlatform();
    }

    getInjectedBaseObject() {
        if (platformHelper.isPlatformWeb()) {
            if (window.edWeb) {
                this.injectedBaseObject = window.edWeb;
            }
        } else if (platformHelper.isPlatformAndroid()) {
            this.injectedBaseObject = window.WebActivityRef;
        }
    }

    validateBaseObject() {
        if (!(this.injectedBaseObject && Object.keys(this.injectedBaseObject).length > 0)) {
            // The console.logs below are added for debugging and investigation of an issue (related task: ET-9172)
            // Make sure to communicate with the author of that task in case you wanted to remove them.

            // eslint-disable-next-line no-console
            console.log('LOG injectedBaseObject >>> ', this.injectedBaseObject);
            // eslint-disable-next-line no-console
            console.log('LOG WebActivityRef >>> ', window?.WebActivityRef);
            // eslint-disable-next-line no-console
            console.log('LOG isPlatformWeb >>> ', platformHelper?.isPlatformWeb());
            // eslint-disable-next-line no-console
            console.log('LOG isPlatformAndroid >>> ', platformHelper?.isPlatformAndroid());

            throw new FatalError('The injectedBaseObject is not valid.', this.injectedBaseObject);
        }
    }

    checkNeededFunctionsInjected() {
        const NEEDED_INJECTED_FUNCTION_NAMES = [
            'getCurrentUserCredentials',
            'back',
            'exit',
            'getOptions',
            'getSessionInfo',
            'onLoadingCompleted',
            'onLoadingFailed',
            // TODO: This function is a new feature therefore doesn't get injected in older versions of mobile apps, so keep it commented for 1 year. (till mid-2025)
            // Not that this array is only to check if the function is injected or not, the actual usage of the function is done somewhere else without issues.
            // 'shareImage',
        ];

        const neededFunctionsInjected = NEEDED_INJECTED_FUNCTION_NAMES.reduce(
            (allNeededFunctionsInjectedUntilNow, injectedFunctionName) => {
                if (allNeededFunctionsInjectedUntilNow) {
                    return Object.prototype.hasOwnProperty.call(this.injectedBaseObject, injectedFunctionName);
                }
                return allNeededFunctionsInjectedUntilNow;
            },
            true
        );
        if (!neededFunctionsInjected) {
            throw new FatalError('Needed functions to be injected not found');
        }
    }

    createFunctionsHelperForPlatform() {
        if (platformHelper.isPlatformWeb()) {
            this.functionsHelper = new NativeToJsFunctionHelperWeb();
        } else if (platformHelper.isPlatformAndroid()) {
            this.functionsHelper = new NativeToJsFunctionHelperAndroid();
        } else if (platformHelper.isPlatformIos()) {
            this.functionsHelper = new NativeToJsFunctionHelperIos();
        }
    }
}

const instance = new NativeToJsHelper();
export default instance;
