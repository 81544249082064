import { FatalError } from '@edf-pkg/app-error';

import { SuperObject } from '../object';
import { ModuleURLsManager } from './module-urls-manager';

export class SpaceURLsManager {
    spaceId: string;

    modules: Record<string, ModuleURLsManager> = {};

    constructor(spaceId: string) {
        this.spaceId = spaceId;
    }

    inSpace() {
        const parsedPath = new URL(window.location.href);
        return parsedPath.pathname.includes(this.spaceId);
    }

    addModule(moduleId: string) {
        if (!SuperObject.hasKey(this.modules, moduleId)) {
            this.modules[moduleId] = new ModuleURLsManager(this.spaceId, moduleId);
        }
        return this.modules[moduleId];
    }

    module(moduleId: string) {
        if (!SuperObject.hasKey(this.modules, moduleId)) {
            this.addModule(moduleId);
        }

        return this.modules[moduleId];
    }

    hasModule(moduleId: string) {
        return SuperObject.hasKey(this.modules, moduleId);
    }

    getCurrentModuleId() {
        const parsedPath = new URL(window.location.href);
        const splitPathname = parsedPath.pathname.split('/');
        let moduleId = '';
        for (let pathPartIndex = 0; pathPartIndex < splitPathname.length; pathPartIndex += 1) {
            if (splitPathname[pathPartIndex] === this.spaceId) {
                moduleId = splitPathname[pathPartIndex + 1];
                break;
            }
        }
        if (Object.keys(this.modules).indexOf(moduleId) >= 0) {
            return moduleId;
        }
        throw new FatalError(`Trying to get moduleId of unknown space module. spaceId: ${this.spaceId} - moduleId: ${moduleId}`);
    }
}
