import React from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';

import classes from './styles.module.scss';

const Card = (props) => {
    const { className, children } = props;

    return <div className={clsx(classes.root, className)}>{children}</div>;
};

Card.defaultProps = {
    className: undefined,
    children: undefined,
};

Card.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default Card;
