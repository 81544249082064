import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import appUtils from '@edf-pkg/app-utils';

import { dialogDuck } from '../../store';

const AsDialogHOC = (WrappedComponent) => {
    class AsDialog extends Component {
        static WrappedComponent = WrappedComponent;

        componentDidMount() {
            const { id, registerDialog } = this.props;
            registerDialog(id);
        }

        componentWillUnmount() {
            const { id, unregisterDialog } = this.props;
            unregisterDialog(id);
        }

        close = () => {
            const { id, closeDialog } = this.props;
            closeDialog(id);
        };

        open = () => {
            const { id, openDialog } = this.props;
            openDialog(id);
        };

        render() {
            const { props } = this;
            const { id, dialogs } = this.props;
            const isOpen = appUtils.object.hasKey(dialogs, id) && dialogs[id].opened === true;
            return <WrappedComponent {...props} isOpen={isOpen} open={this.open} close={this.close} />;
        }
    }

    AsDialog.displayName = `AsDialog(${appUtils.component.getDisplayName(WrappedComponent)})`;

    AsDialog.propTypes = {
        id: PropTypes.string.isRequired,
        dialogs: PropTypes.oneOfType([PropTypes.object]).isRequired,
        registerDialog: PropTypes.func.isRequired,
        unregisterDialog: PropTypes.func.isRequired,
        openDialog: PropTypes.func.isRequired,
        closeDialog: PropTypes.func.isRequired,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
    };

    const mapStateToProps = (state) => {
        return {
            dialogs: dialogDuck.duckSelectors.dialogsSelector(state),
        };
    };

    const mapDispatchToProps = (dispatch) => {
        return {
            registerDialog: (dialogId) => {
                dispatch(dialogDuck.duckActionCreators.register(dialogId));
            },
            unregisterDialog: (dialogId) => {
                dispatch(dialogDuck.duckActionCreators.unregister(dialogId));
            },
            closeDialog: (dialogId) => {
                dispatch(dialogDuck.duckActionCreators.close(dialogId));
            },
            openDialog: (dialogId) => {
                dispatch(dialogDuck.duckActionCreators.open(dialogId));
            },
        };
    };

    AsDialog.defaultProps = {
        onOpen: null,
        onClose: null,
    };

    return connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        hoistNonReactStatics(AsDialog, WrappedComponent)
    );
};

export default AsDialogHOC;
