import React, { useCallback, useLayoutEffect, useState } from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DayjsUtil from '@date-io/dayjs';
import { Button, Dialog, DialogActions, useMediaQuery, useTheme } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import appDateTime from '@edf-pkg/app-date-time';

import useStyles from './styles';
import TimePicker from './time-picker';

const DateTimePicker = ({
    open,
    close,
    value,
    onChange,
    variant,
    clearable,
    disableFuture,
    minuteInterval,
    minDate,
    maxDate,
}) => {
    // TODO: Refactor this component after upgrading to @material-ui/pickers v4
    const classes = useStyles();

    const { t } = useTranslation();

    // TODO: find a better way than `step` to handle date and time selecting
    const [step, setStep] = useState(-1);
    const [internalValue, setInternalValue] = useState(null);

    useLayoutEffect(() => {
        if (open) {
            if (value && (typeof value.isValid !== 'function' || value.isValid())) {
                setInternalValue(appDateTime(value));
            } else if (appDateTime().isBefore(appDateTime(minDate))) {
                setInternalValue(appDateTime(minDate));
            } else if (appDateTime().isAfter(appDateTime(maxDate))) {
                setInternalValue(appDateTime(maxDate));
            } else {
                setInternalValue(appDateTime());
            }
            setStep(variant === 'time' ? 1 : 0);
        }
    }, [maxDate, minDate, open, value, variant]);

    const onInternalChangeCallback = useCallback((dateTime) => setInternalValue(appDateTime(dateTime)), []);

    const dateTimeDateSelect = useCallback((dateTime) => {
        setStep(1);
        setInternalValue(appDateTime(dateTime));
    }, []);

    const onChangeCallback = useCallback(
        (clear) => {
            close();
            if (clear) {
                onChange(null);
            } else {
                const selectedDateTime = appDateTime(internalValue);
                if (variant === 'date') {
                    onChange(selectedDateTime.startOfDay().toISOString());
                } else {
                    onChange(selectedDateTime.startOfMinute().toISOString());
                }
            }
        },
        [close, internalValue, onChange, variant]
    );

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const renderDay = useCallback((_day, _selectedDate, _dayInCurrentMonth, dayComponent) => {
        return {
            ...dayComponent,
            props: {
                ...dayComponent.props,
                'data-testid': 'calendar-day-cell',
            },
        };
    }, []);

    return (
        <Dialog data-testid="dateTimePicker" open={open} onClose={close} maxWidth="lg" classes={{ paper: classes.paper }}>
            <div
                className={clsx(classes.dialogRoot, {
                    [classes.dialogRootLandscape]: matches,
                })}
            >
                <MuiPickersUtilsProvider utils={DayjsUtil}>
                    <>
                        {step === 1 && (
                            <TimePicker
                                value={internalValue}
                                onChange={onInternalChangeCallback}
                                orientation={matches ? 'landscape' : 'portrait'}
                                minuteInterval={minuteInterval}
                                minTime={minDate || undefined}
                                maxTime={maxDate || undefined}
                            />
                        )}
                        {step === 0 && (
                            <DatePicker
                                autoOk
                                variant="static"
                                orientation={matches ? 'landscape' : 'portrait'}
                                value={appDateTime(internalValue).toISOString()}
                                onChange={variant === 'date-time' ? dateTimeDateSelect : onInternalChangeCallback}
                                disableFuture={disableFuture}
                                minDate={minDate || undefined}
                                maxDate={maxDate || undefined}
                                leftArrowButtonProps={{ color: 'primary' }}
                                rightArrowButtonProps={{ color: 'primary' }}
                                renderDay={renderDay}
                            />
                        )}
                    </>
                </MuiPickersUtilsProvider>
            </div>
            <DialogActions className={clsx({ [classes.withAdditionalAction]: clearable })}>
                {clearable && (
                    <Button color="secondary" onClick={() => onChangeCallback(true)}>
                        {t('clear')}
                    </Button>
                )}
                {step === 1 && variant === 'date-time' && (
                    <Button data-testid="selectDate" color="secondary" onClick={() => setStep(0)}>
                        {t('survey_question_calendar_select_date')}
                    </Button>
                )}
                <Button color="secondary" onClick={close} style={{ float: 'left' }}>
                    {t('cancel')}
                </Button>
                {step === 0 && variant === 'date-time' && (
                    <Button data-testid="selectTime" color="secondary" onClick={() => setStep(1)}>
                        {t('survey_question_calendar_select_time')}
                    </Button>
                )}

                {(step === 1 || variant === 'date') && (
                    <Button data-testid="ok" color="secondary" onClick={() => onChangeCallback(false)}>
                        {t('ok')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

DateTimePicker.defaultProps = {
    value: null,
    variant: 'date-time',
    clearable: false,
    disableFuture: undefined,
    minuteInterval: 1,
    minDate: undefined,
    maxDate: undefined,
};

DateTimePicker.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.any]),
    onChange: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['time', 'date', 'date-time']),
    clearable: PropTypes.bool,
    disableFuture: PropTypes.bool,
    minuteInterval: PropTypes.number,
    minDate: PropTypes.oneOfType([PropTypes.any]),
    maxDate: PropTypes.oneOfType([PropTypes.any]),
};

export default DateTimePicker;
