export const HTTP_CALL_STATUS = {
    UNKNOWN: 'UNKNOWN',
    LOADING: 'LOADING',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
};

export const ACTIVITY_STATUS = {
    UNKNOWN: 'UNKNOWN',
    ANSWERABLE: 'ANSWERABLE',
    FINISHED: 'FINISHED',
    UPCOMING: 'UPCOMING',
    PAST: 'PAST',
};

export const TL_TYPES = {
    MANUAL: 0,
    TIME: 2,
    USER: 4,
    TIME_USER_DEF: 6,
};

export const SESSION_STATUSES = {
    UNKNOWN: 0,
    SUBMITTED: 1,
    CANCELED: 2,
    EXPIRED: 3,
    BLOCKED: 4,
    INVALID_CRITERIA: 5,
    IN_PROGRESS: 6,
};
