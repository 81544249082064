import { memo, useLayoutEffect } from 'react';
import { Backdrop, Modal } from '@material-ui/core';

import { errorHandle } from '@edf-pkg/app-error';

import { lazyLoadInline, withTimeoutAndDelay } from '$app-web/utils/lazy-load';

const Capture = lazyLoadInline(() => withTimeoutAndDelay(() => import('./capture')));

interface Props {
    open: boolean;
    preload?: boolean;
    onClose: (file: File | null) => void;
    type: 'image' | 'video' | 'audio';
}

const MediaCapture = ({ open, preload = false, onClose, type }: Props) => {
    useLayoutEffect(() => {
        if (preload) {
            Capture.preload().catch((error) => errorHandle.anError(error));
        }
    }, [preload]);

    return (
        <Modal open={open} BackdropComponent={Backdrop} BackdropProps={{ style: { backgroundColor: '#000' } }}>
            <Capture type={type} onClose={onClose} />
        </Modal>
    );
};

export default memo(MediaCapture);
