import Language from '../core/language';

const pdashNamespace = import('../namespaces/en-us.pdash.namespace');
const rdashNamespace = import('../namespaces/en-us.rdash.namespace');
const webActivitiesNamespace = import('../namespaces/en-us.web-activities.namespace');
const rdashTranslatablesNamespace = import('../namespaces/en-us.rdash-translatables.namespace');

class EnUSLanguage extends Language {
    code = 'en-US';

    locale = 'en';

    localName = 'English';

    englishName = '';

    territory = 'US';

    notLoadedNamespaces = {
        pdash: pdashNamespace,
        rdash: rdashNamespace,
        webActivities: webActivitiesNamespace,
        rdashTranslatables: rdashTranslatablesNamespace,
    };
}

const instance = new EnUSLanguage();
export default instance;
