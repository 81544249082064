import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    loadingWrapper: {
        backgroundColor: theme.palette.background.default,
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        maxHeight: '100%',
        position: 'relative',
    },
    root: {
        backgroundColor: theme.palette.background.default,
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        maxHeight: '100%',
    },
    headerWrapper: {
        height: 52,
        maxHeight: 52,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 7),
        },
    },
    progressWrapper: {
        height: 4,
        maxHeight: 4,
    },
    actGovernmentLogoImage: {
        maxWidth: '140px',
        objectFit: 'fill',
    },
    actArtworkImage: {
        maxWidth: '150px',
        objectFit: 'fill',
    },
    actPurpleProgressRoot: {
        backgroundColor: '#DFD7E3',
    },
    actPurpleProgressBar: {
        backgroundColor: theme.palette.actHealth.main,
    },
    contentWrapper: {
        height: 'calc(100% - 56px)',
        maxHeight: 'calc(100% - 56px)',
    },
    containerWrapper: {
        height: '100%',
        maxHeight: '100%',
    },
    contentInnerWrapper: {
        height: '100%',
        maxHeight: '100%',
    },
    questionWrapper: {
        position: 'relative',
        height: 'calc(100% - 52px)',
        maxHeight: 'calc(100% - 52px)',
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100% - 68px)',
            maxHeight: 'calc(100% - 68px)',
        },
    },
    footerWrapper: {
        marginTop: theme.spacing(0.5),
        height: 48,
        maxHeight: 48,
        [theme.breakpoints.up('sm')]: {
            height: 64,
            maxHeight: 64,
        },
    },
    footerFirstCell: {
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(2),
        },
    },
    questionInnerWrapper: {
        width: '100%',
        minHeight: '100%',
        padding: theme.spacing(1),
        overflowX: 'hidden',
        rowGap: '32px',
    },
}));
