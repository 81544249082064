import produce from 'immer';
import { createSelector } from 'reselect';
import { getSelector, namespaceReducerActions } from 'retrack';

export const DUCK_NAME = 'dialog';

const initialState = {
    dialogs: {},
};

export const duckActions = {
    REGISTER: 'REGISTER',
    UNREGISTER: 'UNREGISTER',
    ADD: 'ADD',
    REMOVE: 'REMOVE',
    OPEN: 'OPEN',
    CLOSE: 'CLOSE',
    UPDATE_OPEN_STATUS: 'UPDATE_OPEN_STATUS',
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case duckActions.ADD:
            draft.dialogs[action.payload.dialogId] = { opened: false };
            break;
        case duckActions.REMOVE:
            delete draft.dialogs[action.payload.dialogId];
            break;
        case duckActions.UPDATE_OPEN_STATUS:
            draft.dialogs[action.payload.dialogId] = {
                opened: action.payload.openStatus,
            };
            break;
        // no default
    }
}, initialState);

namespaceReducerActions(reducer, duckActions);

export default reducer;

export const duckActionCreators = {
    register: function register(dialogId) {
        return {
            type: duckActions.REGISTER,
            payload: { dialogId },
        };
    },
    unregister: function unregister(dialogId) {
        return {
            type: duckActions.UNREGISTER,
            payload: { dialogId },
        };
    },
    add: function add(dialogId) {
        return {
            type: duckActions.ADD,
            payload: { dialogId },
        };
    },
    remove: function remove(dialogId) {
        return {
            type: duckActions.REMOVE,
            payload: { dialogId },
        };
    },
    open: function open(dialogId) {
        return {
            type: duckActions.OPEN,
            payload: { dialogId },
        };
    },
    close: function close(dialogId) {
        return { type: duckActions.CLOSE, payload: { dialogId } };
    },
    setOpenStatusOpened: function setOpenStatusOpened(dialogId) {
        return {
            type: duckActions.UPDATE_OPEN_STATUS,
            payload: { dialogId, openStatus: true },
        };
    },
    setOpenStatusClosed: function setOpenStatusClosed(dialogId) {
        return {
            type: duckActions.UPDATE_OPEN_STATUS,
            payload: { dialogId, openStatus: false },
        };
    },
};

const dialogSelector = getSelector(reducer);

const dialogsSelector = createSelector(dialogSelector, (dialogState) => dialogState.dialogs);

export const duckSelectors = {
    dialogSelector,
    dialogsSelector,
};
