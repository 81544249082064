import { fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        borderLeft: `4px solid ${theme.palette.default.main}`,
        boxShadow: theme.shadows.custom[2],
        backgroundColor: theme.palette.default.light,
        borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
        padding: theme.spacing(0.5, 1.5, 0.5, 0),
        userSelect: 'none',
        alignItems: 'flex-start',
        '@media (hover: hover)': {
            '&:not($disabled):hover': {
                backgroundColor: theme.palette.default.main,
                color: theme.palette.background.default,
            },
        },
        '&$disabled': {
            backgroundColor: fade(theme.palette.default.main, 0.1),
            borderLeft: `4px solid ${fade(theme.palette.default.main, 0.4)}`,
        },
    },
    rootChecked: {
        backgroundColor: theme.palette.default.main,
        color: theme.palette.background.default,
    },
    rootACTPurple: {
        backgroundColor: theme.palette.actHealth.lighter,
        borderLeftColor: theme.palette.actHealth.main,
        '@media (hover: hover)': {
            '&:not($disabled):hover': {
                backgroundColor: theme.palette.actHealth.main,
            },
        },
        '&$disabled': {
            backgroundColor: fade(theme.palette.actHealth.main, 0.1),
            borderLeft: `4px solid ${fade(theme.palette.actHealth.main, 0.4)}`,
        },
        '&$rootChecked': {
            backgroundColor: theme.palette.actHealth.main,
        },
    },
    rootVariantButton: {
        border: 0,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(0.5, 1.5),
        '&$disabled': {
            backgroundColor: fade(theme.palette.default.main, 0.1),
            border: 0,
        },
    },
    rootFullWidth: {
        display: 'flex',
    },
    labelText: {
        paddingTop: 3,
        display: 'inline-block',
        ...theme.typography.caption,
    },
    labelImage: {
        width: 80,
        height: 80,
        maxWidth: 80,
        maxHeight: 80,
        marginBottom: 4,
    },
    labelVariantButton: {
        paddingTop: 0,
    },
    disabled: {},
    controlVariantButton: {
        display: 'none',
    },
}));
