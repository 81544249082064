import { DataPermissionName, ProtocolPermissionName } from '$app-web/types/permissions';

export const ALL_SITES_ID = 0;

export const ACCESS_LEVEL = {
    FULL: 2,
    VIEW: 1,
    DENY: 0,
} as const;

export const ACCESS_LEVEL_NAME = {
    [ACCESS_LEVEL.FULL]: 'FULL',
    [ACCESS_LEVEL.VIEW]: 'VIEW',
    [ACCESS_LEVEL.DENY]: 'DENY',
} as const;

export const PERMISSION_TYPES = {
    PROTOCOL: 'protocol',
    DATA: 'data',
} as const;

export const PROTOCOL_PERMISSION_NAME = {
    BASICS: 'study.basics',
    ACTIVITIES: 'activity.activities',
    DATA_SOURCES: 'dataSource.dataSources',
    NOTIFICATION_TEMPLATES: 'notification.notificationTemplates',
} as const;

export const DATA_PERMISSION_NAME = {
    NOTIFICATION: 'notification.notification',
    PARTICIPATION: 'registration.participation',
    SESSIONS: 'activity.sessions',
    RESPONSES: 'activity.responses',
    SENSOR_DATA: 'dataSource.sensorData',
} as const;

export const PROTOCOL_PERMISSION_NAMES = Object.values(PROTOCOL_PERMISSION_NAME) as Array<ProtocolPermissionName>;
export const DATA_PERMISSION_NAMES = Object.values(DATA_PERMISSION_NAME) as Array<DataPermissionName>;

export const DEFAULT_PROTOCOL_ACCESS_LEVEL = ACCESS_LEVEL.VIEW;
export const DEFAULT_DATA_ACCESS_LEVEL = ACCESS_LEVEL.DENY;

/**
 * All defined user permissions + their available access levels (except DENY).
 * > If ever needed to be updated, please make sure you'll update the related constant(s) as well.
 */
export const PERMISSION = {
    STUDY: {
        // Protocol
        BASICS: {
            FULL: 'study.basics.FULL',
            VIEW: 'study.basics.VIEW',
        },
    },
    ACTIVITY: {
        // Protocol
        ACTIVITIES: {
            FULL: 'activity.activities.FULL',
            VIEW: 'activity.activities.VIEW',
        },
        // Data
        SESSIONS: {
            FULL: 'activity.sessions.FULL',
            VIEW: 'activity.sessions.VIEW',
        },
        // Data
        RESPONSES: {
            FULL: 'activity.responses.FULL',
            VIEW: 'activity.responses.VIEW',
        },
    },
    DATA_SOURCE: {
        // Protocol
        DATA_SOURCES: {
            FULL: 'dataSource.dataSources.FULL',
            VIEW: 'dataSource.dataSources.VIEW',
        },
        // Data
        SENSOR_DATA: {
            VIEW: 'dataSource.sensorData.VIEW',
        },
    },
    NOTIFICATION: {
        // Data
        NOTIFICATION: {
            FULL: 'notification.notification.FULL',
            VIEW: 'notification.notification.VIEW',
        },
        // Protocol
        NOTIFICATION_TEMPLATES: {
            FULL: 'notification.notificationTemplates.FULL',
            VIEW: 'notification.notificationTemplates.VIEW',
        },
    },
    REGISTRATION: {
        // Data
        PARTICIPATION: {
            FULL: 'registration.participation.FULL',
            VIEW: 'registration.participation.VIEW',
        },
    },
};
