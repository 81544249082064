import { memo, ReactNode } from 'react';
import clsx from 'clsx';

import Icon from '$app-web/ui-kit/components/icon';
import Loading from '$app-web/ui-kit/components/loading';

import classes from './styles.module.scss';

interface Props {
    label?: ReactNode;
    disabled?: boolean;
    type?: 'button' | 'submit';
    color?: 'default' | 'primary' | 'secondary' | 'ghost' | 'error' | 'warning' | 'error-light' | 'warning-light';
    small?: boolean;
    className?: string;
    onClick?: (event: unknown) => void;
    outlined?: boolean;
    startIcon?: [string, string];
    endIcon?: [string, string];
    loading?: boolean;
    loadingLabel?: string;
    fullWidth?: boolean;
}

const Button = ({
    label,
    onClick,
    className = '',
    disabled = false,
    type = 'button',
    color = 'default',
    small = false,
    outlined = false,
    loading = false,
    fullWidth = false,
    loadingLabel = '',
    startIcon,
    endIcon,
}: Props) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled || loading}
            type={type === 'button' ? 'button' : 'submit'}
            className={clsx(
                'flex row inline justify-center items-center',
                classes.root,
                {
                    // TODO: Refactor this and make use of the new background-color util instead
                    [classes.primary]: color === 'primary',
                    [classes.secondary]: color === 'secondary',
                    [classes.ghost]: color === 'ghost',
                    [classes.warning]: color === 'warning',
                    [classes.error]: color === 'error',
                    [classes.errorLight]: color === 'error-light',
                    [classes.warningLight]: color === 'warning-light',
                    [classes.loading]: loading,
                    [classes.disabled]: disabled,
                    [classes.outlined]: outlined,
                    [classes.small]: small,
                    [classes.fullWidth]: fullWidth,
                },
                className
            )}
        >
            {startIcon && <Icon size="sm" icon={startIcon} />}
            {loading ? loadingLabel : label}
            {loading && <Loading dotClassName={classes.loadingDots} />}
            {endIcon && <Icon size="sm" icon={endIcon} />}
        </button>
    );
};

export default memo(Button);
