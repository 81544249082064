import { call, put, select, takeEvery } from 'redux-saga/effects';
import uuidV4 from 'uuid';

import {
    duckActionCreators as snackbarActionCreators,
    duckActions as snackbarActions,
    duckSelectors as snackbarSelectors,
} from './snackbar.duck';

export const SAGA_NAME = 'SNACKBAR';

function* setToShowSnackbar() {
    const snackbarState = yield select((state) => snackbarSelectors.snackbarSelector(state));
    if (Object.keys(snackbarState.toShowSnackbar).length === 0 && snackbarState.snackbarsQueue.length > 0) {
        const nextSnackbar = snackbarState.snackbarsQueue[0];
        yield put(snackbarActionCreators.setToShowSnackbar(nextSnackbar));
        yield put(snackbarActionCreators.dequeue());
        yield put(snackbarActionCreators.setShouldOpen(true));
    }
}

function* exited() {
    yield put(snackbarActionCreators.setShowingSnackbarId(''));
    yield put(snackbarActionCreators.setToShowSnackbar({}));
    yield call(setToShowSnackbar);
}

function* closed() {
    yield put(snackbarActionCreators.setShouldOpen(false));
}

function* opened(action) {
    yield put(snackbarActionCreators.setShowingSnackbarId(action.payload.snackbarId));
}

function* show(action) {
    const uuid = uuidV4();
    yield put(snackbarActionCreators.enqueue(Object.assign(action.payload.snackbar, { id: uuid })));
    yield call(setToShowSnackbar);
}

export default function* snackbarSaga() {
    yield takeEvery(snackbarActions.SHOW, show);
    yield takeEvery(snackbarActions.SNACKBAR_OPENED, opened);
    yield takeEvery(snackbarActions.SNACKBAR_CLOSED, closed);
    yield takeEvery(snackbarActions.SNACKBAR_EXITED, exited);
}
