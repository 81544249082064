import { ducksManager, sagasManager } from '@edf-pkg/app-store';

import dashboardReducer, * as dashboardDuck from './dashboard.duck';
import dashboardSaga, { SAGA_NAME as dashboardSagaName } from './dashboard.saga';

export function inject() {
    ducksManager.add(dashboardDuck.DUCK_NAME, dashboardReducer);
    sagasManager.add(dashboardSagaName, dashboardSaga);
}

export { dashboardDuck };
