export const INITIALIZATION_STATUS = {
    UNKNOWN: 'UNKNOWN',
    STARTED: 'STARTED',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
};

// TODO: this has been copied from PDash constants
export const HTTP_CALL_STATUS = {
    UNKNOWN: 'UNKNOWN',
    LOADING: 'LOADING',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
};

export const DATE_RANGE_TYPES = {
    MONTH: 'MONTH',
    START_AND_END: 'START_AND_END',
};

export const EXPORT_FORMATS = {
    CSV: { id: 0, label: 'CSV' },
    KML: { id: 1, label: 'KML' },
    GPX: { id: 2, label: 'GPX' },
    GEXF: { id: 3, label: 'GEXF' },
};
