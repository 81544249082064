import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    top: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        letterSpacing: 0.25,
        '$smallSize &': {
            fontSize: theme.typography.pxToRem(12),
            letterSpacing: 0.21,
        },
    },
    middle: {
        fontSize: theme.typography.pxToRem(24),
        fontWeight: 600,
        letterSpacing: 6,
        lineHeight: 1.6,
        '$smallSize &': {
            fontSize: theme.typography.pxToRem(20),
            letterSpacing: 5,
        },
    },
    bottom: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        letterSpacing: 0.25,
        '$smallSize &': {
            fontSize: theme.typography.pxToRem(12),
            letterSpacing: 0.21,
        },
    },
    smallSize: {},
}));
