import { memo, MouseEvent, useCallback } from 'react';
import clsx from 'clsx';

import OverlayButton from '$app-web/activities/survey/components/choice/overlay-button';
import LazyImage from '$app-web/components/lazy-image';
import { useTranslator } from '$app-web/hooks/use-translator';

import useStyles from './styles';

interface Props {
    variant: 'image' | 'text' | 'button';
    text: string;
    imageSrc: string;
    disabled: boolean;
    onViewClick: () => void;
}

const Label = ({ variant = 'text', text, imageSrc, disabled = false, onViewClick }: Props) => {
    const classes = useStyles();
    const t = useTranslator();

    const handleClick = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            onViewClick();
        },
        [onViewClick]
    );

    if (variant === 'image') {
        return (
            <OverlayButton disabled={disabled} onClick={handleClick} text={t('webActivities:view')}>
                <LazyImage src={imageSrc} className={clsx(classes.image, { [classes.imageDisabled]: disabled })} small />
            </OverlayButton>
        );
    }

    return <span>{text}</span>;
};

export default memo(Label);
