import { CRITERIA_ACCEPTABLE_QUESTION_TYPES, REGISTRATION_TIME_FILTERS } from '$app-web/constants';
import { ObjectType } from '$app-web/types';
import { SuperDate } from '$app-web/utils';
import { criteriaEvaluator } from '$app-web/utils/criteria-evaluator';

import { StoreQuestion } from '../store/types';
import { isAnswerValueEmpty, shouldPresentQuestion } from './helpers';

function validateCriteria(parameters: {
    criteria: string;
    tokens: ObjectType<{ type: string; value: unknown }>;
    disabledQuestions: Array<number>;
    registrationTime: number;
}) {
    const { criteria, tokens, disabledQuestions, registrationTime } = parameters;

    if (criteria == null || criteria === '') {
        return true;
    }

    // First parameter refers to the type of the placeholder (question-value | registration-date-time)
    return criteriaEvaluator(criteria, (type: string, value: string) => {
        if (type === 'registration-date-time') {
            const currentDate = new Date();
            const differenceOfTimes = SuperDate.getDifference(currentDate, registrationTime);

            switch (value) {
                case REGISTRATION_TIME_FILTERS.SECONDS_SINCE_REG_TIME:
                case REGISTRATION_TIME_FILTERS.SECONDS_SINCE_REG_DATE:
                    return differenceOfTimes.seconds;
                case REGISTRATION_TIME_FILTERS.MINUTES_SINCE_REG_TIME:
                case REGISTRATION_TIME_FILTERS.MINUTES_SINCE_REG_DATE:
                    return differenceOfTimes.minutes;
                case REGISTRATION_TIME_FILTERS.HOURS_SINCE_REG_TIME:
                case REGISTRATION_TIME_FILTERS.HOURS_SINCE_REG_DATE:
                    return differenceOfTimes.hours;
                case REGISTRATION_TIME_FILTERS.DAYS_SINCE_REG_TIME:
                case REGISTRATION_TIME_FILTERS.DAYS_SINCE_REG_DATE:
                    return differenceOfTimes.days;
                case REGISTRATION_TIME_FILTERS.WEEKS_SINCE_REG_TIME:
                case REGISTRATION_TIME_FILTERS.WEEKS_SINCE_REG_DATE:
                    return differenceOfTimes.weeks;
                case REGISTRATION_TIME_FILTERS.MONTHS_SINCE_REG_TIME:
                case REGISTRATION_TIME_FILTERS.MONTHS_SINCE_REG_DATE:
                    return differenceOfTimes.months;
                case REGISTRATION_TIME_FILTERS.YEARS_SINCE_REG_TIME:
                case REGISTRATION_TIME_FILTERS.YEARS_SINCE_REG_DATE:
                    return differenceOfTimes.years;
                default:
                    return 0;
            }
        }
        if (type === 'question-value') {
            const numericIdPart = Number(value.replace('Q', ''));
            const isSourceQuestionEnabled = !disabledQuestions.includes(numericIdPart);
            const item = tokens[value];

            if (isSourceQuestionEnabled && item != null && CRITERIA_ACCEPTABLE_QUESTION_TYPES.includes(item.type)) {
                return item.value;
            }
        }

        return undefined;
    });
}

export function applySurveyCriteria(params: {
    questions: Array<StoreQuestion>;
    activityId: number;
    tokens: ObjectType<{ type: string; value: unknown }>;
    registrationTime: number;
}): Array<StoreQuestion> {
    const { questions, activityId, tokens, registrationTime } = params;

    const disabledQuestions = questions
        .filter((question) => !shouldPresentQuestion(question))
        .map((question) => question.questionId);

    const questionsTokens = Object.fromEntries(
        questions
            .filter((question) => !isAnswerValueEmpty(question.answer))
            .map((question) => [[`Q${question.questionId}`], { type: question.questionType, value: question.answer }])
    ) as ObjectType<{ type: string; value: unknown }>;

    const newTokens = { ...tokens, ...questionsTokens };

    const updatedSections = questions.map((question) => {
        const { criteria = '', sectionCriteria = '', questionId } = question;

        const targetToken = `Q${question.questionId}`;
        const isCriteriaSelfPointer = criteria.includes(targetToken) && !criteria.includes(`${targetToken}_`);
        const finalCriteria = isCriteriaSelfPointer
            ? question.criteria.replaceAll(targetToken, `Q${activityId}_${questionId}`)
            : question.criteria;

        const commonProps = {
            disabledQuestions,
            registrationTime,
            tokens: newTokens,
        };

        return {
            ...question,
            isHiddenBySectionCriteria: !validateCriteria({ ...commonProps, criteria: sectionCriteria }),
            isHiddenByCriteria: !validateCriteria({ ...commonProps, criteria: finalCriteria }),
        };
    });

    return updatedSections;
}
