import { object, string } from 'yup';

import { getStore } from '@edf-pkg/app-store';

import { activityDuck } from '../store';

export const getData = (activityUUID) => {
    const state = getStore().getState();
    const sessionUUID = activityDuck.duckSelectors.sessionUUIDSelector(state)(activityUUID);

    return { session_uuid: sessionUUID || undefined };
};

export const getSchema = () =>
    object().shape({
        session_uuid: string().optional(),
    });
