import React from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';

import classes from './styles.module.scss';

const Divider = (props) => {
    const { dense, loose, narrow, noMargin, thick, vertical } = props;
    return (
        <div
            className={clsx(classes.root, {
                [classes.vertical]: vertical,
                [classes.dense]: dense,
                [classes.loose]: loose,
                [classes.narrow]: narrow,
                [classes.thick]: thick,
                [classes.noMargin]: noMargin,
            })}
        />
    );
};

Divider.defaultProps = {
    dense: false,
    loose: false,
    narrow: false,
    thick: false,
    noMargin: false,
    vertical: false,
};

Divider.propTypes = {
    dense: PropTypes.bool,
    loose: PropTypes.bool,
    narrow: PropTypes.bool,
    thick: PropTypes.bool,
    noMargin: PropTypes.bool,
    vertical: PropTypes.bool,
};

export default Divider;
