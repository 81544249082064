import URLParse from 'url-parse';

import { FatalError } from '@edf-pkg/app-error';

enum PLATFORMS {
    NOT_FOUND = 'NOT_FOUND',
    WEB = 'WEB',
    ANDROID = 'ANDROID',
    IOS = 'IOS',
}

class PlatformHelper {
    platform: PLATFORMS | '' = '';

    initialize() {
        this.findPlatform();
        if (!this.isPlatformFound()) {
            throw new FatalError('Cannot find platform.');
        }
    }

    findPlatform() {
        this.findPlatformFromInjectedFunctions();

        if (!this.isPlatformFound()) {
            this.findPlatformFromQueryParams();
        }

        if (!this.isPlatformFound()) {
            this.findPlatformFromUserAgent();
        }
    }

    findPlatformFromInjectedFunctions() {
        // This line is intended for mocking web activities in the web platform but the functionality is not completed yet. Please don't remove this as it will be used in the future with the mocking data that exists in the same directory.
        /* if (window.edWeb != null && Array.isArray(window.edWeb) && Object.keys(window.edWeb).length > 0) {
            this.platform = PLATFORMS.WEB;
        } else */ if (window.WebActivityRef != null && Object.keys(window.WebActivityRef).length > 0) {
            this.platform = PLATFORMS.ANDROID;
        } else if (
            window.webkit != null &&
            window.webkit.messageHandlers != null &&
            Object.keys(window.webkit.messageHandlers).length > 0
        ) {
            this.platform = PLATFORMS.IOS;
        } else {
            this.platform = PLATFORMS.NOT_FOUND;
        }
    }

    findPlatformFromQueryParams() {
        const parsedURL = new URLParse(window.location.href, true);

        switch (parsedURL.query.platform) {
            case 'web':
                this.platform = PLATFORMS.WEB;
                break;
            case 'android':
                this.platform = PLATFORMS.ANDROID;
                break;
            case 'ios':
                this.platform = PLATFORMS.IOS;
                break;
            default:
                this.platform = PLATFORMS.NOT_FOUND;
                break;
        }
    }

    findPlatformFromUserAgent() {
        const userAgent = navigator.userAgent.toLowerCase();

        if (/iphone|ipad|ipod/.test(userAgent)) {
            this.platform = PLATFORMS.IOS;
        } else if (/android/.test(userAgent)) {
            this.platform = PLATFORMS.ANDROID;
        } else if (/mozilla|chrome|safari|firefox|edge|opera/.test(userAgent)) {
            this.platform = PLATFORMS.WEB;
        } else {
            this.platform = PLATFORMS.NOT_FOUND;
        }
    }

    isPlatformFound() {
        return this.platform && this.platform !== PLATFORMS.NOT_FOUND;
    }

    isPlatformWeb() {
        return this.platform && this.platform === PLATFORMS.WEB;
    }

    isPlatformAndroid() {
        return this.platform && this.platform === PLATFORMS.ANDROID;
    }

    isPlatformIos() {
        return this.platform && this.platform === PLATFORMS.IOS;
    }
}

const instance = new PlatformHelper();
export default instance;
