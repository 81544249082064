import asSpace from '$app-web/hoces/as-space';
import PublicModule from '$app-web/modules/public/';

import * as publicStore from './store';

export const SPACE_ID = 'public';

publicStore.inject();

export default asSpace()(PublicModule);

export { publicStore };
