import { useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';

import Slider from '$app-web/components/slider';

import Labels from './labels';
import Layout from './layout';

const VAS = ({
    anchors,
    showSelectionValue,
    showSelectionLabel,
    isVertical,
    labels,
    step,
    max,
    min,
    value,
    onChange,
    sliderHint,
    spacing,
    variant,
    useOuterValue,
}) => {
    const [internalValue, setInternalValue] = useState(value);

    const handleDebouncedChange = useMemo(() => debounce(onChange, 300), [onChange]);
    const handleChange = useCallback(
        (newValue) => {
            if (useOuterValue) {
                onChange(newValue);
            } else {
                setInternalValue(newValue);
                handleDebouncedChange(newValue);
            }
        },
        [handleDebouncedChange, onChange, useOuterValue]
    );

    return (
        <Layout
            vertical={isVertical}
            spacing={spacing}
            variant={variant}
            labels={
                <Labels
                    showSelectionLabel={showSelectionLabel}
                    labels={labels}
                    anchors={anchors}
                    vertical={isVertical}
                    value={useOuterValue ? value : internalValue}
                    variant={variant}
                />
            }
            slider={
                <Slider
                    data-testid="vas-slider"
                    step={step}
                    max={max}
                    min={min}
                    valueLabelDisplay={showSelectionValue ? 'on' : 'off'}
                    value={useOuterValue ? value : internalValue}
                    orientation={isVertical ? 'vertical' : 'horizontal'}
                    onChange={(event, currentValue) => handleChange(currentValue)}
                />
            }
            sliderHint={typeof sliderHint === 'function' ? sliderHint(useOuterValue ? value : internalValue) : sliderHint}
        />
    );
};

VAS.defaultProps = {
    value: null,
    showSelectionValue: true,
    showSelectionLabel: true,
    isVertical: false,
    step: 1,
    max: 100,
    min: 0,
    labels: undefined,
    sliderHint: undefined,
    spacing: undefined,
    variant: 'primary',
    useOuterValue: false,
};

VAS.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
    showSelectionValue: PropTypes.bool,
    showSelectionLabel: PropTypes.bool,
    isVertical: PropTypes.bool,
    labels: PropTypes.objectOf(PropTypes.any),
    anchors: PropTypes.arrayOf(PropTypes.object).isRequired,
    step: PropTypes.number,
    max: PropTypes.number,
    min: PropTypes.number,
    sliderHint: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    spacing: PropTypes.number,
    variant: PropTypes.oneOf(['primary', 'secondary']),
    useOuterValue: PropTypes.bool,
};

export default VAS;
