import Bowser from 'bowser';

export function getDeviceOs(): string | null {
    let deviceOs: string | null = null;

    if (typeof window !== 'undefined' && window.navigator) {
        const userAgentBasedData = Bowser.getParser(window.navigator.userAgent);
        const osName = userAgentBasedData.getOSName();
        const osVersion = userAgentBasedData.getOSVersion();

        if (osName && osVersion) {
            deviceOs = `${osName} ${osVersion}`;
        }
    }

    return deviceOs;
}
