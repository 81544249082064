import { memo } from 'react';
import clsx from 'clsx';
import type { CheckboxProps } from '@material-ui/core';
import { Checkbox, Radio } from '@material-ui/core';

import useStyles from './styles';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

interface Props extends CheckboxProps {
    type: 'single' | 'multiple';
}

const Control = ({ type, ...restProps }: Props) => {
    const classes = useStyles();

    if (type === 'single') {
        return (
            <Radio
                classes={{ root: classes.root, checked: classes.checked }}
                icon={<FontAwesomeIcon icon={['fal', 'circle']} className={clsx(classes.icon, classes.circleIcon)} />}
                checkedIcon={<FontAwesomeIcon icon={['fas', 'circle']} className={clsx(classes.icon, classes.circleIcon)} />}
                {...restProps}
            />
        );
    }

    if (type === 'multiple') {
        return (
            <Checkbox
                classes={{ root: classes.root, checked: classes.checked }}
                icon={<FontAwesomeIcon icon={['fal', 'square-full']} className={clsx(classes.icon, classes.squareIcon)} />}
                checkedIcon={
                    <FontAwesomeIcon
                        icon={['fas', 'check-square']}
                        className={clsx('fa-w-16', classes.icon, classes.squareIcon)}
                    />
                }
                {...restProps}
            />
        );
    }

    return null;
};

export default memo(Control);
