// TODO: Refactor the whole Chat and use derived state + memoization + smaller components + single source for users data as much as possible.
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { current, original } from 'immer';
import { cloneDeep, debounce, maxBy, throttle, uniqBy } from 'lodash-es';
import * as matrixSDK from 'matrix-js-sdk';
import { CallType } from 'matrix-js-sdk/lib/webrtc/call';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

import { errorHandle } from '@edf-pkg/app-error';
import { store as appMainStore } from '@edf-pkg/app-main';
import useSnackbar from '@edf-pkg/app-main/src/hooks/use-snackbar';

import useTones from '$app-web/components/chat/hooks/use-tones';
import { URLsManager } from '$app-web/utils';
import { contextStore } from '$app-web/utils/context-store';

import {
    CHAT_CLIENT_STATUS,
    MATRIX_INTERNAL_CLIENT_STATUS,
    MATRIX_MEMBERSHIP_TYPES,
    MATRIX_TIMELINE_EVENT_TYPES,
    MATRIX_TIMELINE_PAGINATE_DIRECTIONS,
    MATRIX_TIMELINE_PAGINATE_EVENTS_LIMIT,
    MEDIASOUP_EVENT_TYPES,
    ROOM_ALIAS_REGEX,
    ROOM_MESSAGE_DRAFT,
    ROOM_TYPES,
    STUDY_CHAT,
    STUDY_CHAT_STATUS,
    TIMELINE_STATUS,
    VOIP_CALL_DATA,
    VOIP_CALL_STATUS,
    VOIP_CALL_TYPES,
} from '../constants';
import useMatrix from '../hooks/use-matrix';
import {
    createRoomsTimelineFilter,
    getAvatarColorById,
    getChatRoomAvatar,
    getChatRoomTitle,
    getSortedStudyRoomsOrder,
    loadStudyUsersData,
    normalizeMatrixRoom,
} from '../utils/chat-client.utils';
import { initializeMediasoup } from './initialize-mediasoup';

const MATRIX_SERVER_NAME = process.env.REACT_APP_LOCAL_CHAT_MATRIX_SERVER_NAME;

/*
    Chat client state. It's a universal interface between Matrix and the chat UI. It handles all the states related to each study
    rooms and not a study specific chat implementation.
 */
const useChatClientState = ({ chatFlags, studyNameGetter, dashboard }) => {
    const { t } = useTranslation();
    const { newMessageTone } = useTones();
    const currentUserId = useSelector(appMainStore.userDuck.duckSelectors.idSelector);

    const [status, setStatus] = useState(CHAT_CLIENT_STATUS.UNKNOWN);
    const [hasAnyRooms, setHasAnyRooms] = useState(false);
    // TODO: We can use this state now, instead of passing roomOnClick as props to inner components.
    const [roomOnClick, setRoomOnClick] = useState();
    // TODO: This state should be handled better. Currently we pass it to Chat component and there we set the state.
    const [selectedRoomId, setSelectedRoomId] = useState(undefined);
    const [currentStudyId, setCurrentStudyId] = useState(-1);
    // Each study should pass an async function used for loading the user data when needed.
    const [currentStudyUsersDataLoader, setCurrentStudyUsersDataLoader] = useState(null);
    // The main state of the client.
    const [state, updateState] = useImmer({
        studiesChat: {},
    });
    // The draft message the current user has entered in each room.
    const [messagesDraft, updateMessagesDraft] = useImmer({
        rooms: {},
    });
    const [roomsSearchKeyword, setRoomsSearchKeyword] = useState('');
    const [isRoomsSearchRemote, setIsRoomsSearchRemote] = useState(false);
    const [remoteSearchRooms, setRemoteSearchRooms] = useState([]);
    const [isCreatingARoom, setIsCreatingARoom] = useState(false);
    const [isSearchingRemoteRooms, setIsSearchingRemoteRooms] = useState(false);
    // The chat VoIP state.
    const [voip, updateVoip] = useImmer({
        incomingCallsData: [],
        activeCallData: cloneDeep(VOIP_CALL_DATA),
    });
    const [isChatSidebarVisibleOnXs, setIsChatSidebarVisibleOnXs] = useState(true);
    // The retrieved user data for each study will be saved here for future usages.

    const [studiesUsersData, updateStudiesUserData] = useImmer({});

    /*
        Reference to the main timeline filter for each rooms. It'll be created based on user id and will be saved in
        Matrix server to be used in future.
     */
    const roomsTimelineFilter = useRef(null);
    /*
        Reference to the timeline window created using roomsTimelineFilter for each room. As it is not changing over time,
        add them to a ref so it also don't have effect and don't change the references of callback or memos.
     */
    const roomsTimelineWindow = useRef({});
    // Room Id to Study Id. It's a ref, to prevent callback/memos reference changes.
    const roomIdToStudyId = useRef({});
    // VoIP Matrix call instances.

    /**
     * @type {React.RefObject<{incoming: any, active: matrixSDK.MatrixCall, mediasoup: unknown | undefined}>}
     */
    const voipCalls = useRef({
        incoming: {},
        active: null,
    });
    const checkedForCallBeforeOpeningTheApp = useRef(false);

    const matrix = useMatrix(chatFlags.isBaseChatEnabledForAtLeastOneStudy);
    const { showError, showWarning, showInfo } = useSnackbar();
    const isTyping = useRef(false);

    // TODO: I'm not sure why we have this function. currentIncomingCallData is not used in our code.
    const updateCurrentIncomingVoipCallData = useCallback((voipDraft) => {
        if (voipDraft.incomingCallsData.length === 0) {
            voipDraft.currentIncomingCallData = null;
        } else if (voipDraft.currentIncomingCallData === null) {
            const oldestIncomingCallData = voipDraft.incomingCallsData[0];
            if (oldestIncomingCallData) {
                const oldestIncomingCall = voipCalls.current.incoming[oldestIncomingCallData.id];
                voipDraft.currentIncomingCallData = {
                    id: oldestIncomingCall.callId,
                    roomId: oldestIncomingCall.roomId,
                    type: oldestIncomingCall.type,
                };
            } else {
                voipDraft.currentIncomingCallData = null;
            }
        }
    }, []);

    const removeCallFromIncomingCalls = useCallback(
        (callId) => {
            if (voipCalls.current.incoming[callId]) {
                delete voipCalls.current.incoming[callId];
                updateVoip((voipDraft) => {
                    voipDraft.incomingCallsData = voipDraft.incomingCallsData.filter((callData) => callData.id !== callId);
                    updateCurrentIncomingVoipCallData(voipDraft);
                });
            }
        },
        [updateCurrentIncomingVoipCallData, updateVoip]
    );

    const endCall = useCallback(
        (callId) => {
            if (voipCalls.current.active && voipCalls.current.active.callId === callId) {
                voipCalls.current.active = null;
                voipCalls.current.mediasoup?.close();
                voipCalls.current.mediasoup = null;
                updateVoip((voipDraft) => {
                    voipDraft.activeCallData = cloneDeep(VOIP_CALL_DATA);
                });
            } else {
                removeCallFromIncomingCalls(callId);
            }
        },
        [removeCallFromIncomingCalls, updateVoip]
    );

    const onScreenShareStart = useCallback(
        (mediaStream) => {
            updateVoip((voipDraft) => {
                voipDraft.activeCallData.remoteScreenFeed = { stream: mediaStream };
            });
        },
        [updateVoip]
    );

    const onScreenShareStop = useCallback(() => {
        updateVoip((voipDraft) => {
            voipDraft.activeCallData.remoteScreenFeed = null;
        });
    }, [updateVoip]);

    const registerCallEventListeners = useCallback(
        (call, active, isVideoMuted) => {
            call.on('hangup', () => {
                endCall(call.callId);
            });
            call.on('error', () => {
                call.hangup();
            });
            if (active) {
                call.on('feeds_changed', () => {
                    updateVoip((voipDraft) => {
                        const feeds = call.getFeeds();
                        voipDraft.activeCallData.localFeed = feeds.find((feed) => feed.isLocal());
                        voipDraft.activeCallData.remoteFeed = feeds.find((feed) => !feed.isLocal());
                        voipDraft.activeCallData.audioMuted = call.isMicrophoneMuted();
                        voipDraft.activeCallData.videoMuted = call.isLocalVideoMuted() || isVideoMuted;
                    });
                });
                call.on('state', (currentState) => {
                    // fix for the empty candidate issue which causes internal server error
                    if (currentState === 'create_offer' || currentState === 'create_answer') {
                        call.peerConn.addEventListener('icegatheringstatechange', () => {
                            if (
                                call.candidateSendQueue.length > 0 &&
                                !(call.candidateSendQueue[call.candidateSendQueue.length - 1] instanceof RTCIceCandidate)
                            ) {
                                // eslint-disable-next-line no-param-reassign
                                call.candidateSendQueue = call.candidateSendQueue.slice(0, call.candidateSendQueue.length - 1);
                            }
                        });
                    }
                    switch (currentState) {
                        case 'connected': {
                            updateVoip((voipDraft) => {
                                voipDraft.activeCallData.status = VOIP_CALL_STATUS.ACTIVE;
                                voipDraft.activeCallData.isConnecting = false;
                            });
                            if (isVideoMuted) {
                                voipCalls.current.active.setLocalVideoMuted(true);
                            }
                            break;
                        }
                        case 'ended': {
                            endCall(call.callId);
                            break;
                        }
                        case 'invite_sent': {
                            updateVoip((voipDraft) => {
                                voipDraft.activeCallData.status = VOIP_CALL_STATUS.RINGING;
                            });
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                });
            }
        },
        [endCall, updateVoip]
    );

    /*
        Update room read recipient, when user opens the room. It'll tell server that user has seen all messages.
    */
    const updateRoomReadRecipient = useCallback(
        async (roomId, eventIndex) => {
            try {
                if (matrix.client) {
                    const matrixRoom = matrix.client.getRoom(roomId);
                    if (matrixRoom && roomsTimelineWindow.current[roomId]) {
                        const matrixEvents = roomsTimelineWindow.current[roomId].getEvents();
                        const lastCurrentUserReadMarkerEventIndex = matrixEvents.findIndex(
                            ({ event }) => event.event_id === matrixRoom.getEventReadUpTo(matrix.userData.userId)
                        );

                        const matrixEvent = matrixEvents[eventIndex];
                        if (
                            matrixEvent != null &&
                            matrixEvent.getId().startsWith('$') &&
                            lastCurrentUserReadMarkerEventIndex < eventIndex &&
                            eventIndex < matrixEvents.length
                        ) {
                            await matrix.client.sendReceipt(matrixEvent, 'm.read');
                            const roomStudyId = roomIdToStudyId.current[roomId];
                            updateState((stateDraft) => {
                                const unreadMessagesCount = matrixEvents.length - eventIndex - 1;
                                matrixRoom.setUnreadNotificationCount(matrixSDK.NotificationCountType.Total, unreadMessagesCount);
                                stateDraft.studiesChat[roomStudyId].rooms[roomId].unreadMessagesCount = unreadMessagesCount;
                            });
                        }
                    }
                }
            } catch (error) {
                errorHandle.anError(error);
            }
        },
        [matrix.client, matrix.userData.userId, updateState]
    );

    /*
        Reload timeline events.
     */
    const reloadTimelineEvents = useCallback(
        async (roomId) => {
            updateState((stateDraft) => {
                const roomStudyId = roomIdToStudyId.current[roomId];
                stateDraft.studiesChat[roomStudyId].rooms[roomId].timeline.events = uniqBy(
                    roomsTimelineWindow.current[roomId].getEvents(),
                    (e) => e.getId()
                );
            });
        },
        [updateState]
    );

    /*
        Load room timeline. If has timeline window, create it and initialize the room timeline.
     */
    const loadTimeline = useCallback(
        async (roomId, direction = MATRIX_TIMELINE_PAGINATE_DIRECTIONS.BACKWARDS) => {
            try {
                if (matrix.client) {
                    const matrixRoom = matrix.client.getRoom(roomId);
                    if (matrixRoom) {
                        // Set the direction based keys for less if and else clauses.
                        let canLoadByDirectionKey = 'canLoadBackward';
                        let loadingByDirectionKey = 'loadingBackward';
                        if (direction === MATRIX_TIMELINE_PAGINATE_DIRECTIONS.FORWARDS) {
                            canLoadByDirectionKey = 'canLoadForward';
                            loadingByDirectionKey = 'loadingForward';
                        }

                        // Set loading timeline loading in direction
                        updateState((stateDraft) => {
                            stateDraft.studiesChat[roomIdToStudyId.current[roomId]].rooms[roomId].timeline[
                                loadingByDirectionKey
                            ] = true;
                        });

                        // Get the timeline set for the timeline filter.
                        const timelineSet = matrixRoom.getOrCreateFilteredTimelineSet(roomsTimelineFilter.current);

                        // If for the first time, load timeline window before pagination.
                        if (!roomsTimelineWindow.current[roomId]) {
                            roomsTimelineWindow.current[roomId] = new matrixSDK.TimelineWindow(matrix.client, timelineSet);
                            await roomsTimelineWindow.current[roomId].load();
                        }

                        // Paginate the room timeline window. If for the first time, load backwards.
                        await roomsTimelineWindow.current[roomId].paginate(direction, MATRIX_TIMELINE_PAGINATE_EVENTS_LIMIT);

                        // After successful pagination, reset the timeline events based on new events.
                        await reloadTimelineEvents(roomId);

                        updateState((stateDraft) => {
                            const roomStudyId = roomIdToStudyId.current[roomId];
                            const matchedStudyChat = stateDraft.studiesChat?.[roomStudyId];
                            const matchedRoom = matchedStudyChat?.rooms?.[roomId];

                            if (matchedRoom == null) {
                                return;
                            }

                            matchedRoom.timeline[canLoadByDirectionKey] =
                                roomsTimelineWindow.current[roomId].canPaginate(direction);
                            matchedRoom.timeline[loadingByDirectionKey] = false;

                            // Update the room last other members read marker. This will be used to indicate if the current user's messages has been seen or not.
                            try {
                                const roomReadReceipts = matrixRoom.receipts['m.read'];
                                if (roomReadReceipts && Object.keys(roomReadReceipts).length > 0) {
                                    const otherRoomMembersReadReceiptsMarker = maxBy(
                                        Object.keys(roomReadReceipts)
                                            .filter((matrixUserId) => matrixUserId !== matrix.userData.userId)
                                            .map((userId) => ({
                                                eventId: roomReadReceipts[userId].eventId,
                                                timeMs: roomReadReceipts[userId].data.ts,
                                            })),
                                        (readMarker) => readMarker.timeMs
                                    );
                                    if (otherRoomMembersReadReceiptsMarker !== undefined) {
                                        const otherMembersLastReadMarkerEvent = current(stateDraft).studiesChat[
                                            roomStudyId
                                        ].rooms[roomId].timeline.events.find(
                                            ({ event }) => event.event_id === otherRoomMembersReadReceiptsMarker.eventId
                                        );
                                        otherRoomMembersReadReceiptsMarker.event = otherMembersLastReadMarkerEvent;
                                        matchedRoom.otherMembersLastReadMarker = otherRoomMembersReadReceiptsMarker;
                                    }
                                }
                            } catch (error) {
                                errorHandle.anError(error);
                            }

                            // Update unread messages count
                            matchedRoom.unreadMessagesCount = matrixRoom.getUnreadNotificationCount();

                            // Sort rooms
                            matchedStudyChat.roomsOrder = getSortedStudyRoomsOrder(matchedStudyChat.rooms);

                            // If for the first time, set the  timeline statuses.
                            if (matchedRoom.timeline.status === TIMELINE_STATUS.UNKNOWN) {
                                matchedRoom.timeline.status = TIMELINE_STATUS.READY;
                            }
                        });
                    }
                }
            } catch (error) {
                errorHandle.anError(error);
                updateState((stateDraft) => {
                    stateDraft.studiesChat[roomIdToStudyId.current[roomId]].rooms[roomId].timeline.status =
                        TIMELINE_STATUS.FAILED;
                });
            }
        },
        [matrix.client, matrix.userData.userId, reloadTimelineEvents, updateState]
    );

    /*
        Force reset timeline. It will remove the current window and then call load timeline.
     */
    const forceResetTimeline = useCallback(
        async (roomId) => {
            if (roomsTimelineWindow.current[roomId]) {
                roomsTimelineWindow.current[roomId] = null;
            }
            await loadTimeline(roomId);
        },
        [loadTimeline]
    );

    /*
        Prepare study chat for the first time, when it's failed, or when a new room is added.
     */
    const prepareCurrentStudyChat = useCallback(
        async (specificRoomId) => {
            try {
                if (currentStudyId === -1) {
                    return;
                }

                const currentStudyUsersData = await loadStudyUsersData(
                    currentStudyUsersDataLoader,
                    Object.values(state.studiesChat[currentStudyId].rooms)
                );

                updateStudiesUserData((studiesUsersDataDraft) => {
                    studiesUsersDataDraft[currentStudyId] = currentStudyUsersData;
                });

                updateState((stateDraft) => {
                    if (!specificRoomId) {
                        stateDraft.studiesChat[currentStudyId].status = STUDY_CHAT_STATUS.LOADING;
                    }
                    // TODO: Check forEach() for a study that its chat is disabled.
                    stateDraft.studiesChat[currentStudyId].roomsOrder.forEach((roomId) => {
                        if (specificRoomId && roomId !== specificRoomId) {
                            return;
                        }
                        // Set room title.
                        stateDraft.studiesChat[currentStudyId].rooms[roomId].title = getChatRoomTitle(
                            stateDraft.studiesChat[currentStudyId].rooms[roomId],
                            currentStudyUsersData,
                            currentUserId,
                            t
                        );
                        // Set room avatar.
                        stateDraft.studiesChat[currentStudyId].rooms[roomId].avatar = getChatRoomAvatar(
                            stateDraft.studiesChat[currentStudyId].rooms[roomId],
                            currentStudyUsersData
                        );
                    });

                    stateDraft.studiesChat[currentStudyId].roomsOrder = getSortedStudyRoomsOrder(
                        stateDraft.studiesChat[currentStudyId].rooms
                    );
                    stateDraft.studiesChat[currentStudyId].status = STUDY_CHAT_STATUS.READY;
                });
            } catch (error) {
                errorHandle.anError(error);
                updateState((stateDraft) => {
                    stateDraft.studiesChat[currentStudyId].status = STUDY_CHAT_STATUS.FAILED;
                });
            }
        },
        [currentStudyId, currentStudyUsersDataLoader, currentUserId, state.studiesChat, t, updateState, updateStudiesUserData]
    );

    /*
        On Matrix sync event callback. It'll listen to the sync event to get the current status of the client.
     */
    const onMatrixSyncEvent = useCallback((newStatus, previousStatus) => {
        if (newStatus === MATRIX_INTERNAL_CLIENT_STATUS.DISCONNECTED) {
            voipCalls.current.active?.hangup();
        } else if (
            newStatus === MATRIX_INTERNAL_CLIENT_STATUS.SYNCING &&
            (previousStatus === MATRIX_INTERNAL_CLIENT_STATUS.RECONNECTING ||
                previousStatus === MATRIX_INTERNAL_CLIENT_STATUS.ERROR ||
                previousStatus === MATRIX_INTERNAL_CLIENT_STATUS.CATCHUP)
        ) {
            setStatus(CHAT_CLIENT_STATUS.READY);
        } else if (
            newStatus === MATRIX_INTERNAL_CLIENT_STATUS.ERROR ||
            newStatus === MATRIX_INTERNAL_CLIENT_STATUS.RECONNECTING
        ) {
            setStatus(CHAT_CLIENT_STATUS.CONNECTION_LOST);
        } else if (newStatus === MATRIX_INTERNAL_CLIENT_STATUS.CATCHUP) {
            setStatus(CHAT_CLIENT_STATUS.RECONNECTING);
        }
    }, []);

    /*
        On Matrix Room.timeline event callback. It'll listen to the sync event to get the current status of the client.
     */
    const onMatrixRoomTimelineEvent = useCallback(
        async (event, matrixRoom, toStartOfTimeline, removed, data) => {
            try {
                const { roomId } = matrixRoom;
                const studyId = roomIdToStudyId.current[roomId];
                // Do not process if the event is not going to change the room timeline.
                if (roomsTimelineFilter.current && !toStartOfTimeline && data && data.liveEvent) {
                    const roomTimelineSet = matrixRoom.getOrCreateFilteredTimelineSet(roomsTimelineFilter.current);
                    /*
                        If the room timeline window available, it means that user opened this room before, so load it, if not,
                        when user opens the room for the first time, it'll load the new message that we get here too.
                    */
                    if (data.timeline.getTimelineSet() === roomTimelineSet && roomsTimelineWindow.current[roomId]) {
                        await loadTimeline(roomId, MATRIX_TIMELINE_PAGINATE_DIRECTIONS.FORWARDS);
                    } else if (Object.values(MATRIX_TIMELINE_EVENT_TYPES).includes(event.getType())) {
                        if (
                            event.sender.userId !== `@u${currentUserId}:${MATRIX_SERVER_NAME}` &&
                            event.sender.roomId !== selectedRoomId
                        ) {
                            newMessageTone.play();
                        }
                        // If room timeline is not loaded before, add this new event if it's supported by us.
                        updateState((stateDraft) => {
                            const currentState = current(stateDraft);
                            const room = stateDraft.studiesChat[studyId]?.rooms?.[roomId];
                            if (!room) {
                                return;
                            }
                            room.unreadMessagesCount = matrixRoom.getUnreadNotificationCount();
                            room.timeline.events = uniqBy(
                                [...currentState.studiesChat[studyId].rooms[roomId].timeline.events, event],
                                (e) => e.getId()
                            );
                            room.timeline.liveEvent = true;
                        });
                    }
                } else if (!data.liveEvent) {
                    updateState((stateDraft) => {
                        if (current(stateDraft).studiesChat[studyId]) {
                            stateDraft.studiesChat[studyId].rooms[roomId].timeline.liveEvent = false;
                        }
                    });
                }
            } catch (error) {
                errorHandle.anError(error);
            }
        },
        [loadTimeline, updateState, currentUserId, selectedRoomId, newMessageTone]
    );

    /*
        On Matrix Room.timelineReset event callback. It'll force load the timeline if needed.
     */
    const onMatrixRoomTimelineReset = useCallback(
        async (matrixRoom, timelineSet) => {
            try {
                const roomTimelineSet = matrixRoom.getOrCreateFilteredTimelineSet(roomsTimelineFilter.current);
                const { roomId } = matrixRoom;
                /*
                    If the room timeline window available, it means that user opened this room before, so load it, if not,
                    when user opens the room for the first time, it'll load the new message that we get here too.
                */
                if (timelineSet === roomTimelineSet && roomsTimelineWindow.current[roomId]) {
                    await forceResetTimeline(roomId);
                }
            } catch (error) {
                errorHandle.anError(error);
            }
        },
        [forceResetTimeline]
    );

    /*
        On Matrix Room.localEchoUpdated event callback. It'll change the local sent events statuses.
     */
    const onMatrixRoomLocalEchoUpdated = useCallback(
        async (event, matrixRoom) => {
            try {
                const { roomId } = matrixRoom;
                if (roomsTimelineWindow.current[roomId]) {
                    await reloadTimelineEvents(roomId);
                }
            } catch (error) {
                errorHandle.anError(error);
            }
        },
        [reloadTimelineEvents]
    );

    /*
        On Matrix Call.incoming event callback. It'll show the incoming call dialog and save the incoming call instance for future
        usages.
     */
    const onMatrixCallIncoming = useCallback(
        (call) => {
            const studyId = roomIdToStudyId.current[call.roomId];
            if (studyId) {
                const timelineEvents = state.studiesChat[studyId].rooms[call.roomId].timeline.events;
                for (let i = timelineEvents.length - 1; i >= 0; i -= 1) {
                    const { event } = timelineEvents[i];
                    if (event.type === MATRIX_TIMELINE_EVENT_TYPES.CALL_INVITE && event.content.call_id === call.callId) {
                        registerCallEventListeners(call);
                        voipCalls.current.incoming[call.callId] = call;
                        updateVoip((voipDraft) => {
                            voipDraft.incomingCallsData.push({
                                id: call.callId,
                                roomId: call.roomId,
                                // call.type will be null, but will change in a bit later to the correct value; we get the null here.
                                // TODO-MAYBE: Find a better way to determine this from the event.
                                type: event.content?.offer?.sdp?.includes('m=video') ? CallType.Video : CallType.Voice,
                            });
                            updateCurrentIncomingVoipCallData(voipDraft);
                        });
                        break;
                    }
                }
            }
        },
        [registerCallEventListeners, state.studiesChat, updateCurrentIncomingVoipCallData, updateVoip]
    );

    const onMatrixRoomReceipt = useCallback(
        (event, room) => {
            const eventContent = event.getContent();
            const eventId = Object.keys(eventContent)[0];
            let eventTimeMs = 0;
            Object.keys(eventContent[eventId]['m.read']).forEach((matrixUserId) => {
                if (matrixUserId !== matrix.userData.userId) {
                    eventTimeMs = Math.max(eventContent[eventId]['m.read'][matrixUserId].ts, eventTimeMs);
                }
            });
            if (eventTimeMs) {
                updateState((stateDraft) => {
                    const roomStudyId = roomIdToStudyId.current[room.roomId];
                    if (
                        stateDraft.studiesChat[roomStudyId]?.rooms[room.roomId]?.otherMembersLastReadMarker?.timeMs < eventTimeMs
                    ) {
                        const otherMembersLastReadMarkerEvent = current(stateDraft).studiesChat[roomStudyId].rooms[
                            room.roomId
                        ].timeline.events.find(({ event: timelineEvent }) => timelineEvent.event_id === eventId);
                        stateDraft.studiesChat[roomStudyId].rooms[room.roomId].otherMembersLastReadMarker = {
                            timeMs: eventTimeMs,
                            eventId,
                            event: otherMembersLastReadMarkerEvent,
                        };
                    }
                });
            }
        },
        [matrix.userData.userId, updateState]
    );

    const addRoom = useCallback(
        (matrixRoom, shouldPrepareCurrentStudyChat = false) => {
            // TODO-MAYBE: I used studyNameGetter to check if the user belongs to a study.
            const normalizedRoom = normalizeMatrixRoom(matrixRoom, currentUserId, studyNameGetter);
            if (normalizedRoom) {
                const { studyId, id: roomId } = normalizedRoom;
                updateState((stateDraft) => {
                    if (stateDraft.studiesChat[studyId] === undefined) {
                        stateDraft.studiesChat[studyId] = { ...cloneDeep(STUDY_CHAT), studyId };
                    }
                    roomIdToStudyId.current[roomId] = studyId;
                    // To prevent duplicate rooms being added to the array when developing with HMR.
                    if (!stateDraft.studiesChat[studyId].rooms[roomId]) {
                        // It's not currently ordered. Just push the Ids. Will be ordered soon :)
                        stateDraft.studiesChat[studyId].roomsOrder.push(roomId);
                    }
                    stateDraft.studiesChat[studyId].rooms[roomId] = normalizedRoom;
                    // Populate the timeline with the events from the initial sync.
                    stateDraft.studiesChat[studyId].rooms[roomId].timeline.events = uniqBy(
                        [
                            ...matrixRoom.timeline.filter((event) =>
                                Object.values(MATRIX_TIMELINE_EVENT_TYPES).includes(event.getType())
                            ),
                        ],
                        (event) => event.getId()
                    );

                    if (!hasAnyRooms) {
                        setHasAnyRooms(true);
                    }
                });
                if (shouldPrepareCurrentStudyChat) {
                    prepareCurrentStudyChat(matrixRoom.roomId);
                }
            }
        },
        [currentUserId, hasAnyRooms, prepareCurrentStudyChat, studyNameGetter, updateState]
    );

    /*
        On Matrix Room event callback. It'll be fired when the user is invited to or joined a new room.
     */
    const onMatrixRoom = useCallback(
        (room) => {
            addRoom(room, true);
        },
        [addRoom]
    );

    // TODO-MAYBE: We should merge it with onMatrixRoom.
    const onMatrixRoomMembershipChange = useCallback(
        (event, member) => {
            const { roomId, membership, userId: matrixUserId } = member;
            if (matrixUserId.startsWith('@admin:')) {
                return;
            }

            const [, userId] = matrixUserId.match(/@u([0-9]+):/);
            if (membership === MATRIX_MEMBERSHIP_TYPES.LEAVE) {
                const studyId = roomIdToStudyId.current[roomId];
                if (studyId) {
                    updateState((stateDraft) => {
                        const roomStudyChat = original(stateDraft.studiesChat[studyId]);
                        const room = roomStudyChat.rooms[roomId];
                        if (
                            room.type === ROOM_TYPES.PARTICIPANT_TO_RESEARCHER ||
                            room.type === ROOM_TYPES.RESEARCHER_TO_RESEARCHER ||
                            (room.type === ROOM_TYPES.PARTICIPANT_TO_RESEARCHERS &&
                                studiesUsersData[studyId]?.[userId].role === 'participant')
                        ) {
                            if (selectedRoomId === roomId) {
                                roomOnClick && roomOnClick('');
                            }
                            delete stateDraft.studiesChat[studyId].rooms[roomId];
                            const roomsOrderIndex = roomStudyChat.roomsOrder.findIndex((id) => id === roomId);
                            stateDraft.studiesChat[studyId].roomsOrder.splice(roomsOrderIndex, 1);
                            delete roomIdToStudyId.current[roomId];
                        }
                        // TODO: We should make sure the user isn't loaded as part of group rooms members.
                    });
                }
            }
        },
        [roomOnClick, selectedRoomId, studiesUsersData, updateState]
    );

    /*
        TODO: After the user enters the Avicenna app and chat room, while typing the first message of their session,
              if the last sent message does not belong to them, the "RoomMember.typing" event will not occur for other members of
              the room. Just an observation: if you set the initialSyncLimit of client.startClient on useMatrix
              to a large number, the issue will probably be fixed.
    */
    const onRoomMemberTypingEvent = useCallback(
        (event, member) => {
            const { typing, userId } = member;

            if (matrix.userData.userId !== userId) {
                const { roomId } = member;
                const roomStudyId = roomIdToStudyId.current[roomId];

                updateState((stateDraft) => {
                    const room = stateDraft.studiesChat[roomStudyId].rooms[roomId];
                    const membersTyping = room.membersTyping || [];

                    if (typing) {
                        room.membersTyping = [...membersTyping, userId];
                    } else {
                        room.membersTyping = membersTyping.filter((memberTyping) => memberTyping !== userId);
                    }
                });
            } else {
                isTyping.current = typing;
            }
        },
        [matrix.userData.userId, updateState]
    );

    const onRoomNameEvent = useCallback(
        (room) => {
            const studyId = roomIdToStudyId.current[room.roomId];
            if (!state.studiesChat[studyId]?.rooms?.[room.roomId]) {
                return;
            }
            updateState((stateDraft) => {
                stateDraft.studiesChat[studyId].rooms[room.roomId].title = getChatRoomTitle(
                    state.studiesChat[studyId].rooms[room.roomId],
                    studiesUsersData[studyId],
                    currentUserId,
                    t,
                    room.name
                );
            });
        },
        [currentUserId, state.studiesChat, studiesUsersData, t, updateState]
    );

    // It seems that User.presence, RoomMember.powerLevel, and some call events in JS SDK don't work as expected.
    // I used the general event for this purpose.
    const onEvent = useCallback(
        async (event) => {
            switch (event.event.type) {
                case 'm.presence': {
                    if (event.event.sender === `@admin:${MATRIX_SERVER_NAME}`) {
                        return;
                    }
                    const [, userId] = event.event.sender.match(/@u([0-9]+):/);
                    const userIdNumber = +userId;
                    // TODO: Use a constant enum.
                    const newIsOnline = event.event.content.presence === 'online';
                    // Currently, since our users data is spread over multiple studies, I decided to check for the current study only.
                    if (
                        studiesUsersData[currentStudyId]?.[userIdNumber] &&
                        studiesUsersData[currentStudyId]?.[userIdNumber]?.isOnline !== newIsOnline
                    ) {
                        updateStudiesUserData((studiesUsersDataDraft) => {
                            studiesUsersDataDraft[currentStudyId][userIdNumber].isOnline = newIsOnline;
                        });
                    }
                    return;
                }
                case MATRIX_TIMELINE_EVENT_TYPES.CALL_HANGUP: {
                    // This is a quick fix for calls that have been made before opening the app but are not concluded yet.
                    // Without this, if the caller ends such call, the receiver won't get the "hangup" event.
                    endCall(event.event.content.call_id);
                    return;
                }
                case 'm.room.power_levels': {
                    // We shouldn't check if the sender is admin or not, since it's admin who changes the power levels.
                    const newIsArchived =
                        (event.event.content?.users?.[matrix.userData.userId] || event.event.content?.users_default || 0) < 0;
                    const studyId = roomIdToStudyId.current[event.event.room_id];
                    updateState((stateDraft) => {
                        if (newIsArchived !== stateDraft.studiesChat[studyId].rooms[event.event.room_id].isArchived) {
                            stateDraft.studiesChat[studyId].rooms[event.event.room_id].isArchived = newIsArchived;
                        }
                    });
                    break;
                }
                case MEDIASOUP_EVENT_TYPES.CALL_UPGRADE: {
                    if (event.event.sender === matrix.client.credentials.userId) {
                        break;
                    }

                    if (voipCalls.current.mediasoup == null) {
                        updateVoip((voipDraft) => {
                            if (event.event.content.intention === 'screen_share') {
                                voipDraft.activeCallData.isScreenBeingShared = true;
                            } else {
                                voipDraft.activeCallData.isConnecting = true;
                            }
                        });

                        const roomStudyId = roomIdToStudyId.current[voipCalls.current.active.roomId];
                        const mediasoup = initializeMediasoup({
                            roomId: voipCalls.current.active.callId,
                            studyId: roomStudyId,
                            callType: voip.activeCallData.type,
                            onScreenShareStart,
                            onScreenShareStop,
                            onRecordingStop: () => showInfo(t('call_recording_stopped')),
                        });

                        await mediasoup.waitInitialized();
                        mediasoup.addMediaStream(voipCalls.current.active.localAVStream);
                        voipCalls.current.mediasoup = mediasoup;

                        await mediasoup.waitConsumersAdded();
                        const { cameraStream, screenStream } = mediasoup.getMediaStreams();

                        updateVoip((voipDraft) => {
                            voipDraft.activeCallData.remoteFeed = { stream: cameraStream };
                            voipDraft.activeCallData.remoteScreenFeed = screenStream.active ? { stream: screenStream } : null;

                            if (event.event.content.intention === 'screen_share') {
                                voipDraft.activeCallData.isScreenBeingShared = false;
                            } else {
                                voipDraft.activeCallData.isConnecting = false;
                            }
                        });
                        voipCalls.current.active.peerConn.close();
                    }

                    break;
                }
                case MEDIASOUP_EVENT_TYPES.CALL_RECORDING: {
                    if (event.event.sender === matrix.client.credentials.userId) {
                        break;
                    }

                    const isRecording = event.event.content.recording;

                    if (isRecording) {
                        showWarning(t('call_recording_initiated'));
                    } else {
                        showInfo(t('call_recording_stopped'));
                    }

                    voipCalls.current.mediasoup.setIsRecording(isRecording);

                    updateVoip((voipDraft) => {
                        voipDraft.activeCallData.recording = isRecording;
                    });

                    break;
                }
                case MATRIX_TIMELINE_EVENT_TYPES.CALL_ANSWER: {
                    updateVoip((voipDraft) => {
                        voipDraft.activeCallData.isConnecting = true;
                    });
                    break;
                }
                default: {
                    break;
                }
            }
        },
        [
            studiesUsersData,
            currentStudyId,
            updateStudiesUserData,
            endCall,
            matrix.userData.userId,
            matrix.client?.credentials?.userId,
            updateState,
            updateVoip,
            voip.activeCallData.type,
            onScreenShareStart,
            onScreenShareStop,
            showWarning,
            t,
            showInfo,
        ]
    );

    useEffect(() => {
        if (voipCalls.current.active?.state === 'connected') {
            updateVoip((voipDraft) => {
                voipDraft.activeCallData.isConnecting = false;
            });
        }
    }, [updateVoip, voipCalls.current.active?.state]);

    // It seems that User.displayName and User.avatarUrl events in JS SDK don't work as expected.
    // That's why we used this event.
    const onRoomStateMembersEvent = (event, roomState, member) => {
        const room = matrix.client.getRoom(roomState.roomId);
        if (!room || member.userId === `@admin:${MATRIX_SERVER_NAME}`) {
            return;
        }
        const [, userId] = member.userId.match(/@u([0-9]+):/);
        const userIdNumber = +userId;
        const studyId = roomIdToStudyId.current[member.roomId];

        if (studiesUsersData[studyId]?.[userIdNumber]) {
            updateStudiesUserData((studiesUsersDataDraft) => {
                studiesUsersDataDraft[studyId][userIdNumber].avatar.url = member.events.member.event.content.avatar_url;
                studiesUsersDataDraft[studyId][userIdNumber].label = member.name;
            });
        }
    };

    /*
        Initialize all the Matrix rooms. The chat client is not study-specific as the Matrix is not. We create a chat for
        each study and add the related rooms to it.
     */
    const initializeRooms = useCallback(() => {
        matrix.client
            .getRooms()
            .filter((matrixRoom) => matrixRoom.getMyMembership() === MATRIX_MEMBERSHIP_TYPES.JOIN)
            .forEach((matrixRoom) => {
                addRoom(matrixRoom);
            });
    }, [addRoom, matrix.client]);

    /*
        Initialize the client. This will be called once, just after we have a successful Matrix server connection.
     */
    const initialize = useCallback(async () => {
        try {
            setStatus(CHAT_CLIENT_STATUS.LOADING);
            // Create or retrieve the timeline filter for future use.
            roomsTimelineFilter.current = await createRoomsTimelineFilter(matrix.client, matrix.userData);
            // Initialize all the rooms of Matrix that the current user is a member of.
            await initializeRooms();
            // Register the Matrix client event listeners as it's the only way we get notified about what's happening.
            setStatus(CHAT_CLIENT_STATUS.READY);
        } catch (error) {
            errorHandle.anError(error);
            setStatus(CHAT_CLIENT_STATUS.FAILED);
        }
    }, [initializeRooms, matrix.client, matrix.userData]);

    const stopTyping = useCallback(() => {
        matrix.client.sendTyping(selectedRoomId, false);
        isTyping.current = false;
    }, [matrix.client, selectedRoomId]);

    const throttleStartTyping = useMemo(
        () =>
            throttle(
                () => {
                    matrix.client.sendTyping(selectedRoomId, true, 5000);
                    isTyping.current = true;
                },
                5100,
                { trailing: false }
            ),
        [selectedRoomId, matrix.client]
    );

    useEffect(() => {
        return () => {
            throttleStartTyping.cancel();
        };
    }, [throttleStartTyping]);

    useEffect(() => {
        return () => {
            if (isTyping.current) {
                stopTyping();
            }
        };
    }, [stopTyping]);

    /*
        Register event listeners that are needed to work with Matrix. These events are not study-specific and will capture
        all events from Matrix. Then the events will be filtered into ones we really wanted to act based on.
    */
    useEffect(() => {
        // TODO-MAYBE: We might be able to remove the second condition and initializeRooms together.
        // Currently, if we don't include it, and open the Chat page immediately, onMatrixRoom will be called for all the rooms too.
        if (!matrix.client || !matrix.clientStatus.isReady) {
            return undefined;
        }

        matrix.client.on('sync', onMatrixSyncEvent);
        matrix.client.on('Room.timeline', onMatrixRoomTimelineEvent);
        matrix.client.on('Room.timelineReset', onMatrixRoomTimelineReset);
        matrix.client.on('Room.localEchoUpdated', onMatrixRoomLocalEchoUpdated);
        matrix.client.on('Call.incoming', onMatrixCallIncoming);
        matrix.client.on('Room.receipt', onMatrixRoomReceipt);
        matrix.client.on('Room', onMatrixRoom);
        matrix.client.on('RoomMember.membership', onMatrixRoomMembershipChange);
        matrix.client.on('RoomMember.typing', onRoomMemberTypingEvent);
        matrix.client.on('Room.name', onRoomNameEvent);
        matrix.client.on('event', onEvent);
        matrix.client.on('RoomState.members', onRoomStateMembersEvent);

        return () => {
            matrix.client.off('sync', onMatrixSyncEvent);
            matrix.client.off('Room.timeline', onMatrixRoomTimelineEvent);
            matrix.client.off('Room.timelineReset', onMatrixRoomTimelineReset);
            matrix.client.off('Room.localEchoUpdated', onMatrixRoomLocalEchoUpdated);
            matrix.client.off('Call.incoming', onMatrixCallIncoming);
            matrix.client.off('Room.receipt', onMatrixRoomReceipt);
            matrix.client.off('Room', onMatrixRoom);
            matrix.client.off('RoomMember.membership', onMatrixRoomMembershipChange);
            matrix.client.off('RoomMember.typing', onRoomMemberTypingEvent);
            matrix.client.off('Room.name', onRoomNameEvent);
            matrix.client.off('event', onEvent);
            matrix.client.off('RoomState.members', onRoomStateMembersEvent);
        };
    });

    /*
        Initialize when the matrix client is ready.
     */
    useEffect(() => {
        if (matrix.clientStatus.isReady && status === CHAT_CLIENT_STATUS.UNKNOWN && studyNameGetter) {
            initialize();
        }
    }, [status, initialize, matrix.clientStatus.isReady, studyNameGetter]);

    /*
        When unmounted, reset status.
     */
    useEffect(() => {
        return () => {
            setStatus(CHAT_CLIENT_STATUS.UNKNOWN);
        };
    }, []);

    useEffect(() => {
        updateState((stateDraft) => {
            if (!stateDraft.studiesChat[currentStudyId]) {
                stateDraft.studiesChat[currentStudyId] = { ...cloneDeep(STUDY_CHAT), studyId: String(currentStudyId) };
            }
        });
        setRemoteSearchRooms([]);
    }, [currentStudyId, updateState]);

    /*
        Check if our chat client is ready and the necessary data related to the current study exist.
        If yes, prepare the study-specific chat.
     */
    useEffect(() => {
        if (
            status === CHAT_CLIENT_STATUS.READY &&
            currentStudyUsersDataLoader !== null &&
            currentStudyId !== -1 &&
            state.studiesChat[currentStudyId] &&
            (state.studiesChat[currentStudyId].status === STUDY_CHAT_STATUS.UNKNOWN ||
                state.studiesChat[currentStudyId].status === STUDY_CHAT_STATUS.FAILED)
        ) {
            // TODO-MAYBE: We might need to consider studies in which there are lots of participants.
            prepareCurrentStudyChat();
        }
    }, [currentStudyId, currentStudyUsersDataLoader, prepareCurrentStudyChat, state.studiesChat, status]);

    // Check for any call that was started before opening the app and isn't answered/ended yet.
    // TODO-MAYBE: This might be slow for large number of rooms.
    useEffect(() => {
        if (status === CHAT_CLIENT_STATUS.READY && !checkedForCallBeforeOpeningTheApp.current) {
            const roomIds = Object.keys(roomIdToStudyId.current);
            for (let i = 0; i < roomIds.length && !checkedForCallBeforeOpeningTheApp.current; i += 1) {
                const studyId = roomIdToStudyId.current[roomIds[i]];
                if (studyId && state.studiesChat[studyId]) {
                    const studyChatRoom = state.studiesChat[studyId].rooms[roomIds[i]];
                    // roomIdToStudyId might be populated, but the state might not be.
                    const timelineEvents = studyChatRoom?.timeline.events || [];
                    for (let j = timelineEvents.length - 1; j >= 0 && !checkedForCallBeforeOpeningTheApp.current; j -= 1) {
                        const event1 = timelineEvents[j];
                        if (
                            event1.getType() === MATRIX_TIMELINE_EVENT_TYPES.CALL_INVITE &&
                            event1.sender.userId !== `@u${currentUserId}:${MATRIX_SERVER_NAME}`
                        ) {
                            let callEventConcluded = false;
                            for (let k = j + 1; k < timelineEvents.length; k += 1) {
                                const { event: event2 } = timelineEvents[k];
                                if (
                                    [
                                        MATRIX_TIMELINE_EVENT_TYPES.CALL_ANSWER,
                                        MATRIX_TIMELINE_EVENT_TYPES.CALL_HANGUP,
                                        MATRIX_TIMELINE_EVENT_TYPES.CALL_REJECT,
                                    ].includes(event2.type) &&
                                    event2.content.call_id === event1.event.content.call_id
                                ) {
                                    callEventConcluded = true;
                                    break;
                                }
                            }
                            if (!callEventConcluded) {
                                const newCall = matrixSDK.createNewMatrixCall(matrix.client, event1.event.room_id, {
                                    forceTURN: matrix.client.forceTURN,
                                });
                                newCall.callId = event1.event.content.call_id;
                                newCall.initWithInvite(event1).then(() => {
                                    onMatrixCallIncoming(newCall);
                                });
                                checkedForCallBeforeOpeningTheApp.current = true;
                            }
                        }
                    }
                }
            }
            checkedForCallBeforeOpeningTheApp.current = true;
        }
    }, [currentUserId, matrix.client, onMatrixCallIncoming, state.studiesChat, status]);

    const debounceRemoteRoomSearch = useMemo(
        () =>
            debounce((keyword) => {
                setIsSearchingRemoteRooms(true);
                matrix.client
                    // We use user directory endpoints to search for all possible rooms a user can have.
                    ?.searchUserDirectory({ term: keyword })
                    .then((response) => {
                        setRemoteSearchRooms([
                            ...response.results
                                // BE will set the user_id to the room alias.
                                .filter((room) => room.user_id.startsWith(`#s${currentStudyId}_`))
                                .map((room) => {
                                    // TODO: Refactor utils and use them instead.
                                    const roomAlias = room.user_id;
                                    const [, , type, userIdOneString, userIdTwoString] = roomAlias.match(ROOM_ALIAS_REGEX);
                                    const userIds = [Number(userIdOneString), Number(userIdTwoString)];
                                    let roomColorValueIndex;
                                    if (type === ROOM_TYPES.PARTICIPANT_TO_RESEARCHERS) {
                                        roomColorValueIndex = userIds[0];
                                    } else if (
                                        type === ROOM_TYPES.PARTICIPANT_TO_RESEARCHER ||
                                        type === ROOM_TYPES.RESEARCHER_TO_RESEARCHER
                                    ) {
                                        const currentUserIdIndex = userIds.indexOf(currentUserId);
                                        if (currentUserIdIndex >= 0) {
                                            roomColorValueIndex = userIds[1 - currentUserIdIndex];
                                        }
                                    } else if (type === ROOM_TYPES.RESEARCHER_TO_RESEARCHERS) {
                                        roomColorValueIndex = currentStudyId;
                                    }
                                    return {
                                        alias: roomAlias,
                                        label: room.display_name,
                                        studyId: currentStudyId,
                                        avatar: {
                                            url:
                                                room.avatar_url && !room.avatar_url.endsWith('default_avatar.png')
                                                    ? URLsManager.getURLTo(room.avatar_url)
                                                    : '',
                                            label: room.display_name,
                                            color: getAvatarColorById(roomColorValueIndex),
                                        },
                                        direct: [
                                            ROOM_TYPES.RESEARCHER_TO_RESEARCHER,
                                            ROOM_TYPES.PARTICIPANT_TO_RESEARCHER,
                                        ].includes(type),
                                    };
                                }),
                        ]);
                    })
                    .finally(() => {
                        setIsSearchingRemoteRooms(false);
                    });
            }, 1000),
        [currentStudyId, currentUserId, matrix.client]
    );

    useEffect(() => {
        return () => {
            debounceRemoteRoomSearch.cancel();
        };
    }, [debounceRemoteRoomSearch]);

    useEffect(() => {
        if (isRoomsSearchRemote) {
            debounceRemoteRoomSearch(roomsSearchKeyword);
        }
    }, [debounceRemoteRoomSearch, isRoomsSearchRemote, roomsSearchKeyword]);

    /*
        The status that the UI will consume for showing loading, error or other things related to the chat client. It's come both
        from Matrix client statuses and the chat client statuses.
     */
    const cumulativeClientStatus = useMemo(
        () => ({
            isUnknown: matrix.clientStatus.isUnknown && status === CHAT_CLIENT_STATUS.UNKNOWN,
            isLoading: matrix.clientStatus.isReady && status === CHAT_CLIENT_STATUS.LOADING,
            isReady: matrix.clientStatus.isReady && status === CHAT_CLIENT_STATUS.READY,
            isFailed: matrix.clientStatus.isFailed || status === CHAT_CLIENT_STATUS.FAILED,
            isConnectionLost: matrix.clientStatus.isReady && status === CHAT_CLIENT_STATUS.CONNECTION_LOST,
            isReconnecting: matrix.clientStatus.isReady && status === CHAT_CLIENT_STATUS.RECONNECTING,
        }),
        [matrix.clientStatus.isFailed, matrix.clientStatus.isReady, matrix.clientStatus.isUnknown, status]
    );

    // TODO: On PDash, since the Chat is rendered inside a Dialog, it won't set the currentStudyId correctly and we won't be able to make this count work.
    //       If we wanted to make it a page on PDash too, we should make sure Chat urls related to studies with no Chat feature are redirected and the user can't switch to such study if the dropdown is visible.
    //       Also, we should move the Loco key related to "Chat is not enabled for these studies" to PDash project of Loco.
    const totalNumberOfUnreadEvents = useMemo(
        () =>
            Object.fromEntries(
                Object.keys(state.studiesChat).map((studyId) => [
                    studyId,
                    Object.keys(state.studiesChat[studyId].rooms).reduce(
                        (sum, roomId) => sum + (state.studiesChat[studyId]?.rooms[roomId]?.unreadMessagesCount || 0),
                        0
                    ),
                ])
            ),
        [state.studiesChat]
    );

    /*
        CAUTION: This memo, should not be re-evaluated again once created. So the dependency array should be always empty or
        contains values that are not going to change during lifecycle of this state.
     */
    const methods = useMemo(
        () => ({
            setCurrentStudyId(studyId) {
                setCurrentStudyId(studyId);
            },
            setRoomsSearchKeyword(newKeyword) {
                setRoomsSearchKeyword(newKeyword);
            },
            setIsRoomsSearchRemote(isRemoteSearch) {
                setIsRoomsSearchRemote(isRemoteSearch);
            },
            setRoomOnClick(newRoomOnClick) {
                setRoomOnClick(() => newRoomOnClick);
            },
            setSelectedRoomId(newRoomId) {
                setSelectedRoomId(newRoomId);
            },
            /*
                Set current study user data loader. This will be used for getting users data, label, first name, last name
                and avatar if exist.
             */
            setCurrentStudyUsersDataLoader(loader) {
                setCurrentStudyUsersDataLoader(() => loader);
            },
            setIsChatSidebarVisibleOnXs(isVisible) {
                setIsChatSidebarVisibleOnXs(isVisible);
            },
            loadTimeline,
            updateRoomReadRecipient,
            setRoomMessageDraft(roomId, messageText) {
                updateMessagesDraft((messagesDraftDraft) => {
                    if (!messagesDraftDraft.rooms[roomId]) {
                        messagesDraftDraft.rooms[roomId] = cloneDeep(ROOM_MESSAGE_DRAFT);
                    }
                    messagesDraftDraft.rooms[roomId].text = messageText;
                });
            },
            async sendMessageDraftToRoom(roomId, message) {
                try {
                    if (message.text !== '') {
                        updateMessagesDraft((messagesDraftDraft) => {
                            messagesDraftDraft.rooms[roomId] = cloneDeep(ROOM_MESSAGE_DRAFT);
                        });
                        stopTyping();
                        await matrix.client.sendTextMessage(roomId, message.text);
                    }
                } catch (error) {
                    errorHandle.anError(error);
                    // TODO: Maybe add a better message
                    showError(t('generic_error_msg'));
                }
            },
            async resendEvent(event, room) {
                await matrix.client.resendEvent(event, room);
            },
            async rejectIncomingVoipCall(callId) {
                if (voipCalls.current.incoming[callId]) {
                    voipCalls.current.incoming[callId].reject();
                }
            },
            async answerIncomingVoipCall(callId, isVideoMuted) {
                if (voipCalls.current.incoming[callId]) {
                    const previousActiveCall = voipCalls.current.active;
                    voipCalls.current.active = voipCalls.current.incoming[callId];
                    removeCallFromIncomingCalls(callId);
                    registerCallEventListeners(voipCalls.current.active, true, isVideoMuted);
                    updateVoip((voipDraft) => {
                        voipDraft.activeCallData = {
                            ...cloneDeep(VOIP_CALL_DATA),
                            id: voipCalls.current.active.callId,
                            roomId: voipCalls.current.active.roomId,
                            status: VOIP_CALL_STATUS.WAITING_FOR_PERMISSION,
                            audioMuted: voipCalls.current.active.isMicrophoneMuted(),
                            videoMuted: voipCalls.current.active.isLocalVideoMuted() || isVideoMuted,
                            type: voipCalls.current.active.type,
                            isConnecting: true,
                        };
                    });
                    voipCalls.current.active.answer();
                    if (previousActiveCall !== null) {
                        previousActiveCall.hangup();
                    }
                }
            },
            async createVoipCallInRoom(roomId, type = VOIP_CALL_TYPES.VIDEO) {
                try {
                    voipCalls.current.active = matrixSDK.createNewMatrixCall(matrix.client, roomId);

                    registerCallEventListeners(voipCalls.current.active, true);

                    updateVoip((voipDraft) => {
                        voipDraft.activeCallData.status = VOIP_CALL_STATUS.WAITING_FOR_PERMISSION;
                        voipDraft.activeCallData.id = voipCalls.current.active.callId;
                        voipDraft.activeCallData.roomId = voipCalls.current.active.roomId;
                        voipDraft.activeCallData.type = type;
                    });

                    if (type === VOIP_CALL_TYPES.VIDEO) {
                        await voipCalls.current.active.placeVideoCall();
                    } else if (type === VOIP_CALL_TYPES.VOICE) {
                        await voipCalls.current.active.placeVoiceCall();
                    }
                } catch (error) {
                    errorHandle.anError(error);
                    // TODO: Maybe add a better message
                    showError(t('generic_error_msg'));
                }
            },
            hangupActiveVoipCall() {
                if (voipCalls.current.active !== null) {
                    voipCalls.current.active.hangup();
                }
            },
            toggleActiveVoipCallAudio() {
                if (voipCalls.current.active !== null) {
                    updateVoip((voipDraft) => {
                        voipCalls.current.active.setMicrophoneMuted(!voipDraft.activeCallData.audioMuted);
                        voipDraft.activeCallData.audioMuted = !voipDraft.activeCallData.audioMuted;
                    });
                }
            },
            toggleActiveVoipCallVideo() {
                if (voipCalls.current.active !== null) {
                    updateVoip((voipDraft) => {
                        voipCalls.current.active.setLocalVideoMuted(!voipDraft.activeCallData.videoMuted);
                        voipDraft.activeCallData.videoMuted = !voipDraft.activeCallData.videoMuted;
                    });
                }
            },
            async startRecordingActiveCall() {
                if (voipCalls.current.active !== null) {
                    updateVoip((voipDraft) => {
                        voipDraft.activeCallData.isConnecting = true;
                    });

                    if (voipCalls.current.mediasoup == null) {
                        const roomStudyId = roomIdToStudyId.current[voipCalls.current.active.roomId];
                        const mediasoup = initializeMediasoup({
                            roomId: voipCalls.current.active.callId,
                            studyId: roomStudyId,
                            callType: voip.activeCallData.type,
                            onScreenShareStart,
                            onScreenShareStop,
                            onRecordingStop: () => showInfo(t('call_recording_stopped')),
                        });

                        await mediasoup.waitInitialized();

                        // Upgrade the call to mediasoup
                        matrix.client.sendEvent(voipCalls.current.active.roomId, MEDIASOUP_EVENT_TYPES.CALL_UPGRADE);

                        await mediasoup.addMediaStream(voipCalls.current.active.localAVStream);
                        voipCalls.current.mediasoup = mediasoup;

                        await mediasoup.waitConsumersAdded();
                        const { cameraStream } = mediasoup.getMediaStreams();

                        updateVoip((voipDraft) => {
                            voipDraft.activeCallData.remoteFeed = { stream: cameraStream };
                        });

                        await mediasoup.startRecording();
                        voipCalls.current.active.peerConn.close();
                    } else if (!voipCalls.current.mediasoup.isRecording()) {
                        await voipCalls.current.mediasoup.startRecording();
                    }

                    await matrix.client.sendEvent(voipCalls.current.active.roomId, MEDIASOUP_EVENT_TYPES.CALL_RECORDING, {
                        recording: true,
                        version: '1',
                        call_id: voipCalls.current.active.callId,
                        party_id: voipCalls.current.active.invitee || voipCalls.current.active.getOpponentMember()?.userId,
                    });

                    showWarning(t('call_recording_initiated'));

                    updateVoip((voipDraft) => {
                        voipDraft.activeCallData.recording = true;
                        voipDraft.activeCallData.isConnecting = false;
                    });
                }
            },
            async stopRecordingActiveCall() {
                if (
                    voipCalls.current.active == null ||
                    voipCalls.current.mediasoup == null ||
                    voipCalls.current.mediasoup.isRecording() !== true
                ) {
                    return;
                }
                await voipCalls.current.mediasoup.stopRecording();
                await matrix.client.sendEvent(voipCalls.current.active.roomId, MEDIASOUP_EVENT_TYPES.CALL_RECORDING, {
                    recording: false,
                    version: '1',
                    call_id: voipCalls.current.active.callId,
                    party_id: voipCalls.current.active.invitee || voipCalls.current.active.getOpponentMember()?.userId,
                });

                updateVoip((voipDraft) => {
                    voipDraft.activeCallData.recording = false;
                });
            },
            logout() {
                matrix.logout();
            },
            getStudyName: studyNameGetter,
        }),
        [
            loadTimeline,
            updateRoomReadRecipient,
            studyNameGetter,
            updateMessagesDraft,
            stopTyping,
            matrix,
            showError,
            t,
            removeCallFromIncomingCalls,
            registerCallEventListeners,
            updateVoip,
            showWarning,
            voip.activeCallData.type,
            onScreenShareStart,
            onScreenShareStop,
            showInfo,
        ]
    );

    const dynamicMethods = useMemo(
        () => ({
            getStudyIdByMatrixRoomId(matrixRoomId) {
                return roomIdToStudyId.current[matrixRoomId];
            },
            getRoom(roomId) {
                const roomStudyId = roomIdToStudyId.current[roomId];
                if (roomStudyId) {
                    return state.studiesChat[roomStudyId]?.rooms[roomId] || {};
                }
                return {};
            },
            getRoomTitle(roomId) {
                const roomStudyId = roomIdToStudyId.current[roomId];
                if (roomStudyId) {
                    return state.studiesChat[roomStudyId]?.rooms[roomId]?.title || '';
                }
                return '';
            },
            getMembersDataByMatrixRoomId(matrixRoomId) {
                const studyId = roomIdToStudyId.current[matrixRoomId];
                const room = state.studiesChat[studyId].rooms[matrixRoomId];
                const membersData = {};

                // Here, I needed to spread the users data, otherwise I'd get "can't define property 'x': 'obj' is not extensible" when setting hasDirect.
                if (room.type === ROOM_TYPES.RESEARCHER_TO_RESEARCHERS || room.type === ROOM_TYPES.PARTICIPANT_TO_RESEARCHERS) {
                    Object.keys(studiesUsersData[studyId]).forEach((userId) => {
                        if (studiesUsersData[studyId][userId].role !== 'participant') {
                            membersData[userId] = { ...studiesUsersData[studyId][userId] };
                        }
                    });

                    if (room.type === ROOM_TYPES.PARTICIPANT_TO_RESEARCHERS) {
                        membersData[room.otherMemberId] = { ...studiesUsersData[studyId][room.otherMemberId] };
                    }
                } else {
                    membersData[room.otherMemberId] = { ...studiesUsersData[studyId][room.otherMemberId] };
                    membersData[currentUserId] = { ...studiesUsersData[studyId][currentUserId] };
                }

                Object.keys(state.studiesChat[currentStudyId].rooms).forEach((currentStudyRoomId) => {
                    const { direct, otherMemberId } = state.studiesChat[currentStudyId].rooms[currentStudyRoomId];

                    try {
                        if (direct && membersData[otherMemberId]) {
                            // TODO: This property is not used anymore.
                            membersData[otherMemberId].hasDirect = true;
                        }
                    } catch (error) {
                        // Do nothing.
                    }
                });
                return Object.values(membersData);
            },
            setCurrentRoomIdByUserId(userId) {
                const roomId = Object.keys(state.studiesChat[currentStudyId].rooms).find(
                    (currentStudyRoomId) =>
                        userId &&
                        state.studiesChat[currentStudyId].rooms[currentStudyRoomId].direct &&
                        state.studiesChat[currentStudyId].rooms[currentStudyRoomId].otherMemberId === +userId
                );
                if (roomId) {
                    if (roomOnClick && typeof roomOnClick === 'function') {
                        roomOnClick(roomId);
                    }
                } else {
                    let calculatedAlias = '';
                    const selectedRoom = state.studiesChat[currentStudyId].rooms[selectedRoomId];
                    if (!selectedRoom) {
                        return;
                    }
                    // We don't need to check direct rooms since we'd be already in them otherwise.
                    if (selectedRoom.type === ROOM_TYPES.RESEARCHER_TO_RESEARCHERS) {
                        calculatedAlias = `#s${currentStudyId}_r2r_${userId}_${currentUserId}:${MATRIX_SERVER_NAME}`;
                    } else if (selectedRoom.type === ROOM_TYPES.PARTICIPANT_TO_RESEARCHERS) {
                        if (selectedRoom.otherMemberId === +userId) {
                            calculatedAlias = `#s${currentStudyId}_p2r_${userId}_${currentUserId}:${MATRIX_SERVER_NAME}`;
                        } else if (selectedRoom.otherMemberId === currentUserId) {
                            calculatedAlias = `#s${currentStudyId}_p2r_${currentUserId}_${userId}:${MATRIX_SERVER_NAME}`;
                        } else {
                            calculatedAlias = `#s${currentStudyId}_r2r_${userId}_${currentUserId}:${MATRIX_SERVER_NAME}`;
                        }
                    }
                    if (calculatedAlias) {
                        dynamicMethods.createOrOpenRoom(calculatedAlias);
                    }
                }
            },
            setRoomMessageDraftAndSendTyping(messageText) {
                methods.setRoomMessageDraft(selectedRoomId, messageText);
                throttleStartTyping();
            },
            /*
                Get study user data by matrix id. As the study user data is saved by the user id and not matrix user id, it needs
                a little bit work before getting the actual user data.
             */
            getStudyUserDataByMatrixId(studyId, matrixUserId) {
                const [, userId] = matrixUserId.match(/@u([0-9]+):/);
                return studiesUsersData[studyId]?.[userId];
            },
            createOrOpenRoom(roomAlias) {
                if (!roomAlias) {
                    return;
                }
                const [, studyId, type, userIdOneString, userIdTwoString] = roomAlias.match(ROOM_ALIAS_REGEX);
                const alternativeRoomAlias =
                    type === ROOM_TYPES.RESEARCHER_TO_RESEARCHER
                        ? `#s${studyId}_r2r_${userIdTwoString}_${userIdOneString}:${MATRIX_SERVER_NAME}`
                        : '';
                const foundRoom = Object.values(state.studiesChat[currentStudyId].rooms).find(
                    (room) => room.alias === roomAlias || (alternativeRoomAlias && room.alias === alternativeRoomAlias)
                );
                if (foundRoom) {
                    setIsRoomsSearchRemote(false);
                    setRoomsSearchKeyword('');
                    roomOnClick(foundRoom.id);
                    // TODO: Move this to roomOnClick itself. See the next usage below too.
                    setIsChatSidebarVisibleOnXs(false);
                } else {
                    setIsCreatingARoom(true);
                    matrix.client
                        .createRoom({ room_alias_name: roomAlias })
                        .then((response) => {
                            setIsRoomsSearchRemote(false);
                            setRoomsSearchKeyword('');
                            roomOnClick(response.room_id);
                            setIsChatSidebarVisibleOnXs(false);
                        })
                        .catch((error) => {
                            errorHandle.anError(error);
                            // TODO-MAYBE: Use a better message.
                            showError(t('generic_error_msg'));
                        })
                        .finally(() => {
                            setIsCreatingARoom(false);
                        });
                }
            },
        }),
        [
            state.studiesChat,
            currentStudyId,
            studiesUsersData,
            currentUserId,
            roomOnClick,
            showError,
            methods,
            selectedRoomId,
            throttleStartTyping,
            matrix.client,
            t,
        ]
    );

    /*
        Current study chat status used for UI.
        TODO: This state isn't used anymore.
        TODO-MAYBE: As it depends on state.studiesChat, updates on that property may make this reevaluated more than needed. If it caused
                    lots of issues, we can move the study chat statuses into a separated state.
     */
    const currentStudyChatStatus = useMemo(
        () => ({
            status: state.studiesChat[currentStudyId]?.status,
            isUnknown: state.studiesChat[currentStudyId]?.status === STUDY_CHAT_STATUS.UNKNOWN,
            isLoading: state.studiesChat[currentStudyId]?.status === STUDY_CHAT_STATUS.LOADING,
            isReady: state.studiesChat[currentStudyId]?.status === STUDY_CHAT_STATUS.READY,
            isFailed: state.studiesChat[currentStudyId]?.status === STUDY_CHAT_STATUS.FAILED,
        }),
        [currentStudyId, state.studiesChat]
    );

    return {
        methods,
        dynamicMethods,
        hasAnyRooms,
        state,
        currentStudyId,
        currentStudyChatStatus,
        status: cumulativeClientStatus,
        userData: matrix.userData,
        messagesDraft,
        roomsSearchKeyword,
        isRoomsSearchRemote,
        remoteSearchRooms,
        isCreatingARoom,
        isSearchingRemoteRooms,
        voip,
        totalNumberOfUnreadEvents,
        isChatSidebarVisibleOnXs,
        chatFlags,
        studiesUsersData,
        currentUserId,
        dashboard,
    };
};

const [
    ChatClientContextProvider,
    useChat,
    useMethods,
    useDynamicMethods,
    useChatUserData,
    useCurrentStudyId,
    useCurrentStudyChatStatus,
    useCurrentStudyRoomsOrder,
    useCurrentStudyRooms,
    useMessagesDraft,
    useRoomsSearchKeyword,
    useIsRoomsSearchRemote,
    useRemoteSearchRooms,
    useIsCreatingARoom,
    useIsSearchingRemoteRooms,
    useVoip,
    useTotalNumberOfUnreadEvents,
    useIsChatSidebarVisibleOnXs,
    useChatFlags,
    useCurrentStudyUsersData,
    useCurrentUserId,
    useDashboard,
] = contextStore(
    useChatClientState,
    (state) => state,
    (state) => state.methods,
    (state) => state.dynamicMethods,
    (state) => state.userData,
    (state) => state.currentStudyId,
    (state) => state.currentStudyChatStatus,
    (state) => state.state.studiesChat[state.currentStudyId]?.roomsOrder,
    (state) => state.state.studiesChat[state.currentStudyId]?.rooms,
    (state) => state.messagesDraft,
    (state) => state.roomsSearchKeyword,
    (state) => state.isRoomsSearchRemote,
    (state) => state.remoteSearchRooms,
    (state) => state.isCreatingARoom,
    (state) => state.isSearchingRemoteRooms,
    (state) => state.voip,
    (state) => state.totalNumberOfUnreadEvents,
    (state) => state.isChatSidebarVisibleOnXs,
    (state) => state.chatFlags,
    (state) => state.studiesUsersData?.[state.currentStudyId] || {},
    (state) => state.currentUserId,
    (state) => state.dashboard
);

export {
    ChatClientContextProvider,
    useChat,
    useMethods,
    useDynamicMethods,
    useChatUserData,
    useCurrentStudyId,
    useCurrentStudyChatStatus,
    useCurrentStudyRoomsOrder,
    useCurrentStudyRooms,
    useMessagesDraft,
    useRoomsSearchKeyword,
    useIsRoomsSearchRemote,
    useRemoteSearchRooms,
    useIsCreatingARoom,
    useIsSearchingRemoteRooms,
    useVoip,
    useTotalNumberOfUnreadEvents,
    useIsChatSidebarVisibleOnXs,
    useChatFlags,
    useCurrentStudyUsersData,
    useCurrentUserId,
    useDashboard,
};
