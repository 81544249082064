import i18next from 'i18next';

import languages from './languages';
import * as store from './store';

function t(key, variables) {
    return i18next.t(key, variables);
}

function keyExists(key) {
    return i18next.exists(key);
}

export { languages, store, t, keyExists };
