import { createContext, useContext } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { createStore } from 'zustand';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { store as i18nStore } from '@edf-pkg/app-i18n';
import { store as appMainStore, utils as appMainUtils } from '@edf-pkg/app-main';
import { getStore } from '@edf-pkg/app-store';

import { INITIALIZATION_STATUS } from '../constants';

const useZustandStore = () => {
    const reduxDispatch = useDispatch();
    const reduxStore = getStore();

    return createStore((set) => ({
        isLoading: true,
        isError: false,
        initializeApp: () => {
            // Initialize Yup
            appMainUtils.yup.initializeYup();

            // Initialize i18n
            const userLanguage = appMainUtils.user.getUserLanguage();
            reduxDispatch(i18nStore.i18nDuck.duckActionCreators.initialize(userLanguage));

            // Initialize user
            reduxDispatch(appMainStore.userDuck.duckActionCreators.initialize());

            // TODO: Remove this below snippet when `i18n` and `user` sagas are no longer in use.
            // This subscription emulates the behavior of the old `app` redux store that depended on the state of the `i18n` and `user` redux stores.
            // And the reason is the gradual refactoring process. We have refactored the `app` saga into this zustand file while maintaining the `i18n` and `user` sagas. This code ensures compatibility between the new Zustand store and the existing Redux setup.
            // It listens for updates in the Redux store and sets the Zustand store accordingly once the `i18n` and `user `initialization status reaches the 'SUCCEEDED' state.
            const unsubscribe = reduxStore.subscribe(() => {
                const reduxState = reduxStore.getState();

                const i18nInitializationStatus = reduxState.main.i18n.initializationStatus;
                const userInitializationStatus = reduxState.main.user.initializationStatus;
                if (
                    i18nInitializationStatus === INITIALIZATION_STATUS.SUCCEEDED &&
                    userInitializationStatus === INITIALIZATION_STATUS.SUCCEEDED
                ) {
                    set({ isLoading: false, isError: false });
                } else if (
                    i18nInitializationStatus === INITIALIZATION_STATUS.FAILED ||
                    userInitializationStatus === INITIALIZATION_STATUS.FAILED
                ) {
                    set({ isLoading: false, isError: true });
                }
            });

            return unsubscribe;
        },
    }));
};

const StoreContext = createContext(null);

export const StoreProvider = ({ children }) => {
    const store = useZustandStore();
    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

StoreProvider.propTypes = {
    children: PropTypes.element.isRequired,
};

export function useStore(selector, equalityFn) {
    const store = useContext(StoreContext);
    if (store === undefined) {
        throw new Error('useStore must be used within a StoreProvider');
    }

    return useStoreWithEqualityFn(store, selector, equalityFn);
}
