import React, { useRef } from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';

import Chip from '$app-web/ui-kit/components/chip';
import useChipsTruncate from '$app-web/ui-kit/hooks/use-chips-truncate';

import classes from './styles.module.scss';

const ChipsGroup = (props) => {
    const { absolutePositioned, endOffset, items, truncate, onClick, onDelete } = props;

    const ref = useRef(null);
    const chipsWrapperRef = useRef(null);

    const { isChipsReady, chipsToShow, hiddenChipsCount } = useChipsTruncate({
        chipsWrapperRef,
        mainContainerRef: ref,
        total: items.length,
        enabled: truncate,
        endOffset,
    });

    return (
        <div
            aria-hidden="true"
            className={clsx(classes.root, {
                [classes.absolutePositioned]: absolutePositioned,
                [classes.hidden]: truncate && !isChipsReady,
                [classes.truncate]: truncate,
            })}
            ref={ref}
            onClick={onClick}
            data-is-truncated={hiddenChipsCount > 0}
        >
            <div className={classes.chipsWrapper} ref={chipsWrapperRef}>
                {items.map((item) => (
                    <div
                        className={clsx(classes.chipWrapper, {
                            [classes.displayNone]: truncate && isChipsReady && !chipsToShow.includes(`${item.value}`),
                        })}
                        data-value={item.value}
                        key={item.value}
                    >
                        <Chip disabled={item.disabled} label={item.label} {...(onDelete && { onDelete: () => onDelete(item) })} />
                    </div>
                ))}
                {truncate && hiddenChipsCount > 0 && <div className={classes.extraChipsCounter}>+{hiddenChipsCount}</div>}
            </div>
        </div>
    );
};

ChipsGroup.defaultProps = {
    onClick: undefined,
    endOffset: undefined,
    truncate: false,
    absolutePositioned: false,
    onDelete: undefined,
};

ChipsGroup.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            disabled: PropTypes.bool,
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            onDelete: PropTypes.func,
        })
    ).isRequired,
    absolutePositioned: PropTypes.bool,
    endOffset: PropTypes.number,
    truncate: PropTypes.bool,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
};

export default ChipsGroup;
