import Language from '../core/language';

const pdashNamespace = import('../namespaces/nl-nl.pdash.namespace');
const webActivitiesNamespace = import('../namespaces/nl-nl.web-activities.namespace');
const rdashTranslatablesNamespace = import('../namespaces/nl-nl.rdash-translatables.namespace');

class NlNlLanguage extends Language {
    code = 'nl-NL';

    locale = 'nl';

    localName = 'Nederlands';

    englishName = 'Dutch';

    territory = 'NL';

    timezones = ['Europe/Amsterdam'];

    notLoadedNamespaces = {
        pdash: pdashNamespace,
        webActivities: webActivitiesNamespace,
        rdashTranslatables: rdashTranslatablesNamespace,
    };
}

const instance = new NlNlLanguage();
export default instance;
