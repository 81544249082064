import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    secondaryHorizontalWrapper: {
        maxWidth: '33%',
    },
    secondaryVerticalWrapper: {
        maxHeight: '33%',
    },
    secondaryInnerWrapper: {
        padding: theme.spacing(0.5, 1),
        borderRadius: '5px',
        background: '#F0F4F6',
    },
    text: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
    },
    smallerText: {
        fontSize: theme.typography.pxToRem(12),
    },
    horizontalAnchorWrapper: {
        minWidth: '33%',
        maxWidth: '33%',
    },
    verticalAnchorWrapper: {
        minHeight: '33%',
        maxHeight: '33%',
    },
}));
