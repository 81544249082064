import { fade } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

const inputTheme = (theme) =>
    createMuiTheme(theme, {
        props: {
            MuiFilledInput: {
                disableUnderline: true,
                rows: 3,
                rowsMax: 9,
            },
        },
        overrides: {
            MuiInputBase: {
                input: {
                    '&[type=number]': {
                        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                        },
                        fontSize: theme.typography.pxToRem(61),
                        '-moz-appearance': 'textfield',
                    },
                    padding: theme.spacing(2),
                },
                multiline: {
                    padding: theme.spacing(2),
                },
            },
            MuiFilledInput: {
                root: {
                    'border-top-left-radius': undefined,
                    'border-top-right-radius': undefined,
                    border: `solid 1px ${fade(theme.palette.default.main, 0.2)}`,
                    backgroundColor: fade(theme.palette.default.main, 0.1),
                    '&:hover': {
                        border: `solid 1px ${fade(theme.palette.default.main, 0.5)}`,
                        outline: `solid 0.5px ${fade(theme.palette.default.main, 0.5)}`,
                        backgroundColor: fade(theme.palette.default.main, 0.1),
                    },
                    '&$disabled': {
                        opacity: 0.5,
                        border: `solid 1px ${fade(theme.palette.default.main, 0.2)}`,
                        backgroundColor: fade(theme.palette.default.main, 0.1),
                        outline: 'none',
                    },
                },
                input: {
                    padding: theme.spacing(2),
                },
                multiline: {
                    padding: theme.spacing(2),
                },
            },
        },
    });

export default inputTheme;
