import { useCallback, useEffect, useMemo } from 'react';

import { useTotalNumberOfUnreadEvents } from '$app-web/components/chat/state/chat-client.state';

const usePage = (title) => {
    const totalNumberOfUnreadEvents = useTotalNumberOfUnreadEvents();

    const hasUnreadEvents = useMemo(
        () => Object.values(totalNumberOfUnreadEvents).reduce((sum, numberOfUnreadEvents) => sum + numberOfUnreadEvents, 0) > 0,
        [totalNumberOfUnreadEvents]
    );

    const setPageTitle = useCallback(
        (pageTitle, includeBase = true) => {
            const titlePrefix = hasUnreadEvents ? '* ' : '';

            if (includeBase) {
                document.title =
                    titlePrefix +
                    `${process.env.REACT_APP_PAGE_TITLE_BASE} ${pageTitle && ` | ${pageTitle}`}`.replace(/<br\/>/g, ' ');
            } else {
                document.title = titlePrefix + `${pageTitle && `${pageTitle}`}`.replace(/<br\/>/g, ' ');
            }
        },
        [hasUnreadEvents]
    );

    useEffect(() => {
        if (title) {
            setPageTitle(title);
        }
        return () => {
            setPageTitle('');
        };
    }, [setPageTitle, title]);

    return [setPageTitle];
};

export default usePage;
