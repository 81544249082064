import { ducksManager, sagasManager } from '@edf-pkg/app-store';

import webActivitiesReducer, * as webActivitiesDuck from './web-activities.duck';
import webActivitiesSaga, { SAGA_NAME as webActivitiesSagaName } from './web-activities.saga';

export function inject() {
    ducksManager.add(webActivitiesDuck.DUCK_NAME, webActivitiesReducer);
    sagasManager.add(webActivitiesSagaName, webActivitiesSaga);
}

export { webActivitiesDuck };
