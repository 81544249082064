export const DIALOG_IDS = Object.freeze({
    ACCOUNT_DELETION_INITIATED: 'ACCOUNT_DELETION_INITIATED',
    ACCOUNT_DELETION_REVOKED: 'ACCOUNT_DELETION_REVOKED',
    SETTINGS_VERIFY_EMAIL: 'settings_verify_email',
    SETTINGS_CHANGE_EMAIL: 'settings_change_email',
    SETTINGS_VERIFY_PHONE_NUMBER: 'settings_verify_phone_number',
    SETTINGS_ADD_CHANGE_PHONE_NUMBER: 'settings_add_change_phone_number',
    VERIFY_PASSWORD: 'verify_password',
    CHAT_RECORD: 'chat_record',
    CHAT_RECORD_STOP: 'chat_record_stop',
    CHAT_RECORD_CALL_END: 'chat_record_call_end',
});
