import { ducksManager, sagasManager } from '@edf-pkg/app-store';

import activityReducer, * as activityDuck from './activities.duck';
import activitiesSaga, { SAGA_NAME as ACTIVITY_SAGA_NAME } from './activities.saga';

export function inject() {
    ducksManager.add(activityDuck.DUCK_NAME, activityReducer);
    sagasManager.add(ACTIVITY_SAGA_NAME, activitiesSaga);
}

export { activityDuck };
