import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const BackHandlerContext = createContext(undefined);

export const BackHandlerProvider = (props) => {
    const { children, backHandlerEventTarget } = props;
    return <BackHandlerContext.Provider value={backHandlerEventTarget}>{children}</BackHandlerContext.Provider>;
};

BackHandlerProvider.propTypes = {
    children: PropTypes.node.isRequired,
    backHandlerEventTarget: PropTypes.instanceOf(EventTarget).isRequired,
};

export function useBackHandler() {
    try {
        return useContext(BackHandlerContext);
    } catch {
        return undefined;
    }
}
