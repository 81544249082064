import { memo, ReactElement, useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import actArtworkImage from '@assets/images/survey/act-artwork.png';
import actGovernmentLogoImage from '@assets/images/survey/act-government-logo.png';
import { Container, Grid, IconButton, LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { store as mainStore, useDialog } from '@edf-pkg/app-main';

import Button from '$app-web/activities/survey/components/button';
import {
    Audio,
    AudioText,
    Barcode,
    Calendar,
    FreeFromText,
    Image,
    Length,
    Mass,
    MultipleChoice,
    Number as NumberQuestion,
    SingleChoice,
    Vas,
    Video,
} from '$app-web/activities/survey/components/question';
import { CHOICE_TYPES, DIALOG_IDS, QUESTION_TYPES, TRIGGERING_LOGIC_TYPE_IDS } from '$app-web/activities/survey/constants';
import Loading from '$app-web/components/loading';
import ScrollableView from '$app-web/components/scrollable-view';
import SurveyQuestionContent from '$app-web/components/survey-question-content';
import { SESSION_STATUSES } from '$app-web/constants';
import usePage from '$app-web/hooks/use-page';
import useStopPlayingAudios from '$app-web/hooks/use-stop-playing-audios';
import { useTranslator } from '$app-web/hooks/use-translator';
import { Length as LengthType, Mass as MassType } from '$app-web/types';
import { getIsACTMaternityStudy } from '$app-web/utils';

import { useStore } from '../../store';
import { StoreQuestion } from '../../store/types';
import { getDefaultValue, isQuestionUnanswered } from '../../utils';
import Dialogs from './dialogs';
import useStyles from './styles';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

interface Props {
    question: StoreQuestion;
    content: string;
    isFirstQuestion: boolean;
    isLastQuestion: boolean;
    progressPercent: number;
    translationId: string;
}

function PageSurvey({ question, content, isFirstQuestion, isLastQuestion, progressPercent, translationId }: Props): ReactElement {
    const t = useTranslator();
    const classes = useStyles();

    usePage(t('webActivities:survey_title'));

    const { studyId } = useParams<{
        studyId: string;
    }>();

    const isACTMaternityStudy = useMemo(() => getIsACTMaternityStudy(studyId), [studyId]);

    const isLoading = useStore((state) => state.isLoading);
    const isError = useStore((state) => state.isError);
    const isSurveyProgressVisible = useStore((state) => state.isSurveyProgressVisible);
    const isSandboxMode = useStore((state) => state.isSandboxMode);
    const session = useStore((state) => state.session);
    const activityType = useStore((state) => state.type);
    const goNextQuestion = useStore((state) => state.goNextQuestion);
    const goToPreviousQuestion = useStore((state) => state.goToPreviousQuestion);
    const skipQuestion = useStore((state) => state.skipQuestion);
    const cancelSession = useStore((state) => state.cancelSession);
    const updateCurrentQuestion = useStore((state) => state.updateCurrentQuestion);
    const addMediaInteraction = useStore((state) => state.addMediaInteraction);
    const deleteSession = useStore((state) => state.deleteSession);
    const isPreviousButtonEnabled = useStore((state) => state.isPreviousButtonEnabled);

    const defaultValue = useMemo(() => getDefaultValue(question.questionType), [question.questionType]);

    const handleGoNextQuestion = useCallback(() => {
        goNextQuestion({ isLastQuestion });
    }, [goNextQuestion, isLastQuestion]);

    const handleGoToPreviousQuestion = useCallback(() => {
        goToPreviousQuestion();
    }, [updateCurrentQuestion, defaultValue, goToPreviousQuestion]);

    const handleSkipQuestion = useCallback(() => {
        updateCurrentQuestion(defaultValue);
        if (isLastQuestion) {
            handleGoNextQuestion();
        } else {
            skipQuestion();
        }
    }, [defaultValue, handleGoNextQuestion, isLastQuestion, skipQuestion, updateCurrentQuestion]);

    const { answer } = question;

    const isSessionExpired = session.statusId === SESSION_STATUSES.EXPIRED;

    const isAnswerImageType = useMemo(() => {
        return (question.answers || []).some(({ answerType }) => answerType === CHOICE_TYPES.URL);
    }, [question.answers]);

    const questionOptions = useMemo(() => {
        return (question.answers || []).map(
            ({ answerType, answerId, answerUrl, answerContent, answerContentTranslation = {} }) => {
                const text = answerContentTranslation[translationId] ?? answerContent;

                return {
                    label: (answerType === CHOICE_TYPES.TEXT ? text : answerUrl) as string,
                    value: answerId.toString(),
                };
            }
        );
    }, [question.answers, translationId]);

    const questionInputRef = useRef(null);
    const closeDialog = useDialog(DIALOG_IDS.CLOSE);
    const deleteDialog = useDialog(DIALOG_IDS.DELETE);
    const triggeringLogicTypeId = session.tlTypeId;

    const isAnonymousUser = useSelector(mainStore.userDuck.duckSelectors.isAnonymousUserSelector);

    useStopPlayingAudios();

    const handleMediaInteraction = useCallback(
        (interaction: { playPeriod: [number, number]; mediaType: 'audio' | 'video'; mediaUrl: string }) => {
            const { playPeriod, mediaUrl, mediaType } = interaction;
            addMediaInteraction({ questionId: question.questionId, playPeriod, mediaUrl, mediaType });
        },
        [question.questionId, addMediaInteraction]
    );

    const showCloseButton = !isAnonymousUser || (isAnonymousUser && triggeringLogicTypeId === TRIGGERING_LOGIC_TYPE_IDS.PUBLIC);

    const getQuestionInputField = () => {
        switch (question.questionType) {
            case QUESTION_TYPES.SINGLE_CHOICE:
                return (
                    <SingleChoice
                        layout={question.layout as string}
                        options={questionOptions}
                        onChange={updateCurrentQuestion}
                        value={answer as string | null}
                        type={isAnswerImageType ? 'url' : 'text'}
                    />
                );
            case QUESTION_TYPES.MULTIPLE_CHOICE:
                return (
                    <MultipleChoice
                        value={answer as Array<string>}
                        onChange={updateCurrentQuestion}
                        maxChoices={question.maxChoices}
                        layout={question.layout as string}
                        options={questionOptions}
                        exclusiveAnswerId={question.exclusiveAnswerId}
                        type={isAnswerImageType ? 'url' : 'text'}
                    />
                );
            case QUESTION_TYPES.FREE_FORM_TEXT:
                return (
                    <FreeFromText
                        inputRef={questionInputRef}
                        hint={question.hint}
                        autocompleteOptions={question.autocompleteOptions}
                        multiLine={question.multiLine}
                        value={answer as string}
                        onChange={updateCurrentQuestion}
                        forcePickAutocompleteOption={question?.forcePickAutocompleteOption}
                    />
                );
            case QUESTION_TYPES.VAS:
                return (
                    <Vas
                        showSelectionValue={question.showSelectionValue}
                        showSelectionLabel={question.showSelectionLabel}
                        isVertical={question.isVertical}
                        min={question.min}
                        max={question.max}
                        value={answer as number}
                        step={question.step}
                        labels={question.labels}
                        onChange={updateCurrentQuestion}
                        anchors={question.anchors ?? []}
                    />
                );
            case QUESTION_TYPES.IMAGE:
                return <Image value={answer as File | string} onChange={updateCurrentQuestion} />;
            case QUESTION_TYPES.AUDIO:
                return <Audio value={answer as File | string} onChange={updateCurrentQuestion} />;
            case QUESTION_TYPES.VIDEO:
                return <Video value={answer as File | string} onChange={updateCurrentQuestion} />;
            case QUESTION_TYPES.AUDIO_TEXT:
                return (
                    <AudioText
                        onChange={updateCurrentQuestion}
                        value={answer as { audioUrl: File | string | null; text: string }}
                        hint={question.hint}
                        multiLine={question.multiLine}
                    />
                );
            case QUESTION_TYPES.NUMBER:
                return (
                    <NumberQuestion
                        defaultValue={question.default}
                        min={question.min}
                        max={question.max}
                        value={answer as number}
                        unit={question.displayUnit}
                        step={question.step}
                        onChange={updateCurrentQuestion}
                    />
                );
            case QUESTION_TYPES.MASS:
                return (
                    <Mass
                        min={question.min}
                        max={question.max}
                        value={answer as number}
                        defaultValue={question.default}
                        step={question.step}
                        onChange={updateCurrentQuestion}
                        defaultUnit={question.defaultUnit as MassType}
                        prefUnit={question.prefUnit as MassType | undefined}
                    />
                );
            case QUESTION_TYPES.LENGTH:
                return (
                    <Length
                        min={question.min}
                        max={question.max}
                        value={answer as number}
                        defaultValue={question.default}
                        step={question.step}
                        onChange={updateCurrentQuestion}
                        defaultUnit={question.defaultUnit as LengthType}
                        prefUnit={question.prefUnit as LengthType | undefined}
                    />
                );
            case QUESTION_TYPES.CALENDAR: {
                const variant = question.selector === 'date_time' ? 'date-time' : question.selector;

                return (
                    <Calendar
                        variant={variant as 'date' | 'time' | 'date-time'}
                        requirePeriod={question.requirePeriod}
                        minuteInterval={question.minuteInterval}
                        value={answer as [Date, Date]}
                        onChange={updateCurrentQuestion}
                    />
                );
            }
            case QUESTION_TYPES.BARCODE:
                return <Barcode value={answer as string} onChange={updateCurrentQuestion} />;
            default:
                return null;
        }
    };

    if (isSessionExpired) {
        return <Alert severity="error">{t('webActivities:survey_is_expired')}</Alert>;
    } else if (isLoading || isError) {
        return (
            <div data-testid="survey" className={classes.loadingWrapper}>
                <Loading
                    variant="parent-overlay"
                    loading={isLoading}
                    error={isError}
                    showErrorText
                    errorHasButton
                    errorButtonText={t('back')}
                />
            </div>
        );
    } else {
        return (
            <>
                <Grid container direction="column" className={classes.root}>
                    <Grid item container alignItems="center" className={classes.headerWrapper}>
                        <Grid item>
                            {!isSandboxMode && showCloseButton ? (
                                <IconButton data-testid="closeButton" color="primary" onClick={closeDialog.open} size="medium">
                                    <FontAwesomeIcon icon={['fal', 'times']} />
                                </IconButton>
                            ) : null}
                        </Grid>
                        <Grid item xs />
                        {!isSandboxMode && activityType === 'time-use' ? (
                            <Grid item>
                                <IconButton color="primary" onClick={deleteDialog.open} size="small">
                                    <FontAwesomeIcon icon={['fas', 'trash']} />
                                </IconButton>
                            </Grid>
                        ) : null}
                        <Grid item>
                            <Button
                                data-testid="skip"
                                variant="text"
                                color="primary"
                                disabled={question.isMandatory || (isLastQuestion && isSandboxMode)}
                                onClick={handleSkipQuestion}
                                fullWidth
                            >
                                {t('webActivities:skip')}
                            </Button>
                        </Grid>
                    </Grid>
                    {activityType !== 'time-use' && isSurveyProgressVisible ? (
                        <Grid item className={classes.progressWrapper}>
                            <LinearProgress
                                variant="determinate"
                                classes={
                                    isACTMaternityStudy
                                        ? {
                                              root: classes.actPurpleProgressRoot,
                                              bar: classes.actPurpleProgressBar,
                                          }
                                        : undefined
                                }
                                color="secondary"
                                value={isSurveyProgressVisible ? progressPercent : 0}
                            />
                        </Grid>
                    ) : null}
                    <Grid item xs className={classes.contentWrapper}>
                        <Grid container direction="column" className={classes.contentInnerWrapper}>
                            <Grid item xs className={classes.questionWrapper}>
                                <Container maxWidth="xs" className={classes.containerWrapper}>
                                    <Loading
                                        variant="parent-overlay"
                                        loading={isLoading}
                                        error={isError}
                                        showErrorText
                                        errorHasButton
                                        errorButtonText={t('back')}
                                        onErrorButtonClick={cancelSession}
                                    />
                                    <Grid
                                        key={`${question.questionId}-${question.iteration}`}
                                        component={ScrollableView}
                                        container
                                        direction="column"
                                        className={classes.questionInnerWrapper}
                                        wrap="nowrap"
                                    >
                                        <Grid item>
                                            <SurveyQuestionContent onMediaInteraction={handleMediaInteraction}>
                                                {content}
                                            </SurveyQuestionContent>
                                        </Grid>
                                        <Grid item xs>
                                            {getQuestionInputField()}
                                        </Grid>
                                        {isACTMaternityStudy && (
                                            <Grid item>
                                                <Grid container alignItems="flex-end" justify="space-between" wrap="nowrap">
                                                    <Grid>
                                                        <img
                                                            className={classes.actGovernmentLogoImage}
                                                            src={actGovernmentLogoImage}
                                                            alt="ACTHealth"
                                                        />
                                                    </Grid>
                                                    <Grid>
                                                        <img
                                                            className={classes.actArtworkImage}
                                                            src={actArtworkImage}
                                                            alt="ACTHealth"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Container>
                            </Grid>
                            <Grid item className={classes.footerWrapper}>
                                <Container maxWidth="xs" className={classes.containerWrapper} disableGutters>
                                    <Grid container wrap="nowrap">
                                        <Grid item xs className={classes.footerFirstCell}>
                                            {isPreviousButtonEnabled && (
                                                <Button
                                                    data-testid="previous"
                                                    variant="contained"
                                                    startIcon={<FontAwesomeIcon icon={['fal', 'arrow-circle-up']} />}
                                                    disabled={isFirstQuestion}
                                                    onClick={handleGoToPreviousQuestion}
                                                    fullWidth
                                                >
                                                    {t('webActivities:previous')}
                                                </Button>
                                            )}
                                        </Grid>
                                        <Grid item xs>
                                            <Button
                                                data-testid="next"
                                                variant="contained"
                                                color="green"
                                                startIcon={
                                                    <FontAwesomeIcon
                                                        icon={['fal', isLastQuestion ? 'check-circle' : 'arrow-circle-down']}
                                                    />
                                                }
                                                onClick={handleGoNextQuestion}
                                                disabled={
                                                    (question.format != null &&
                                                        (answer as string).match(question.format) == null) ||
                                                    (isSandboxMode && isLastQuestion) ||
                                                    isQuestionUnanswered(question) ||
                                                    (question.questionType === QUESTION_TYPES.NUMBER &&
                                                        typeof answer == 'number' &&
                                                        ((question.min != null && answer < question.min) ||
                                                            (question.max != null && answer > question.max)))
                                                }
                                                fullWidth
                                            >
                                                {isLastQuestion ? t('webActivities:submit') : t('webActivities:next')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialogs
                    studyId={Number(studyId)}
                    triggeringLogicTypeId={session.tlTypeId}
                    onCancelSession={cancelSession}
                    onDelete={deleteSession}
                />
            </>
        );
    }
}

export default memo(PageSurvey);
