import produce from 'immer';
import { createSelector } from 'reselect';
import { getSelector, namespaceReducerActions } from 'retrack';

import { INITIALIZATION_STATUS } from '../constants';

export const DUCK_NAME = 'i18n';

const initialState = {
    initializationStatus: INITIALIZATION_STATUS.UNKNOWN,
    language: '',
    namespace: '',
};

export const duckActions = {
    INITIALIZE: 'INITIALIZE',
    SET_INITIALIZATION_STATUS: 'SET_INITIALIZATION_STATUS',
    ASK_SPACE_FOR_LANGUAGE_NAMESPACE: 'ASK_SPACE_FOR_LANGUAGE_NAMESPACE',
    ASK_SPACE_FOR_LANGUAGE_NAMESPACE_SUCCEEDED: 'ASK_SPACE_FOR_LANGUAGE_NAMESPACE_SUCCEEDED',
    SET_LANGUAGE: 'SET_LANGUAGE',
    SET_NAMESPACE: 'SET_NAMESPACE',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
    CHANGE_NAMESPACE: 'CHANGE_NAMESPACE',
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case duckActions.SET_INITIALIZATION_STATUS:
            draft.initializationStatus = action.payload.status;
            break;
        case duckActions.SET_LANGUAGE:
            draft.language = action.payload.language;
            break;
        case duckActions.SET_NAMESPACE:
            draft.namespace = action.payload.namespace;
            break;
        // no default
    }
}, initialState);
namespaceReducerActions(reducer, duckActions);

export default reducer;

export const duckActionCreators = {
    initialize: function initialize(userLanguage) {
        return { type: duckActions.INITIALIZE, payload: { userLanguage } };
    },
    initializationSucceeded: function initializationSucceeded() {
        return {
            type: duckActions.SET_INITIALIZATION_STATUS,
            payload: { status: INITIALIZATION_STATUS.SUCCEEDED },
        };
    },
    initializationFailed: function initializationFailed() {
        return {
            type: duckActions.SET_INITIALIZATION_STATUS,
            payload: { status: INITIALIZATION_STATUS.FAILED },
        };
    },
    askSpaceForLanguageNamespace: function askSpaceForLanguageNamespace() {
        return {
            type: duckActions.ASK_SPACE_FOR_LANGUAGE_NAMESPACE,
        };
    },
    askSpaceForLanguageNamespaceSucceeded: function askSpaceForLanguageNamespace(namespace) {
        return {
            type: duckActions.ASK_SPACE_FOR_LANGUAGE_NAMESPACE_SUCCEEDED,
            payload: {
                namespace,
            },
        };
    },
    setLanguage: function setLanguage(language) {
        return {
            type: duckActions.SET_LANGUAGE,
            payload: {
                language,
            },
        };
    },
    setNamespace: function setNamespace(namespace) {
        return {
            type: duckActions.SET_NAMESPACE,
            payload: {
                namespace,
            },
        };
    },
    changeLanguage: function changeLanguage(languageCode) {
        return {
            type: duckActions.CHANGE_LANGUAGE,
            payload: {
                languageCode,
            },
        };
    },
    changeNamespace: function changeNamespace(namespace) {
        return {
            type: duckActions.CHANGE_NAMESPACE,
            payload: {
                namespace,
            },
        };
    },
};

const i18nSelector = getSelector(reducer);

const languageSelector = createSelector(i18nSelector, (i18nState) => i18nState.language);

const namespaceSelector = createSelector(i18nSelector, (i18nState) => i18nState.namespace);

export const duckSelectors = {
    i18nSelector,
    languageSelector,
    namespaceSelector,
};
