export default {
    name: 'zu-zu',
    weekdays: 'ISonto_UMsombuluko_ULwesibili_ULwesithathu_ULwesine_ULwesihlanu_UMgqibelo'.split('_'),
    weekdaysShort: 'Xon_Mso_Bil_Tha_Sin_Hla_Mgq'.split('_'),
    weekdaysMin: 'S_M_B_T_S_H_M'.split('_'),
    months: 'Januwari_Februwari_Mashi_Ephreli_Meyi_Juni_Julayi_Agasti_Septhemba_Okthoba_Novemba_Disemba'.split('_'),
    monthsShort: 'Jan_Feb_Mas_Eph_Mey_Jun_Jul_Aga_Sep_Okta_Nov_Dis'.split('_'),
    ordinal: (n) => `${n}º`,
    formats: {
        LTS: 'h:mm:ss A',
        LT: 'h:mm A',
        L: 'MM/DD/YYYY',
        LL: 'MMMM D, YYYY',
        LLL: 'MMMM D, YYYY h:mm A',
        LLLL: 'dddd, MMMM D, YYYY h:mm A',
        l: 'D/M/YYYY',
        ll: 'D MMM, YYYY',
        lll: 'D MMM, YYYY h:mm A',
        llll: 'ddd, MMM D, YYYY h:mm A',
    },
    relativeTime: {
        future: 'olandelayo %s',
        past: '%s edlule',
        s: 'imizuzwana embalwa',
        m: 'umzuzu',
        mm: '%d imizuzu',
        h: 'ihora',
        hh: '%d amahora',
        d: 'usuku',
        dd: '%d izinsuku',
        M: 'ngenyanga',
        MM: '%d izinyanga',
        y: 'unyaka',
        yy: '%d iminyaka',
    },
};
