import getActivitySession from './get-activity-session.endpoint.api';
import getActivitySessionStatus from './get-activity-session-status.endpoint.api';

const client = {
    api: {
        getActivitySession,
        getActivitySessionStatus,
    },
};

export default client;
