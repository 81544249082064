import { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import ReactPlayer from 'react-player';
import { Typography } from '@material-ui/core';

import appDateTime from '@edf-pkg/app-date-time';

import LazyImage from '$app-web/components/lazy-image';
import MediaViewer from '$app-web/components/media-viewer';
import { getImageOriginalAndThumbUrl } from '$app-web/utils/image';

import useStyles from './styles';
import parseHTML from './utils';

const YOUTUBE_VIDEO_STATE = {
    ENDED: 0,
    PLAYING: 1,
    PAUSED: 2,
    BUFFERING: 3,
    CUED: 5,
};

interface Props {
    children: string;
    onMediaInteraction: (props: { mediaUrl: string; mediaType: 'audio' | 'video'; playPeriod: [number, number] }) => void;
    className?: string;
}

const SurveyQuestionContent = ({ className, children, onMediaInteraction }: Props) => {
    const classes = useStyles();

    const [imagesMediaViewerOpened, setImagesMediaViewerOpened] = useState<Record<string, boolean>>({});
    const playbackPeriods = useRef<Record<string, { startMoment: { timeStamp: number; time: number } } | null>>({});

    const setMediaStartMoment = useCallback(({ src, startTime }) => {
        playbackPeriods.current[src] = { startMoment: { timeStamp: appDateTime().toMillis(), time: startTime } };
    }, []);

    const setMediaEndMoment = useCallback(
        ({ src, mediaType }) => {
            const currentPeriods = playbackPeriods.current[src];
            if (currentPeriods) {
                const start = currentPeriods.startMoment;
                const startAndPauseTimeDifference = (appDateTime().toMillis() - start.timeStamp) / 1000;
                const endTime = start.time + startAndPauseTimeDifference;
                const playPeriod: [number, number] = [Math.floor(start.time), Math.ceil(endTime)];

                playbackPeriods.current[src] = null;

                onMediaInteraction({ mediaUrl: src, mediaType, playPeriod });
            }
        },
        [onMediaInteraction]
    );

    const handleAudioPlay = useCallback(
        (event) => {
            const src = event.currentTarget.currentSrc;
            const startTime = event.currentTarget.currentTime;
            setMediaStartMoment({ src, startTime });
        },
        [setMediaStartMoment]
    );

    const handleAudioPause = useCallback(
        (event) => {
            const src = event.currentTarget.currentSrc;
            setMediaEndMoment({ src, mediaType: 'audio' });
        },
        [setMediaEndMoment]
    );

    const handleYoutubeVideoStateChange = useCallback(
        (event, src) => {
            const { data, target } = event;
            const currentTime = target.getCurrentTime();

            const isVideoPlaying = data === YOUTUBE_VIDEO_STATE.PLAYING;
            const isVideoPausedOrEnded = [YOUTUBE_VIDEO_STATE.PAUSED, YOUTUBE_VIDEO_STATE.ENDED].includes(data);

            if (isVideoPlaying) {
                setMediaStartMoment({ src, startTime: currentTime });
            } else if (isVideoPausedOrEnded) {
                setMediaEndMoment({ src, mediaType: 'video' });
            }
        },
        [setMediaStartMoment, setMediaEndMoment]
    );

    const CustomP = useCallback(
        (props: React.HTMLAttributes<HTMLParagraphElement>) => {
            return <div className={classes.paragraph} {...props} />;
        },
        [classes.paragraph]
    );

    const CustomBlockquote = useCallback(
        (props: React.HTMLAttributes<HTMLQuoteElement>) => {
            return <blockquote className={classes.blockquote} {...props} />;
        },
        [classes.blockquote]
    );

    const CustomLink = useCallback(
        (props: React.HTMLAttributes<HTMLAnchorElement>) => {
            return <a className={classes.link} {...props} />;
        },
        [classes.link]
    );

    const CustomImage = useCallback(
        ({ src, ...props }: React.ImgHTMLAttributes<HTMLImageElement>) => {
            if (typeof src !== 'string' && src !== '') {
                return null;
            }

            const imageUrls = getImageOriginalAndThumbUrl(src);

            return (
                <>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-expect-error */}
                    <MediaViewer
                        url={imageUrls.original}
                        open={imagesMediaViewerOpened[src] || false}
                        type="image"
                        alignCloseStart
                        onCloseClick={() => {
                            setImagesMediaViewerOpened({ ...imagesMediaViewerOpened, [src]: false });
                        }}
                    />
                    <div className={classes.imageWrapper}>
                        <LazyImage
                            className={classes.image}
                            src={imageUrls.thumb}
                            {...props}
                            onClick={() => setImagesMediaViewerOpened({ ...imagesMediaViewerOpened, [src]: true })}
                        />
                    </div>
                </>
            );
        },
        [classes.image, classes.imageWrapper, imagesMediaViewerOpened]
    );

    const CustomIframe = useCallback(
        ({ src }: React.IframeHTMLAttributes<HTMLIFrameElement>) => {
            return (
                <ReactPlayer
                    url={src}
                    style={{ width: '100%', maxWidth: '100%' }}
                    controls
                    config={{
                        youtube: {
                            playerVars: { modestbranding: true },
                            // https://developers.google.com/youtube/iframe_api_reference#Events
                            embedOptions: {
                                events: {
                                    onStateChange: (event: Record<string, unknown>) => handleYoutubeVideoStateChange(event, src),
                                },
                            },
                        },
                    }}
                />
            );
        },
        [handleYoutubeVideoStateChange]
    );

    const CustomAudio = useCallback(
        ({ src }: React.AudioHTMLAttributes<HTMLAudioElement>) => {
            return <audio controls src={src} onPlay={handleAudioPlay} onPause={handleAudioPause} />;
        },
        [handleAudioPause, handleAudioPlay]
    );

    const parsedElements = parseHTML(children, {
        p: CustomP,
        blockquote: CustomBlockquote,
        a: CustomLink,
        img: CustomImage,
        iframe: CustomIframe,
        audio: CustomAudio,
    });

    return (
        <Typography data-testid="questionContent" className={clsx(classes.root, className)} component="div" variant="body2">
            {parsedElements}
        </Typography>
    );
};

export default SurveyQuestionContent;
