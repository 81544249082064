import camelcaseKeys from 'camelcase-keys';
import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';

import appDateTime from '@edf-pkg/app-date-time';
import { errorHandle } from '@edf-pkg/app-error';
import { store as PHRStore } from '@edf-pkg/app-phr';

import client from '../client';
import { ACTIVITY_STATUS, SESSION_STATUSES, TL_TYPES } from '../constants';
import {
    duckActionCreators as activityActionCreators,
    duckActions as activityActions,
    duckSelectors as activitySelectors,
} from './activities.duck';

export const SAGA_NAME = 'ACTIVITIES';

function* sendClosePHR(action) {
    const { activityFinished, activityUUID, activityCanceled } = action.payload;
    const isSandbox = yield select((state) => activitySelectors.isSandboxSelector(state)(activityUUID));
    if (isSandbox) {
        yield put(activityActionCreators.setCloseData(activityUUID, false, activityFinished));
        return;
    }
    const { studyId, activityId, uuid, sTimeMs, pTimeMs, tlTypeId } = yield select((state) =>
        activitySelectors.sessionInfoSelector(state)(activityUUID)
    );

    const ensurePHRSent = yield select((state) => activitySelectors.ensurePHRSentSelector(state)(activityUUID));
    yield put(activityActionCreators.setClosingInProgress(activityUUID));
    if (activityFinished) {
        yield put(
            PHRStore.PHRDuck.duckActionCreators.sendActivityFinished(
                [studyId],
                activityId,
                uuid,
                sTimeMs,
                tlTypeId === TL_TYPES.USER ? sTimeMs : pTimeMs,
                appDateTime().toMillis(),
                activityCanceled ? SESSION_STATUSES.CANCELED : SESSION_STATUSES.SUBMITTED,
                tlTypeId
            )
        );
    } else {
        yield put(PHRStore.PHRDuck.duckActionCreators.sendActivityClosed([studyId], activityId, uuid));
    }
    if (ensurePHRSent) {
        yield race({
            success: take(PHRStore.PHRDuck.duckActions.SET_SEND_STATUS),
        });
    }
    yield put(activityActionCreators.setCloseData(activityUUID, false, activityFinished));
}

function* getActivitySessionStatus(activityType, sessionInfo) {
    if (
        sessionInfo.tlTypeId === TL_TYPES.MANUAL ||
        sessionInfo.tlTypeId === TL_TYPES.TIME ||
        sessionInfo.tlTypeId === TL_TYPES.TIME_USER_DEF
    ) {
        const { validated } = yield call(client.api.getActivitySessionStatus, sessionInfo.uuid, activityType);
        const sessionStatusData = camelcaseKeys(validated, { deep: true });
        if (appDateTime().isBefore(appDateTime(sessionStatusData.scheduledTime))) {
            return ACTIVITY_STATUS.UPCOMING;
        }
        if (appDateTime().isAfter(appDateTime(sessionStatusData.expiryTime))) {
            return ACTIVITY_STATUS.PAST;
        }
        if (
            !(
                sessionStatusData.status.id === SESSION_STATUSES.UNKNOWN ||
                sessionStatusData.status.id === SESSION_STATUSES.IN_PROGRESS
            )
        ) {
            return ACTIVITY_STATUS.FINISHED;
        }
    }
    return ACTIVITY_STATUS.ANSWERABLE;
}

function* loadData(action) {
    const { activityUUID, sessionUUID, activityType, activityOptions, activitySessionInfo, ensurePHRSent } = action.payload;
    try {
        yield put(activityActionCreators.add(activityUUID));
        yield put(activityActionCreators.setLoadDataStatusLoading(activityUUID));
        let sessionInfo = activitySessionInfo;
        let options = activityOptions;
        let status = ACTIVITY_STATUS.ANSWERABLE;

        sessionInfo = camelcaseKeys(sessionInfo);
        options = camelcaseKeys(options);

        if ((sessionUUID || sessionInfo.uuid) && !options.isSandbox) {
            status = yield call(getActivitySessionStatus, activityType.replace('-', '_'), sessionInfo);
        }

        yield put(
            activityActionCreators.setActivityData(
                activityUUID,
                activityType,
                status,
                sessionUUID,
                sessionInfo,
                options,
                ensurePHRSent
            )
        );
        const { studyId, activityId } = sessionInfo;
        if (!options.isSandbox) {
            yield put(PHRStore.PHRDuck.duckActionCreators.sendActivityOpened([studyId], activityId, sessionUUID));
        }
        yield put(activityActionCreators.setLoadDataStatusSucceeded(activityUUID));
    } catch (error) {
        errorHandle.anError(error);
        yield put(activityActionCreators.setLoadDataStatusFailed(activityUUID));
    }
}

export default function* activitiesSaga() {
    yield takeLatest(activityActions.LOAD_DATA, loadData);
    yield takeLatest(activityActions.CLOSE, sendClosePHR);
}
