import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import appUtils from '@edf-pkg/app-utils';

import { dialogDuck } from '../../store';

function useDialog(dialogId, shouldRegister = false) {
    const dispatch = useDispatch();

    const dialogs = useSelector(dialogDuck.duckSelectors.dialogsSelector);

    const register = useCallback(
        (id) => {
            dispatch(dialogDuck.duckActionCreators.register(id));
        },
        [dispatch]
    );

    const open = useCallback(() => {
        dispatch(dialogDuck.duckActionCreators.open(dialogId));
    }, [dialogId, dispatch]);

    const close = useCallback(() => {
        dispatch(dialogDuck.duckActionCreators.close(dialogId));
    }, [dialogId, dispatch]);

    const isOpen = useMemo(
        () => appUtils.object.hasKey(dialogs, dialogId) && dialogs[dialogId].opened === true,
        [dialogId, dialogs]
    );

    useEffect(() => {
        if (shouldRegister && !appUtils.object.hasKey(dialogs, dialogId)) {
            register(dialogId);
        }
        return undefined;
    }, [dialogId, dialogs, register, shouldRegister]);

    return useMemo(
        () => ({
            isOpen,
            open,
            close,
        }),
        [close, isOpen, open]
    );
}

export default useDialog;
