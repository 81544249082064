import { useCallback, useEffect } from 'react';

const BOTTOM_SCROLL_OFFSET = 500;

const useLazyLoadingList = (props) => {
    const { enabled = true, fetchingNextPage, fetchNextPage, hasNextPage, loading, listElementRef } = props;

    const fetchNextPageIfNeeded = useCallback(() => {
        if (listElementRef.current == null) {
            return;
        }

        const { clientHeight, scrollHeight, scrollTop } = listElementRef.current;

        if (
            enabled &&
            !loading &&
            !fetchingNextPage &&
            hasNextPage &&
            scrollHeight - scrollTop - clientHeight < BOTTOM_SCROLL_OFFSET
        ) {
            fetchNextPage();
        }
    }, [enabled, fetchNextPage, fetchingNextPage, hasNextPage, listElementRef, loading]);

    const handleScroll = useCallback(() => {
        fetchNextPageIfNeeded();
    }, [fetchNextPageIfNeeded]);

    useEffect(() => {
        fetchNextPageIfNeeded();
    }, [fetchNextPageIfNeeded]);

    const getLazyLoadingListProps = (externalProps) =>
        enabled
            ? {
                  ...externalProps,
                  onScroll: (event) => {
                      handleScroll(event);
                      externalProps?.onScroll?.(event);
                  },
              }
            : { ...externalProps };

    return { getLazyLoadingListProps };
};

export default useLazyLoadingList;
