import { useMemo } from 'react';
import callBusyAudio from '@assets/audios/chat/call-busy.mp3';
import callDeclinedAudio from '@assets/audios/chat/call-declined.mp3';
import callIncomingAudio from '@assets/audios/chat/call-incoming.mp3';
import callOutgoingAudio from '@assets/audios/chat/call-outgoing.mp3';
import messageNewAudio from '@assets/audios/chat/message-new.mp3';

const useTones = () => {
    const tones = useMemo(() => {
        const incomingCallTone = new Audio(callIncomingAudio);
        incomingCallTone.loop = true;

        const outgoingCallTone = new Audio(callOutgoingAudio);
        outgoingCallTone.loop = true;

        const declinedCallTone = new Audio(callDeclinedAudio);
        const busyCallTone = new Audio(callBusyAudio);
        const newMessageTone = new Audio(messageNewAudio);

        return {
            incomingCallTone,
            outgoingCallTone,
            declinedCallTone,
            busyCallTone,
            newMessageTone,
        };
    }, []);

    return tones;
};

export default useTones;
