import Language from '../core/language';

const pdashNamespace = import('../namespaces/fr-ca.pdash.namespace');
const webActivitiesNamespace = import('../namespaces/fr-ca.web-activities.namespace');
const rdashTranslatablesNamespace = import('../namespaces/fr-ca.rdash-translatables.namespace');

class FrCaLanguage extends Language {
    code = 'fr-CA';

    locale = 'fr';

    localName = 'Français';

    englishName = 'French';

    territory = 'CA';

    timezones = ['America/Montreal', 'Europe/Paris'];

    notLoadedNamespaces = {
        pdash: pdashNamespace,
        webActivities: webActivitiesNamespace,
        rdashTranslatables: rdashTranslatablesNamespace,
    };
}

const instance = new FrCaLanguage();
export default instance;
