import asModule from '$app-web/hoces/as-module';
import { URLsManager } from '$app-web/utils';
import lazyLoad, { withTimeoutAndDelay } from '$app-web/utils/lazy-load';

// TODO: This should be on the routes/index.js preferably.
URLsManager.space('dashboard').addModule('rdash').setURLs({
    HOME: '/',
    MY_STUDIES: '/my-studies/',
    CREATE_STUDY: '/create-study/',
    STUDY_BASICS: '/study/:studyId/basics/',
    STATISTICS: '/study/:studyId/statistics/',
    ACTIVITIES: '/study/:studyId/activities/',
    DATA_SOURCES: '/study/:studyId/data-sources/',
    RESEARCHERS: '/study/:studyId/researchers/',
    ROLES: '/study/:studyId/roles/',
    PARTICIPATION: '/study/:studyId/participation/',
    PARTICIPATION_PENDING_INVITATION: '/study/:studyId/participation-pending-invitation/',
    DATA_EXPORT: '/study/:studyId/data-export/',
    BEACONS: '/study/:studyId/data-sources-beacons/',
    ACTIVITY_SESSIONS: '/study/:studyId/activity-sessions/',
    ACTIVITY_RESPONSES: '/study/:studyId/activity-responses/',
    ACTIVITY_SINGLE_SESSION: '/study/:studyId/activity-sessions/:sessionUUID',
    SURVEY_RESPONSES: '/study/:studyId/survey-responses/',
    SETTINGS_PERSONAL_INFORMATION: '/settings/personal-information',
    SETTINGS_SECURITY: '/settings/security',
    SETTINGS_NOTIFICATIONS: '/settings/notifications',
    PHR: '/study/:studyId/phr/',
    AUDIT_TRAIL: '/study/:studyId/audit-trail/',
    CHAT: '/study/:studyId/chat/',
    RECORDINGS: '/study/:studyId/recordings/',
    NOTIFICATIONS: '/study/:studyId/notifications/',
    NOTIFICATION_TEMPLATES: '/study/:studyId/notification-templates/',
    SUMMARY_TABLES: '/study/:studyId/summary-tables/',
    SENSOR_DATA_VISUALIZATIONS: '/study/:studyId/sensor-data-visualizations/',
});

const RdashModule = lazyLoad(() => withTimeoutAndDelay(() => import('./module')));

function shouldRender({ user }) {
    return user.isAuthenticated && user.role === 'researcher' && user.initializationStatusSucceeded;
}

export default asModule(shouldRender, 'dashboard.rdash')(RdashModule);
