import { memo, useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash-es';

import { useReadAutoCompleteSetOptions } from '$app-web/endpoints';
import { useTranslator } from '$app-web/hooks/use-translator';
import Select from '$app-web/ui-kit/components/select';
import { SuperString } from '$app-web/utils';

interface Props {
    value?: string;
    onChange: (label: string) => void;
    referenceSetKey: string;
    freeInput?: boolean;
}

interface Option {
    label: string;
    uuid: string;
}

const debouncedSetUserQuery = debounce((newValue: string, callback: (value: string) => void) => {
    callback(newValue);
}, 700);

const DynamicSearchSelect = ({ value, onChange, referenceSetKey, freeInput }: Props) => {
    const initializeSelectState = useCallback(() => {
        const optionsLookupMap = new Map<string, Option>();
        let selectedOption = '';

        if (value) {
            const id = SuperString.generateUniqueId();
            const valueAsAnOption = value.toLowerCase();
            optionsLookupMap.set(valueAsAnOption, { label: value, uuid: id });
            selectedOption = id;
        }

        return { optionsLookupMap, selectedOption };
    }, [value]);

    const { optionsLookupMap: initialOptionsLookupMap, selectedOption: initialSelectedOption } = useMemo(initializeSelectState, [
        initializeSelectState,
    ]);

    const [userQuery, setUserQuery] = useState('');
    const [inputCustomValue, setInputCustomValue] = useState(initialSelectedOption);
    const [selectedOption, setSelectedOption] = useState(initialSelectedOption);
    const [optionsLookupMap, setOptionsLookupMap] = useState(initialOptionsLookupMap);
    const t = useTranslator();

    const trimmedUserQuery = userQuery.trim();

    const { isLoading, isIdle } = useReadAutoCompleteSetOptions({
        setId: referenceSetKey,
        isEnabled: Boolean(referenceSetKey && trimmedUserQuery.length >= 3),
        query: trimmedUserQuery,
        onSuccess: (response) => {
            const newOptionsLookupMap = new Map(optionsLookupMap);

            response.items.forEach((item) => {
                const lowerCaseItem = item.value.toLowerCase();
                if (!newOptionsLookupMap.has(lowerCaseItem)) {
                    newOptionsLookupMap.set(lowerCaseItem, { label: item.value, uuid: SuperString.generateUniqueId() });
                }
            });

            setOptionsLookupMap(newOptionsLookupMap);
        },
    });

    const handleSearchInputDebounce = useCallback((newValue) => {
        debouncedSetUserQuery(newValue, setUserQuery);
    }, []);

    const onOptionSelect = useCallback(
        (selectedItem: string | undefined, options?: { isCustom: boolean }) => {
            if (options?.isCustom && selectedItem !== undefined) {
                setInputCustomValue(selectedItem);
            }

            setSelectedOption(selectedItem || '');

            const selectedEntity = Array.from(optionsLookupMap.values()).find((entity) => entity.uuid === selectedItem);
            if (selectedEntity) {
                onChange(selectedEntity.label);

                // getting an explicit `undefined` for selectedItem means the user has cleared the input.
            } else if (options?.isCustom || selectedItem === undefined) {
                onChange(selectedItem || '');
            }
        },
        [onChange, optionsLookupMap]
    );

    const options = useMemo(
        () => Array.from(optionsLookupMap.values()).map((item) => ({ label: item.label, value: item.uuid })),
        [optionsLookupMap]
    );

    return (
        <div>
            <p>{t('webActivities:remote_auto_complete_input_warning')}</p>
            <Select
                freeInput={freeInput}
                customValue={freeInput ? inputCustomValue : undefined}
                options={options}
                value={selectedOption}
                loading={isLoading}
                loadingText={t('webActivities:searching')}
                clearable
                noOptionsText={t('webActivities:remote_auto_complete_empty_response_error')}
                onChange={onOptionSelect}
                onInputChange={handleSearchInputDebounce}
                hideDropdown={isIdle}
            />
        </div>
    );
};

export default memo(DynamicSearchSelect);
