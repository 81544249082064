import appDateTime from '@edf-pkg/app-date-time';

import {
    CALENDAR_BASED_QUESTION_SELECTORS as CALENDAR_SELECTOR,
    QUESTION_TYPES,
    SURVEY_QUESTION_CONTENT_NON_QUESTION_BASED_PLACEHOLDERS,
} from '$app-web/constants';
import { QUESTION_CHOICE_BASED_ANSWER_TYPES } from '$app-web/modules/activity-editor/constants';
import type { LastResponse, ObjectType, Section, SessionResponse } from '$app-web/types';
import { SuperArray, SuperDate, SuperNumber, SuperObject } from '$app-web/utils';

import { LENGTH_UNITS, MASS_UNITS } from '../constants';
import { StoreQuestion } from '../store/types';
import { isAnswerValueEmpty } from './helpers';

function getStandardDateByString(dateString: string, type: string) {
    if (dateString == null || dateString === '') {
        return null;
    }

    if (type === CALENDAR_SELECTOR.TIME) {
        const [hour, minute, second] = dateString.split(':').map(Number);
        return new Date(0, 0, 0, hour, minute, second);
    }

    if (type === CALENDAR_SELECTOR.DATE) {
        const sanitizedDateString = `${dateString} 00:00:00`;
        return appDateTime(sanitizedDateString);
    }
    return appDateTime(dateString);
}

export function getDefaultValue(questionType?: string) {
    switch (questionType) {
        case QUESTION_TYPES.MULTIPLE_CHOICE:
            return [];
        case QUESTION_TYPES.FREE_FORM_TEXT:
        case QUESTION_TYPES.BARCODE:
        case QUESTION_TYPES.INFORMATION:
            return '';
        case QUESTION_TYPES.NUMBER:
        case QUESTION_TYPES.VAS:
        case QUESTION_TYPES.LENGTH:
        case QUESTION_TYPES.MASS:
            return null;
        case QUESTION_TYPES.CALENDAR:
            return [null, null];
        case QUESTION_TYPES.SINGLE_CHOICE:
        case QUESTION_TYPES.IMAGE:
        case QUESTION_TYPES.VIDEO:
        case QUESTION_TYPES.AUDIO:
            return null;
        case QUESTION_TYPES.AUDIO_TEXT:
            return { text: '', audioUrl: null };
        default:
            return null;
    }
}

export function isQuestionUnanswered(question: StoreQuestion) {
    const { questionType, answer, requirePeriod } = question;

    switch (questionType) {
        case QUESTION_TYPES.MULTIPLE_CHOICE:
            return SuperObject.isEqual(answer, []);
        case QUESTION_TYPES.FREE_FORM_TEXT:
        case QUESTION_TYPES.BARCODE:
            return (answer as string).trim() === '';
        case QUESTION_TYPES.NUMBER:
        case QUESTION_TYPES.VAS:
        case QUESTION_TYPES.LENGTH:
        case QUESTION_TYPES.MASS:
            return answer == null;
        case QUESTION_TYPES.CALENDAR: {
            const [start, end] = answer as [Date | null, Date | null];

            if (requirePeriod) {
                return start == null || end == null;
            }
            return start == null;
        }
        case QUESTION_TYPES.SINGLE_CHOICE:
        case QUESTION_TYPES.IMAGE:
        case QUESTION_TYPES.VIDEO:
        case QUESTION_TYPES.AUDIO:
            return answer == null;
        case QUESTION_TYPES.AUDIO_TEXT: {
            const { text, audioUrl } = answer as { text: string; audioUrl: null | string };

            return SuperObject.isEqual({ text: text.trim(), audioUrl }, { text: '', audioUrl: null });
        }
        case QUESTION_TYPES.INFORMATION:
            return false;
        default:
            return answer == null;
    }
}

export function getDefaultAnswer(params: { questionType: string; questionId: number; iteration?: number | null }) {
    const { questionType, questionId, iteration = null } = params;

    const defaultProps = { questionId, iteration, location: { accu: null, lat: null, lng: null, speed: null } };
    const getValue = (value: unknown) => ({ ...defaultProps, value });

    switch (questionType) {
        case QUESTION_TYPES.MULTIPLE_CHOICE:
            return getValue([]);
        case QUESTION_TYPES.FREE_FORM_TEXT:
        case QUESTION_TYPES.BARCODE:
        case QUESTION_TYPES.INFORMATION:
            return getValue('');
        case QUESTION_TYPES.NUMBER:
        case QUESTION_TYPES.VAS:
        case QUESTION_TYPES.LENGTH:
        case QUESTION_TYPES.MASS:
            return getValue(null);
        case QUESTION_TYPES.CALENDAR:
            return getValue([null, null]);
        case QUESTION_TYPES.SINGLE_CHOICE:
        case QUESTION_TYPES.IMAGE:
        case QUESTION_TYPES.VIDEO:
        case QUESTION_TYPES.AUDIO:
            return getValue(null);
        case QUESTION_TYPES.AUDIO_TEXT:
            return getValue({ text: '', audioUrl: null });
        default:
            return getValue(null);
    }
}

export function getAnswerOfQuestion(params: {
    questionId: number;
    questionType: string;
    resp: SessionResponse['resp'];
    iteration?: number | null;
}): {
    value: unknown;
    questionId: number;
    iteration: number | null;
    location: { lat: number | null; lng: number | null; accu: number | null; speed: number | null };
} {
    const { questionId, questionType, iteration = null, resp } = params;
    const location = resp[0].location ?? { accu: null, lat: null, lng: null, speed: null };
    const defaultProps = { questionId, iteration, location };
    const getValue = (value: unknown) => ({ ...defaultProps, value });

    if (resp.length === 0) {
        return getDefaultAnswer({ questionType, questionId, iteration });
    }

    const answerContent = resp[0].answerContent ?? '';
    const numericAnswer = SuperNumber.round(Number(answerContent), 2);
    const selector = resp[0].selector ?? '';
    const periodEnd = resp[0].periodEnd ?? '';
    const answerUrl = resp[0].answerUrl ?? null;
    const answerId = resp[0].answerId ? resp[0].answerId.toString() : null;
    const answerIds = resp.filter((item) => item.answerId != null).map((item) => item.answerId!.toString());

    switch (questionType) {
        case QUESTION_TYPES.SINGLE_CHOICE:
            return getValue(answerId);
        case QUESTION_TYPES.MULTIPLE_CHOICE:
            return getValue(answerIds);
        case QUESTION_TYPES.INFORMATION:
        case QUESTION_TYPES.FREE_FORM_TEXT:
        case QUESTION_TYPES.BARCODE:
            return getValue(answerContent);
        case QUESTION_TYPES.NUMBER:
        case QUESTION_TYPES.VAS:
        case QUESTION_TYPES.LENGTH:
        case QUESTION_TYPES.MASS:
            return getValue(numericAnswer);
        case QUESTION_TYPES.CALENDAR:
            return getValue([
                getStandardDateByString(answerContent as string, selector),
                getStandardDateByString(periodEnd, selector),
            ]);
        case QUESTION_TYPES.IMAGE:
        case QUESTION_TYPES.VIDEO:
        case QUESTION_TYPES.AUDIO:
            return getValue(answerUrl);
        case QUESTION_TYPES.AUDIO_TEXT:
            return getValue({
                text: resp[0]?.answerContent ?? resp[1]?.answerContent ?? '',
                audioUrl: resp[0]?.answerUrl ?? resp[1]?.answerUrl ?? null,
            });
        default:
            return getValue(null);
    }
}

export function extractAnswerOfResponse(response: SessionResponse) {
    const { qType: questionType, qId: questionId, resp, loopCount } = response;

    if (loopCount != null && loopCount > 1) {
        return Array.from({ length: loopCount }, (_, index) => {
            const filteredResp = resp.filter((item) => item.iteration === index);

            return getAnswerOfQuestion({ questionId, questionType, iteration: index, resp: filteredResp });
        });
    }

    return getAnswerOfQuestion({ questionId, questionType, resp });
}

export function extractTokensAndPlaceholders(sections: Array<Section>, currentActivityId: number) {
    const QUESTION_TOKEN_REGEX = /Q\d+(_\d+)?/g; // Detects both Qn_m and Qn
    const QUESTION_PLACEHOLDER_REGEX = /\{\{Q\d+_\d+\}\}/g; // Detects both {{Qn_m}} and {{Qn}}

    const sectionsCriteria = sections.map((section) => section.criteria);
    const questions = sections.flatMap((section) => section.questions);
    const questionsCriteria = questions.map((question) => question.criteria);
    const criteriaList = SuperArray.removeNullAndUndefined([...sectionsCriteria, ...questionsCriteria]);
    const detectedTokens = criteriaList.flatMap((criteria) => criteria.match(QUESTION_TOKEN_REGEX) ?? []);
    const normalizedTokens = detectedTokens.map((token) => {
        if (token.includes('_')) {
            return token;
        }
        return token.replace('Q', `Q${currentActivityId}_`);
    });

    const questionContents = questions.map((question) => question.questionContent);
    const placeholders = questionContents.flatMap((content) => content.match(QUESTION_PLACEHOLDER_REGEX) ?? []);
    const normalizedPlaceholders = placeholders.map((token) => {
        if (token.includes('_')) {
            return token;
        }
        return token.replace('Q', `Q${currentActivityId}_`);
    });

    const result = [...normalizedTokens, ...normalizedPlaceholders].map((str) => str.replaceAll('{{', '').replaceAll('}}', ''));

    return SuperArray.removeDuplicates(result);
}

export function getQuestionTokens(data: Array<LastResponse>) {
    const tokens = data.reduce(
        (all, item) => {
            const { activityId, questionId, response } = item;
            const placeholderKey = `Q${activityId}_${questionId}`;
            const answer = extractAnswerOfResponse(response);
            const value = Array.isArray(answer) ? answer[0].value : answer.value;

            return { ...all, [placeholderKey]: { type: response.qType, value } };
        },
        {} as ObjectType<{ type: string; value: unknown }>
    );

    return tokens;
}

export function humanizeQuestionAnswer(question: StoreQuestion, t: (value: string) => string) {
    const answer = question.answer;
    const questionOptions = question.answers ?? [];
    const unit = question.prefUnit ?? question.defaultUnit;

    if (isAnswerValueEmpty(answer)) return '';

    switch (question.questionType) {
        case QUESTION_TYPES.INFORMATION:
        case QUESTION_TYPES.BARCODE:
        case QUESTION_TYPES.FREE_FORM_TEXT:
        case QUESTION_TYPES.NUMBER:
        case QUESTION_TYPES.VAS:
            return ((answer ?? '') as number | string).toString();
        case QUESTION_TYPES.LENGTH:
            if (unit === LENGTH_UNITS.FT_IN) {
                const { feet, inches } = SuperNumber.centimetersToFeetAndInches(answer as number);

                return `${feet} ft ${inches} in`;
            } else {
                return `${answer as number} cm`;
            }
        case QUESTION_TYPES.MASS:
            if (unit === MASS_UNITS.LBS) {
                const lbs = SuperNumber.kilogramToPound(answer as number);

                return `${lbs} lbs`;
            } else {
                return `${answer as number} kg`;
            }
        case QUESTION_TYPES.SINGLE_CHOICE: {
            const matchedOption = questionOptions.find((option) => option.answerId.toString() === (answer as string));
            return matchedOption?.answerContent ?? '';
        }
        case QUESTION_TYPES.CALENDAR: {
            const [start, end] = answer as [Date, Date | null];

            const startDateString = SuperDate.humanizeDateTime(start);

            if (question.requirePeriod) {
                const endDateString = SuperDate.humanizeDateTime(end as Date);
                const fromLabel = t('webActivities:from_label').toLocaleUpperCase();
                const toLabel = t('webActivities:to_label').toLocaleUpperCase();

                if (question.selector === 'time') {
                    return `${fromLabel} ${startDateString.slice(-5)} ${toLabel} ${endDateString.slice(-5)}`;
                }
                if (question.selector === 'date') {
                    return `${fromLabel} ${startDateString.slice(0, -7)} ${toLabel} ${endDateString.slice(0, -7)}`;
                }
                return `${fromLabel} ${startDateString} ${toLabel} ${endDateString}`;
            }

            if (question.selector === 'time') {
                return startDateString.slice(-5);
            }
            if (question.selector === 'date') {
                return startDateString.slice(0, -7);
            }
            return startDateString;
        }
        case QUESTION_TYPES.MULTIPLE_CHOICE: {
            const answersType = questionOptions[0]?.answerType;

            if (answersType !== QUESTION_CHOICE_BASED_ANSWER_TYPES.TEXT) {
                return '';
            }

            return (answer as Array<string>)
                .map((selectedId) => {
                    const matchedOption = questionOptions.find((option) => option.answerId.toString() === selectedId);

                    return matchedOption?.answerContent ?? '';
                })
                .join(', ');
        }
        case QUESTION_TYPES.AUDIO_TEXT: {
            const { text = '' } = answer as { text: string; audioUrl: null | string };

            return text.trim();
        }
        default:
            return '';
    }
}

export function normalizeQuestionContent(questionContent: string, placeholders: ObjectType<string>) {
    const SPECIAL_PLACEHOLDERS = Object.values(SURVEY_QUESTION_CONTENT_NON_QUESTION_BASED_PLACEHOLDERS);
    const QUESTION_PLACEHOLDER_REGEX = /\{\{Q\d+(_\d+)?\}\}/g; // Detects both {{Qn_m}} and {{Qn}}
    const questionsPlaceholders = SuperArray.removeDuplicates(questionContent.match(QUESTION_PLACEHOLDER_REGEX) ?? []);

    const finalQuestionContent = [...SPECIAL_PLACEHOLDERS, ...questionsPlaceholders].reduce(
        (result, token) => result.replaceAll(token, placeholders[token] ?? ''),
        questionContent
    );

    return finalQuestionContent;
}

export function normalizeQuestionsForStore(sections: Array<Section>): Array<StoreQuestion> {
    const questionsWithSectionId = sections.flatMap((section) => {
        const updatedQuestions = section.questions.map((question) => ({
            sectionId: section.id,
            sectionCriteria: section.criteria,
            sectionLoopSourceQid: section.loopSourceQid,
            sectionNextSection: section.nextSection,
            ...question,
        }));

        return updatedQuestions;
    });

    return questionsWithSectionId.map((question) => ({
        ...question,
        answer: getDefaultValue(question.questionType),
        isHiddenBySectionsRandomization: false,
        isHiddenBySectionCriteria: false,
        isHiddenByNoSectionLoop: false,
        isHiddenByRandomization: false,
        isHiddenBySectionsFlow: false,
        isHiddenByCriteria: false,
        isHiddenByFlow: null,
        isEnabledByAnswers: false,
        iteration: null,
    }));
}

export function applySurveyLoopSections(questions: Array<StoreQuestion>): Array<StoreQuestion> {
    const filteredQuestions: Array<StoreQuestion> = questions
        .filter((question, index, self) => {
            const questionId = question.questionId;
            const itemIndex = self.findIndex((item) => item.questionId === questionId);

            return itemIndex === index;
        })
        .map((question) => ({ ...question, iteration: null }));

    const questionsWithIteration: Array<StoreQuestion> = filteredQuestions.flatMap((question, _, self) => {
        const loopSourceQuestion = self.find(({ questionId }) => questionId === question.sectionLoopSourceQid);

        if (loopSourceQuestion == null) {
            return [question];
        } else {
            // Answer refers to the value of a number question or multiple answer question
            const answer = (loopSourceQuestion.answer as number | Array<string>) ?? 0;
            const size = Array.isArray(answer) ? answer.length : answer;

            if (size > 0) {
                return Array.from({ length: size }, (_, index) => {
                    const questionInClone = questions.find((item) =>
                        SuperObject.isSuperset(item, { questionId: question.questionId, iteration: index })
                    );

                    return {
                        ...question,
                        isHiddenByNoSectionLoop: false,
                        iteration: index,
                        answer: questionInClone ? questionInClone.answer : getDefaultValue(question.questionType),
                    };
                });
            } else {
                return [{ ...question, isHiddenByNoSectionLoop: true, iteration: null }];
            }
        }
    });

    const reorderedQuestions: Array<StoreQuestion> = questionsWithIteration.sort((a, b) => {
        if (a.sectionId === b.sectionId && typeof a.iteration === 'number' && typeof b.iteration === 'number') {
            if (a.iteration > b.iteration) {
                return 1;
            } else if (a.iteration < b.iteration) {
                return -1;
            } else {
                return 0;
            }
        }
        return 0;
    });

    return reorderedQuestions;
}

export function getValidQuestions(questions: Array<StoreQuestion>) {
    return questions.filter((question) => {
        if (!question.enabled && !question.isEnabledByAnswers) {
            return false;
        } else if (question.isHiddenByCriteria) {
            return false;
        } else if (question.isHiddenBySectionsRandomization) {
            return false;
        } else if (question.isHiddenByRandomization) {
            return false;
        } else if (question.isHiddenBySectionCriteria) {
            return false;
        } else if (question.isHiddenBySectionsFlow && question.isHiddenByFlow !== false) {
            return false;
        } else if (question.isHiddenByNoSectionLoop) {
            return false;
        } else if (question.isHiddenByFlow === true) {
            return false;
        } else {
            return true;
        }
    });
}

export function extractPlaceholderByResponse(lastResponse: LastResponse, t: (value: string) => string) {
    const responses = lastResponse.response.resp;
    const resp = responses[0];

    switch (lastResponse.response.qType) {
        case QUESTION_TYPES.INFORMATION:
        case QUESTION_TYPES.BARCODE:
        case QUESTION_TYPES.FREE_FORM_TEXT:
        case QUESTION_TYPES.NUMBER:
        case QUESTION_TYPES.VAS:
            return ((resp.answerContent ?? '') as number | string).toString();
        case QUESTION_TYPES.LENGTH:
            return `${resp.answerContent as number} cm`;
        case QUESTION_TYPES.MASS:
            return `${resp.answerContent as number} kg`;
        case QUESTION_TYPES.SINGLE_CHOICE: {
            const { answerUrl = '' } = resp;

            if (answerUrl !== '') {
                return `${t('webActivities:option')} ${resp.answerId}`;
            } else {
                return resp.answerContent as string;
            }
        }
        case QUESTION_TYPES.CALENDAR: {
            const { selector } = lastResponse.response;
            const start = (responses[0]?.answerContent ?? '') as string;
            const end = (responses[1]?.answerContent ?? null) as string | null;

            if (end != null) {
                const fromLabel = t('webActivities:from_label').toLocaleUpperCase();
                const toLabel = t('webActivities:to_label').toLocaleUpperCase();

                if (selector === 'time') {
                    return `${fromLabel} ${start.slice(-5)} ${toLabel} ${end.slice(-5)}`;
                }
                if (selector === 'date') {
                    return `${fromLabel} ${start.slice(0, -6)} ${toLabel} ${end.slice(0, -6)}`;
                }
                return `${fromLabel} ${start} ${toLabel} ${end}`;
            }

            if (selector === 'time') {
                return start.slice(-5);
            }
            if (selector === 'date') {
                return start.slice(0, -6);
            }
            return start;
        }
        case QUESTION_TYPES.MULTIPLE_CHOICE: {
            const isImageBase = resp.answerUrl != null && resp.answerUrl !== '';

            if (isImageBase) {
                return '';
            } else {
                return responses.map((option) => option.answerContent).join(', ');
            }
        }
        default:
            return '';
    }
}
