import { memo } from 'react';
import clsx from 'clsx';
import { ReactComponent as ButtonTriangle } from '@assets/images/survey/button-triangle.svg';
import type { ButtonProps } from '@material-ui/core';
import { Button as MuiButton, ButtonBase } from '@material-ui/core';

import useStyles from './styles';

interface Props extends Omit<ButtonProps, 'variant' | 'color'> {
    round?: boolean;
    reverse?: boolean;
    variant?: 'text' | 'outlined' | 'contained' | 'triangle';
    color?: ButtonProps['color'] | 'green';
}

const Button = ({
    variant = 'text',
    round = false,
    reverse = false,
    color = 'default',
    className,
    children,
    ...restProps
}: Props) => {
    const classes = useStyles();

    if (variant === 'triangle') {
        return (
            <ButtonBase
                classes={{
                    root: clsx(classes.triangleRoot, className),
                    disabled: classes.triangleDisabled,
                }}
                {...restProps}
            >
                <ButtonTriangle className={clsx(classes.triangleImage, { [classes.triangleImageReverse]: reverse })} />
                <span className={clsx(classes.triangleIcon, { [classes.triangleIconReverse]: reverse })}>{children}</span>
            </ButtonBase>
        );
    }
    return (
        <MuiButton
            className={clsx({ [classes.round]: round, [classes.green]: color === 'green' }, className)}
            variant={variant}
            color={color !== 'green' ? color : 'default'}
            {...restProps}
        >
            {children}
        </MuiButton>
    );
};

export default memo(Button);
