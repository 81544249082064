import { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import LazyImage from '$app-web/components/lazy-image';
import MediaViewer from '$app-web/components/media-viewer';
import { getImageOriginalAndThumbUrl } from '$app-web/utils/image';

import useStyles from './styles';

const ImageAnchor = ({ url }) => {
    const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
    const classes = useStyles();

    const imageUrls = getImageOriginalAndThumbUrl(url);

    return url ? (
        <Grid item>
            <MediaViewer
                open={isImageViewerOpen}
                onCloseClick={() => setIsImageViewerOpen(false)}
                url={imageUrls.original}
                type="image"
            />
            <LazyImage
                className={classes.image}
                src={imageUrls.thumb}
                small
                shadow={false}
                onClick={() => setIsImageViewerOpen(true)}
            />
        </Grid>
    ) : null;
};

ImageAnchor.propTypes = {
    url: PropTypes.string.isRequired,
};

export default ImageAnchor;
