import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import Dashboard, { SPACE_ID as DASHBOARD_SPACE_ID } from './dashboard';
import PublicSpace, { SPACE_ID as PUBLIC_SPACE_ID } from './public';
import WebActivitiesSpace, { SPACE_ID as WEB_ACTIVITIES_SPACE_ID } from './web-activities';

// eslint-disable-next-line react/prefer-stateless-function
class SpacesContainer extends Component {
    render() {
        return (
            <Switch>
                <Route path={`/${DASHBOARD_SPACE_ID}`} component={Dashboard} />
                <Route path={`/${WEB_ACTIVITIES_SPACE_ID}`} component={WebActivitiesSpace} />
                <Route path={`/${PUBLIC_SPACE_ID}`} component={PublicSpace} />
                <Route
                    path="/asa24"
                    render={({ location }) => <Redirect to={`/${WEB_ACTIVITIES_SPACE_ID}/asa24${location.search}`} />}
                />
                <Route
                    path="/stroop"
                    render={({ location }) => <Redirect to={`/${WEB_ACTIVITIES_SPACE_ID}/stroop${location.search}`} />}
                />
                <Route
                    path="/matrix-reasoning"
                    render={({ location }) => <Redirect to={`/${WEB_ACTIVITIES_SPACE_ID}/matrix-reasoning${location.search}`} />}
                />
                <Route
                    path="/working-memory-updating"
                    render={({ location }) => (
                        <Redirect to={`/${WEB_ACTIVITIES_SPACE_ID}/working-memory-updating${location.search}`} />
                    )}
                />
                <Route
                    path="/wheel-of-fortune"
                    render={({ location }) => <Redirect to={`/${WEB_ACTIVITIES_SPACE_ID}/wheel-of-fortune${location.search}`} />}
                />
                <Route
                    path="/time-pref"
                    render={({ location }) => <Redirect to={`/${WEB_ACTIVITIES_SPACE_ID}/time-pref${location.search}`} />}
                />
                <Route
                    path="/summary-page"
                    render={({ location }) => <Redirect to={`/${WEB_ACTIVITIES_SPACE_ID}/summary-page${location.search}`} />}
                />
                <Route
                    path="/progress-reward"
                    render={({ location }) => <Redirect to={`/${WEB_ACTIVITIES_SPACE_ID}/progress-reward${location.search}`} />}
                />
                <Route
                    path="/steps-leaderboard"
                    render={({ location }) => <Redirect to={`/${WEB_ACTIVITIES_SPACE_ID}/steps-leaderboard${location.search}`} />}
                />
                <Route
                    path="/steps-performance"
                    render={({ location }) => <Redirect to={`/${WEB_ACTIVITIES_SPACE_ID}/steps-performance${location.search}`} />}
                />
                <Route
                    path="/my-progress"
                    render={({ location }) => <Redirect to={`/${WEB_ACTIVITIES_SPACE_ID}/my-progress${location.search}`} />}
                />
                <Route
                    path="/sopro-report-chart"
                    render={({ location }) => (
                        <Redirect to={`/${WEB_ACTIVITIES_SPACE_ID}/sopro-report-chart${location.search}`} />
                    )}
                />
                <Route render={() => <Redirect to="/dashboard" />} />
            </Switch>
        );
    }
}

SpacesContainer.propTypes = {};

export default withRouter(SpacesContainer);
