import appDateTime from '@edf-pkg/app-date-time';

type Time = number | string | Date;

function createDateTimeDisplaySections(dateTime: Time) {
    const date = appDateTime(dateTime).format(appDateTime.FORMATS.DATE_MED);
    const [time, meridiem] = appDateTime(dateTime).format(appDateTime.FORMATS.HOUR_MINUTE_MERIDIEM_WITH_SEPARATOR).split('|');

    return [date, time, meridiem];
}

function createDateDisplaySections(dateTime: Time) {
    const [month, day, year] = appDateTime(dateTime).format(appDateTime.FORMATS.DATE_HUMAN_READABLE_WITH_SEPARATOR).split('|');

    return [month, day, year];
}

function createTimeDisplaySections(dateTime: Time) {
    const [time, meridiem] = appDateTime(dateTime).format(appDateTime.FORMATS.HOUR_MINUTE_MERIDIEM_WITH_SEPARATOR).split('|');

    return ['', time, meridiem];
}

export function createDisplaySections(displayVariant: 'date-time' | 'date' | 'time', dateTime: Time) {
    switch (displayVariant) {
        case 'date-time':
            return createDateTimeDisplaySections(dateTime);
        case 'date':
            return createDateDisplaySections(dateTime);
        case 'time':
            return createTimeDisplaySections(dateTime);
        default:
            return ['', '', ''];
    }
}
