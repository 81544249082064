import { mixed, object, string } from 'yup';

import { core as appClientCore, utils as appClientUtils } from '@edf-pkg/app-client';
import { utils as appMainUtils } from '@edf-pkg/app-main';

const requestSchema = object().shape({
    user_id: string().nullable(),
    session_uuid: string().nullable(),
    message: mixed().required(),
    tag: string().required(),
    device_id: string().required(),
});

export default async function PHCreateEndpoint(message, sessionUUID = null) {
    try {
        const { id, deviceId } = appMainUtils.user.getData();
        const data = await requestSchema.validate({
            message,
            tag: 'ph',
            device_id: deviceId || 'anonymous',
            user_id: id,
            session_uuid: sessionUUID,
        });
        await appClientCore.axiosClientsManager
            .getClient('api')
            .post('/study/ph/create/', appClientUtils.object.toFormData(data));
        return true;
    } catch (error) {
        appClientCore.errorMapper(error);
    }
    return null;
}
