import { lighten } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    circle: {
        borderRadius: '50%',
        overflow: 'hidden',
        color: theme.palette.default.dark,
    },
    outerCircle: {
        backgroundColor: lighten(theme.palette.default.main, 0.9),
        display: 'inline-block',
    },
    innerCircle: {
        backgroundColor: lighten(theme.palette.default.main, 0.8),
    },
    contentWrapper: {
        textAlign: 'center',
    },
    mediumSize: {
        '& $innerCircle': {
            width: 172,
            height: 172,
            margin: theme.spacing(2),
        },
    },
    smallSize: {
        '& $innerCircle': {
            width: 108,
            height: 108,
            margin: theme.spacing(1),
        },
    },
}));
