import { core as appClientCore, utils as appClientUtils } from '@edf-pkg/app-client';

import { ObjectType } from '$app-web/types';
import { SuperDate, SuperString, URLsManager } from '$app-web/utils';

interface Response {
    success: boolean;
}

interface Params {
    sessionUuid: string;
    file: File;
    userId: number;
}

const FILE_EXTENSION_MAPPER: ObjectType<string> = {
    jpg: 'image',
    jpeg: 'image',
    png: 'image',
    webp: 'image',
    mp4: 'video',
    mkv: 'video',
    mp3: 'audio',
    webm: 'audio',
};

export async function uploadMediaOnSurveySession(parameters: Params) {
    const { sessionUuid, file, userId } = parameters;

    const currentDateId = SuperDate.getCurrentTimeAsId();
    const randomChars = SuperString.getRandomChars();
    const fileExtension = file.name.split('.').pop() ?? '';
    const randomName = SuperString.getRandomChars(8, { onlyLetters: true });
    const fileName = `${currentDateId}-${randomChars}-${userId}-${randomName}.${fileExtension}`;

    const data = { session_uuid: sessionUuid, data: file };

    try {
        const response = (await appClientCore.axiosClientsManager
            .getClient('api')
            .post('/study/user/upload/', appClientUtils.object.toFormData(data, { data: fileName }))) as { data?: Response };

        if (response?.data?.success) {
            const fileType = FILE_EXTENSION_MAPPER[fileExtension];

            return URLsManager.getUploadedMediaURL({ userId, fileName, fileType }).url;
        }

        return null;
    } catch (error) {
        appClientCore.errorMapper(error as Error);
        return null;
    }
}
