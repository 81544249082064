import appDateTime from '@edf-pkg/app-date-time';

import {
    CALENDAR_BASED_QUESTION_SELECTORS as CALENDAR_SELECTOR,
    QUESTION_TYPES as Q_TYPES,
    SESSION_STATUSES,
    UNANSWERED_QUESTION_REASONS as UNANSWERED_REASONS,
} from '$app-web/constants';
import { uploadMediaOnSurveySession } from '$app-web/endpoints';
import type { Answer, ObjectType, Resp, Session, SessionResponse } from '$app-web/types';
import { SuperObject } from '$app-web/utils';

import { PureStore, StoreQuestion } from '../store/types';
import { isAnswerValueEmpty } from './helpers';

export async function processQuestionsAnswersForBackend(params: {
    sessionUuid: string;
    userId: number;
    questions: Array<StoreQuestion>;
}) {
    const { sessionUuid, userId, questions } = params;

    const updatedQuestions: Array<StoreQuestion> = [];

    try {
        for await (const question of questions) {
            const answer = question.answer;
            // Image - Audio - Video
            if (answer instanceof File) {
                const fileUrl = await uploadMediaOnSurveySession({ sessionUuid, userId, file: answer });

                if (fileUrl != null) {
                    updatedQuestions.push({ ...question, answer: fileUrl });
                } else {
                    throw Error();
                }
            }
            // Text/Audio
            else if ((answer as ObjectType)?.audioUrl instanceof File) {
                const { text, audioUrl: file } = answer as { audioUrl: File; text: string };
                const data = { sessionUuid, userId, file };
                const fileUrl = await uploadMediaOnSurveySession(data);

                if (fileUrl != null) {
                    updatedQuestions.push({ ...question, answer: { text, audioUrl: fileUrl } });
                } else {
                    throw Error();
                }
            } else {
                updatedQuestions.push(question);
            }
        }

        return updatedQuestions;
    } catch {
        return [];
    }
}

function getStringifiedDate(date: unknown, selector?: string) {
    if (date == null) {
        return undefined;
    }

    if (selector === CALENDAR_SELECTOR.TIME) {
        return appDateTime(date as typeof appDateTime).format(appDateTime.FORMATS.TIME);
    }
    if (selector === CALENDAR_SELECTOR.DATE) {
        return appDateTime(date as typeof appDateTime).format(appDateTime.FORMATS.DATE);
    }

    return appDateTime(date as typeof appDateTime).format(appDateTime.FORMATS.DATE_TIME);
}

function getRespFieldOfQuestion({
    question,
    expiryTimeMs,
}: {
    question: StoreQuestion;
    expiryTimeMs: number;
}): Array<Partial<Resp>> {
    const {
        answer,
        enabled,
        answers = [],
        selector,
        questionType,
        isEnabledByAnswers,
        isHiddenByFlow,
        isHiddenByCriteria,
        isHiddenBySectionsFlow,
        isHiddenByNoSectionLoop,
        isHiddenByRandomization,
        isHiddenBySectionCriteria,
        isHiddenBySectionsRandomization,
    } = question;

    if (expiryTimeMs < Date.now() && isAnswerValueEmpty(answer)) {
        return [{ unansweredReason: UNANSWERED_REASONS.SESSION_STATUS }];
    } else if (isHiddenBySectionsRandomization) {
        return [{ unansweredReason: UNANSWERED_REASONS.SECTION_RANDOMIZATION }];
    } else if (isHiddenByRandomization) {
        return [{ unansweredReason: UNANSWERED_REASONS.QUESTION_RANDOMIZATION }];
    } else if (isHiddenBySectionCriteria) {
        return [{ unansweredReason: UNANSWERED_REASONS.SECTION_CRITERIA }];
    } else if (isHiddenByCriteria) {
        return [{ unansweredReason: UNANSWERED_REASONS.QUESTION_CRITERIA }];
    } else if (isHiddenByNoSectionLoop) {
        return [{ unansweredReason: UNANSWERED_REASONS.NO_SECTION_LOOP }];
    } else if (!enabled && !isEnabledByAnswers) {
        return [{ unansweredReason: UNANSWERED_REASONS.DISABLED_QUESTION }];
    } else if (isHiddenByFlow || (isHiddenBySectionsFlow && isHiddenByFlow !== false)) {
        return [{ unansweredReason: UNANSWERED_REASONS.SECTIONS_FLOW }];
    } else if (isAnswerValueEmpty(answer)) {
        return [{ unansweredReason: UNANSWERED_REASONS.SKIPPED }];
    } else {
        switch (questionType) {
            case Q_TYPES.INFORMATION:
                return [{}];
            case Q_TYPES.FREE_FORM_TEXT:
            case Q_TYPES.BARCODE:
            case Q_TYPES.NUMBER:
            case Q_TYPES.VAS:
            case Q_TYPES.LENGTH:
            case Q_TYPES.MASS:
                return [{ answerContent: answer as string | number }];
            case Q_TYPES.IMAGE:
            case Q_TYPES.AUDIO:
            case Q_TYPES.VIDEO:
                return [{ answerUrl: answer as string }];
            case Q_TYPES.CALENDAR: {
                const [startDate, endDate] = answer as [unknown, unknown];

                return [
                    {
                        answerContent: getStringifiedDate(startDate, selector),
                        periodEnd: getStringifiedDate(endDate, selector),
                        selector,
                    },
                ];
            }
            case Q_TYPES.AUDIO_TEXT: {
                const { text, audioUrl } = answer as { text: string; audioUrl: string | null };

                if (audioUrl == null) return [{ answerContent: text }];
                else return [{ answerContent: text }, { answerUrl: audioUrl }];
            }
            case Q_TYPES.SINGLE_CHOICE: {
                const relatedAnswer = (answers.find(({ answerId }) => `${answerId}` === answer) ?? {}) as Answer;
                const requiredAnswerProps = SuperObject.pick(relatedAnswer, ['answerId', 'answerContent', 'answerUrl']);

                return [{ ...requiredAnswerProps }];
            }
            case Q_TYPES.MULTIPLE_CHOICE:
                return (answer as Array<number>).map((item) => {
                    const relatedAnswer = (answers.find(({ answerId }) => `${answerId}` === `${item}`) ?? {}) as Answer;
                    const requiredAnswerProps = SuperObject.pick(relatedAnswer, ['answerId', 'answerContent', 'answerUrl']);

                    return { ...requiredAnswerProps };
                });
            default:
                return [{}];
        }
    }
}

export type GenerateBackendResponsesReturn = Session & {
    pTimeMs: number;
    statusId: number;
    responses: Array<Partial<SessionResponse>>;
    inProgressInfo: PureStore['currentQuestion'] | Record<string, never>;
};

export function generateBackendResponses(params: {
    questions: Array<StoreQuestion>;
    location: PureStore['location'];
    session: Session;
    mediaInteractions: PureStore['mediaInteractions'];
    answeringQuestion?: PureStore['currentQuestion'];
    translationId: string;
    newSessionStatus?: number;
}): GenerateBackendResponsesReturn {
    const { session, questions, location, mediaInteractions, answeringQuestion, translationId } = params;
    const newSessionStatus = params.newSessionStatus ?? session.statusId;

    const indexOfAnsweringQuestion = questions.findIndex((question) => {
        if (answeringQuestion?.iteration == null) {
            return question.questionId === answeringQuestion?.questionId;
        } else {
            return SuperObject.isSuperset(question, answeringQuestion);
        }
    });
    const validIndexOfAnsweringQuestion = indexOfAnsweringQuestion >= 0 ? indexOfAnsweringQuestion : Number.MAX_SAFE_INTEGER;

    const necessaryQuestions = questions.filter((_, index) => index < validIndexOfAnsweringQuestion);

    const responses: Array<Partial<SessionResponse>> = necessaryQuestions.map((question) => {
        const targetMediaInteractions = mediaInteractions
            .filter((interaction) => interaction.questionId === question.questionId)
            .map((item) => SuperObject.omit(item, ['questionId']));

        const { iteration, questionContent, questionContentTranslation } = question;
        const qContent = questionContentTranslation?.[translationId] ?? questionContent;
        const subResponses = getRespFieldOfQuestion({ question, expiryTimeMs: session.expiryTimeMs });

        // const commonRespFields = { respTimeMs: Date.now(), iteration, qContent };
        // TODO: Replace with the above line after the backend is updated [The BE update is estimated to take place by the end of October 2024]
        const commonRespFields = iteration == null ? { respTimeMs: Date.now() } : { respTimeMs: Date.now(), iteration, qContent };

        // TODO: Comment `as Array<Resp>` after the backend is updated [The BE update is estimated to take place by the end of October 2024]
        const resp: Array<Resp> = subResponses.map((item) => ({ ...commonRespFields, ...item })) as Array<Resp>;

        return {
            bgColor: '#FEFEFE',
            qId: question.questionId,
            qType: question.questionType,
            userId: session.userId,
            version: session.version,
            loopCount: 0,
            location,
            mediaInteractions: targetMediaInteractions,
            ...resp[0],
            resp,
        };
    });

    return {
        ...session,
        pTimeMs: session.sTimeMs,
        statusId: session.expiryTimeMs < Date.now() ? SESSION_STATUSES.EXPIRED : newSessionStatus,
        responses,
        inProgressInfo: answeringQuestion ?? {},
    };
}
