import { ChangeEvent, memo, useCallback } from 'react';
import clsx from 'clsx';
import { MenuItem, RadioGroup, Select } from '@material-ui/core';

import Choice from '$app-web/activities/survey/components/choice';
import { CHOICE_TYPES, CHOICES_LAYOUT_TYPES } from '$app-web/activities/survey/constants';

import classes from './styles.module.scss';

interface Props {
    onChange: (newOption: string | null) => void;
    options: Array<{
        label: string;
        value: string;
        disabled?: boolean;
    }>;
    layout: string;
    type: 'text' | 'url';
    value: string | null;
}

const TypeSingleChoice = ({ layout, type, options, value, onChange }: Props) => {
    const enabledOptions = options.filter((option) => !option.disabled);

    const handleChange = useCallback(
        (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
            const newValue = event.target.value as string;
            onChange(newValue);
        },
        [onChange]
    );

    if (layout === CHOICES_LAYOUT_TYPES.DROPDOWN) {
        return (
            <Select
                data-testid="single-choice-dropdown"
                fullWidth
                value={typeof value === 'string' ? value : ''} // TODO-MAYBE: Is this check necessary? We set proptype to string.
                onChange={handleChange}
            >
                {enabledOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        );
    }

    return (
        <RadioGroup
            row={type === CHOICE_TYPES.TEXT || layout === CHOICES_LAYOUT_TYPES.BUTTON}
            className={clsx({
                [classes.imageRadioGroup]: type === CHOICE_TYPES.URL,
            })}
        >
            {enabledOptions.map((choice) => {
                const sharedProps = {
                    key: choice.value,
                    type: 'single' as const,
                    checked: value === choice.value,
                    onChange: () => {
                        onChange(choice.value);
                    },
                };
                return type === CHOICE_TYPES.URL ? (
                    <Choice
                        className={clsx(classes.choice, classes.imageChoice)}
                        variant="image"
                        imageSrc={choice.label}
                        {...sharedProps}
                    />
                ) : (
                    <Choice
                        className={classes.choice}
                        variant={layout === CHOICES_LAYOUT_TYPES.BUTTON ? 'button' : 'text'}
                        label={choice.label}
                        {...sharedProps}
                    />
                );
            })}
            {/* This s a fix for odd number of only image choices to make the last choice stick to left */}
            {type === CHOICE_TYPES.URL && enabledOptions.length % 2 !== 0 && <div style={{ width: '136px', display: 'flex' }} />}
        </RadioGroup>
    );
};

export default memo(TypeSingleChoice);
