import { ChangeEvent, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import type { CheckboxProps } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';

import Control from '$app-web/activities/survey/components/choice/control';
import MediaViewer from '$app-web/components/media-viewer';
import { getIsACTMaternityStudy } from '$app-web/utils';
import { getImageOriginalAndThumbUrl } from '$app-web/utils/image';

import Label from './label';
import useStyles from './styles';

interface Props extends CheckboxProps {
    checked: boolean;
    type: 'single' | 'multiple';
    onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    variant?: 'image' | 'text' | 'button';
    label?: string;
    imageSrc?: string;
    fullWidth?: boolean;
}

const Choice = ({
    className,
    type,
    variant = 'text',
    label,
    imageSrc,
    checked,
    disabled = false,
    fullWidth = false,
    ...restProps
}: Props) => {
    const classes = useStyles();

    const { studyId } = useParams<{
        studyId: string;
    }>();

    const isACTMaternityStudy = useMemo(() => getIsACTMaternityStudy(studyId), [studyId]);

    const [imageViewerOpened, setImageViewerOpened] = useState(false);

    const isVariantButton = variant === 'button';
    const isVariantImage = variant === 'image';

    const imageUrls = getImageOriginalAndThumbUrl(imageSrc);
    return (
        <>
            {isVariantImage && (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                <MediaViewer
                    open={imageViewerOpened}
                    url={imageUrls.original}
                    onCloseClick={() => setImageViewerOpened(false)}
                    type="image"
                    alignCloseStart
                    disableRestoreFocus
                />
            )}
            <FormControlLabel
                data-testid="choice-option"
                data-selected={checked}
                className={className}
                classes={{
                    root: clsx(classes.root, {
                        [classes.rootACTPurple]: isACTMaternityStudy,
                        [classes.rootChecked]: checked,
                        [classes.rootVariantButton]: isVariantButton,
                        [classes.rootFullWidth]: fullWidth,
                    }),
                    label: clsx({
                        [classes.labelText]: !isVariantImage,
                        [classes.labelImage]: isVariantImage,
                        [classes.labelVariantButton]: isVariantButton,
                    }),
                    disabled: classes.disabled,
                }}
                disabled={disabled}
                checked={checked}
                label={
                    <Label
                        variant={variant}
                        text={label ?? ''}
                        imageSrc={imageUrls ? imageUrls.thumb : ''}
                        disabled={disabled}
                        onViewClick={() => setImageViewerOpened(true)}
                    />
                }
                control={
                    <Control
                        type={type}
                        className={clsx({ [classes.controlVariantButton]: isVariantButton })}
                        disableRipple
                        checked={checked}
                        disabled={disabled}
                        {...restProps}
                    />
                }
            />
        </>
    );
};

export default Choice;
