const styles = (theme) => ({
    root: {
        color: '#FFFF',
        width: '60%',
        maxWidth: 350,
        backgroundColor: '#707070',
        borderRadius: '5px',
        boxShadow: '0px 2px 3px 0px rgba(110,110,110,1)',
        display: 'block',
        margin: '0 auto',
        padding: theme.spacing(1, 0),
        '&:hover': {
            backgroundColor: '#707070',
            boxShadow: '0px 2px 3px 0px rgba(110,110,110,1)',
        },
    },
    label: {
        whiteSpace: 'nowrap',
    },
});

export default styles;
