import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        '&$checked': { color: theme.palette.green.main },
    },
    checked: {},
    icon: {
        fontSize: theme.typography.pxToRem(8),
    },
    squareIcon: {
        background: theme.palette.default.contrastText,
    },
    circleIcon: {
        background: theme.palette.default.contrastText,
        borderRadius: '50%',
    },
}));
