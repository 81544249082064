import { tree } from 'antlr4';

import appDateTime from '@edf-pkg/app-date-time';

import { criteriaParser as CriteriaParser } from '$app-web/utils/criteria-evaluator/antlr/criteriaParser';

export default class Visitor extends tree.ParseTreeVisitor {
    getData = () => false;

    timeResolutionValues = ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years'];

    static compareValues(left, right, comparator) {
        if (left == null || right == null || left === '' || right === '') {
            return false;
        }

        // Contains multiple choice value
        if (Array.isArray(left) || Array.isArray(right)) {
            if (comparator !== '==' && comparator !== '!=') {
                return false;
            }

            if (Array.isArray(left) && !Array.isArray(right)) {
                const hasValue = left.includes(`${right}`);

                return comparator === '==' ? hasValue : !hasValue;
            }

            if (!Array.isArray(left) && Array.isArray(right)) {
                const hasValue = right.includes(`${left}`);

                return comparator === '==' ? hasValue : !hasValue;
            }

            const sortFunction = (a, b) => a > b;
            const areArraysSame = [...left].sort(sortFunction).join() === [...right].sort(sortFunction).join();

            return comparator === '==' ? areArraysSame : !areArraysSame;
        }

        return eval(`${left} ${comparator} ${right}`);
    }

    constructor(getData) {
        super();
        this.getData = getData;
    }

    visitChildren(context) {
        if (context instanceof CriteriaParser.AtomExprContext) {
            return this.visitAtomExpr(context);
        }

        if (context instanceof CriteriaParser.OpCompareExprContext) {
            return this.visitOpCompareExpr(context);
        }
        if (context instanceof CriteriaParser.AtomContext) {
            return this.visitAtom(context);
        }
        if (context instanceof CriteriaParser.ParenExprContext) {
            return this.visitParenExpr(context);
        }
        if (context instanceof CriteriaParser.OpLogicExprContext) {
            return this.visitOpLogicExpr(context);
        }
        return false;
    }

    visitAtomExpr(context) {
        const result = this.visit(context.children)[0];
        return result;
    }

    visitOpCompareExpr(context) {
        const comparator = context.op.text;
        const left = this.visit(context.left);
        const right = this.visit(context.right);
        return Visitor.compareValues(left, right, comparator);
    }

    visitAtom(context) {
        const { token } = context;
        if (context.parser.symbolicNames[token.type] === 'INT') {
            return parseInt(token.text, 10);
        }
        if (context.parser.symbolicNames[token.type] === 'DOUBLE') {
            return parseFloat(token.text);
        }
        if (context.parser.symbolicNames[token.type] === 'QID') {
            return this.getData('question-value', token.text);
        }
        if (context.parser.symbolicNames[token.type] === 'REG_DT') {
            const tokenTextSplit = token.text.split('_since_reg_');
            if (
                tokenTextSplit.length === 2 &&
                tokenTextSplit[0][0] === '_' &&
                this.timeResolutionValues.indexOf(tokenTextSplit[0].substring(1)) >= 0
            ) {
                const timeResolution = tokenTextSplit[0].substring(1);
                const dateOrTime = tokenTextSplit[1];
                let registrationDateTime = appDateTime(this.getData('registration-date-time'));
                if (dateOrTime === 'date') {
                    registrationDateTime = registrationDateTime.startOfDay();
                }
                const nowDateTime = appDateTime();

                switch (timeResolution) {
                    case 'seconds':
                        return nowDateTime.diffSecond(registrationDateTime);
                    case 'minutes':
                        return nowDateTime.diffMinute(registrationDateTime);
                    case 'hours':
                        return nowDateTime.diffHour(registrationDateTime);
                    case 'days':
                        return nowDateTime.diffDay(registrationDateTime);
                    case 'weeks':
                        return nowDateTime.diffWeek(registrationDateTime);
                    case 'months':
                        return nowDateTime.diffMonth(registrationDateTime);
                    case 'years':
                        return nowDateTime.diffYear(registrationDateTime);
                    default:
                        return 0;
                }
            } else {
                return false;
            }
        }

        return false;
    }

    visitParenExpr(context) {
        return this.visit(context.inner);
    }

    visitOpLogicExpr(context) {
        const right = this.visit(context.right);
        if (context.parser.symbolicNames[context.op.type] === 'AND') {
            const left = this.visit(context.left);

            return eval(`${left} && ${right}`);
        }
        if (context.parser.symbolicNames[context.op.type] === 'OR') {
            const left = this.visit(context.left);

            return eval(`${left} || ${right}`);
        }
        if (context.parser.symbolicNames[context.op.type] === 'NOT') {
            if (Array.isArray(right) && right.length === 0) {
                return true;
            }

            return eval(!right);
        }
        return false;
    }
}
