import axios from 'axios';

import { ClientError } from '@edf-pkg/app-error';
import appUtils from '@edf-pkg/app-utils';

class AxiosClientsManager {
    constructor() {
        this.clients = {};
    }

    createClient(clientId, options) {
        this.clients[clientId] = axios.create(options);
        return this.clients[clientId];
    }

    getClient(clientId) {
        if (appUtils.object.hasKey(this.clients, clientId)) {
            return this.clients[clientId];
        }
        throw new ClientError(`Trying to get missing client. Please call createClient first. clientId: ${clientId}`);
    }
}

const instance = new AxiosClientsManager();
export default instance;
