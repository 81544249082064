import { PropsWithChildren } from 'react';
import { AxiosRequestConfig } from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { ObjectType } from '$app-web/types';

import { callReactQuery } from './react-query-caller';

interface Props {
    showDevtools?: boolean;
}

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            queryFn: ({ queryKey: [queryKey, data], pageParam }) =>
                callReactQuery({ endpoint: queryKey as string, data: data as ObjectType, pageParam }),
            refetchOnWindowFocus: false,
            retry: 0,
        },
        mutations: {
            mutationFn: (variables) => {
                const { queryKey, extraAxiosConfig, ...data } = variables as {
                    queryKey: string;
                    extraAxiosConfig: AxiosRequestConfig;
                } & ObjectType;

                return callReactQuery({ endpoint: queryKey, data, extraAxiosConfig, cancelable: false });
            },
        },
    },
});

const ClientProvider = ({ children, showDevtools = true }: PropsWithChildren<Props>) => {
    return (
        <QueryClientProvider client={queryClient}>
            {showDevtools ? <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> : null}
            {children}
        </QueryClientProvider>
    );
};

export default ClientProvider;
