import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import { store as phrStore } from '@edf-pkg/app-phr';
import { configureStore } from '@edf-pkg/app-store';

import * as mainAppStore from '../../store';

mainAppStore.inject();
phrStore.inject();
const { store } = configureStore({});

// eslint-disable-next-line react/prefer-stateless-function
export default class MainApp extends Component {
    render() {
        const { children } = this.props;
        return <Provider store={store}>{children}</Provider>;
    }
}

MainApp.propTypes = {
    children: PropTypes.element.isRequired,
};
