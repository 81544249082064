import i18nReducer, * as i18nDuck from './i18n.duck';
import i18nSaga, { SAGA_NAME as i18nSagaName } from './i18n.saga';

/*
    This function is here because we don't want to inject i18n store independently.
    The i18n store duck and saga will be added as app-main package store.
 */
export function getStoreAsObject() {
    return {
        reducer: i18nReducer,
        duck: i18nDuck,
        saga: i18nSaga,
        SAGA_NAME: i18nSagaName,
    };
}

export { i18nDuck };
