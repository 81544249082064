import produce from 'immer';
import { memoize } from 'lodash-es';
import { createSelector } from 'reselect';
import { getSelector, namespaceReducerActions } from 'retrack';

import appUtils from '@edf-pkg/app-utils';

import { ACTIVITY_STATUS, HTTP_CALL_STATUS } from '../constants';

export const DUCK_NAME = 'activities';

const activityBase = {
    status: ACTIVITY_STATUS.UNKNOWN,
    loadDataStatus: HTTP_CALL_STATUS.UNKNOWN,
    type: '',
    sessionUUID: '',
    sessionInfo: {},
    options: {},
    open: true,
    activityFinished: false,
    closingInProgress: false,
    ensurePHRSent: false,
};

const initialState = {
    activitiesByUUID: {},
};

export const duckActions = {
    ADD: 'ADD',
    LOAD_DATA: 'LOAD_DATA',
    SET_LOAD_DATA_STATUS: 'SET_LOAD_DATA_STATUS',
    RESET: 'RESET',
    SET_ACTIVITY_DATA: 'SET_ACTIVITY_DATA',
    SET_ACTIVITY_SESSION_INFO: 'SET_ACTIVITY_SESSION_INFO',
    SET_ACTIVITY_SESSION_UUID: 'SET_ACTIVITY_SESSION_UUID',
    CLOSE: 'CLOSE',
    SET_CLOSING_IN_PROGRESS: 'SET_CLOSING_IN_PROGRESS',
    SET_CLOSE_DATA: 'SET_CLOSE_DATA',
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case duckActions.RESET:
            delete draft.activitiesByUUID[action.payload.activityUUID];
            break;
        case duckActions.ADD:
            draft.activitiesByUUID[action.payload.activityUUID] = { ...activityBase };
            break;
        case duckActions.SET_LOAD_DATA_STATUS:
            draft.activitiesByUUID[action.payload.activityUUID].loadDataStatus = action.payload.status;
            break;
        case duckActions.SET_ACTIVITY_DATA:
            draft.activitiesByUUID[action.payload.activityUUID].type = action.payload.type;
            draft.activitiesByUUID[action.payload.activityUUID].status = action.payload.status;
            draft.activitiesByUUID[action.payload.activityUUID].sessionUUID = action.payload.sessionUUID;
            draft.activitiesByUUID[action.payload.activityUUID].sessionInfo = action.payload.sessionInfo;
            draft.activitiesByUUID[action.payload.activityUUID].options = action.payload.options;
            draft.activitiesByUUID[action.payload.activityUUID].ensurePHRSent = action.payload.ensurePHRSent;
            break;
        case duckActions.SET_ACTIVITY_SESSION_UUID:
            draft.activitiesByUUID[action.payload.activityUUID].sessionUUID = action.payload.sessionUUID;
            break;
        case duckActions.SET_ACTIVITY_SESSION_INFO:
            draft.activitiesByUUID[action.payload.activityUUID].sessionInfo = action.payload.sessionInfo;
            break;
        case duckActions.SET_CLOSING_IN_PROGRESS:
            draft.activitiesByUUID[action.payload.activityUUID].closingInProgress = true;
            break;
        case duckActions.SET_CLOSE_DATA:
            draft.activitiesByUUID[action.payload.activityUUID].open = action.payload.open;
            draft.activitiesByUUID[action.payload.activityUUID].activityFinished = action.payload.activityFinished;
            break;
        // no default
    }
    return draft;
}, initialState);

namespaceReducerActions(reducer, duckActions);

export default reducer;

export const duckActionCreators = {
    add: (activityUUID) => {
        return {
            type: duckActions.ADD,
            payload: {
                activityUUID,
            },
        };
    },
    reset: (activityUUID) => {
        return {
            type: duckActions.RESET,
            payload: {
                activityUUID,
            },
        };
    },
    loadData: (activityUUID, activityType, sessionUUID, activityOptions, activitySessionInfo, ensurePHRSent) => {
        return {
            type: duckActions.LOAD_DATA,
            payload: {
                activityUUID,
                activityType,
                sessionUUID,
                activityOptions,
                activitySessionInfo,
                ensurePHRSent,
            },
        };
    },
    setLoadDataStatusLoading: (activityUUID) => {
        return {
            type: duckActions.SET_LOAD_DATA_STATUS,
            payload: {
                status: HTTP_CALL_STATUS.LOADING,
                activityUUID,
            },
        };
    },
    setLoadDataStatusSucceeded: (activityUUID) => {
        return {
            type: duckActions.SET_LOAD_DATA_STATUS,
            payload: {
                status: HTTP_CALL_STATUS.SUCCEEDED,
                activityUUID,
            },
        };
    },
    setLoadDataStatusFailed: (activityUUID) => {
        return {
            type: duckActions.SET_LOAD_DATA_STATUS,
            payload: {
                status: HTTP_CALL_STATUS.FAILED,
                activityUUID,
            },
        };
    },
    setActivityData: (activityUUID, type, status, sessionUUID, sessionInfo, options, ensurePHRSent) => {
        return {
            type: duckActions.SET_ACTIVITY_DATA,
            payload: {
                activityUUID,
                type,
                status,
                sessionUUID,
                sessionInfo,
                options,
                ensurePHRSent,
            },
        };
    },
    setActivitySessionUUID: (activityUUID, sessionUUID) => {
        return {
            type: duckActions.SET_ACTIVITY_SESSION_UUID,
            payload: {
                activityUUID,
                sessionUUID,
            },
        };
    },
    setActivitySessionInfo: (activityUUID, sessionInfo) => {
        return {
            type: duckActions.SET_ACTIVITY_SESSION_INFO,
            payload: {
                activityUUID,
                sessionInfo,
            },
        };
    },
    close: (activityUUID, activityFinished = false, activityCanceled = false) => {
        return {
            type: duckActions.CLOSE,
            payload: {
                activityUUID,
                activityFinished,
                activityCanceled,
            },
        };
    },
    setClosingInProgress: (activityUUID) => {
        return {
            type: duckActions.SET_CLOSING_IN_PROGRESS,
            payload: {
                activityUUID,
            },
        };
    },
    setCloseData: (activityUUID, open, activityFinished = false) => {
        return {
            type: duckActions.SET_CLOSE_DATA,
            payload: {
                activityUUID,
                open,
                activityFinished,
            },
        };
    },
};

const activitiesSelector = getSelector(reducer);

const activitiesByUUIDSelector = createSelector(activitiesSelector, (activitiesState) => activitiesState.activitiesByUUID);

const isLoadDataStatusLoadingSelector = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID)
            ? activitiesByUUIDState[activityUUID].loadDataStatus === HTTP_CALL_STATUS.LOADING
            : false
    )
);

const isLoadDataStatusSucceededSelector = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID)
            ? activitiesByUUIDState[activityUUID].loadDataStatus === HTTP_CALL_STATUS.SUCCEEDED
            : false
    )
);

const isLoadDataStatusFailedSelector = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID)
            ? activitiesByUUIDState[activityUUID].loadDataStatus === HTTP_CALL_STATUS.FAILED
            : false
    )
);

const isActivityStatusAnswerable = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID)
            ? activitiesByUUIDState[activityUUID].status === ACTIVITY_STATUS.ANSWERABLE
            : false
    )
);

const isActivityStatusUpcoming = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID)
            ? activitiesByUUIDState[activityUUID].status === ACTIVITY_STATUS.UPCOMING
            : false
    )
);

const isActivityStatusPast = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID)
            ? activitiesByUUIDState[activityUUID].status === ACTIVITY_STATUS.PAST
            : false
    )
);

const isActivityStatusFinished = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID)
            ? activitiesByUUIDState[activityUUID].status === ACTIVITY_STATUS.FINISHED
            : false
    )
);

const closingInProgressSelector = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID)
            ? activitiesByUUIDState[activityUUID].closingInProgress
            : activityBase.closingInProgress
    )
);

const openSelector = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID) ? activitiesByUUIDState[activityUUID].open : activityBase.open
    )
);

const activityFinishedSelector = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID)
            ? activitiesByUUIDState[activityUUID].activityFinished
            : activityBase.activityFinished
    )
);

const typeSelector = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID) ? activitiesByUUIDState[activityUUID].type : activityBase.type
    )
);

const sessionUUIDSelector = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID)
            ? activitiesByUUIDState[activityUUID].sessionUUID
            : activityBase.sessionUUID
    )
);

const sessionInfoSelector = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID)
            ? activitiesByUUIDState[activityUUID].sessionInfo
            : activityBase.sessionInfo
    )
);

const optionsSelector = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID)
            ? activitiesByUUIDState[activityUUID].options
            : activityBase.options
    )
);

const ensurePHRSentSelector = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID)
            ? activitiesByUUIDState[activityUUID].ensurePHRSent
            : activityBase.ensurePHRSent
    )
);

const isSandboxSelector = createSelector(activitiesByUUIDSelector, (activitiesByUUIDState) =>
    memoize((activityUUID) =>
        appUtils.object.hasKey(activitiesByUUIDState, activityUUID)
            ? activitiesByUUIDState[activityUUID].options.isSandbox
            : false
    )
);

export const duckSelectors = {
    activitiesSelector,
    activitiesByUUIDSelector,
    isActivityStatusAnswerable,
    isActivityStatusUpcoming,
    isActivityStatusPast,
    isActivityStatusFinished,
    isLoadDataStatusLoadingSelector,
    isLoadDataStatusSucceededSelector,
    isLoadDataStatusFailedSelector,
    closingInProgressSelector,
    openSelector,
    activityFinishedSelector,
    typeSelector,
    sessionUUIDSelector,
    sessionInfoSelector,
    optionsSelector,
    ensurePHRSentSelector,
    isSandboxSelector,
};
