import { pick } from 'lodash-es';
import { call, put, race, take, takeEvery } from 'redux-saga/effects';

import { auth as appClientAuth } from '@edf-pkg/app-client';
import { errorHandle, FatalError } from '@edf-pkg/app-error';
import { store as i18nStore } from '@edf-pkg/app-i18n';
import appUtils from '@edf-pkg/app-utils';

import client from '../client';
import { duckActionCreators as userActionCreators, duckActions as userActions } from './user.duck';

export const SAGA_NAME = 'USER';

function getAvatarUrl(url) {
    if (!appUtils.env.isDev()) {
        return url;
    }

    const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL || '';
    const urlWithoutLeadingSlash = url.replace(/^\/+/g, '');
    const parsedUrl = new URL(urlWithoutLeadingSlash, serverBaseUrl);

    return parsedUrl.toString();
}

function normalizeUserDataFromAPI(userData) {
    const { avatar, orgName, mfaEnabled, phone } = userData;

    return {
        ...pick(userData, [
            'username',
            'apiKey',
            'role',
            'id',
            'firstName',
            'lastName',
            'language',
            'isEmailVerified',
            'isPhoneVerified',
            'matrixPassword',
            'subscriptionConfigs',
            'preferredNotificationMediumIds',
            'hasAutoLogout',
            'hasPasswordExpiry',
            // TODO: Below fields are deprecate. Remove them after adjusting the UI in PDash.
            'ageId',
            'genderId',
            'educationId',
            'maritalStatusId',
            'occupationId',
            'salaryId',
        ]),
        isMFAEnabled: mfaEnabled,
        phoneNumber: phone,
        avatar: avatar ? getAvatarUrl(avatar) : '',
        organization: orgName,
    };
}

function persistUserData(userData) {
    if (window.localStorage) {
        localStorage.setItem(
            process.env.REACT_APP_LOCAL_STORAGE_KEY_USER,
            JSON.stringify({
                apiKey: userData.apiKey || userData.accessToken,
            })
        );
    }
}

function* handleUserDeviceIdFromSpace(userDataFromSpace) {
    if (appUtils.object.hasKey(userDataFromSpace, 'deviceId')) {
        const { deviceId } = userDataFromSpace;
        yield put(userActionCreators.setDeviceId(deviceId));
    }
}

function* logout(action) {
    const { onSuccess } = action.payload;
    try {
        yield put(userActionCreators.logoutLoading());
        yield call(client.api.userLogout);
        yield put(userActionCreators.reset());
        yield put(userActionCreators.resetInitializationStatus());
        if (window.localStorage) {
            yield call(() => localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_USER));
            yield call(() => localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_AUTH_STORE));
        }
        yield put(userActionCreators.logoutSucceeded());
        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (error) {
        errorHandle.anError(error);
        yield put(userActionCreators.logoutFailed());
    }
}

function* setUserData(userData, shouldPersistUserData, shouldLoadFromAPI, shouldSetInitializationStatus = false) {
    if (shouldPersistUserData) {
        yield call(persistUserData, userData);
    }

    if (userData.language) {
        yield put(i18nStore.i18nDuck.duckActionCreators.changeLanguage(userData.language));
    }

    yield put(userActionCreators.setUserData(userData));

    if (shouldLoadFromAPI) {
        yield call(loadUserDataFromAPI, { payload: { shouldSetInitializationStatus } });
    } else if (shouldSetInitializationStatus) {
        yield put(userActionCreators.initializationSucceeded());
    }
}

function* loadUserDataFromAPI(action = { payload: { shouldPersistUserData: false, shouldSetInitializationStatus: false } }) {
    const { shouldSetInitializationStatus, shouldPersistUserData } = action.payload;
    if (shouldSetInitializationStatus) {
        yield put(userActionCreators.initializationStarted());
    }
    const { accessToken } = appClientAuth.useAuthenticationStore.getState();

    if (accessToken) {
        try {
            const userData = yield call(client.api.userRead, accessToken);

            yield put(userActionCreators.setDeviceId(userData.deviceId));

            const normalizedUserData = normalizeUserDataFromAPI(userData);

            yield call(setUserData, normalizedUserData, shouldPersistUserData);

            if (shouldSetInitializationStatus) {
                yield put(userActionCreators.initializationSucceeded());
            }
        } catch (error) {
            errorHandle.anError(error);

            yield call(setUserData, { username: '', password: '' });

            if (shouldSetInitializationStatus) {
                yield put(userActionCreators.initializationFailed());
            }
        }
    }
    if (shouldSetInitializationStatus) {
        yield put(userActionCreators.initializationSucceeded());
    }
}

function* setUserDataExternal(action) {
    const {
        payload: { userData, actionSource, shouldLoadFromAPI, shouldPersistUserData, shouldSetInitializationStatus },
    } = action;
    if (userData) {
        const normalizedUserData = normalizeUserDataFromAPI(userData);
        yield put(userActionCreators.setUserDataExternalActionSource(actionSource));
        yield call(setUserData, normalizedUserData, shouldPersistUserData, shouldLoadFromAPI, shouldSetInitializationStatus);
    }
}

function* askSpaceToLoadUserData() {
    yield put(userActionCreators.askSpaceToLoadUserData());

    const result = yield race({
        success: take(userActions.LOAD_USER_DATA_FROM_SPACE_SUCCEEDED),
        error: take(userActions.LOAD_USER_DATA_FROM_SPACE_FAILED),
    });

    if (result.error) {
        throw new FatalError('An error occurred during askSpaceToSetUserData.');
    } else {
        const {
            success: {
                payload: { userData, shouldLoadFromAPI, shouldPersistUserData },
            },
        } = result;

        if (userData) {
            // There is only one place where the `userData` might include `deviceId` field and that is for web activities where FE is being accessed via Mobile. otherwise the `userData` loaded from spaces only includes user credentials.
            yield call(handleUserDeviceIdFromSpace, userData);
            yield call(setUserData, userData, shouldPersistUserData, shouldLoadFromAPI, true);
        } else {
            yield put(userActionCreators.initializationSucceeded());
        }
    }
}

function* initialize() {
    try {
        yield put(userActionCreators.reset());
        yield call(askSpaceToLoadUserData);
    } catch (error) {
        errorHandle.anError(error);
        if (appUtils.object.hasKey(error, 'status') && error.status < 500) {
            yield put(userActionCreators.initializationSucceeded());
        } else {
            yield put(userActionCreators.initializationFailed());
        }
    }
}

export default function* userSaga() {
    yield take(userActions.INITIALIZE);
    yield takeEvery(userActions.SET_USER_DATA_EXTERNAL, setUserDataExternal);
    yield takeEvery(userActions.LOAD_USER_DATA_FROM_API, loadUserDataFromAPI);
    yield takeEvery(userActions.LOGOUT, logout);
    yield call(initialize);
}
