type Translator = (t: string) => string;

const NOTIFICATION_EVENT_IDS = Object.freeze({
    ON_DEMAND: 0,
    REQUEST_LICENSE_UPGRADE: 1,
    TRIAL_STUDY_WIPE_NOTIF_3_DAYS: 2,
    TRIAL_STUDY_WIPE_NOTIF_1_DAY: 3,
    TRIAL_STUDY_WIPE_NOTIF_DONE: 4,
    TRIAL_STUDY_RM_NOTIF_2_WEEKS: 5,
    TRIAL_STUDY_RM_NOTIF_1_WEEK: 6,
    TRIAL_STUDY_RM_NOTIF_1_DAY: 7,
    TRIAL_STUDY_RM_NOTIF_DONE: 8,
    STUDY_INVITATION: 9,
    PARTICIPANT_JOINED: 10,
    EXPORT_READY: 11,
    SESSION_RELEASED: 12,
    SESSION_COMPLETED: 13,
    SESSION_CANCELED: 14,
    SESSION_EXPIRED: 15,
    ACCOUNT_DELETION_INITIATED: 16,
    ACCOUNT_DELETION_REMINDER: 17,
    ACCOUNT_DELETION_REVOKED: 18,
    ACCOUNT_DELETION_SUCCEEDED: 19,
});

export const NOTIFICATION_EVENT_LABELS = Object.freeze({
    [NOTIFICATION_EVENT_IDS.ON_DEMAND]: (t: Translator) => t('rdash:notification_event_on_demand'),
    [NOTIFICATION_EVENT_IDS.REQUEST_LICENSE_UPGRADE]: (t: Translator) => t('rdash:notification_event_request_license_upgrade'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_WIPE_NOTIF_3_DAYS]: (t: Translator) =>
        t('rdash:notification_event_trial_study_wipe_notif_three_days'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_WIPE_NOTIF_1_DAY]: (t: Translator) =>
        t('rdash:notification_event_trial_study_wipe_notif_one_day'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_WIPE_NOTIF_DONE]: (t: Translator) =>
        t('rdash:notification_event_trial_study_wipe_notif_done'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_RM_NOTIF_2_WEEKS]: (t: Translator) =>
        t('rdash:notification_event_trial_study_remove_notif_two_weeks'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_RM_NOTIF_1_WEEK]: (t: Translator) =>
        t('rdash:notification_event_trial_study_remove_notif_one_week'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_RM_NOTIF_1_DAY]: (t: Translator) =>
        t('rdash:notification_event_trial_study_remove_notif_one_day'),
    [NOTIFICATION_EVENT_IDS.TRIAL_STUDY_RM_NOTIF_DONE]: (t: Translator) =>
        t('rdash:notification_event_trial_study_remove_notif_done'),
    [NOTIFICATION_EVENT_IDS.STUDY_INVITATION]: (t: Translator) => t('rdash:notification_event_study_invitation'),
    [NOTIFICATION_EVENT_IDS.PARTICIPANT_JOINED]: (t: Translator) => t('rdash:notification_event_participant_joined'),
    [NOTIFICATION_EVENT_IDS.EXPORT_READY]: (t: Translator) => t('rdash:notification_event_export_ready'),
    [NOTIFICATION_EVENT_IDS.SESSION_RELEASED]: (t: Translator) => t('rdash:notification_event_session_released'),
    [NOTIFICATION_EVENT_IDS.SESSION_COMPLETED]: (t: Translator) => t('rdash:notification_event_session_completed'),
    [NOTIFICATION_EVENT_IDS.SESSION_CANCELED]: (t: Translator) => t('rdash:notification_event_session_canceled'),
    [NOTIFICATION_EVENT_IDS.SESSION_EXPIRED]: (t: Translator) => t('rdash:notification_event_session_expired'),
    [NOTIFICATION_EVENT_IDS.ACCOUNT_DELETION_INITIATED]: (t: Translator) =>
        t('rdash:notification_event_account_deletion_initiated'),
    [NOTIFICATION_EVENT_IDS.ACCOUNT_DELETION_REMINDER]: (t: Translator) =>
        t('rdash:notification_event_account_deletion_reminder'),
    [NOTIFICATION_EVENT_IDS.ACCOUNT_DELETION_REVOKED]: (t: Translator) => t('rdash:notification_event_account_deletion_revoked'),
    [NOTIFICATION_EVENT_IDS.ACCOUNT_DELETION_SUCCEEDED]: (t: Translator) =>
        t('rdash:notification_event_account_deletion_succeeded'),
});

export const NOTIFICATION_MEDIUM_IDS = Object.freeze({
    SMS: 0,
    EMAIL: 1,
    IN_APP: 2,
});

export const NOTIFICATION_MEDIUM_IDS_TO_LABELS = Object.freeze({
    [NOTIFICATION_MEDIUM_IDS.SMS]: (t: Translator) => t('pdash:sms'),
    [NOTIFICATION_MEDIUM_IDS.IN_APP]: (t: Translator) => t('pdash:in_app'),
    [NOTIFICATION_MEDIUM_IDS.EMAIL]: (t: Translator) => t('pdash:email'),
});
