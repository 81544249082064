import Bowser from 'bowser';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import jssPluginTemplate from 'jss-plugin-template';
import { darken, fade, lighten } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { jssPreset } from '@material-ui/styles';

const actHealthMainColor = '#7F5E83';

export const theme = createMuiTheme({
    palette: {
        type: 'light',
        background: {
            default: '#fefefe',
            gray: {
                light: '#fcfcfc',
                main: '#f1f1f1',
            },
        },
        primary: {
            light: '#f5f5f5',
            main: '#efefef',
            dark: '#707070',
            contrastText: '#000',
        },
        secondary: {
            light: '#87acff',
            main: '#3f7fe5',
            dark: '#043672',
            contrastText: '#fefefe',
        },
        actHealth: {
            lighter: '#E1D6E4',
            light: lighten(actHealthMainColor, 0.2),
            main: actHealthMainColor,
            dark: darken(actHealthMainColor, 0.2),
            contrastText: '#fefefe',
        },
        text: {
            primary: '#000',
        },
        error: {
            main: '#ff375f',
        },
        success: {
            main: '#30d158',
        },
        info: {
            main: '#0b83ff',
        },
        warning: {
            main: '#ffae42',
        },
        logo: {
            blue: {
                light: '#87acff',
                main: '#3f7fe5',
                dark: '#0052b5',
            },
            green: {
                light: '#b5fff8',
                main: '#6ED5C6',
                dark: '#50a595',
            },
            purple: {
                light: '#b0bdff',
                main: '#7891F3',
                dark: '#474EB8',
            },
            yellow: {
                light: '#ffe888',
                main: '#F4B963',
                dark: '#b6862a',
            },
        },
        white: '#ffffff',
        warmPurple: '#9d2d73',
    },
    typography: {
        htmlFontSize: 14,
        fontSize: 14,
        fontFamily: [
            '"Open Sans"',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h3: {
            lineHeight: 1.4,
            letterSpacing: -0.5,
        },
        h4: {
            lineHeight: 1.4,
            letterSpacing: 0.25,
        },
        h6: {
            fontWeight: '600',
        },
        body1: {
            letterSpacing: '0.018em',
            lineHeight: 1.9,
        },
        body2: {
            letterSpacing: '0.018em',
        },
        subtitle1: {
            fontWeight: 600,
            fontSize: '1.14rem',
            lineHeight: 1.9,
        },
        subtitle2: {
            fontWeight: 600,
            lineHeight: 1.429,
            letterSpacing: '0.0072em',
        },
        button: {
            fontSize: '1rem',
            lineHeight: 1.3575,
            letterSpacing: '0.0536em',
            fontWeight: 600,
        },
        caption: {
            letterSpacing: '.4px',
        },
    },
    platform: Bowser.getParser(window.navigator.userAgent).getPlatformType(true),
});

theme.props = {
    MuiSlider: {
        valueLabelFormat: (value) => (Number.isNaN(Number(value)) ? '' : value),
    },
};

theme.overrides = {
    MuiButton: {
        root: {
            '&.containedACTColor': {
                backgroundColor: theme.palette.actHealth.main,
                color: theme.palette.actHealth.contrastText,
                '&:hover': {
                    backgroundColor: theme.palette.actHealth.light,
                },
            },
            '&.textACTColor': {
                color: theme.palette.actHealth.main,
                '&:hover': {
                    backgroundColor: lighten(actHealthMainColor, 0.7),
                },
            },
        },
    },
    MuiBackdrop: {
        root: {
            backgroundColor: fade(theme.palette.primary.contrastText, 0.85),
        },
    },
    MuiSlider: {
        root: {
            color: theme.palette.primary.dark,
            height: 4,
        },
        thumb: {
            height: 20,
            width: 20,
            border: '2px solid currentColor',
            marginTop: theme.spacing(-1),
            marginLeft: theme.spacing(-1.5),
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },
            '&:after': {
                boxShadow: theme.shadows[1],
                top: 'unset',
                left: 'unset',
                bottom: 'unset',
                right: 'unset',
                width: 20,
                height: 20,
            },
            '$vertical &': {
                marginLeft: theme.spacing(-1),
            },
            '$vertical &$disabled': undefined,
            '&$disabled': undefined,
        },
        valueLabel: {
            fontSize: theme.typography.pxToRem(14),
            left: '-50%',
            fontWeight: 600,
            top: -22,
            '& *': {
                background: 'transparent',
                color: theme.palette.primary.dark,
            },
            '$vertical &': {
                top: 2,
                left: -32,
            },
        },
        track: {
            height: 4,
            borderRadius: 2,
            '$vertical &': {
                width: 4,
            },
        },
        rail: {
            height: 4,
            borderRadius: 2,
            '$vertical &': {
                width: 4,
            },
        },
        vertical: {},
        disabled: {},
    },
    MuiPickersToolbar: {
        toolbar: {
            backgroundColor: theme.palette.logo.blue.main,
        },
        toolbarLandscape: {
            maxWidth: 250,
            width: 250,
        },
    },
    MuiPickersBasePicker: {
        pickerView: {
            '-webkit-overflow-scrolling': 'touch',
        },
    },
    MuiPickersToolbarButton: {},
    MuiPickersToolbarText: {
        toolbarTxt: {
            color: theme.palette.background.default,
        },
        toolbarBtnSelected: {
            color: theme.palette.background.default,
        },
    },
    MuiPickersDay: {
        day: {
            '&:hover': {
                color: theme.palette.background.default,
                backgroundColor: theme.palette.logo.blue.dark,
            },
        },
        daySelected: {
            backgroundColor: theme.palette.logo.blue.main,
            color: theme.palette.background.default,
            '&:hover': {
                willChange: 'background-color',
                backgroundColor: theme.palette.logo.blue.dark,
            },
        },
        current: {
            color: theme.palette.logo.blue.main,
        },
    },
    MuiPickersYear: {
        yearSelected: {
            color: theme.palette.logo.blue.main,
        },
    },
    MuiPickersModal: {
        withAdditionalAction: {
            '& button': {
                color: [theme.palette.logo.blue.main, '!important'],
            },
        },
    },
    MuiPickersClock: {
        clock: {
            backgroundColor: theme.palette.logo.blue.main,
        },
    },
    MuiPickersClockNumber: {
        clockNumber: {
            color: theme.palette.background.default,
        },
    },
    MuiPickersCalendarHeader: {
        transitionContainer: {
            height: 30,
        },
    },
};

theme.shadows.custom = {
    ...theme.shadows.custom,
    1: '0 3px 16px 0 rgba(0, 0, 0, 0.16)',
    2: '0 3px 6px 0 rgba(0, 0, 0, 0.04)',
};

export const jss = create({
    plugins: [jssPluginTemplate(), jssExtend(), ...jssPreset().plugins],
});
