import { ObjectType } from '$app-web/types';

type Func = (...args: Array<unknown>) => void;

class JsToNativeHelper {
    NEEDED_JS_TO_NATIVE_FUNCTION_NAMES = ['onBackPressed', 'onUrlChanged'];
    functionHandlers: Record<string, Func> = {};

    initialize() {
        this.registerFunctionsToWindow();
    }

    registerFunctionsToWindow() {
        this.NEEDED_JS_TO_NATIVE_FUNCTION_NAMES.forEach((functionName) => {
            (window as unknown as ObjectType)[functionName] = (...args: Array<unknown>) =>
                this.callFunctionHandler(functionName, ...args);
        });
    }

    callFunctionHandler(functionName: string, ...args: Array<unknown>) {
        if (Object.prototype.hasOwnProperty.call(this.functionHandlers, functionName)) {
            this.functionHandlers[functionName](...args);
        }
    }

    registerFunctionHandler(functionName: string, func: Func) {
        if (this.NEEDED_JS_TO_NATIVE_FUNCTION_NAMES.indexOf(functionName) >= 0) {
            this.functionHandlers[functionName] = func;
        }
    }
}

const instance = new JsToNativeHelper();
export default instance;
