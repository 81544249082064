import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    secondary: {
        background: '#F0F4F6',
        borderRadius: '5px',
        padding: theme.spacing(0.5, 1),
    },
    horizontalRoot: {
        minHeight: '120px',
    },
    verticalRoot: {
        width: '100%',
        height: '100%',
    },
    image: {
        height: 80,
        width: 80,
        margin: '0 auto',
        cursor: 'pointer',
    },
    text: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
    },
    smallerText: {
        fontSize: theme.typography.pxToRem(12),
    },
    horizontalRootSecondary: {
        minHeight: '40px',
    },
}));
