export const MATRIX_REASONING_TIMED_OUT_ROUND_BEHAVIOR_ENUM = Object.freeze({
    INCORRECT: 'incorrect',
    NOT_ANSWERED: 'not_answered',
});

export const MATRIX_REASONING_SKIP_ROUND_BEHAVIOR_ENUM = Object.freeze({
    DISABLED: 'disabled',
    INCORRECT: 'incorrect',
    NOT_ANSWERED: 'not_answered',
});

export const MATRIX_REASONING_QUESTION_INCLUSION = Object.freeze({
    MAIN: 'main',
    PRACTICE: 'practice',
    NOT_INCLUDED: 'not_included',
});
