import produce from 'immer';
import { createSelector } from 'reselect';
import { getSelector, namespaceReducerActions } from 'retrack';

import { HTTP_CALL_STATUS } from '../constants';

export const DUCK_NAME = 'data-source-options';

const initialState = {
    data: null,
    status: HTTP_CALL_STATUS.UNKNOWN,
};

export const duckActions = {
    FETCH_DATA_SOURCE_OPTIONS: 'FETCH_DATA_SOURCE_OPTIONS',
    SET_DATA_SOURCE_OPTIONS: 'SET_DATA_SOURCE_OPTIONS',
    SET_DATA_SOURCE_OPTIONS_STATUS: 'SET_DATA_SOURCE_OPTIONS_STATUS',
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case duckActions.SET_DATA_SOURCE_OPTIONS_STATUS:
            draft.status = action.payload.status;
            break;
        case duckActions.SET_DATA_SOURCE_OPTIONS:
            draft.data = action.payload.data;
            break;
        default:
            break;
    }
    return draft;
}, initialState);

namespaceReducerActions(reducer, duckActions);

export default reducer;

export const duckActionCreators = {
    fetchDataSourceOptions: function fetchDataSourceOptions() {
        return {
            type: duckActions.FETCH_DATA_SOURCE_OPTIONS,
        };
    },
    fetchDataSourceOptionsRequested: function fetchDataSourceOptionsRequested() {
        return {
            type: duckActions.SET_DATA_SOURCE_OPTIONS_STATUS,
            payload: {
                status: HTTP_CALL_STATUS.LOADING,
            },
        };
    },
    fetchDataSourceOptionsSucceeded: function fetchDataSourceOptionsSucceeded() {
        return {
            type: duckActions.SET_DATA_SOURCE_OPTIONS_STATUS,
            payload: {
                status: HTTP_CALL_STATUS.SUCCEEDED,
            },
        };
    },
    fetchDataSourceOptionsFailed: function fetchDataSourceOptionsFailed() {
        return {
            type: duckActions.SET_DATA_SOURCE_OPTIONS_STATUS,
            payload: {
                status: HTTP_CALL_STATUS.FAILED,
            },
        };
    },
    setDataSourceOptions: function setDataSourceOptions(data) {
        return {
            type: duckActions.SET_DATA_SOURCE_OPTIONS,
            payload: {
                data,
            },
        };
    },
};

const dataSourceOptionsSelector = getSelector(reducer);

const dataSourceOptionsDataSelector = createSelector(dataSourceOptionsSelector, (state) => state.data);

const dataSourceOptionsLoadingSelector = createSelector(
    dataSourceOptionsSelector,
    (state) => state.status === HTTP_CALL_STATUS.LOADING
);

const dataSourceOptionsSucceededSelector = createSelector(
    dataSourceOptionsSelector,
    (state) => state.status === HTTP_CALL_STATUS.SUCCEEDED
);

const dataSourceOptionsFailedSelector = createSelector(
    dataSourceOptionsSelector,
    (state) => state.status === HTTP_CALL_STATUS.FAILED
);

const dataSourceOptionsInitializedSelector = createSelector(
    dataSourceOptionsSelector,
    (state) => state.status !== HTTP_CALL_STATUS.UNKNOWN
);

export const duckSelectors = {
    dataSourceOptionsSelector,
    dataSourceOptionsDataSelector,
    dataSourceOptionsLoadingSelector,
    dataSourceOptionsSucceededSelector,
    dataSourceOptionsFailedSelector,
    dataSourceOptionsInitializedSelector,
};
