import { memo, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';

import useStyles from './styles';

interface Props {
    className?: string;
    size?: 'small' | 'medium';
}

const Frame = ({ className, size = 'medium', children }: PropsWithChildren<Props>) => {
    const classes = useStyles();

    const stylingClasses = clsx(
        classes.circle,
        classes.outerCircle,
        size === 'medium' ? classes.mediumSize : classes.smallSize,
        className
    );

    return (
        <div data-testid="roundFrame" className={clsx(classes.circle, classes.outerCircle, stylingClasses, className)}>
            <Grid className={clsx(classes.circle, classes.innerCircle)} container justify="center" alignItems="center">
                <Grid item className={classes.contentWrapper}>
                    {children}
                </Grid>
            </Grid>
        </div>
    );
};

export default memo(Frame);
