import { cancel, fork, race, take } from 'redux-saga/effects';

import * as utils from './utils';

const CANCEL_ALL_SAGAS_ACTION_TYPE = utils.createActionName('sagas-manager', 'CANCEL_ALL_SAGAS');

class SagasManger {
    constructor() {
        this.emitChange = null;
        this.sagaMiddleware = null;
        this.store = null;
        this.sagas = {};
    }

    setSagas(sagas) {
        this.sagas = sagas;
    }

    getSagas() {
        return this.sagas;
    }

    setSagaMiddleware(sagaMiddleware) {
        this.sagaMiddleware = sagaMiddleware;
    }

    setStore(store) {
        this.store = store;
    }

    static createCancelableSaga(sagaName, originalSaga, cancelSagaActionType) {
        return function* cancelableSaga() {
            const sagaTask = yield fork(originalSaga());

            yield race([take(CANCEL_ALL_SAGAS_ACTION_TYPE), take(cancelSagaActionType)]);
            yield cancel(sagaTask);
        };
    }

    static cancelSagaActionTypeCreator(sagaName) {
        return utils.createActionName('sagas-manager', `CANCEL_${sagaName}_SAGA`);
    }

    add(sagaName, originalSaga) {
        if (Object.prototype.hasOwnProperty.call(this.sagas, sagaName) && this.sagas[sagaName].started) {
            this.store.dispatch({
                type: this.sagas[sagaName].cancelTaskAction,
            });
        }

        const cancelableSagaActionType = SagasManger.cancelSagaActionTypeCreator(sagaName);
        const cancelAbleSaga = SagasManger.createCancelableSaga(sagaName, () => originalSaga, cancelableSagaActionType);
        this.sagas[sagaName] = {
            originalSaga,
            cancelableSaga: cancelAbleSaga,
            cancelTaskAction: cancelableSagaActionType,
            started: false,
        };

        if (this.emitChange) {
            this.emitChange();
        }
    }

    startSagas() {
        if (this.sagaMiddleware) {
            Object.keys(this.sagas).forEach((sagaName) => {
                if (!this.sagas[sagaName].started) {
                    this.sagaMiddleware.run(this.sagas[sagaName].cancelableSaga);
                    this.sagas[sagaName].started = true;
                }
            });
        }
    }

    cancelSagas() {
        if (this.store) {
            this.store.dispatch({
                type: CANCEL_ALL_SAGAS_ACTION_TYPE,
            });
        }
    }

    setChangeListener(listener) {
        /*
         * TODO
         * Next line is just a fix for app-main store which register the saga, before configure-store register a listener,
         * so we run the listener after being registered. It's an edge case and will occur only for app-main. So, It's
         * safe for now.
         */
        if (!this.emitChange) {
            listener();
        }
        this.emitChange = listener;
    }
}

const instance = new SagasManger();
export default instance;
