/* eslint-disable no-console */
import { ObjectType } from '$app-web/types/helpers';

import { NativeToJavaScriptFunctionHelper } from './native-to-js-function';
import PromiseWithTimeout from './promise-with-timeout';

class NativeToJsFunctionHelperAndroid extends NativeToJavaScriptFunctionHelper {
    back(): Promise<void> {
        super.logBack();

        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.WebActivityRef.back();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    exit(): Promise<void> {
        super.logExit();

        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.WebActivityRef.exit();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    onLoadingCompleted(): Promise<void> {
        super.logOnLoadingCompleted();

        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.WebActivityRef.onLoadingCompleted();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    onLoadingFailed(): Promise<void> {
        super.logOnLoadingFailed();

        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.WebActivityRef.onLoadingFailed();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    getCurrentUserCredentials(): Promise<ObjectType> {
        super.logGetCurrentUserCredentials();

        return PromiseWithTimeout((resolve, reject) => {
            try {
                const data = window.WebActivityRef.getCurrentUserCredentials();
                if (data && typeof data === 'string') {
                    resolve(JSON.parse(data));
                } else {
                    reject(new Error('Returned value not supported.'));
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    getOptions(): Promise<ObjectType> {
        super.logGetOptions();

        return PromiseWithTimeout((resolve, reject) => {
            try {
                const data = window.WebActivityRef.getOptions();
                if (data && typeof data === 'string') {
                    resolve(JSON.parse(data));
                } else {
                    reject(new Error('Returned value not supported.'));
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    getSessionInfo(): Promise<ObjectType> {
        super.logGetSessionInfo();

        return PromiseWithTimeout((resolve, reject) => {
            try {
                const data = window.WebActivityRef.getSessionInfo();
                if (data && typeof data === 'string') {
                    resolve(JSON.parse(data));
                } else {
                    reject(new Error('Returned value not supported.'));
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    shareImage(imageBase64: string): Promise<void> {
        super.logShareImage();

        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.WebActivityRef.shareImage(imageBase64);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default NativeToJsFunctionHelperAndroid;
