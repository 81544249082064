import { memo, ReactElement, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { store as mainAppStore, store as mainStore } from '@edf-pkg/app-main';

import Loading from '$app-web/components/loading';
import {
    QUESTION_TYPES,
    SURVEY_QUESTION_CONTENT_NON_QUESTION_BASED_PLACEHOLDERS as SPECIAL_PLACEHOLDERS,
} from '$app-web/constants';
import { useTranslator } from '$app-web/hooks/use-translator';
import { SuperNumber, SuperObject, URLsManager } from '$app-web/utils';

import { useStore } from '../../store';
import { getValidQuestions, humanizeQuestionAnswer, normalizeQuestionContent } from '../../utils';
import SurveyPage from '.';

function SurveyContainer(): ReactElement {
    const firstName = useSelector(mainStore.userDuck.duckSelectors.firstNameSelector);
    const lastName = useSelector(mainStore.userDuck.duckSelectors.lastNameSelector);
    const fullName = useSelector(mainStore.userDuck.duckSelectors.fullNameSelector);
    const userLanguage = useSelector(mainAppStore.userDuck.duckSelectors.languageSelector);
    const t = useTranslator();
    const history = useHistory();
    const timer = useRef<NodeJS.Timeout | null>(null);
    const setLocation = useStore((store) => store.setLocation);
    const expireSession = useStore((store) => store.expireSession);
    const currentQuestion = useStore((store) => store.currentQuestion);
    const { expiryTimeMs } = useStore((store) => store.session);
    const questions = useStore((store) => store.questions);
    const isSandboxMode = useStore((store) => store.isSandboxMode);
    const captureLocation = useStore((store) => store.captureLocation);
    const activityType = useStore((store) => store.type);
    const externalPlaceholders = useStore((store) => store.placeholders);

    const question = questions.find((question) => SuperObject.isSuperset(question, currentQuestion));

    const loopIterationPlaceholder = useMemo(() => {
        const loopSourceQuestion = questions.find((item) => item.questionId === question?.sectionLoopSourceQid);

        if (loopSourceQuestion != null && currentQuestion.iteration != null) {
            const type = loopSourceQuestion.questionType;
            if (type === QUESTION_TYPES.MULTIPLE_CHOICE) {
                const currentQuestionId = (loopSourceQuestion.answer as Array<string>)[currentQuestion.iteration];
                const matchedAnswer = loopSourceQuestion.answers!.find((item) => `${item.answerId}` === currentQuestionId);
                return matchedAnswer?.answerContent ?? '';
            } else if (type === QUESTION_TYPES.NUMBER) {
                return SuperNumber.getOrdinalNumber(currentQuestion.iteration + 1, userLanguage);
            }
        } else {
            return '';
        }
    }, [currentQuestion.iteration, question?.sectionLoopSourceQid, questions, userLanguage]);

    const internalPlaceholders = questions.reduce(
        (acc, question) => ({ ...acc, [`{{Q${question.questionId}}}`]: humanizeQuestionAnswer(question, t) }),
        {
            [SPECIAL_PLACEHOLDERS.USER_FIRST_NAME]: firstName,
            [SPECIAL_PLACEHOLDERS.USER_LAST_NAME]: lastName,
            [SPECIAL_PLACEHOLDERS.USER_NAME]: fullName,
            [SPECIAL_PLACEHOLDERS.LOOP_VALUE]: loopIterationPlaceholder,
        } as Record<string, string>
    );
    const placeholders = { ...internalPlaceholders, ...externalPlaceholders };

    const translationId = userLanguage.replace('-', '');

    const validQuestions = getValidQuestions(questions);
    const questionContent = question?.questionContentTranslation?.[translationId] ?? question?.questionContent ?? '';
    const normalizedQuestionContent = normalizeQuestionContent(questionContent ?? '', placeholders);
    const isFirstQuestion = SuperObject.isSuperset(validQuestions[0], currentQuestion);
    const isLastQuestion = SuperObject.isSuperset(validQuestions[validQuestions.length - 1], currentQuestion);
    const progressPercent = (validQuestions.indexOf(question!) / (validQuestions.length - 1)) * 100;

    useEffect(() => {
        if (captureLocation && !isSandboxMode && navigator.geolocation != null) {
            navigator.geolocation.getCurrentPosition(({ coords }) => setLocation(coords));
        }
    }, [captureLocation, isSandboxMode, setLocation]);

    // Handle session expiry
    useEffect(() => {
        timer.current = setInterval(async () => {
            if (!isSandboxMode && expiryTimeMs < Date.now()) {
                if (activityType === 'eligibility') {
                    history.replace(URLsManager.to('dashboard:pdash:INELIGIBILITY'));
                } else if (activityType === 'survey') {
                    await expireSession();
                }
            }
        }, 5_000);

        return () => {
            if (timer.current != null) {
                clearInterval(timer.current);
            }
        };
    }, [activityType, expireSession, expiryTimeMs, history, isSandboxMode]);

    if (questions.length === 0 || question == null) {
        return <Loading variant="parent-overlay" loading />;
    } else {
        return (
            <SurveyPage
                question={question}
                content={normalizedQuestionContent}
                isFirstQuestion={isFirstQuestion}
                isLastQuestion={isLastQuestion}
                progressPercent={progressPercent}
                translationId={translationId}
            />
        );
    }
}

export default memo(SurveyContainer);
