import { number, object, string } from 'yup';

import { core as appClientCore } from '@edf-pkg/app-client';

const requestSchema = object().shape({
    session_uuid: string().required(),
    activity_type: string().required(),
});

const responseSchema = object().shape({
    scheduled_time: number().required(),
    expiry_time: number().required(),
    status: object().shape({
        id: number().required(),
        name: string().required(),
        label: string().required(),
    }),
});

export default async function getActivitySessionStatusEndpoint(sessionUUID, activityType) {
    try {
        const params = await requestSchema.validate({
            session_uuid: sessionUUID,
            activity_type: activityType,
        });
        const response = await appClientCore.axiosClientsManager.getClient('api').get('/study/activity/session/status/', {
            params,
        });
        const validatedData = await responseSchema.validate(response.data, {
            stripUnknown: true,
        });
        return {
            validated: validatedData,
            raw: response.data,
        };
    } catch (error) {
        appClientCore.errorMapper(error);
    }
    return null;
}
