import {
    AnchorHTMLAttributes,
    AudioHTMLAttributes,
    DOMElement,
    HTMLAttributes,
    IframeHTMLAttributes,
    ImgHTMLAttributes,
    ReactElement,
} from 'react';
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { isEmpty } from 'lodash-es';

interface Replacer {
    p?: (props: HTMLAttributes<HTMLParagraphElement>) => ReactElement<HTMLParagraphElement>;
    a?: (props: AnchorHTMLAttributes<HTMLAnchorElement>) => ReactElement<HTMLAnchorElement>;
    blockquote?: (props: HTMLAttributes<HTMLQuoteElement>) => ReactElement<HTMLQuoteElement>;
    img?: (props: ImgHTMLAttributes<HTMLImageElement>) => null | ReactElement<HTMLImageElement>;
    audio?: (props: AudioHTMLAttributes<HTMLAudioElement>) => ReactElement<HTMLAudioElement>;
    iframe?: (props: IframeHTMLAttributes<HTMLIFrameElement>) => ReactElement<HTMLIFrameElement>;
}

function parseHTML(html: string, replacer: Replacer) {
    const normalizedHTML = html.replaceAll('\n', '<br />');

    const options: HTMLReactParserOptions = {
        replace: (domNode: {
            name?: keyof Replacer;
            attribs?: HTMLAttributes<HTMLElement>;
            children: Array<DOMElement<HTMLAttributes<HTMLElement>, HTMLElement>>;
        }) => {
            const { name } = domNode;
            const createReactElement = name && replacer[name];
            if (!createReactElement) {
                return undefined;
            }

            const attributes = domNode.attribs || {};
            const children = domNode.children && domToReact(domNode.children, options);
            const props = { ...attributes };
            if (children && !isEmpty(children)) {
                props.children = children;
            }

            return createReactElement(props);
        },
    };

    return parse(normalizedHTML, options);
}

export default parseHTML;
