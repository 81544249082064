import React from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';

import Badge from '$app-web/ui-kit/components/badge';
import Checkbox from '$app-web/ui-kit/components/checkbox';
import Icon from '$app-web/ui-kit/components/icon';
import IconButton from '$app-web/ui-kit/components/icon-button';
import Tooltip from '$app-web/ui-kit/components/tooltip';

import classes from './styles.module.scss';

const Item = (props) => {
    const {
        draggable,
        dragHandleProps,
        multiple,
        onClick,
        option,
        selected,
        button,
        tooltipMaxWidth,
        tooltipSide,
        customTooltipContent,
        customTooltipOpen,
    } = props;

    const optionLabelContent = (
        <>
            {option.statusColor !== undefined && (
                <Badge
                    className={classes.marginRight}
                    color={option.disabled ? 'space-light' : option.statusColor}
                    variant="dot"
                />
            )}
            {option.icon && (
                <Icon
                    size="sm"
                    icon={option.icon}
                    color={option.disabled ? 'gray-200' : 'gray-400'}
                    className={clsx(classes.icon, classes.marginRight)}
                />
            )}
            <span className={classes.label}>{option.label}</span>
            {option.warning && (
                <Icon
                    size="sm"
                    icon={['fas', 'exclamation-triangle']}
                    color={option.disabled ? 'yellow-200' : 'yellow-400'}
                    className={clsx(classes.icon, classes.marginRight)}
                />
            )}
            {option.action !== undefined && (
                <span className={clsx(classes.action, { [classes.notPersistent]: !option.action.persistent })}>
                    <Icon
                        icon={['fas', 'cog']}
                        color={option.disabled ? 'gray-200' : 'gray-500'}
                        onClick={option.action.onClick}
                    />
                </span>
            )}
        </>
    );

    const innerElements = (
        <>
            <div
                className="flex auto-width item items-center nowrap"
                {...(multiple ? { onClick: (event) => event.stopPropagation() } : {})}
            >
                {multiple ? (
                    <Checkbox
                        disabled={option.disabled}
                        checked={selected}
                        indeterminate={selected === 'indeterminate'}
                        label={optionLabelContent}
                        onChange={() => onClick()}
                    />
                ) : (
                    optionLabelContent
                )}
            </div>
            {option.endLabel && <div className={clsx(classes.endLabel, 'flex item xs-auto')}>{option.endLabel}</div>}
            {Boolean(option.badge) && (
                <div className={clsx(classes.badgeWrapper, 'item flex items-center xs-auto nowrap')}>
                    <Badge content={option.badge} color={option.disabled ? 'space-light' : 'red'} />
                </div>
            )}
            {draggable && (
                <div
                    className={classes.dragHandle}
                    {...(option.disabled ? {} : dragHandleProps)}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                >
                    <IconButton
                        disabled={option.disabled}
                        className={classes.icon}
                        icon={['fas', 'grip-lines']}
                        small
                        color="ghost"
                    />
                </div>
            )}
        </>
    );

    const contentElement = (
        <div
            className={clsx(classes.root, 'flex justify-between items-center nowrap', {
                [classes.disabled]: option.disabled,
                [classes.highlightedBackground]: selected === true && !multiple,
                [classes.button]: button,
            })}
            role="button"
            tabIndex={0}
            {...(!option.disabled ? { onClick } : {})}
        >
            {customTooltipContent ? (
                <Tooltip content={customTooltipContent} open={customTooltipOpen}>
                    {innerElements}
                </Tooltip>
            ) : (
                innerElements
            )}
        </div>
    );

    return option.description !== undefined ? (
        <Tooltip content={option.description} side={tooltipSide} maxWidth={tooltipMaxWidth}>
            {contentElement}
        </Tooltip>
    ) : (
        contentElement
    );
};

Item.defaultProps = {
    multiple: false,
    selected: false,
    button: false,
    tooltipSide: 'top',
    customTooltipContent: null,
    customTooltipOpen: false,
    draggable: false,
    dragHandleProps: undefined,
};

Item.propTypes = {
    onClick: PropTypes.func.isRequired,
    option: PropTypes.shape({
        label: PropTypes.node.isRequired,
        statusColor: PropTypes.string,
        description: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        disabled: PropTypes.bool,
        endLabel: PropTypes.string,
        icon: PropTypes.arrayOf(PropTypes.string),
        action: PropTypes.shape({
            onClick: PropTypes.func,
            persistent: PropTypes.bool,
        }),
        warning: PropTypes.bool,
    }).isRequired,
    multiple: PropTypes.bool,
    selected: PropTypes.oneOf([false, 'indeterminate', true]),
    button: PropTypes.bool,
    tooltipMaxWidth: PropTypes.number,
    tooltipSide: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
    customTooltipContent: PropTypes.string,
    customTooltipOpen: PropTypes.bool,
    draggable: PropTypes.bool,
    dragHandleProps: PropTypes.object,
};

export default Item;
