import { EDFError, FatalError } from '@edf-pkg/app-error';

import { SuperObject } from '../object';

type StringObject = Record<string, string>;

export class ModuleURLsManager {
    spaceId: string;

    moduleId: string;

    subModules: Record<string, { baseUrl: string; URLs: StringObject }> = {};

    URLs: Record<string, StringObject> = {};

    constructor(spaceId: string, moduleId: string) {
        this.spaceId = spaceId;
        this.moduleId = moduleId;
    }

    inModule(): boolean {
        const parsedPath = new URL(window.location.href);
        return parsedPath.pathname.includes(this.moduleId);
    }

    addSubModule(subModuleId: string, baseUrl: string, URLs: Record<string, string>) {
        if (!SuperObject.hasKey(this.subModules, subModuleId)) {
            this.subModules[subModuleId] = { baseUrl, URLs };
        } else {
            throw new FatalError(
                `Trying to sub module which exists.`,
                new EDFError(`Sub module with Id: ${subModuleId} already exists.`)
            );
        }
    }

    setURLs(URLs: Record<string, StringObject>) {
        this.URLs = { ...this.URLs, ...URLs };
    }

    replaceParamsInLink(url: string, params: StringObject) {
        const paramsKeys = Object.keys(params);

        return paramsKeys.reduce((finalURL, key) => finalURL.replace(`:${key}`, params[key]), url);
    }

    to(id: string, params: StringObject = {}, includeModulePath = true, subModuleId = '') {
        if (SuperObject.hasKey(this.subModules, subModuleId) || SuperObject.hasKey(this.URLs, id)) {
            const matchedModule = this.subModules[subModuleId];
            return this.replaceParamsInLink(
                `/${this.spaceId}${includeModulePath ? `/${this.moduleId}` : ''}${
                    matchedModule != null ? `/${matchedModule.baseUrl}` : ''
                }${matchedModule != null ? matchedModule.URLs[id] : this.URLs[id]}`,
                params
            );
        }
        throw new FatalError(`Trying to get url to not existing path. Path ${id} doesn't exist.`);
    }
}
