import { BaseError } from 'make-error-cause';

export default class EDFError extends BaseError {
    constructor(message, code, cause) {
        super(message, cause);
        this.name = this.constructor.name;
        this.code = code || 'EDF:Error';
    }

    toJSON() {
        return { name: this.name, message: this.message, code: this.code };
    }
}
