import { callPromiseWithTimeout } from '$app-web/utils/promise-timeout';

type Callback<T> = (resolve: (value?: T) => void, reject: (reason?: unknown) => void) => void;

export default function promiseWithTimeout<T>(callback: Callback<T>) {
    return callPromiseWithTimeout(
        new Promise((resolve, reject) => {
            callback(resolve, reject);
        }),
        3000
    ) as Promise<T>;
}
