import { useMemo } from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from '@material-ui/core';

import { asActivity } from '@edf-pkg/app-activities';

import Asa24Activity from '$app-web/activities/asa24';
import MatrixReasoningActivity from '$app-web/activities/matrix-reasoning';
import MyProgressActivity from '$app-web/activities/my-progress';
import PageActivity from '$app-web/activities/page';
import ProgressRewardActivity from '$app-web/activities/progress-reward';
import SoProReportChartActivity from '$app-web/activities/sopro-report-chart';
import StepsActivity from '$app-web/activities/steps';
import StroopActivity from '$app-web/activities/stroop';
import SummaryPageActivity from '$app-web/activities/summary-page';
import SurveyActivity from '$app-web/activities/survey';
import TimePrefActivity from '$app-web/activities/time-pref';
import TimeUseActivity from '$app-web/activities/time-use';
import WheelOfFortune from '$app-web/activities/wheel-of-fortune';
import WorkingMemoryUpdating from '$app-web/activities/working-memory-updating';
import Loading from '$app-web/components/loading';
import BigButton from '$app-web/components/web-activities-big-button';
import { URLsManager } from '$app-web/utils';

import styles from './styles';

const Activity = (props) => {
    const history = useHistory();

    const {
        type,
        isActivityLoadDataStatusLoading,
        isActivityLoadDataStatusSucceeded,
        isActivityLoadDataStatusFailed,
        isActivityStatusAnswerable,
        isActivityStatusUpcoming,
        isActivityStatusPast,
        isActivityStatusFinished,
        closingInProgress,
        onClose,
        classes,
        showError,
        showFinishedBack,
    } = props;

    const MatchedActivity = useMemo(() => {
        switch (type) {
            case 'survey':
                return SurveyActivity;
            case 'time-use':
                return TimeUseActivity;
            case 'stroop':
                return StroopActivity;
            case 'matrix-reasoning':
                return MatrixReasoningActivity;
            case 'working-memory-updating':
                return WorkingMemoryUpdating;
            case 'wheel-of-fortune':
                return WheelOfFortune;
            case 'time-pref':
                return TimePrefActivity;
            case 'asa24':
                return Asa24Activity;
            case 'page':
                return PageActivity;
            case 'summary-page':
                return SummaryPageActivity;
            case 'progress-reward':
                return ProgressRewardActivity;
            case 'steps-leaderboard':
            case 'steps-performance':
                return StepsActivity;
            case 'my-progress':
                return MyProgressActivity;
            case 'sopro-report-chart':
                return SoProReportChartActivity;
            default:
                history.replace(URLsManager.to('dashboard:pdash:HOME'));
        }
    }, [history, type]);

    const { t } = useTranslation();
    return (
        <div className={classes.root}>
            <Loading
                variant="parent-overlay"
                loading={isActivityLoadDataStatusLoading || closingInProgress}
                error={showError && isActivityLoadDataStatusFailed}
                showErrorText
                errorText={t('generic_error_msg')}
                errorHasButton
                errorButtonText={t('back')}
                onErrorButtonClick={onClose}
            />
            {(isActivityStatusUpcoming || isActivityStatusPast) && (
                <Dialog open fullScreen>
                    <DialogTitle>
                        {isActivityStatusUpcoming && t('webActivities:activity_upcoming')}
                        {isActivityStatusPast && t('webActivities:activity_past')}
                    </DialogTitle>
                    <DialogContent />
                    {showFinishedBack && (
                        <DialogActions className={classes.dialogActionsWrapper}>
                            <BigButton handleClick={() => onClose()} text={t('back')} />
                        </DialogActions>
                    )}
                </Dialog>
            )}
            {isActivityStatusFinished && (
                <Dialog open fullScreen>
                    <DialogTitle>{t('webActivities:activity_completed')}</DialogTitle>
                    <DialogContent />
                    {showFinishedBack && (
                        <DialogActions className={classes.dialogActionsWrapper}>
                            <BigButton handleClick={() => onClose(true)} text={t('back')} />
                        </DialogActions>
                    )}
                </Dialog>
            )}
            {isActivityLoadDataStatusSucceeded && isActivityStatusAnswerable && (
                <span className={clsx({ [classes.hiddenActivity]: closingInProgress })}>
                    <MatchedActivity {...props} />
                </span>
            )}
        </div>
    );
};

Activity.defaultProps = {
    onClose: () => {},
    ensurePHRSent: false,
    showError: true,
    showFinishedBack: true,
    sessionUUID: undefined,
    surveyVersion: undefined,
    isEligibilitySurvey: false,
    isTimeUse: false,
    sessionInfo: {},
    translationId: 'base',
};

Activity.propTypes = {
    type: PropTypes.string.isRequired,
    isActivityLoadDataStatusLoading: PropTypes.bool.isRequired,
    isActivityLoadDataStatusSucceeded: PropTypes.bool.isRequired,
    isActivityLoadDataStatusFailed: PropTypes.bool.isRequired,
    isActivityStatusAnswerable: PropTypes.bool.isRequired,
    isActivityStatusUpcoming: PropTypes.bool.isRequired,
    isActivityStatusPast: PropTypes.bool.isRequired,
    isActivityStatusFinished: PropTypes.bool.isRequired,
    closingInProgress: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    ensurePHRSent: PropTypes.bool,
    showError: PropTypes.bool,
    showFinishedBack: PropTypes.bool,
    sessionUUID: PropTypes.string,
    surveyVersion: PropTypes.number,
    isEligibilitySurvey: PropTypes.bool,
    isTimeUse: PropTypes.bool,
    translationId: PropTypes.string,
    sessionInfo: PropTypes.object,
    participantRegistrationTime: PropTypes.number,
};

export default asActivity(withStyles(styles)(Activity));
