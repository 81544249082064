import clsx from 'clsx';

import Button from '$app-web/ui-kit/components/button';
import DropdownButton from '$app-web/ui-kit/components/dropdown-button';

import classes from './styles.module.scss';

interface Option {
    value: string | null;
    description: string;
    statusColor: string;
    label: React.ReactNode;
    disabled: boolean;
    customTooltipContent: string;
    customTooltipOpen: boolean;
    persistent: boolean;
}

interface Props {
    direction?: 'row' | 'column';
    className?: string;
    dense?: boolean;
    items?: Array<{
        disabled?: boolean;
        type?: 'button' | 'submit';
        color?: 'default' | 'primary' | 'secondary' | 'ghost' | 'error' | 'warning' | 'error-light' | 'warning-light';
        small?: boolean;
        className?: string;
        onClick?: (event: unknown) => void;
        label?: string | number;
        outlined?: boolean;
        startIcon?: [string, string];
        endIcon?: [string, string];
        loading?: boolean;
        loadingLabel?: string;
        fullWidth?: boolean;
        dropdownAlign?: 'start' | 'center' | 'end';
        optionsTooltipSide?: 'top' | 'bottom' | 'right' | 'left';
        persistent?: boolean;
        selectableGroups?: boolean;
        selectAll?: boolean;
        selectAllLabel?: string;
        icon?: [string, string];
        multiple?: boolean;
        value?: string | Array<string>;
        options?: Array<Option>;
        onChange?: (event: unknown) => void;
        onOptionClick?: (event: unknown) => void;
        onClose?: (event: unknown) => void;
        draggable?: (event: unknown) => void;
        onReorder?: (event: unknown) => void;
        dropdownActions?: Array<{
            closeOnClick: boolean;
            color: string;
            disabled: boolean;
            label: string;
            onClick: (event: unknown) => void;
        }>;
    }>;
}

const ButtonGroup = ({ items = [], direction = 'row', className = '', dense = false }: Props) => {
    return (
        <div
            className={clsx('flex inline items-center', { column: direction === 'column', row: direction === 'row' }, className)}
        >
            {items.map(({ className: itemOuterClassName, options, ...item }, index) => {
                const itemClassName = clsx(itemOuterClassName, {
                    [classes.horizontalMargin]: direction === 'row',
                    [classes.verticalMargin]: direction === 'column',
                    // it's `true` if both of current and next button type is ghost otherwise it's `false`
                    [classes.ghost]: item.color === 'ghost' && items.length > index + 1 && items[index + 1].color === 'ghost',
                    [classes.dense]: dense,
                    [classes.lastItem]: index === items.length - 1,
                });

                return options !== undefined ? (
                    <DropdownButton
                        key={item.label}
                        className={itemClassName}
                        options={options as Array<Option & { label: string }>}
                        {...(item as Record<string, unknown>)}
                    />
                ) : (
                    <Button key={item.label} className={itemClassName} {...(item as Record<string, unknown>)} />
                );
            })}
        </div>
    );
};

export default ButtonGroup;
