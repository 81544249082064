import { fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    green: {
        color: theme.palette.green.contrastText,
        backgroundColor: theme.palette.green.main,
        '&:hover': {
            backgroundColor: theme.palette.green.dark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.green.main,
            },
        },
    },
    round: {
        borderRadius: theme.shape.borderRadius,
    },
    triangleRoot: {
        position: 'relative',
        textAlign: 'center',
        width: 120,
        height: 60,
        color: theme.palette.background.default,
        '&$triangleDisabled': {
            color: fade(theme.palette.primary.contrastText, 0.5),
        },
        '&:hover': {
            '& $triangleImage': {
                fill: theme.palette.primary.dark,
                '@media (hover: none)': {
                    fill: theme.palette.primary.dark,
                },
            },
        },
    },
    triangleImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        fill: theme.palette.primary.main,
        '$triangleDisabled &': {
            fill: fade(theme.palette.primary.main, 0.2),
        },
    },
    triangleImageReverse: {
        transform: 'rotate(180deg)',
    },
    triangleDisabled: {},
    triangleIcon: {
        position: 'relative',
        zIndex: theme.zIndex.mobileStepper,
        fontSize: theme.typography.pxToRem(38),
        width: 38,
        height: 38,
        top: 4,
    },
    triangleIconReverse: {
        top: -6,
    },
}));
