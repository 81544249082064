import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import camelcaseKeys from 'camelcase-keys';
import type { MatrixClient } from 'matrix-js-sdk';
import * as matrixSDK from 'matrix-js-sdk';
import { useSelector } from 'react-redux';

import { errorHandle, ErrorType } from '@edf-pkg/app-error';
import { store as appMainStore, utils as appMainUtils } from '@edf-pkg/app-main';
import appUtils from '@edf-pkg/app-utils';

import { MATRIX_CLIENT_STATUS, MATRIX_INTERNAL_CLIENT_STATUS, MATRIX_TIMELINE_PAGINATE_EVENTS_LIMIT } from '../constants';
import { getToken as getFirebaseToken } from './firebase';

const isDevelopmentMode =
    appUtils.env.isDev() || process.env.REACT_APP_RELEASE_FOR === 'staging' || process.env.REACT_APP_RELEASE_FOR === 'local';

const pusherOptions = {
    lang: 'en',
    kind: 'http',
    app_display_name: 'Avicenna Research Web App',
    device_display_name: `${appMainUtils.user.getDeviceId()} - ${window?.navigator?.userAgent || null}`,
    profile_tag: 'web-app',
    app_id: 'com.ethicadata.app.web',
    pushkey: '',
    data: { url: `https://avicennaresearch.${isDevelopmentMode ? 'dev' : 'com'}/_matrix/push/v1/notify` },
    append: false,
};

interface ChatUserData {
    userId: string;
    deviceId: string;
    accessToken: string;
}

interface ReturnValue {
    userData: Partial<ChatUserData>;
    client: MatrixClient | null;
    clientStatus: {
        status: string;
        isUnknown: boolean;
        isLoading: boolean;
        isReady: boolean;
        isFailed: boolean;
    };
    logout: () => void;
}

const useMatrix = (isBaseChatEnabledForAtLeastOneStudy: boolean): ReturnValue => {
    const [clientStatus, setClientStatus] = useState<string>(MATRIX_CLIENT_STATUS.UNKNOWN);
    const [userData, setUserData] = useState<Partial<ChatUserData>>({});

    const clientRef = useRef<MatrixClient | null>(null);
    const clientCreationTimeout = useRef<NodeJS.Timeout | null>(null);
    const isLogoutSelected = useRef(false);
    const chatUserDataRef = useRef<ChatUserData | null>(
        JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_CHAT_USER_DATA || '') || 'null')
    );

    const matrixUsername = useSelector<unknown, string>(appMainStore.userDuck.duckSelectors.matrixUsernameSelector);
    const matrixPassword = useSelector<unknown, string>(appMainStore.userDuck.duckSelectors.matrixPasswordSelector);

    const clearClientCreationTimeout = useCallback((): void => {
        if (clientCreationTimeout.current) {
            clearTimeout(clientCreationTimeout.current);
            clientCreationTimeout.current = null;
        }
    }, []);

    const logout = useCallback((): void => {
        isLogoutSelected.current = true;
        if (clientRef.current !== null) {
            clientRef.current.setPusher({
                ...pusherOptions,
                kind: null,
            });
            clientRef.current.stopClient();
            clientRef.current.logout();
            clientRef.current = null;
            chatUserDataRef.current = null;
            isLogoutSelected.current = false;
            localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_CHAT_USER_DATA || '');
        }
    }, []);

    const createClient = useCallback(async (): Promise<void> => {
        try {
            setClientStatus(MATRIX_CLIENT_STATUS.LOADING);

            const client = matrixSDK.createClient({
                baseUrl: process.env.REACT_APP_LOCAL_CHAT_MATRIX_SERVER_URL || '',
                timelineSupport: true,
                ...(chatUserDataRef.current && {
                    userId: chatUserDataRef.current.userId,
                    deviceId: chatUserDataRef.current.deviceId,
                    accessToken: chatUserDataRef.current.accessToken,
                }),
            });

            client.once('sync', (status: string, previousStatus: string | null) => {
                if (status === MATRIX_INTERNAL_CLIENT_STATUS.PREPARED && previousStatus === null) {
                    setClientStatus(MATRIX_CLIENT_STATUS.READY);
                } else if (status === MATRIX_INTERNAL_CLIENT_STATUS.ERROR && previousStatus === null) {
                    setClientStatus(MATRIX_CLIENT_STATUS.FAILED);
                }
            });

            if (chatUserDataRef.current == null) {
                chatUserDataRef.current = camelcaseKeys(await client.loginWithPassword(matrixUsername, matrixPassword), {
                    deep: true,
                }) as ChatUserData;
                localStorage.setItem(
                    process.env.REACT_APP_LOCAL_STORAGE_KEY_CHAT_USER_DATA || '',
                    JSON.stringify(chatUserDataRef.current)
                );
            }

            setUserData(chatUserDataRef.current);

            if ('serviceWorker' in navigator) {
                navigator.serviceWorker
                    .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`, { scope: `${process.env.PUBLIC_URL}/` })
                    .then(async (registration) => {
                        Notification.requestPermission()
                            .then(async (permission) => {
                                if (permission === 'granted') {
                                    const token = await getFirebaseToken(registration);
                                    if (token) {
                                        await client.setPusher({
                                            ...pusherOptions,
                                            pushkey: token,
                                        });
                                    }
                                }
                            })
                            .catch(() => {
                                // TODO: Handle the error.
                            });
                    })
                    .catch(() => {
                        // TODO: Handle the error.
                    });
            }

            await client.startClient({
                initialSyncLimit: MATRIX_TIMELINE_PAGINATE_EVENTS_LIMIT,
                lazyLoadMembers: true,
            });

            clientRef.current = client;
            clearClientCreationTimeout();
        } catch (error) {
            errorHandle.anError(error as ErrorType);
            setClientStatus(MATRIX_CLIENT_STATUS.FAILED);
            clientCreationTimeout.current = setTimeout(createClient, 60000);
        }
        isLogoutSelected.current && logout();
    }, [clearClientCreationTimeout, matrixPassword, matrixUsername, logout]);

    const stopClient = useCallback((): void => {
        if (clientRef.current !== null) {
            clientRef.current.stopClient();
            clientRef.current = null;
        }
    }, []);

    useEffect(() => {
        if (chatUserDataRef.current?.userId !== matrixUsername) {
            chatUserDataRef.current = null;
            localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_CHAT_USER_DATA || '');
        }
        if (
            isBaseChatEnabledForAtLeastOneStudy &&
            matrixUsername !== '' &&
            matrixPassword !== '' &&
            clientStatus === MATRIX_CLIENT_STATUS.UNKNOWN
        ) {
            createClient();
        }
    }, [clientStatus, createClient, matrixPassword, matrixUsername, isBaseChatEnabledForAtLeastOneStudy]);

    useEffect(() => {
        return () => {
            if (process.env.NODE_ENV !== 'development') {
                stopClient();
                setClientStatus(MATRIX_CLIENT_STATUS.UNKNOWN);
                setUserData({});
                clientRef.current = null;
            }

            clearClientCreationTimeout();
        };
    }, [clearClientCreationTimeout, stopClient]);

    const completeClientStatus = useMemo(
        () => ({
            status: clientStatus,
            isUnknown: clientStatus === MATRIX_CLIENT_STATUS.UNKNOWN,
            isLoading: clientStatus === MATRIX_CLIENT_STATUS.LOADING,
            isReady: clientStatus === MATRIX_CLIENT_STATUS.READY,
            isFailed: clientStatus === MATRIX_CLIENT_STATUS.FAILED,
        }),
        [clientStatus]
    );

    return { userData, client: clientRef.current, clientStatus: completeClientStatus, logout };
};

export default useMatrix;
