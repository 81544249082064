export const QUESTION_TYPES = Object.freeze({
    INFORMATION: 'information',
    SINGLE_CHOICE: 'single_choice',
    MULTIPLE_CHOICE: 'multiple_choice',
    FREE_FORM_TEXT: 'free_form_text',
    NUMBER: 'number',
    MASS: 'mass',
    LENGTH: 'length',
    VAS: 'vas',
    CALENDAR: 'calendar',
    IMAGE: 'image',
    VIDEO: 'video',
    AUDIO: 'audio',
    AUDIO_TEXT: 'audio_text',
    BARCODE: 'barcode',
});

export const QUESTION_TYPES_ID = Object.freeze({
    UNANSWERED: -1,
    SINGLE_CHOICE: 0,
    INFORMATION: 1,
    MULTIPLE_CHOICE: 2,
    FREE_FORM_TEXT: 3,
    NUMBER: 4,
    AUDIO: 5,
    IMAGE: 6,
    VIDEO: 7,
    VAS: 8,
    MASS: 9,
    LENGTH: 10,
    AUDIO_TEXT: 11,
    BARCODE: 12,
    CALENDAR: 13,
});

export const QUESTION_TYPES_ICON = Object.freeze({
    [QUESTION_TYPES.SINGLE_CHOICE]: ['fas', 'dot-circle'],
    [QUESTION_TYPES_ID.SINGLE_CHOICE]: ['fas', 'dot-circle'],
    [QUESTION_TYPES.INFORMATION]: ['fas', 'info'],
    [QUESTION_TYPES_ID.INFORMATION]: ['fas', 'info'],
    [QUESTION_TYPES.MULTIPLE_CHOICE]: ['fas', 'check'],
    [QUESTION_TYPES_ID.MULTIPLE_CHOICE]: ['fas', 'check'],
    [QUESTION_TYPES.FREE_FORM_TEXT]: ['fas', 'text'],
    [QUESTION_TYPES_ID.FREE_FORM_TEXT]: ['fas', 'text'],
    [QUESTION_TYPES.NUMBER]: ['fas', 'sort-circle'],
    [QUESTION_TYPES_ID.NUMBER]: ['fas', 'sort-circle'],
    [QUESTION_TYPES.AUDIO]: ['fas', 'microphone'],
    [QUESTION_TYPES_ID.AUDIO]: ['fas', 'microphone'],
    [QUESTION_TYPES.IMAGE]: ['fas', 'image'],
    [QUESTION_TYPES_ID.IMAGE]: ['fas', 'image'],
    [QUESTION_TYPES.VIDEO]: ['fas', 'video'],
    [QUESTION_TYPES_ID.VIDEO]: ['fas', 'video'],
    [QUESTION_TYPES.VAS]: ['fas', 'sliders-h-square'],
    [QUESTION_TYPES_ID.VAS]: ['fas', 'sliders-h-square'],
    [QUESTION_TYPES.MASS]: ['fas', 'weight'],
    [QUESTION_TYPES_ID.MASS]: ['fas', 'weight'],
    [QUESTION_TYPES.LENGTH]: ['fas', 'ruler'],
    [QUESTION_TYPES_ID.LENGTH]: ['fas', 'ruler'],
    [QUESTION_TYPES.AUDIO_TEXT]: ['fas', 'text'],
    [QUESTION_TYPES_ID.AUDIO_TEXT]: ['fas', 'text'],
    [QUESTION_TYPES.BARCODE]: ['fas', 'barcode'],
    [QUESTION_TYPES_ID.BARCODE]: ['fas', 'barcode'],
    [QUESTION_TYPES.CALENDAR]: ['fas', 'calendar-alt'],
    [QUESTION_TYPES_ID.CALENDAR]: ['fas', 'calendar-alt'],
}) as Record<string, ['fas', string]>;

export const CRITERIA_ACCEPTABLE_QUESTION_TYPES = [
    QUESTION_TYPES.NUMBER,
    QUESTION_TYPES.LENGTH,
    QUESTION_TYPES.MASS,
    QUESTION_TYPES.VAS,
    QUESTION_TYPES.SINGLE_CHOICE,
    QUESTION_TYPES.MULTIPLE_CHOICE,
] as ReadonlyArray<string>;

export const SELECTABLE_QUESTION_TYPES = [QUESTION_TYPES.SINGLE_CHOICE, QUESTION_TYPES.MULTIPLE_CHOICE] as ReadonlyArray<string>;

export const SURVEY_QUESTION_CONTENT_NON_QUESTION_BASED_PLACEHOLDERS = Object.freeze({
    USER_NAME: '{{user_name}}',
    USER_FIRST_NAME: '{{user_fname}}',
    USER_LAST_NAME: '{{user_lname}}',
    LOOP_VALUE: '{{loop_value}}',
});

export const SURVEY_QUESTION_CONTENT_QUESTION_BASED_PLACEHOLDER_REGEX = /{{Q(\d+)(_(\d+))?}}/i;

export const SURVEY_QUESTION_CONTENT_PLACEHOLDER_REGEX = new RegExp(
    `${Object.values(SURVEY_QUESTION_CONTENT_NON_QUESTION_BASED_PLACEHOLDERS).join('|')}|${
        SURVEY_QUESTION_CONTENT_QUESTION_BASED_PLACEHOLDER_REGEX.source
    }`,
    'gi'
);

export const CALENDAR_BASED_QUESTION_SELECTORS = Object.freeze({
    DATE_TIME: 'date_time',
    DATE: 'date',
    TIME: 'time',
});
