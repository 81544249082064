import { memo } from 'react';

import RoundFrame from '$app-web/activities/survey/components/round-frame';
import { FILE_INPUT_ACCEPTED_MIME_TYPES_AND_EXTENSIONS } from '$app-web/constants';
import { useTranslator } from '$app-web/hooks/use-translator';

import MediaBase from '../media-base';

interface Props {
    value?: string | File | null;
    onChange: (value: File | string | null) => void;
}

const TypeAudio = ({ value = null, onChange }: Props) => {
    const t = useTranslator();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const icon = <RoundFrame variant="audio-playing" iconSize="small" />;

    return (
        <MediaBase
            type="audio"
            mediaViewerProps={{ type: 'audio', autoPlay: true, icon }}
            roundFrameProps={value != null ? { variant: 'audio-playing', type: 'icon-text' } : { variant: 'microphone' }}
            viewButtonText={t('webActivities:survey_audio_recorder_play')}
            captureButtonText={t('webActivities:survey_audio_recorder_rec')}
            fileInputProps={{
                accept: FILE_INPUT_ACCEPTED_MIME_TYPES_AND_EXTENSIONS.AUDIO,
                children: t('webActivities:choose_file'),
            }}
            value={value}
            onChange={onChange}
        />
    );
};

export default memo(TypeAudio);
