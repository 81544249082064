import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => {
    const toolbarBackground = theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.background.default;
    return {
        dialogRoot: {},
        dialogRootLandscape: {
            width: 440,
        },
        toolbar: {
            backgroundColor: toolbarBackground,
            color: theme.palette.getContrastText(toolbarBackground),
        },
        input: {
            '&::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator': {
                '-webkit-appearance': 'none',
                display: 'none',
            },
        },
        paper: { minWidth: 310 },
        withAdditionalAction: {
            justifyContent: 'flex-start',

            '& > *:first-child': {
                marginRight: 'auto',
            },
        },
    };
});
