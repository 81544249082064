import appUtils from '@edf-pkg/app-utils';

export default class Language {
    code = '';

    locale = '';

    localName = '';

    englishName = '';

    territory = '';

    timezones = [];

    notLoadedNamespaces = {};

    namespaces = {};

    namespaceHasTranslations = (namespace) => {
        return appUtils.object.hasKey(this.notLoadedNamespaces, namespace);
    };

    getNamespaceTranslations = async (namespace) => {
        if (!appUtils.object.hasKey(this.namespaces, namespace)) {
            if (appUtils.object.hasKey(this.notLoadedNamespaces, namespace)) {
                const { default: namespaceTranslation } = await this.notLoadedNamespaces[namespace];
                this.namespaces[namespace] = namespaceTranslation;
            } else {
                this.namespaces[namespace] = {};
            }
        }
        return this.namespaces[namespace];
    };
}
