import { memo, useCallback, useState } from 'react';

import { useTranslator } from '$app-web/hooks/use-translator';
import { SuperNumber } from '$app-web/utils';

import TypeNumber from '../number';
import classes from './styles.module.scss';

interface Props {
    value: number | null;
    min?: number;
    max?: number;
    step?: number;
    defaultValue?: number;
    defaultUnit?: string;
    prefUnit?: 'kg' | 'lbs';
    onChange: (value: number | null) => void;
}

const TypeMass = ({
    min = 0,
    max = Number.MAX_VALUE,
    step = 1,
    defaultValue = Math.max(0, min),
    prefUnit,
    defaultUnit = 'kg',
    value = null,
    onChange,
}: Props) => {
    const t = useTranslator();
    const [selectedUnit, setSelectedUnit] = useState(prefUnit ?? defaultUnit);
    const switchInKilograms = useCallback(() => setSelectedUnit('kg'), []);
    const switchInPounds = useCallback(() => setSelectedUnit('lbs'), []);

    const pound = value == null ? null : SuperNumber.round(SuperNumber.kilogramToPound(value), 2);
    const minPound = SuperNumber.kilogramToPound(min);
    const maxPound = SuperNumber.kilogramToPound(max);
    const defaultValuePound = SuperNumber.kilogramToPound(defaultValue);

    const handleChangePound = useCallback(
        (poundValue: number | null) => {
            if (poundValue == null) {
                onChange(null);
            } else {
                const valueOnKilogram = SuperNumber.poundToKilogram(poundValue);
                onChange(valueOnKilogram);
            }
        },
        [onChange]
    );

    return (
        <div className={classes.container}>
            <TypeNumber
                key={selectedUnit}
                min={selectedUnit === 'kg' ? min : minPound}
                max={selectedUnit === 'kg' ? max : maxPound}
                step={step}
                value={selectedUnit === 'kg' ? value : pound}
                defaultValue={selectedUnit === 'kg' ? defaultValue : defaultValuePound}
                onChange={selectedUnit === 'kg' ? onChange : handleChangePound}
            />
            <div className={classes.unitsWrapper}>
                <button type="button" onClick={switchInKilograms} disabled={selectedUnit === 'kg'}>
                    {t('webActivities:kg')}
                </button>
                <button type="button" onClick={switchInPounds} disabled={selectedUnit === 'lbs'}>
                    {t('webActivities:lbs')}
                </button>
            </div>
        </div>
    );
};

export default memo(TypeMass);
