import React, { useCallback, useState } from 'react';
import { omit } from 'lodash-es';
import * as PropTypes from 'prop-types';

import Button from '$app-web/ui-kit/components/button';
import IconButton from '$app-web/ui-kit/components/icon-button';
import OptionItems from '$app-web/ui-kit/components/option-items';
import Popover from '$app-web/ui-kit/components/popover';

import classes from './styles.module.scss';

const DropdownButton = (props) => {
    const {
        className,
        icon,
        options,
        onClose,
        onOptionClick,
        multiple,
        value,
        dropdownActions,
        dropdownAlign,
        label,
        loadingLabel,
        outlined,
        loading,
        optionsTooltipMaxWidth,
        optionsTooltipSide,
        persistent,
        selectableGroups,
        selectAll,
        selectAllLabel,
        draggable,
        onChange,
        onReorder,
        ...rest
    } = props;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const currentIcon = isDropdownOpen ? ['fas', 'chevron-up'] : ['fas', 'chevron-down'];

    const dropdownActionsWithAutoClose =
        dropdownActions?.map((dropdownAction) => ({
            ...omit(dropdownAction, ['closeOnClick']),
            ...(dropdownAction.closeOnClick && {
                onClick: () => {
                    dropdownAction.onClick();
                    setIsDropdownOpen(false);
                },
            }),
        })) || undefined;

    const toggleIsDropdownOpen = useCallback(() => setIsDropdownOpen((previous) => !previous), []);

    const closeDropdown = useCallback(() => {
        setIsDropdownOpen(false);
        onClose?.();
    }, [onClose]);

    const handleOptionClick = useCallback(
        (optionItem) => {
            onOptionClick?.(optionItem);
            if (!multiple && !persistent && !optionItem.persistent) {
                closeDropdown();
            }
        },
        [closeDropdown, multiple, onOptionClick, persistent]
    );

    return (
        <div className={classes.root}>
            <Popover
                onInteractOutside={closeDropdown}
                align={dropdownAlign}
                open={isDropdownOpen}
                content={
                    <OptionItems
                        actions={dropdownActionsWithAutoClose}
                        button={!multiple}
                        multiple={multiple}
                        onOptionClick={handleOptionClick}
                        draggable={draggable}
                        selectableGroups={selectableGroups}
                        selectAll={selectAll}
                        selectAllLabel={selectAllLabel}
                        onChange={onChange}
                        onReorder={onReorder}
                        value={value}
                        options={options}
                        optionsTooltipMaxWidth={optionsTooltipMaxWidth}
                        optionsTooltipSide={optionsTooltipSide}
                    />
                }
            >
                <div className={className}>
                    {label ? (
                        <Button
                            {...rest}
                            label={label}
                            loading={loading}
                            loadingLabel={loadingLabel}
                            onClick={toggleIsDropdownOpen}
                            endIcon={currentIcon}
                            outlined={outlined}
                        />
                    ) : (
                        <IconButton
                            {...rest}
                            onClick={toggleIsDropdownOpen}
                            icon={icon !== undefined ? icon : currentIcon}
                            iconProps={{ size: 'md' }}
                        />
                    )}
                </div>
            </Popover>
        </div>
    );
};

export const optionsPropType = PropTypes.arrayOf(
    PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        description: PropTypes.string,
        statusColor: PropTypes.string,
        label: PropTypes.node,
        disabled: PropTypes.bool,
        customTooltipContent: PropTypes.string,
        customTooltipOpen: PropTypes.bool,
        persistent: PropTypes.bool,
    })
);

DropdownButton.defaultProps = {
    icon: undefined,
    multiple: false,
    value: 'option-select-value',
    disabled: false,
    type: 'button',
    color: 'default',
    label: undefined,
    small: false,
    className: undefined,
    outlined: false,
    loading: false,
    loadingLabel: undefined,
    dropdownAlign: 'end',
    optionsTooltipMaxWidth: undefined,
    optionsTooltipSide: 'top',
    persistent: false,
    selectableGroups: false,
    selectAll: false,
    selectAllLabel: undefined,
    onClose: undefined,
    dropdownActions: undefined,
    draggable: false,
    onChange: undefined,
    onOptionClick: undefined,
    onReorder: undefined,
    startIcon: undefined,
};

DropdownButton.propTypes = {
    icon: PropTypes.arrayOf(PropTypes.string),
    startIcon: PropTypes.arrayOf(PropTypes.string),
    multiple: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(['button', 'submit']),
    color: PropTypes.oneOf(['default', 'primary', 'secondary', 'ghost', 'error', 'warning', 'error-light', 'warning-light']),
    small: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string,
    outlined: PropTypes.bool,
    loading: PropTypes.bool,
    loadingLabel: PropTypes.string,
    options: optionsPropType.isRequired,
    onChange: PropTypes.func,
    onOptionClick: PropTypes.func,
    onClose: PropTypes.func,
    draggable: PropTypes.bool,
    onReorder: PropTypes.func,
    dropdownActions: PropTypes.arrayOf(
        PropTypes.shape({
            closeOnClick: PropTypes.bool,
            color: PropTypes.string,
            disabled: PropTypes.bool,
            label: PropTypes.string,
            onClick: PropTypes.func,
        })
    ),
    dropdownAlign: PropTypes.oneOf(['start', 'center', 'end']),
    optionsTooltipMaxWidth: PropTypes.number,
    optionsTooltipSide: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
    persistent: PropTypes.bool,
    selectableGroups: PropTypes.bool,
    selectAll: PropTypes.bool,
    selectAllLabel: PropTypes.string,
};

export default DropdownButton;
