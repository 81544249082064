import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import appUtils from '@edf-pkg/app-utils';

import { activityDuck } from '../../store';

export function useActivity({ activityUUID }) {
    const state = useSelector((reduxState) => reduxState);
    const dispatch = useDispatch();

    return {
        isActivityStatusAnswerable: activityDuck.duckSelectors.isActivityStatusAnswerable(state)(activityUUID),
        isActivityStatusFinished: activityDuck.duckSelectors.isActivityStatusFinished(state)(activityUUID),
        isActivityLoadDataStatusLoading: activityDuck.duckSelectors.isLoadDataStatusLoadingSelector(state)(activityUUID),
        isActivityLoadDataStatusSucceeded: activityDuck.duckSelectors.isLoadDataStatusSucceededSelector(state)(activityUUID),
        isActivityLoadDataStatusFailed: activityDuck.duckSelectors.isLoadDataStatusFailedSelector(state)(activityUUID),
        activityType: activityDuck.duckSelectors.typeSelector(state)(activityUUID),
        activitySessionUUID: activityDuck.duckSelectors.sessionUUIDSelector(state)(activityUUID),
        activitySessionInfo: activityDuck.duckSelectors.sessionInfoSelector(state)(activityUUID),
        activityOptions: activityDuck.duckSelectors.optionsSelector(state)(activityUUID),
        activityOpen: activityDuck.duckSelectors.openSelector(state)(activityUUID),
        closeActivity: (finished = false, canceled = false) =>
            dispatch(activityDuck.duckActionCreators.close(activityUUID, finished, canceled)),
    };
}

const WithActivityHOC = (WrappedComponent) => {
    const WithActivity = (props) => {
        const { activityUUID, ...restProps } = props;

        const {
            isActivityStatusAnswerable,
            isActivityStatusFinished,
            isActivityLoadDataStatusLoading,
            isActivityLoadDataStatusSucceeded,
            isActivityLoadDataStatusFailed,
            activityType,
            activitySessionUUID,
            activitySessionInfo,
            activityOptions,
            closeActivity,
            activityOpen,
        } = useActivity({ activityUUID });

        return (
            <WrappedComponent
                isActivityLoadDataStatusLoading={isActivityLoadDataStatusLoading}
                isActivityLoadDataStatusSucceeded={isActivityLoadDataStatusSucceeded}
                isActivityLoadDataStatusFailed={isActivityLoadDataStatusFailed}
                isActivityStatusAnswerable={isActivityStatusAnswerable}
                isActivityStatusFinished={isActivityStatusFinished}
                activityType={activityType}
                activitySessionUUID={activitySessionUUID}
                activitySessionInfo={activitySessionInfo}
                activityOptions={activityOptions}
                closeActivity={closeActivity}
                activityOpen={activityOpen}
                activityUUID={activityUUID}
                {...restProps}
            />
        );
    };

    WithActivity.displayName = `WithActivity(${appUtils.component.getDisplayName(WrappedComponent)})`;

    WithActivity.propTypes = {
        activityUUID: PropTypes.string.isRequired,
    };

    return hoistNonReactStatics(WithActivity, WrappedComponent);
};

export default WithActivityHOC;
