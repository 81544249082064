export const EDC_STUDY_IDS = [805, 874];
export const DRIEHOEK_STUDY_ID = 4220; // AKA 3HOEK

// ACT HEALTH Study Id: 1209
export const ACT_HEALTH_ACTIVITY_IDS = [
    12588, 12584, 12589, 12590, 12562, 12573, 12587, 12572, 12563, 12564, 12565, 12566, 12567, 12568, 12569, 12570, 12571, 12582,
    12576, 12574, 12575, 12583, 12577, 12578, 12579, 12580, 12581, 12594, 12593,
] as const;

export const STUDY_IDS_WITH_RDASH_SUMMARY_TABLES = [1404, 2315] as const; // GO_EXCAP: 1404, MAAT_GE: 2315

export const ON_PREMISE_INSTANCES = Object.freeze({
    EDEN: 'eden',
    DGC: 'dgc',
    ACT: 'acthealth',
    MNS: 'mns',
});
