import React from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';

import Divider from '$app-web/ui-kit/components/divider';
import Icon from '$app-web/ui-kit/components/icon';
import Tooltip from '$app-web/ui-kit/components/tooltip';

import classes from './styles.module.scss';

const Label = (props) => {
    const { disabled, helperText, htmlFor, label, required, rowMode, type, warning, warningTooltip } = props;

    const isGroupLabel = type === 'group-label';

    return (
        <div
            className={clsx('flex items-center auto-width', classes.root, {
                [classes.bottomMargin]: !rowMode,
                [classes.disabled]: disabled,
                [classes.groupLabel]: isGroupLabel,
            })}
        >
            <label htmlFor={htmlFor} className={classes.label}>
                {label}
            </label>
            {required && <span className={classes.requiredIndicator}>*</span>}
            {helperText != null && (
                <Tooltip content={helperText} disabled={disabled} side="top">
                    <Icon icon={['fas', 'question-circle']} size="sm" color="gray-300" className={classes.labelHelperTextIcon} />
                </Tooltip>
            )}
            {warning && (
                <Tooltip content={warningTooltip} disabled={disabled} side="top">
                    <Icon
                        icon={['fas', 'exclamation-triangle']}
                        size="sm"
                        color="yellow-400"
                        className={classes.labelWarningIcon}
                    />
                </Tooltip>
            )}
            {isGroupLabel && <Divider dense />}
        </div>
    );
};

Label.defaultProps = {
    type: 'default',
    disabled: false,
    helperText: undefined,
    required: false,
    rowMode: false,
    warning: false,
    warningTooltip: undefined,
};

Label.propTypes = {
    type: PropTypes.oneOf(['default', 'group-label']),
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
    htmlFor: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    rowMode: PropTypes.bool,
    warning: PropTypes.bool,
    warningTooltip: PropTypes.string,
};

export default Label;
