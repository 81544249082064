export const FILE_INPUT_MAX_SIZE = 200 * 1024 * 1024; // 200MB

export const CSV_FILE_INPUT_MAX_SIZE = 1024 * 1024; // 1MB

export const FILE_INPUT_ACCEPTED_MIME_TYPES_AND_EXTENSIONS = Object.freeze({
    IMAGE: {
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
        'image/webp': ['.webp'],
    },
    AUDIO: {
        'audio/mpeg': ['.mp3'],
    },
    VIDEO: {
        'video/mp4': ['.mp4'],
    },
    JSON: {
        'application/json': ['.json'],
    },
    CSV: {
        'text/csv': ['.csv'],
        'application/vnd.ms-excel': ['.csv'],
    },
});

// https://en.wikipedia.org/wiki/List_of_file_signatures
// https://mimesniff.spec.whatwg.org/#matching-an-audio-or-video-type-pattern
export const FILE_TYPES_SIGNATURE = Object.freeze({
    'image/jpeg': 'FFD8FF',
    'image/png': '89504E',
    'image/webp': '524946',
    'audio/mpeg': '494433',
});
