import { memo } from 'react';
import clsx from 'clsx';

import classes from './styles.module.scss';

interface Props {
    className?: string;
    dotClassName?: string;
    center?: boolean;
    color?: 'ocean' | 'space';
}

const Loading = ({ className, dotClassName, color, center = false }: Props) => {
    const dotClasses = clsx(classes.dot, dotClassName, classes[color ?? '']);

    return (
        <div className={clsx(classes.wave, className, { [classes.center]: center })}>
            <span className={dotClasses} />
            <span className={dotClasses} />
            <span className={dotClasses} />
        </div>
    );
};

export default memo(Loading);
