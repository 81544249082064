import jsToNativeHelper from './js-to-native-helper';
import nativeToJsHelper from './native-to-js-helper';
import platformHelper from './platform-helper';

class WebActivityHelper {
    initialized = false;

    platformHelper = platformHelper;

    nativeToJsHelper = nativeToJsHelper;

    jsToNativeHelper = jsToNativeHelper;

    initialize() {
        if (!this.initialized) {
            platformHelper.initialize();
            nativeToJsHelper.initialize();
            jsToNativeHelper.initialize();
            this.initialized = true;
        }
    }
}

const instance = new WebActivityHelper();
export default instance;
