import appDateTime from '@edf-pkg/app-date-time';
import * as appMain from '@edf-pkg/app-main';

import { useAuthenticationStore } from '../auth';
import {
    ACCOUNT_LOCKED_TIME_IN_MINUTES,
    AUTHENTICATION_ENDPOINT,
    CHECK_MFA_ENDPOINT,
    EDEN_CONFIRMATION_ENDPOINT,
    FORGET_PASSWORD_ENDPOINT,
    RESET_PASSWORD_ENDPOINT,
    UPDATE_USER_EMAIL_ENDPOINT,
    UPDATE_USER_PHONE_NUMBER_ENDPOINT,
} from '../constants';

export const isUserAuthenticated = (): boolean => {
    const userData = appMain.utils.user.getAuthData();
    const authData = useAuthenticationStore.getState();

    return Boolean(userData.username && userData.username !== 'anonymous' && userData.role && authData?.accessToken);
};

export const isAutoLogoutOccurred = (): boolean => {
    const autoLogoutFlag = localStorage.getItem(process.env.REACT_APP_AUTO_LOGOUT_FLAG_STORAGE_KEY || '');
    if (autoLogoutFlag != null) {
        return JSON.parse(autoLogoutFlag);
    }
    return false;
};

export const setAutoLogoutLocalStorageFlag = (isTrue: boolean): void => {
    if (isTrue) {
        localStorage.setItem(process.env.REACT_APP_AUTO_LOGOUT_FLAG_STORAGE_KEY || '', JSON.stringify(isTrue));
    } else {
        localStorage.removeItem(process.env.REACT_APP_AUTO_LOGOUT_FLAG_STORAGE_KEY || '');
    }
};

export const getAccountLockedDataFromLocalStorage = (): number | null => {
    const timestamp = localStorage.getItem(process.env.REACT_APP_ACCOUNT_LOCKED_STORAGE_KEY || '');
    return timestamp ? parseInt(timestamp, 10) : null;
};

export const setAccountLockedDataInLocalStorage = (timestampMs: number): void => {
    if (timestampMs) {
        localStorage.setItem(process.env.REACT_APP_ACCOUNT_LOCKED_STORAGE_KEY || '', timestampMs.toString());
    }
};

export const removeAccountLockedDataInLocalStorage = (): void => {
    localStorage.removeItem(process.env.REACT_APP_ACCOUNT_LOCKED_STORAGE_KEY || '');
};

export const calculateAccountLockedTimeLeftFromLocalStorage = (): number => {
    const accountLockedTimestamp = getAccountLockedDataFromLocalStorage();
    const nowDateTimeMS = appDateTime().toMillis();
    let minutesLeft = 0;

    if (accountLockedTimestamp) {
        const diffInMS = nowDateTimeMS - accountLockedTimestamp;
        const diffInMinutes = Math.floor(diffInMS / 60000);
        minutesLeft = ACCOUNT_LOCKED_TIME_IN_MINUTES - diffInMinutes;
    } else {
        setAccountLockedDataInLocalStorage(nowDateTimeMS);
        minutesLeft = ACCOUNT_LOCKED_TIME_IN_MINUTES;
    }

    return Math.max(minutesLeft, 0);
};

export const isPublicEndpoint = (endpoint: string | undefined): boolean => {
    if (endpoint) {
        const endpointWithTrimmedSlashes = endpoint.replace(/^\/|\/$/g, '');

        const publicEndpoints = [
            AUTHENTICATION_ENDPOINT,
            FORGET_PASSWORD_ENDPOINT,
            RESET_PASSWORD_ENDPOINT,
            CHECK_MFA_ENDPOINT,
            EDEN_CONFIRMATION_ENDPOINT,
            UPDATE_USER_EMAIL_ENDPOINT,
            UPDATE_USER_PHONE_NUMBER_ENDPOINT,
        ];

        return publicEndpoints.includes(endpointWithTrimmedSlashes);
    }
    return false;
};
