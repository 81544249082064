import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { snackbarDuck } from '../../store';

export default function useSnackbar() {
    const dispatch = useDispatch();

    const showSuccess = useCallback(
        (message) => {
            dispatch(
                snackbarDuck.duckActionCreators.show({
                    variant: 'success',
                    message,
                })
            );
        },
        [dispatch]
    );

    const showInfo = useCallback(
        (message) => {
            dispatch(
                snackbarDuck.duckActionCreators.show({
                    variant: 'info',
                    message,
                })
            );
        },
        [dispatch]
    );

    const showWarning = useCallback(
        (message) => {
            dispatch(
                snackbarDuck.duckActionCreators.show({
                    variant: 'warning',
                    message,
                })
            );
        },
        [dispatch]
    );

    const showError = useCallback(
        (message) => {
            dispatch(
                snackbarDuck.duckActionCreators.show({
                    variant: 'error',
                    message,
                })
            );
        },
        [dispatch]
    );

    return { showSuccess, showInfo, showWarning, showError };
}
