import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash-es';

const getElementStyleValue = (element, style) => parseInt(window.getComputedStyle(element)?.[style] || '0', 10);

const END_OFFSET = 30;

const useChipsTruncate = (props) => {
    const { chipsWrapperRef, enabled = true, endOffset = END_OFFSET, mainContainerRef, total } = props;

    const [isChipsReady, setIsChipsReady] = useState(true);
    const [chipsToShow, setChipsToShow] = useState([]);
    const [hiddenChipsCount, setHiddenChipsCount] = useState(0);

    useEffect(() => {
        if (!enabled || mainContainerRef.current === null || chipsWrapperRef.current === null || isChipsReady) {
            return;
        }

        const newChipsToShow = [];
        const mainContainerWidth = mainContainerRef.current.getBoundingClientRect().width;
        const chipsWrapperMarginLeft = getElementStyleValue(chipsWrapperRef.current, 'margin-left');
        let accumulativeChipsWidth = chipsWrapperMarginLeft;

        [...chipsWrapperRef.current.children].forEach((chipElement) => {
            const chipValue = chipElement.getAttribute('data-value');
            if (!chipValue) {
                return;
            }

            const chipsElementMarginRight = getElementStyleValue(chipElement, 'margin-right');
            const chipElementWidth = chipElement.getBoundingClientRect().width;
            accumulativeChipsWidth += chipsElementMarginRight + chipElementWidth;

            if (mainContainerWidth > accumulativeChipsWidth + endOffset) {
                newChipsToShow.push(chipValue);
            }
        });

        setChipsToShow(newChipsToShow);
        setHiddenChipsCount(total - newChipsToShow.length);
        setIsChipsReady(true);
    }, [isChipsReady, chipsWrapperRef, mainContainerRef, total, enabled, endOffset]);

    const recalculate = useCallback(() => setIsChipsReady(false), []);

    useEffect(() => {
        if (isChipsReady) {
            /*
             * recalculate should only be called if total is changed
             * and isChipsReady is true (no calculation in progress)
             */
            recalculate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recalculate, total]);

    useEffect(() => {
        const mainContainerElement = mainContainerRef.current;
        const observer = new ResizeObserver(debounce(recalculate, 1));
        if (mainContainerElement) {
            observer.observe(mainContainerElement);
        }
        return () => {
            if (mainContainerElement) {
                observer.unobserve(mainContainerElement);
            }
        };
    }, [mainContainerRef, recalculate]);

    return {
        isChipsReady,
        chipsToShow,
        hiddenChipsCount,
    };
};

export default useChipsTruncate;
