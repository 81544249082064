import React from 'react';
import * as PropTypes from 'prop-types';
import * as RUIPopover from '@radix-ui/react-popover';

const Popover = (props) => {
    const { children, content, open, modal, align, side, onInteractOutside, className } = props;

    return (
        <RUIPopover.Root className={className} open={open} modal={modal}>
            <RUIPopover.Trigger asChild>{children}</RUIPopover.Trigger>
            <RUIPopover.Portal>
                <RUIPopover.Content
                    onOpenAutoFocus={(e) => e.preventDefault()}
                    onCloseAutoFocus={(e) => e.preventDefault()}
                    onInteractOutside={onInteractOutside}
                    sideOffset={4}
                    asChild
                    side={side}
                    align={align}
                    onClick={(e) => {
                        // As we're using Portal for our Popover, the following `stopPropagation` call is needed to make it compatible with our `useOutsideClick` hook. It will prevent the Popover to be closed by that hook when clicking on the content inside.
                        e.stopPropagation();
                    }}
                >
                    <div>{content}</div>
                </RUIPopover.Content>
            </RUIPopover.Portal>
        </RUIPopover.Root>
    );
};

Popover.defaultProps = {
    open: undefined,
    modal: false,
    align: 'center',
    side: 'bottom',
    onInteractOutside: undefined,
    className: undefined,
};

Popover.propTypes = {
    children: PropTypes.element.isRequired,
    content: PropTypes.element.isRequired,
    open: PropTypes.bool,
    modal: PropTypes.bool,
    align: PropTypes.oneOf(['start', 'center', 'end']),
    side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    onInteractOutside: PropTypes.func,
    className: PropTypes.string,
};

export default Popover;
