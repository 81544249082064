import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

import { ClientError } from '@edf-pkg/app-error';

import { ObjectType } from '$app-web/types';

import client from '.';

interface Params {
    endpoint: string;
    data: ObjectType;
    pageParam?: unknown;
    cancelable?: boolean;
    extraAxiosConfig?: AxiosRequestConfig;
}

export function callReactQuery(params: Params) {
    const { endpoint, data, pageParam, cancelable = true, extraAxiosConfig = {} } = params;

    if (typeof endpoint !== 'string') {
        throw new ClientError(`Provided endpoint is not valid. It should be string. endpoint: ${endpoint}`);
    }

    if (cancelable) {
        const cancelSource: CancelTokenSource = axios.CancelToken.source();

        return client.callEndpoint(endpoint, data, pageParam, {
            cancelToken: cancelSource.token,
            ...extraAxiosConfig,
        });
    }

    return client.callEndpoint(endpoint, data, undefined, extraAxiosConfig);
}
