import withActivityErrorBoundary from '$app-web/hoces/with-activity-error-boundary';
import loadable, { withTimeoutAndDelay } from '$app-web/utils/lazy-load';

// TODO: Scrolls probably won't work on iOS Safari. If this is a web activity, please make sure you check the next line if necessary.
// TODO: onTouchMove => stopPropagation() is a temporary fix for the scroll issue in ios safari and a better solution must be found
//       Please check other instances of the above TODO whenever you found the solution.
export default withActivityErrorBoundary('time-use')(
    // TODO: Fix the cycle when we moved from redux to hooks.

    loadable(() => withTimeoutAndDelay(() => import('./containers/time-use-activity')), true)
);
