import { core as appClientCore } from '@edf-pkg/app-client';

import { SuperObject } from '$app-web/utils';

export async function quitStudy(studyId: number) {
    try {
        const data = SuperObject.toSnakeCase({ studyId });

        await appClientCore.axiosClientsManager.getClient('api').post('/study/quit/', data);

        return true;
    } catch (error) {
        appClientCore.errorMapper(error as Error);
        return false;
    }
}
