import { ForwardedRef, forwardRef, HTMLProps } from 'react';
import clsx from 'clsx';

import Icon from '$app-web/ui-kit/components/icon';

import classes from './styles.module.scss';

interface Props extends HTMLProps<HTMLButtonElement> {
    icon: string | Array<string> | { iconName: string; prefix: string };
    border?: boolean;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    small?: boolean;
    fab?: boolean;
    top?: boolean;
    right?: boolean;
    bottom?: boolean;
    left?: boolean;
    color?: 'primary' | 'secondary' | 'ghost' | 'formatting' | 'error';
    iconProps?: Record<string, unknown>;
    selected?: boolean;
    variant?: 'default' | 'circular';
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const IconButton = forwardRef((props: Props, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
        border = false,
        disabled = false,
        type = 'button',
        small = false,
        fab = false,
        top = false,
        right = false,
        bottom = false,
        left = false,
        selected = false,
        variant = 'default',
        className,
        color,
        icon,
        onClick,
        iconProps,
        ...restProps
    } = props;

    return (
        <button
            ref={ref}
            // eslint-disable-next-line react/button-has-type
            type={type}
            disabled={disabled}
            className={clsx(
                classes.root,
                {
                    [classes.fab]: fab,
                    [classes.left]: left,
                    [classes.right]: right,
                    [classes.top]: top,
                    [classes.bottom]: bottom,
                    [classes.primary]: color === 'primary',
                    [classes.secondary]: color === 'secondary',
                    [classes.ghost]: color === 'ghost',
                    [classes.error]: color === 'error',
                    [classes.formatting]: color === 'formatting',
                    [classes.selected]: selected,
                    [classes.circular]: variant === 'circular',
                    [classes.border]: border,
                },
                className
            )}
            onClick={(event) => {
                event.stopPropagation();
                onClick?.(event);
            }}
            {...restProps}
        >
            <Icon size={small ? 'md' : 'lg'} icon={icon} {...iconProps} />
        </button>
    );
});

export default IconButton;
