import { core as appClientCore } from '@edf-pkg/app-client';

import { SuperObject } from '$app-web/utils';

interface SoProReportChartDataSlice {
    id: number;
    answerContent: string;
    totalTimeSeconds: number;
    totalValue: number;
}

export interface SoProReportData {
    weekly: Array<SoProReportChartDataSlice>;
    total: Array<SoProReportChartDataSlice>;
    weeklyStartDate: string | null;
}

export async function fetchSoProReportData(activityId: number): Promise<SoProReportData | null> {
    try {
        const response = await appClientCore.axiosClientsManager.getClient('api').get(`v1/sopro/stats/${activityId}/`);

        return SuperObject.toCamelCase(response.data) as SoProReportData;
    } catch {
        return null;
    }
}
