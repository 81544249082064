import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { errorHandle } from '@edf-pkg/app-error';
import useSnackbar from '@edf-pkg/app-main/src/hooks/use-snackbar';

export default function useErrorHandler() {
    const { t } = useTranslation();

    const { showError } = useSnackbar();

    const handleError = useCallback(
        (error) => {
            // We usually define error messages and map them to error codes in each react-query endpoint, this line below is implemented to show the BE error message if there were no error message defined in the frontend.
            const messageText = error?.innerError?.data?.errorExtraInfo ?? error?.innerError?.data?.error ?? error.message;
            const errorMessage = typeof messageText === 'string' && messageText.trim() ? messageText : t('generic_error_msg');

            errorHandle.anError(error);

            showError(errorMessage);
        },
        [showError, t]
    );

    return { handleError };
}
