import { core as appClientCore } from '@edf-pkg/app-client';

import client from '$app-web/core/client';
import useErrorHandler from '$app-web/hooks/use-error-handler';
import type { LastResponse } from '$app-web/types';
import { SuperObject } from '$app-web/utils';

interface Params {
    questions: Array<{ activityId: number; questionId: number }>;
    userId: number;
    atTime?: string;
    shouldCall?: boolean;
    onSuccess?: (response: Array<LastResponse>) => void;
    onError?: (error: Error) => void;
}

interface ReturnValue {
    data: Array<LastResponse>;
    isLoading: boolean;
    isFetching: boolean;
    isSuccess: boolean;
    isIdle: boolean;
    isError: boolean;
    refetch: () => void;
}

export const useFetchLastResponses = ({ userId, atTime, questions, shouldCall, onError, onSuccess }: Params) => {
    const { handleError } = useErrorHandler();

    const data = SuperObject.toSnakeCase({ userId, atTime, questions });

    const callEndpoint = client.registerEndpoint('POST api/fetch-surveys-responses', '/v1/survey/last_response/', {});
    const result = callEndpoint(
        {},
        {
            // TODO: It should be fixed in the future
            // Passed the Axios fetcher as 'queryFn' here, because we need to send an array in the requests's payload,
            // But ReactQuery is altering the payload by converting it to an object,
            // likely due to a React-Query misconfiguration in our project which should be fixed later.
            queryFn: async () => {
                const response = await appClientCore.axiosClientsManager.getClient('api').post('/v1/survey/last_response/', data);
                return response.data ?? [];
            },
            enabled: shouldCall,
            onError: (error) => {
                if (userId && questions.length > 0) {
                    onError?.(error as Error);
                    handleError(error);
                }
            },
            onSuccess: (response) => {
                const normalizedData = SuperObject.toCamelCase(response as Array<LastResponse>) as Array<LastResponse>;
                onSuccess?.(normalizedData);
            },
        }
    ) as ReturnValue;

    return result;
};
