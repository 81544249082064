import client from '$app-web/core/client';
import useErrorHandler from '$app-web/hooks/use-error-handler';
import { KeysToSnakeCase } from '$app-web/types';
import { SuperObject } from '$app-web/utils';

interface Response {
    type: string | null;
    items: Array<{
        value: string;
        raw: {
            street: string | null;
            city: string | null;
            name: string | null;
            postalCode: string | null;
        };
    }>;
}

export const useReadAutoCompleteSetOptions = ({
    setId,
    query,
    isEnabled,
    onSuccess,
}: {
    setId: string;
    query: string;
    isEnabled?: boolean;
    onSuccess: (response: Response) => void;
}) => {
    const { handleError } = useErrorHandler();

    const fetchOptions = client.registerEndpoint(
        'GET api/read-autocomplete-set-options',
        `v1/survey/autocomplete_sets/${setId}/`,
        {}
    );

    const { data, isLoading, isSuccess, isError, isIdle } = fetchOptions(
        {
            q: query,
        },
        {
            enabled: query !== '' && isEnabled,
            cacheTime: 0,
            staleTime: Infinity,
            onError: (error) => handleError(error),
            onSuccess: (response) => {
                onSuccess(SuperObject.toCamelCase(response as KeysToSnakeCase<Response>) as Response);
            },
        }
    );

    return { data, isLoading, isSuccess, isError, isIdle };
};
