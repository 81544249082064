/* eslint-disable import/no-extraneous-dependencies */
import { faApple, faGooglePlay } from '$common/fontawesome-pro/brands';
import {
    faArrowSquareLeft as faArrowSquareLeftDU,
    faArrowSquareRight as faArrowSquareRightDU,
    faBallot as faBallotDU,
    faBallotCheck as faBallotCheckDU,
    faBarcodeRead as faBarcodeReadDU,
    faBedPulse as faBedPulseDU,
    faBluetooth as faBluetoothDU,
    faBoxOpen as faBoxOpenDU,
    faBrain as faBrainDU,
    faCalendarAlt as faCalendarAltDU,
    faCameraAlt as faCameraAltDU,
    faCircle as faCircleDU,
    faClock as faClockDU,
    faCog as faCogDU,
    faDatabase as faDatabaseDU,
    faDumbbell as faDumbbellDU,
    faEnvelope as faEnvelopeDU,
    faExclamationTriangle as faExclamationTriangleDU,
    faGraduationCap as faGraduationCapDU,
    faHeadphonesAlt as faHeadphonesAltDU,
    faHeadSideHeart as faHeadSideHeartDU,
    faHeartBroken as faHeartBrokenDU,
    faHourglassStart as faHourglassStartDU,
    faMicrophoneAlt as faMicrophoneAltDU,
    faMinusSquare as faMinusSquareDU,
    faMugTea as faMugTeaDU,
    faPaperclip as faPaperclipDU,
    faPaperPlane as faPaperPlaneDu,
    faPhoneAlt as faPhoneAltDU,
    faPlusSquare as faPlusSquareDU,
    faPuzzlePiece as faPuzzlePieceDU,
    faRepeat as faRepeatDU,
    faTags as faTagsDU,
    faTshirt as faTshirtDU,
    faUserAlt as faUserAltDU,
    faUserChart as faUserChartDU,
    faUserFriends as faUserFriendsDU,
    faVideo as faVideoDU,
    faWavePulse as faWavePulseDU,
    faWeight as faWeightDU,
    faWheat as faWheatDU,
    faWindowRestore as faWindowRestoreDU,
} from '$common/fontawesome-pro/duotone';
import { library } from '$common/fontawesome-pro/fontawesome-svg-core';
import {
    faAnalytics as faAnalyticsLight,
    faAngleDown as faAngleDownLight,
    faAngleLeft as faAngleLeftLight,
    faAngleRight as faAngleRightLight,
    faAngleUp as faAngleUpLight,
    faArrowCircleDown as faArrowCircleDownLight,
    faArrowCircleUp as faArrowCircleUpLight,
    faArrowsAlt as faArrowsAltLight,
    faBadgeCheck as faBadgeCheckLight,
    faBallotCheck as faBallotCheckLight,
    faBatteryHalf as faBatteryHalfLight,
    faBed as faBedLight,
    faBedPulse as faBedPulseLight,
    faBell as faBellLight,
    faBicycle as faBicycleLight,
    faBluetooth as faBluetoothLight,
    faBook as faBookLight,
    faBookAlt as faBookAltLight,
    faBooks as faBooksLight,
    faBrain as faBrainLight,
    faCalendarAlt as faCalendarAltLight,
    faCalendarDay as faCalendarDayLight,
    faCalendarEdit as faCalendarEditLight,
    faCar as faCarLight,
    faCheck as faCheckLight,
    faCheckCircle as faCheckCircleLight,
    faCheckDouble as faCheckDoubleLight,
    faChevronLeft as faChevronLeftLight,
    faChevronRight as faChevronRightLight,
    faChevronUp as faChevronUpLight,
    faCircle as faCircleLight,
    faClipboardList as faClipboardListLight,
    faClock as faClockLight,
    faCog as faCogLight,
    faComments as faCommentsLight,
    faCompressArrowsAlt as faCompressArrowsAltLight,
    faCrosshairs as faCrosshairsLight,
    faDatabase as faDatabaseLight,
    faDumbbell as faDumbbellLight,
    faEnvelope as faEnvelopeLight,
    faExclamationCircle as faExclamationCircleLight,
    faExclamationTriangle as faExclamationTriangleLight,
    faExpandArrows as faExpandArrowsLight,
    faEye as faEyeLight,
    faEyeSlash as faEyeSlashLight,
    faFileExport as faFileExportLight,
    faFileInvoice as faFileInvoiceLight,
    faFileSignature as faFileSignatureLight,
    faFolder as faFolderLight,
    faFolderOpen as faFolderOpenLight,
    faGift as faGiftLight,
    faGlobe as faGlobeLight,
    faHashtag as faHashtagLight,
    faHeadSideBrain as faHeadSideBrainLight,
    faHeadSideHeart as faHeadSideHeartLight,
    faHeartbeat as faHeartbeatLight,
    faHistory as faHistoryLight,
    faInfoCircle as faInfoCircleLight,
    faLandmark as faLandmarkLight,
    faLightbulb as faLightbulbLight,
    faLock as faLockLight,
    faLockAlt as faLockAltLight,
    faLockOpen as faLockOpenLight,
    faLongArrowRight as faLongArrowRightLight,
    faLungs as faLungsLight,
    faMagnet as faMagnetLight,
    faMale as faMaleLight,
    faMicrochip as faMicrochipLight,
    faMicrophoneSlash as faMicrophoneSlashLight,
    faMinus as faMinusLight,
    faMinusCircle as faMinusCircleLight,
    faMobile as faMobileLight,
    faPauseCircle as faPauseCircleLight,
    faPencil as faPencilLight,
    faPhone as faPhoneLight,
    faPlayCircle as faPlayCircleLight,
    faPlus as faPlusLight,
    faPlusCircle as faPlusCircleLight,
    faQuestion as faQuestionLight,
    faQuestionCircle as faQuestionCircleLight,
    faRedo as faRedoLight,
    faSearch as faSearchLight,
    faShieldAlt as faShieldAltLight,
    faShoePrints as faShoePrintsLight,
    faSignOutAlt as faSignOutAltLight,
    faSms as faSmsLight,
    faSquare as faSquareLight,
    faSquareFull as faSquareFullLight,
    faStarChristmas as faStarChristmasLight,
    faSun as faSunLight,
    faTable as faTableLight,
    faTachometerAlt as faTachometerAltLight,
    faThumbtack as faThumbtackLight,
    faTimes as faTimesLight,
    faTshirt as faTshirtLight,
    faUniversity as faUniversityLight,
    faUserAlt as faUserAltLight,
    faUserChart as faUserChartLight,
    faUserCheck as faUserCheckLight,
    faUserCircle as faUserCircleLight,
    faUserClock as faUserClockLight,
    faUserEdit as faUserEditLight,
    faUserFriends as faUserFriendsLight,
    faUsers as faUsersLight,
    faUserSlash as faUserSlashLight,
    faUserTimes as faUserTimesLight,
    faVideoSlash as faVideoSlashLight,
    faWalking as faWalkingLight,
    faWavePulse as faWavePulseLight,
    faWeight as faWeightLight,
    faWheat as faWheatLight,
    faWifi as faWifiLight,
    faWindowRestore as faWindowRestoreLight,
} from '$common/fontawesome-pro/light';
import {
    faAlarmClock as faAlarmClockRegular,
    faAngleDown as faAngleDownRegular,
    faAngleUp as faAngleUpRegular,
    faAppleAlt as faAppleAltRegular,
    faArrowAltFromTop as faArrowAltFromTopRegular,
    faArrowSquareUp as faArrowSquareUpRegular,
    faBallotCheck as faBallotCheckRegular,
    faBarcodeRead as faBarcodeReadRegular,
    faBatteryHalf as faBatteryHalfRegular,
    faBed as faBedRegular,
    faBedPulse as faBedPulseRegular,
    faBell as faBellRegular,
    faBiking as faBikingRegular,
    faBluetooth as faBluetoothRegular,
    faBold as faBoldRegular,
    faBrain as faBrainRegular,
    faBullseye as faBullseyeRegular,
    faCalendarAlt as faCalendarAltRegular,
    faCalendarDay as faCalendarDayRegular,
    faCameraHome as faCameraHomeRegular,
    faCheckSquare as faCheckSquareRegular,
    faChevronLeft as faChevronLeftRegular,
    faChevronRight as faChevronRightRegular,
    faClone as faCloneRegular,
    faCommentDots as faCommentDotsRegular,
    faComments as faCommentsRegular,
    faCompass as faCompassRegular,
    faDatabase as faDatabaseRegular,
    faDewpoint as faDewpointRegular,
    faDotCircle as faDotCircleRegular,
    faDrawCircle as faDrawCircleRegular,
    faDumbbell as faDumbbellRegular,
    faEnvelope as faEnvelopeRegular,
    faExclamationCircle as faExclamationCircleRegular,
    faExclamationTriangle as faExclamationTriangleRegular,
    faExternalLink as faExternalLinkRegular,
    faFileExport as faFileExportRegular,
    faFileImport as faFileImportRegular,
    faFileInvoice as faFileInvoiceRegular,
    faFileSignature as faFileSignatureRegular,
    faFilesMedical as faFilesMedicalRegular,
    faFilm as faFilmRegular,
    faFolder as faFolderRegular,
    faFolderOpen as faFolderOpenRegular,
    faGlobe as faGlobeRegular,
    faHeadSideBrain as faHeadSideBrainRegular,
    faHeadSideHeart as faHeadSideHeartRegular,
    faHeartbeat as faHeartbeatRegular,
    faHeartRate as faHeartRateRegular,
    faHeat as faHeatRegular,
    faHistory as faHistoryRegular,
    faImage as faImageRegular,
    faImagePolaroid as faImagePolaroidRegular,
    faInfoCircle as faInfoCircleRegular,
    faInfoSquare as faInfoSquareRegular,
    faItalic as faItalicRegular,
    faLink as faLinkRegular,
    faListOl as faListOlRegular,
    faListUl as faListUlRegular,
    faLocation as faLocationRegular,
    faLungs as faLungsRegular,
    faMagnet as faMagnetRegular,
    faMale as faMaleRegular,
    faMicrophone as faMicrophoneRegular,
    faMinus as faMinusRegular,
    faMobile as faMobileRegular,
    faMoneyBillWave as faMoneyBillWaveRegular,
    faPhoneAlt as faPhoneAltRegular,
    faPlay as faPlayRegular,
    faPlus as faPlusRegular,
    faPollH as faPollHRegular,
    faPortrait as faPortraitRegular,
    faProcedures as faProceduresRegular,
    faQuestionCircle as faQuestionCircleRegular,
    faRedo as faRedoRegular,
    faRemoveFormat as faRemoveFormatRegular,
    faRuler as faRulerRegular,
    faRunning as faRunningRegular,
    faSadTear as faSadTearRegular,
    faSearch as faSearchRegular,
    faSensorOn as faSensorOnRegular,
    faSensorSmoke as faSensorSmokeRegular,
    faSms as faSmsRegular,
    faSortCircle as faSortCircleRegular,
    faSquare as faSquareRegular,
    faStrikethrough as faStrikethroughRegular,
    faSubscript as faSubscriptRegular,
    faSun as faSunRegular,
    faSunCloud as faSunCloudRegular,
    faSuperscript as faSuperscriptRegular,
    faTable as faTableRegular,
    faTabletAndroid as faTabletAndroidRegular,
    faTachometerAlt as faTachometerAltRegular,
    faTachometerFast as faTachometerFastRegular,
    faTasksAlt as faTasksAltRegular,
    faTemperatureHigh as faTemperatureHighRegular,
    faText as faTextRegular,
    faThList as faThListRegular,
    faTransporter as faTransporterRegular,
    faTshirt as faTshirtRegular,
    faUnderline as faUnderlineRegular,
    faUndo as faUndoRegular,
    faUserChart as faUserChartRegular,
    faUserFriends as faUserFriendsRegular,
    faVial as faVialRegular,
    faVideo as faVideoRegular,
    faWalking as faWalkingRegular,
    faWaveform as faWaveformRegular,
    faWaveformPath as faWaveformPathRegular,
    faWavePulse as faWavePulseRegular,
    faWeight as faWeightRegular,
    faWifi as faWifiRegular,
    faWindowRestore as faWindowRestoreRegular,
} from '$common/fontawesome-pro/regular';
import {
    faAlignJustify as faAlignJustifySolid,
    faAngleDown as faAngleDownSolid,
    faAngleUp as faAngleUpSolid,
    faArchive as faArchiveSolid,
    faArrowAltDown as faArrowAltDownSolid,
    faArrowAltFromTop as faArrowAltFromTopSolid,
    faArrowDown as faArrowDownSolid,
    faArrowLeft as faArrowLeftSolid,
    faArrowRight as faArrowRightSolid,
    faArrows as faArrowsSolid,
    faArrowsAlt as faArrowsAltSolid,
    faArrowToBottom as faArrowToBottomSolid,
    faArrowUp as faArrowUpSolid,
    faAsterisk as faAsteriskSolid,
    faAtom as faAtomSolid,
    faBallotCheck as faBallotCheckSolid,
    faBan as faBanSolid,
    faBarcode as faBarcodeSolid,
    faBarcodeRead as faBarcodeReadSolid,
    faBars as faBarsSolid,
    faBatteryFull as faBatteryFullSolid,
    faBatteryHalf as faBatteryHalfSolid,
    faBed as faBedSolid,
    faBedPulse as faBedPulseSolid,
    faBell as faBellSolid,
    faBiking as faBikingSolid,
    faBluetooth as faBluetoothSolid,
    faBook as faBookSolid,
    faBrain as faBrainSolid,
    faBullseye as faBullseyeSolid,
    faCalendar as faCalendarSold,
    faCalendarAlt as faCalendarAltSolid,
    faCalendarCheck as faCalendarCheckSolid,
    faCalendarDay as faCalendarDaySolid,
    faCalendarEdit as faCalendarEditSolid,
    faCalendarWeek as faCalendarWeekSolid,
    faCameraHome as faCameraHomeSolid,
    faCaretRight as faCaretRightSolid,
    faCertificate as faCertificateSolid,
    faCheck as faCheckSolid,
    faCheckCircle as faCheckCircleSolid,
    faCheckSquare as faCheckSquareSolid,
    faChevronDown as faChevronDownSolid,
    faChevronLeft as faChevronLeftSolid,
    faChevronRight as faChevronRightSolid,
    faChevronUp as faChevronUpSolid,
    faCircle as faCircleSolid,
    faClipboardList as faClipboardListSolid,
    faClipboardUser as faClipboardUserSolid,
    faClock as faClockSolid,
    faCodeBranch as faCodeBranchSolid,
    faCog as faCogSolid,
    faCommentAltLines as faCommentAltLinesSolid,
    faComments as faCommentsSolid,
    faCompass as faCompassSolid,
    faCompressAlt as faCompressAltSolid,
    faCompressArrowsAlt as faCompressArrowsAltSolid,
    faCopy as faCopySolid,
    faDatabase as faDatabaseSolid,
    faDewpoint as faDewpointSolid,
    faDoorOpen as faDoorOpenSolid,
    faDotCircle as faDotCircleSolid,
    faDownload as faDownloadSolid,
    faDrawCircle as faDrawCircleSolid,
    faDumbbell as faDumbbellSolid,
    faEllipsisH as faEllipsisHSolid,
    faEllipsisV as faEllipsisVSolid,
    faEnvelope as faEnvelopeSolid,
    faEraser as faEraserSolid,
    faExclamationCircle as faExclamationCircleSolid,
    faExclamationSquare as faExclamationSquareSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
    faExpandAlt as faExpandAltSolid,
    faEye as faEyeSolid,
    faFileCheck as faFileCheckSolid,
    faFileExport as faFileExportSolid,
    faFileImport as faFileImportSolid,
    faFileInvoice as faFileInvoiceSolid,
    faFileSignature as faFileSignatureSolid,
    faFilesMedical as faFilesMedicalSolid,
    faFileUpload as faFileUploadSolid,
    faFilter as faFilterSolid,
    faFolder as faFolderSolid,
    faFolderTree as faFolderTreeSolid,
    faGameBoardAlt as faGameBoardAltSolid,
    faGlobe as faGlobeSolid,
    faGlobeAmericas as faGlobeAmericasSolid,
    faGripLines as faGripLinesSolid,
    faGripVertical as faGripVerticalSolid,
    faHashtag as faHashtagSolid,
    faHeadSideBrain as faHeadSideBrainSolid,
    faHeadSideHeart as faHeadSideHeartSolid,
    faHeartbeat as faHeartbeatSolid,
    faHeartRate as faHeartRateSolid,
    faHeat as faHeatSolid,
    faHistory as faHistorySolid,
    faHourglassHalf as faHourglassHalfSolid,
    faHSquare as faHSquareSolid,
    faHumidity as faHumiditySolid,
    faIcons as faIconsSolid,
    faImage as faImageSolid,
    faImagePolaroid as faImagePolaroidSolid,
    faInfo as faInfoSolid,
    faInfoCircle as faInfoCircleSolid,
    faInfoSquare as faInfoSquareSolid,
    faLink as faLinkSolid,
    faLocation as faLocationSolid,
    faLock as faLockSolid,
    faLockAlt as faLockAltSolid,
    faLungs as faLungsSolid,
    faMagnet as faMagnetSolid,
    faMale as faMaleSolid,
    faMapMarkerAlt as faMapMarkerAltSolid,
    faMicrochip as faMicrochipSolid,
    faMicrophone as faMicrophoneSolid,
    faMinus as faMinusSolid,
    faMinusSquare as faMinusSquareSolid,
    faMobileAlt as faMobileAltSolid,
    faPaperPlane as faPaperPlaneSolid,
    faPen as faPenSolid,
    faPencil as faPencilSolid,
    faPeopleArrows as faPeopleArrowsSolid,
    faPhone as faPhoneSolid,
    faPhoneAlt as faPhoneAltSolid,
    faPhoneSlash as faPhoneSlashSolid,
    faPlus as faPlusSolid,
    faPortrait as faPortraitSolid,
    faProcedures as faProceduresSolid,
    faQrcode as faQrcodeSolid,
    faQuestionCircle as faQuestionCircleSolid,
    faRadar as faRadarSolid,
    faRecordVinyl as faRecordVinylSolid,
    faRedo as faRedoSolid,
    faRedoAlt as faRedoAltSolid,
    faRuler as faRulerSolid,
    faRulerVertical as faRulerVerticalSolid,
    faRunning as faRunningSolid,
    faSadTear as faSadTearSolid,
    faSave as faSaveSolid,
    faSearchPlus as faSearchPlusSolid,
    faSensorOn as faSensorOnSolid,
    faSensorSmoke as faSensorSmokeSolid,
    faSlidersH as faSlidersHSolid,
    faSlidersHSquare as faSlidersHSquareSolid,
    faSms as faSmsSolid,
    faSort as faSortSolid,
    faSortAlt as faSortAltSolid,
    faSortAmountDown as faSortAmountDownSolid,
    faSortAmountDownAlt as faSortAmountDownAltSolid,
    faSortCircle as faSortCircleSolid,
    faSpinner as faSpinnerSolid,
    faSpinnerThird as faSpinnerThirdSolid,
    faSquare as faSquareSolid,
    faSquareFull as faSquareFullSolid,
    faStar as faStarSolid,
    faStarChristmas as faStarChristmasSolid,
    faStopCircle as faStopCircleSolid,
    faSun as faSunSolid,
    faSunCloud as faSunCloudSolid,
    faTable as faTableSolid,
    faTabletAndroid as faTabletAndroidSolid,
    faTabletAndroidAlt as faTabletAndroidAltSolid,
    faTachometerAlt as faTachometerAltSolid,
    faTachometerFast as faTachometerFastSolid,
    faTag as faTagSolid,
    faTasksAlt as faTasksAltSolid,
    faTemperatureHigh as faTemperatureHighSolid,
    faText as faTextSolid,
    faThumbtack as faThumbtackSolid,
    faTimes as faTimesSolid,
    faToolbox as faToolboxSolid,
    faTransporter as faTransporterSolid,
    faTrash as faTrashSolid,
    faTrashAlt as faTrashAltSolid,
    faTriangle as faTriangleSolid,
    faTshirt as faTshirtSolid,
    faUniversity as faUniversitySolid,
    faUnlink as faUnlinkSolid,
    faUpload as faUploadSolid,
    faUser as faUserSolid,
    faUserAlt as faUserAltSolid,
    faUserChart as faUserChartSolid,
    faUserCheck as faUserCheckSolid,
    faUserClock as faUserClockSolid,
    faUserFriends as faUserFriendsSolid,
    faUserLock as faUserLockSolid,
    faUserPlus as faUserPlusSolid,
    faUserTimes as faUserTimesSolid,
    faVial as faVialSolid,
    faVideo as faVideoSolid,
    faVideoSlash as faVideoSlashSolid,
    faVolume as faVolumeSolid,
    faVolumeSlash as faVolumeSlashSolid,
    faWalking as faWalkingSolid,
    faWaveform as faWaveformSolid,
    faWaveformPath as faWaveformPathSolid,
    faWavePulse as faWavePulseSolid,
    faWeight as faWeightSolid,
    faWeightHanging as faWeightHangingSolid,
    faWheat as faWheatSolid,
    faWifi as faWifiSolid,
    faWifi2 as faWifi2Solid,
    faWindowRestore as faWindowRestoreSolid,
} from '$common/fontawesome-pro/solid';

const icons = [
    faPortraitRegular,
    faPortraitSolid,
    faFolderRegular,
    faTachometerAltRegular,
    faFileInvoiceRegular,
    faDatabaseRegular,
    faBellRegular,
    faUserFriendsRegular,
    faCalendarDayRegular,
    faHistoryRegular,
    faCommentsRegular,
    faFileSignatureRegular,
    faFileExportRegular,
    faTableRegular,
    faQuestionCircleRegular,
    faAsteriskSolid,
    faCheckLight,
    faTimesLight,
    faBadgeCheckLight,
    faClockLight,
    faEnvelopeLight,
    faAngleLeftLight,
    faLockAltLight,
    faUserAltLight,
    faLandmarkLight,
    faBookAltLight,
    faUniversityLight,
    faDatabaseLight,
    faInfoCircleLight,
    faHistoryLight,
    faAngleRightLight,
    faBooksLight,
    faGlobeLight,
    faUserEditLight,
    faSignOutAltLight,
    faUserCircleLight,
    faPencilLight,
    faPlayCircleLight,
    faPauseCircleLight,
    faWindowRestoreSolid,
    faBallotCheckSolid,
    faCircleDU,
    faRepeatDU,
    faCommentsSolid,
    faUserSlashLight,
    // TODO: faPlus faGraduationCap added for drawer. Remove when settings redesign.
    faPlusLight,
    // Solid
    faMaleSolid,
    faDatabaseSolid,
    faInfoCircleSolid,
    faHistorySolid,
    faCogSolid,
    faArrowAltDownSolid,
    faUserAltSolid,
    faMapMarkerAltSolid,
    // data sources
    faCogLight,
    faPhoneLight,
    faEnvelopeLight,
    faThumbtackLight,
    faMobileLight,
    faSunLight,
    faLightbulbLight,
    faCrosshairsLight,
    faBicycleLight,
    faQuestionLight,
    faMaleLight,
    faBatteryHalfLight,
    faWifiLight,
    faDumbbellLight,
    faSmsLight,
    faWheatLight,
    faAngleUpLight,
    faAngleDownLight,
    faCommentsLight,
    faCarLight,
    faMagnetLight,
    faArrowsAltLight,
    faHeartbeatLight,
    faMicrochipLight,
    faLungsLight,
    faUsersLight,
    // duotone
    faHeartBrokenDU,
    faHourglassStartDU,
    faMugTeaDU,
    faWindowRestoreDU,
    faBallotCheckDU,
    faEnvelopeDU,
    faExclamationTriangleDU,
    faBoxOpenDU,
    faCalendarAltDU,
    faArrowSquareLeftDU,
    faArrowSquareRightDU,
    faPuzzlePieceDU,
    faPlusSquareDU,
    faMinusSquareDU,
    // rdash
    faRedoSolid,
    faAngleUpSolid,
    faAngleDownSolid,
    faCalendarSold,
    faAngleDownRegular,
    faChevronDownSolid,
    faChevronRightSolid,
    faChevronLeftSolid,
    faAngleUpRegular,
    faChevronUpSolid,
    faEllipsisHSolid,
    faEllipsisVSolid,
    faExclamationSquareSolid,
    faExclamationTriangleSolid,
    faMinusSquareSolid,
    faQuestionCircleLight,
    faSortAltSolid,
    faSortAmountDownAltSolid,
    faSortAmountDownSolid,
    faSquareRegular,
    faTachometerAltSolid,
    faTachometerAltLight,
    faUserFriendsLight,
    faCalendarDayLight,
    faFileExportLight,
    faAnalyticsLight,
    faBookLight,
    faBookSolid,
    faClipboardListLight,
    faClipboardListSolid,
    faUserClockLight,
    faUserClockSolid,
    faCheckCircleSolid,
    faCalendarAltLight,
    faPenSolid,
    faUniversitySolid,
    faClockSolid,
    faSquareFullLight,
    faSquareFullSolid,
    faImageSolid,
    faBallotDU,
    faDatabaseDU,
    faUserFriendsDU,
    faUserPlusSolid,
    faUserLockSolid,
    faRedoLight,
    faSaveSolid,
    faChevronUpLight,
    faSquareLight,
    faCheckSquareSolid,
    faSquareSolid,
    faTrashSolid,
    faTrashAltSolid,
    faSortSolid,
    faPencilSolid,
    faStarSolid,
    faCheckSolid,
    faFileUploadSolid,
    faFileSignatureSolid,
    faCopySolid,
    faArrowsAltSolid,
    faPaperPlaneSolid,
    faLockLight,
    faLockOpenLight,
    faPhoneAltDU,
    faUserChartDU,
    faUserChartSolid,
    faUserChartLight,
    faUserChartRegular,
    faWeightSolid,
    faFileExportSolid,
    faUploadSolid,
    faClipboardUserSolid,
    faHashtagSolid,
    faLinkSolid,
    faQrcodeSolid,
    faRedoAltSolid,
    faTagSolid,
    faRedoSolid,
    faHSquareSolid,
    faCalendarEditSolid,
    faCheckCircleLight,
    faExclamationTriangleLight,
    faEnvelopeSolid,
    faUserFriendsSolid,
    faCalendarWeekSolid,
    faSearchLight,
    faTachometerAltLight,
    faDownloadSolid,
    faArrowToBottomSolid,
    faSensorOnSolid,
    faFileInvoiceSolid,
    faCalendarDaySolid,
    faDotCircleSolid,
    faAlignJustifySolid,
    faInfoSquareSolid,
    faImagePolaroidSolid,
    faMicrophoneSolid,
    faCameraHomeSolid,
    faSlidersHSquareSolid,
    faSortCircleSolid,
    faWeightHangingSolid,
    faRulerVerticalSolid,
    faBarcodeReadSolid,
    faCalendarAltSolid,
    faUserTimesLight,
    faUserTimesSolid,
    faPeopleArrowsSolid,
    faMobileAltSolid,
    faArrowAltFromTopSolid,
    faAtomSolid,
    faBatteryFullSolid,
    faCompassSolid,
    faCompressArrowsAltSolid,
    faHumiditySolid,
    faLocationSolid,
    faMagnetSolid,
    faPhoneAltSolid,
    faRadarSolid,
    faRunningSolid,
    faSmsSolid,
    faSpinnerThirdSolid,
    faSunSolid,
    faTabletAndroidAltSolid,
    faTachometerFastSolid,
    faTemperatureHighSolid,
    faWalkingSolid,
    faWalkingLight,
    faWifi2Solid,
    faTriangleSolid,
    faFileInvoiceLight,
    faFileSignatureLight,
    faStarChristmasLight,
    faStarChristmasSolid,
    faStarChristmasSolid,
    faCertificateSolid,
    faExclamationCircleLight,
    faShieldAltLight,
    faUserSolid,
    faLockAltSolid,
    faLockSolid,
    faEyeSlashLight,
    faEyeLight,
    faEyeSolid,
    faWeightRegular,
    faWeightLight,
    faWeightSolid,
    faWeightDU,
    faWindowRestoreRegular,
    faEnvelopeRegular,
    faCommentDotsRegular,
    faMobileRegular,
    faMoneyBillWaveRegular,
    faFileImportRegular,
    faFileImportSolid,
    faGlobeSolid,
    faGlobeAmericasSolid,
    faTagsDU,
    faFilterSolid,
    faBellLight,
    faTableSolid,
    faTableLight,
    faLungsSolid,
    faHeartbeatSolid,
    faWheatSolid,
    faBedSolid,
    faMicrochipSolid,
    faWheatDU,
    faCogDU,
    faBedLight,
    // survey
    faCameraAltDU,
    faMicrophoneAltDU,
    faVideoDU,
    faBarcodeReadDU,
    faClockDU,
    faArrowCircleUpLight,
    faArrowCircleDownLight,
    faCircleSolid,
    faCircleLight,
    faHeadphonesAltDU,
    faWaveformSolid,
    faMinusLight,
    // Stroop
    faLongArrowRightLight,
    faHashtagLight,
    // Auth
    faGraduationCapDU,
    faUserAltDU,
    // activity editor
    faInfoSquareRegular,
    faFolderOpenRegular,
    faGlobeRegular,
    faHeadSideBrainRegular,
    faFolderTreeSolid,
    faAlarmClockRegular,
    faAppleAltRegular,
    faBallotCheckRegular,
    faBarcodeReadRegular,
    faCalendarAltRegular,
    faCameraHomeRegular,
    faCheckSquareRegular,
    faDotCircleRegular,
    faImagePolaroidRegular,
    faMicrophoneRegular,
    faVideoRegular,
    faRulerRegular,
    faSortCircleRegular,
    faTextRegular,
    faThListRegular,
    faQuestionCircleSolid,
    faExclamationTriangleRegular,
    faExclamationCircleRegular,
    faInfoCircleRegular,
    faPlusCircleLight,
    faMinusCircleLight,
    faHourglassHalfSolid,
    faUserCheckLight,
    faUserCheckSolid,
    faGripVerticalSolid,
    faBallotCheckLight,
    faFolderOpenLight,
    faSearchRegular,
    faPollHRegular,
    faSpinnerSolid,
    faFolderLight,
    faFolderSolid,
    faChevronLeftLight,
    faChevronLeftRegular,
    faChevronRightRegular,
    faCloneRegular,
    faChevronRightLight,
    faPlusRegular,
    faMinusRegular,
    faExpandArrowsLight,
    faCompressArrowsAltLight,
    faPlusSolid,
    faMinusSolid,
    faCaretRightSolid,
    faToolboxSolid,
    faCodeBranchSolid,
    faFileCheckSolid,
    faBellSolid,
    faIconsSolid,
    faArrowsSolid,
    faTextSolid,
    faCalendarCheckSolid,
    faCommentAltLinesSolid,
    faGameBoardAltSolid,
    faSlidersHSquareSolid,
    faEraserSolid,
    faUnlinkSolid,
    // Chat
    faPaperPlaneDu,
    faPaperclipDU,
    faArrowDownSolid,
    faPhoneSolid,
    faPhoneSlashSolid,
    faVolumeSolid,
    faVolumeSlashSolid,
    faVideoSolid,
    faVideoSlashSolid,
    faCheckDoubleLight,
    faArrowLeftSolid,
    faArrowRightSolid,
    faShoePrintsLight,
    faMicrophoneSlashLight,
    faVideoSlashLight,
    faCompressAltSolid,
    faExpandAltSolid,
    faExclamationCircleSolid,
    faArchiveSolid,
    // My Progress
    faInfoSolid,
    faArrowUpSolid,
    faBanSolid,
    faTimesSolid,
    faApple,
    faGooglePlay,
    faGiftLight,
    faBoldRegular,
    faImageRegular,
    faItalicRegular,
    faLinkRegular,
    faListOlRegular,
    faListUlRegular,
    faRedoRegular,
    faRemoveFormatRegular,
    faStrikethroughRegular,
    faSubscriptRegular,
    faSuperscriptRegular,
    faUnderlineRegular,
    faUndoRegular,
    faFilmRegular,
    faBarsSolid,
    faGripLinesSolid,
    faEllipsisVSolid,
    faTachometerFastRegular,
    faTemperatureHighRegular,
    faDrawCircleRegular,
    faArrowAltFromTopRegular,
    faSunRegular,
    faMagnetRegular,
    faCompassRegular,
    faHeatRegular,
    faBullseyeRegular,
    faDewpointRegular,
    faLocationRegular,
    faWifiRegular,
    faBatteryHalfRegular,
    faTasksAltRegular,
    faTabletAndroidRegular,
    faRunningRegular,
    faWalkingRegular,
    faSensorOnRegular,
    faPhoneAltRegular,
    faSmsRegular,
    faFilesMedicalRegular,
    faWaveformPathRegular,
    faSensorSmokeRegular,
    faWaveformRegular,
    faHeartbeatRegular,
    faLungsRegular,
    faProceduresRegular,
    faBedRegular,
    faDrawCircleSolid,
    faHeatSolid,
    faBullseyeSolid,
    faDewpointSolid,
    faWifiSolid,
    faBatteryHalfSolid,
    faTasksAltSolid,
    faTabletAndroidSolid,
    faFilesMedicalSolid,
    faWaveformPathSolid,
    faSensorSmokeSolid,
    faProceduresSolid,
    faHeadSideBrainSolid,
    faWindowRestoreLight,
    faHeadSideBrainLight,
    faCalendarEditLight,
    faTransporterRegular,
    faTransporterSolid,
    faSadTearRegular,
    faSadTearSolid,
    faVialRegular,
    faVialSolid,
    faRulerSolid,
    faBarcodeSolid,
    faSearchPlusSolid,
    faPlayRegular,
    faStopCircleSolid,
    faBikingRegular,
    faBikingSolid,
    faHeartRateRegular,
    faHeartRateSolid,
    faSunCloudRegular,
    faSunCloudSolid,
    faSlidersHSolid,
    faMaleRegular,
    faDoorOpenSolid,
    faThumbtackSolid,
    faRecordVinylSolid,
    faExternalLinkRegular,
    faDumbbellSolid,
    faBluetoothSolid,
    faHeadSideHeartSolid,
    faBedPulseSolid,
    faWavePulseSolid,
    faDumbbellRegular,
    faBluetoothRegular,
    faHeadSideHeartRegular,
    faBedPulseRegular,
    faWavePulseRegular,
    faDumbbellLight,
    faBluetoothLight,
    faHeadSideHeartLight,
    faBedPulseLight,
    faWavePulseLight,
    faDumbbellDU,
    faBluetoothDU,
    faHeadSideHeartDU,
    faBedPulseDU,
    faWavePulseDU,
    faArrowSquareUpRegular,
    faBrainLight,
    faBrainSolid,
    faBrainRegular,
    faBrainDU,
    faTshirtDU,
    faTshirtLight,
    faTshirtSolid,
    faTshirtRegular,
];

export function initialize() {
    library.add(...icons);
}

// TODO-MAYBE: This code is dirty. Check it.
export const normalizeIcon = (faIcon) => {
    if (!faIcon) {
        return '';
    }

    // exceptions list

    switch (faIcon) {
        case 'fa-thumb-tack':
            return 'thumbtack';
    }

    let iconName = faIcon;

    // remove `fa-` at the start of the icon name
    if (faIcon.startsWith('fa-')) {
        iconName = iconName.slice(3);
    }

    // removing `-o` at the end of the icon name
    if (faIcon.endsWith('-o')) {
        iconName = iconName.slice(0, iconName.length - 2);
    }

    return iconName;
};
