import { combineTrackReducers, setNamespacingFunction } from 'retrack';

export class DucksManager {
    constructor() {
        this.emitChange = null;
        this.reducers = {};
        setNamespacingFunction((strArr) => `edf/${strArr.join('/')}`);
    }

    setReducers(reducers) {
        this.reducers = reducers;
    }

    getReducers() {
        return { ...this.reducers };
    }

    add(name, reducer) {
        this.reducers = { ...this.reducers, [name]: reducer };
        if (this.emitChange) {
            this.emitChange();
        }
    }

    combine() {
        return combineTrackReducers(this.reducers);
    }

    setChangeListener(listener) {
        if (!this.emitChange) {
            listener();
        }
        this.emitChange = listener;
    }
}

const ducksManager = new DucksManager();
export default ducksManager;
