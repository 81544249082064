import { useCallback, useState } from 'react';

import { contextStore } from '$app-web/utils/context-store';

const useStoredUrl = () => {
    const [urlParams, setUrlParams] = useState({});

    const updateUrlParams = useCallback((url, search) => {
        setUrlParams((previousUrlParams) => ({
            ...previousUrlParams,
            [url]: search,
        }));
    }, []);

    return {
        urlParams,
        updateUrlParams,
    };
};

const [StoredUrlProvider, useStoredUrlContext] = contextStore(useStoredUrl);

export { StoredUrlProvider, useStoredUrlContext };
