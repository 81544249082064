function normalizeArray(array) {
    // will remove first `[` and last `]` and keep number and string array elements
    // for string elements, it'll remove quotations around them
    // BE interprets commas as separators, and there's no case in which strings in array inputs would have commas since arrays of strings are only used to pass a subset of enums (nothing to worry about)
    return array
        .map((item) => {
            const stringifiedItem = JSON.stringify(item);
            if (typeof item === 'string') {
                return stringifiedItem.slice(1, stringifiedItem.length - 1);
            }
            return stringifiedItem;
        })
        .join();
}

function toFormData(data, customFilesName = {}) {
    const customFormDataFilesName = typeof customFilesName === 'object' ? customFilesName : {};
    const formData = new FormData();
    Object.keys(data).forEach((id) => {
        if (typeof data[id] === 'undefined') {
            // skip undefined values
            return;
        }
        if (typeof data[id] === 'object') {
            if (Array.isArray(data[id])) {
                formData.append(id, normalizeArray(data[id]));
            } else if (data[id] instanceof File) {
                formData.append(id, data[id], customFormDataFilesName[id] || data[id].name);
            } else {
                formData.append(id, JSON.stringify(data[id]));
            }
        } else {
            formData.append(id, data[id]);
        }
    });
    return formData;
}

const objectUtils = {
    toFormData,
};

export default objectUtils;
