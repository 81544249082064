import { ReactChild, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { errorHandle } from '@edf-pkg/app-error';

import Button from '$app-web/activities/survey/components/button';
import MediaCapture from '$app-web/activities/survey/components/media-capture';
import FileInput from '$app-web/components/file-input';
import MediaViewer from '$app-web/components/media-viewer';
import { useTranslator } from '$app-web/hooks/use-translator';

import RoundFrameBase from '../round-frame-base';
import classes from './styles.module.scss';

interface Props {
    type: 'image' | 'video' | 'audio';
    mediaViewerProps: Record<string, unknown>;
    roundFrameProps: Record<string, unknown>;
    viewButtonText: string;
    captureButtonText: string;
    fileInputProps: Record<string, unknown> & { children?: ReactChild };
    mediaType?: 'audio' | 'video';
    value: File | string | null;
    onChange: (file: File | string | null) => void;
}

const MediaBase = ({
    type,
    mediaViewerProps,
    roundFrameProps,
    captureButtonText,
    viewButtonText,
    fileInputProps,
    value = null,
    onChange,
}: Props) => {
    const t = useTranslator();

    const [fileUrl, setFileUrl] = useState('');
    const fileUrlRef = useRef<string>('');

    const [viewerOpened, setViewerOpened] = useState(false);
    const [captureOpened, setCaptureOpened] = useState(false);

    const [duration, setDuration] = useState(0);

    useEffect(() => {
        if (fileUrlRef.current !== '') {
            URL.revokeObjectURL(fileUrlRef.current);
        }

        if (typeof value === 'string') {
            setFileUrl(value);
            fileUrlRef.current = '';
        } else if (value instanceof Blob || value! instanceof File) {
            const internalUrl = URL.createObjectURL(value);
            setFileUrl(internalUrl);
            fileUrlRef.current = internalUrl;
        } else {
            setFileUrl('');
            fileUrlRef.current = '';
        }
    }, [value]);

    useLayoutEffect(() => {
        if (type === 'audio' || type === 'video') {
            try {
                const mediaElement = document.createElement(type);
                mediaElement.preload = 'metadata';
                mediaElement.onloadedmetadata = () => {
                    setDuration(mediaElement.duration);
                    mediaElement.remove();
                };
                mediaElement.src = fileUrl;
            } catch (error) {
                errorHandle.anError(error as Error);
            }
        }
    }, [fileUrl, type, value]);

    return (
        <>
            <MediaCapture
                open={captureOpened}
                preload
                onClose={(file) => {
                    onChange(file);
                    setCaptureOpened(false);
                }}
                type={type}
            />
            {value != null ? (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                <MediaViewer
                    open={viewerOpened}
                    url={fileUrl}
                    onCloseClick={() => setViewerOpened(false)}
                    alignCloseStart
                    disableRestoreFocus
                    {...mediaViewerProps}
                />
            ) : null}
            <RoundFrameBase
                roundFrameProps={{ ...roundFrameProps, value: type !== 'image' ? duration : fileUrl }}
                secondSlot={
                    !value && (
                        <FileInput
                            variant="button"
                            onChange={(fileArray) => {
                                onChange(fileArray[0]);
                            }}
                            ButtonComponent={Button}
                            ButtonProps={{ className: classes.button }}
                            showAcceptableProperties={false}
                            {...fileInputProps}
                        >
                            {fileInputProps.children || t('webActivities:survey_image_pick_from_gallery_button')}
                        </FileInput>
                    )
                }
                mainSlotButtonProps={{
                    children: value ? viewButtonText : captureButtonText,
                    onClick: () => {
                        if (value) {
                            setViewerOpened(true);
                        } else {
                            setCaptureOpened(true);
                        }
                    },
                }}
                secondSlotButtonProps={
                    value != null ? { children: t('webActivities:delete'), onClick: () => onChange(null) } : undefined
                }
            />
        </>
    );
};

export default MediaBase;
