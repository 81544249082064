import { call, put, select, takeEvery } from 'redux-saga/effects';

import { errorHandle } from '@edf-pkg/app-error';

import client from '../client';
import {
    duckActionCreators as dataSourceOptionsActionCreators,
    duckActions as dataSourceOptionsActions,
    duckSelectors as dataSourceOptionsSelector,
} from './data-source-options.duck';

export const SAGA_NAME = 'DATA_SOURCE_OPTIONS';

function* handleFetchDataSourceOptions() {
    try {
        const isInitialized = yield select(dataSourceOptionsSelector.dataSourceOptionsInitializedSelector);
        if (!isInitialized) {
            yield put(dataSourceOptionsActionCreators.fetchDataSourceOptionsRequested());
            const data = yield call(client.api.readDataSourceOptions);
            yield put(dataSourceOptionsActionCreators.setDataSourceOptions(data));
            yield put(dataSourceOptionsActionCreators.fetchDataSourceOptionsSucceeded());
        }
    } catch (error) {
        errorHandle.anError(error);
        yield put(dataSourceOptionsActionCreators.fetchDataSourceOptionsFailed());
    }
}

export default function* dataSourceOptionsSaga() {
    yield takeEvery(dataSourceOptionsActions.FETCH_DATA_SOURCE_OPTIONS, handleFetchDataSourceOptions);
}
