export enum MATRIX_CLIENT_STATUS {
    UNKNOWN = 'UNKNOWN',
    LOADING = 'LOADING',
    READY = 'READY',
    FAILED = 'FAILED',
}

export enum MATRIX_INTERNAL_CLIENT_STATUS {
    PREPARED = 'PREPARED',
    SYNCING = 'SYNCING',
    STOPPED = 'STOPPED',
    RECONNECTING = 'RECONNECTING',
    DISCONNECTED = 'DISCONNECTED',
    ERROR = 'ERROR',
    CATCHUP = 'CATCHUP',
}

export enum MATRIX_MEMBERSHIP_TYPES {
    JOIN = 'join',
    LEAVE = 'leave',
    INVITE = 'invite',
}

export enum CHAT_CLIENT_STATUS {
    UNKNOWN = 'UNKNOWN',
    LOADING = 'LOADING',
    READY = 'READY',
    FAILED = 'FAILED',
    CONNECTION_LOST = 'CONNECTION_LOST',
    RECONNECTING = 'RECONNECTING',
}

export const ROOM_ALIAS_REGEX = /s([0-9]+)_([a-z0-9]+)_?([0-9]+)?_?([0-9]+)?/im;

export enum TIMELINE_STATUS {
    UNKNOWN = 'UNKNOWN',
    READY = 'READY',
    FAILED = 'FAILED',
}

export const AVATAR = {
    url: undefined,
    color: undefined,
    label: undefined,
};

// TODO: Remove this after the Chat page is redesigned (use the global getAvatarColor instead of convertToAvatarColor if applicable):
export const AVATAR_COLORS = ['#8FDDD1', '#96A9F2', '#216583', '#293462', '#4591E0', '#AC5768', '#7891F3', '#ECB159'];

export const ROOM = {
    studyId: '',
    id: '',
    type: '',
    direct: false,
    otherMemberId: -1,
    otherMembersLastReadMarker: undefined,
    title: '',
    avatar: {
        ...AVATAR,
    },
    modifiedTimeMs: '',
    unreadMessagesCount: -1,
    timeline: {
        status: TIMELINE_STATUS.UNKNOWN,
        canLoadBackward: false,
        loadingBackward: false,
        canLoadForward: false,
        loadingForward: false,
        events: [],
    },
};

export enum STUDY_CHAT_STATUS {
    UNKNOWN = 'UNKNOWN',
    LOADING = 'LOADING',
    READY = 'READY',
    FAILED = 'FAILED',
}

export const STUDY_CHAT = {
    studyId: '',
    rooms: {},
    roomsOrder: [],
    status: STUDY_CHAT_STATUS.UNKNOWN,
};

export enum ROOM_TYPES {
    PARTICIPANT_TO_RESEARCHERS = 'p2rs',
    RESEARCHER_TO_RESEARCHERS = 'r2rs',
    PARTICIPANT_TO_RESEARCHER = 'p2r',
    RESEARCHER_TO_RESEARCHER = 'r2r',
}

export enum CATEGORY_TYPES {
    ALL = 'all',
    GROUP = 'group',
    DIRECT = 'direct',
}

export enum MATRIX_TIMELINE_EVENT_TYPES {
    MESSAGE = 'm.room.message',
    CALL_INVITE = 'm.call.invite',
    CALL_REJECT = 'm.call.reject',
    CALL_HANGUP = 'm.call.hangup',
    CALL_ANSWER = 'm.call.answer',
}

export enum MEDIASOUP_EVENT_TYPES {
    CALL_UPGRADE = 'm.call.mediasoup.upgrade',
    CALL_RECORDING = 'm.call.mediasoup.recording',
}

export const MATRIX_TIMELINE_MESSAGE_CONTENT_TYPES = {
    TEXT: 'm.text',
};

export const MATRIX_TIMELINE_PAGINATE_EVENTS_LIMIT = 20;

export enum MATRIX_TIMELINE_PAGINATE_DIRECTIONS {
    BACKWARDS = 'b',
    FORWARDS = 'f',
}

export enum MATRIX_TIMELINE_EVENTS_STATUS {
    CANCELLED = 'cancelled',
    NOT_SENT = 'not_sent',
    QUEUED = 'queued',
    SENDING = 'sending',
    SENT = 'sent',
    COMPLETED = 'completed',
}

export const ROOM_MESSAGE_DRAFT = {
    text: '',
    files: [],
};

export const VOIP_CALL_TYPES = {
    VOICE: 'voice',
    VIDEO: 'video',
};

export enum VOIP_CALL_STATUS {
    UNKNOWN = 'UNKNOWN',
    RINGING = 'RINGING',
    ACTIVE = 'ACTIVE',
    WAITING_FOR_PERMISSION = 'WAITING_FOR_PERMISSION',
}

export const VOIP_CALL_DATA = {
    id: '',
    roomId: '',
    type: '',
    status: VOIP_CALL_STATUS.UNKNOWN,
    recording: false,
    audioMuted: false,
    videoMuted: false,
    localFeed: null,
    remoteFeed: null,
    remoteScreenFeed: null,
    isScreenBeingShared: false,
    isConnecting: false,
};
