import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import DateTime from '@edf-pkg/app-date-time';

import FontAwesomeButton from '$app-web/components/font-awesome-button';

const Controller = ({ playedSecond, duration, isPlaying, setPlaying }) => {
    return (
        <Grid container direction="row" alignItems="center" wrap="nowrap">
            <Grid item>
                <Typography data-testid="playedSecond" variant="button">
                    {DateTime.humanizeDuration(playedSecond)}
                </Typography>
            </Grid>
            <Grid container item xs direction="row" justify="center" wrap="nowrap">
                <Grid item>
                    {!isPlaying && (
                        <FontAwesomeButton icon={['fal', 'play-circle']} color="white" onClick={() => setPlaying(!isPlaying)} />
                    )}
                    {isPlaying && (
                        <FontAwesomeButton icon={['fal', 'pause-circle']} color="white" onClick={() => setPlaying(!isPlaying)} />
                    )}
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="button">{DateTime.humanizeDuration(duration)}</Typography>
            </Grid>
        </Grid>
    );
};

Controller.propTypes = {
    playedSecond: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    setPlaying: PropTypes.func.isRequired,
};

export default Controller;
