import dayjs from 'dayjs';

function DateTime(dateTime, parseFormat) {
    if (this instanceof DateTime) {
        if (dateTime instanceof DateTime) {
            // clone `dateTime`
            this.instance = dayjs(dateTime.instance, parseFormat);
        } else {
            // create dayjs instance of `dateTime`
            this.instance = dayjs(dateTime, typeof dateTime === 'number' ? undefined : parseFormat);
        }
        return this;
    }
    return new DateTime(dateTime, parseFormat);
}

DateTime.prototype.isValid = function isValid() {
    return this.instance != null && this.instance.isValid();
};

DateTime.prototype.isBefore = function isBefore(dateTime) {
    return this.instance.isBefore(dateTime.instance);
};

DateTime.prototype.isAfter = function isAfter(dateTime) {
    return this.instance.isAfter(dateTime.instance);
};

DateTime.prototype.isBeforeToday = function isBeforeToday() {
    return this.instance.isBefore(DateTime().instance, 'day');
};

DateTime.prototype.isSame = function isSame(dateTime, unit) {
    return this.instance.isSame(dateTime.instance, unit);
};

DateTime.prototype.isSameDay = function isSameDay(dateTime) {
    return this.instance.isSame(dateTime.instance, 'day');
};

DateTime.prototype.isSameWeek = function isSameWeek(dateTime) {
    return this.instance.isSame(dateTime.instance, 'week');
};

DateTime.prototype.isSameMonth = function isSameMonth(dateTime) {
    return this.instance.isSame(dateTime.instance, 'month');
};

DateTime.prototype.isSameYear = function isSameYear(dateTime) {
    return this.instance.isSame(dateTime.instance, 'year');
};

// return: MilliSeconds between two dateTimes in number
DateTime.prototype.diffMillisecond = function diffMillisecond(dateTime, floatingPointResult = false) {
    return this.instance.diff(dateTime.instance, 'millisecond', floatingPointResult);
};

DateTime.prototype.diffSecond = function diffSecond(dateTime, floatingPointResult = false) {
    return this.instance.diff(dateTime.instance, 'second', floatingPointResult);
};

DateTime.prototype.diffMinute = function diffMinute(dateTime, floatingPointResult = false) {
    return this.instance.diff(dateTime.instance, 'minute', floatingPointResult);
};

DateTime.prototype.diffHour = function diffHour(dateTime, floatingPointResult = false) {
    return this.instance.diff(dateTime.instance, 'hour', floatingPointResult);
};

DateTime.prototype.diffDay = function diffDay(dateTime, floatingPointResult = false) {
    return this.instance.diff(dateTime.instance, 'day', floatingPointResult);
};

DateTime.prototype.diffWeek = function diffWeek(dateTime, floatingPointResult = false) {
    return this.instance.diff(dateTime.instance, 'week', floatingPointResult);
};

DateTime.prototype.diffMonth = function diffMonth(dateTime, floatingPointResult = false) {
    return this.instance.diff(dateTime.instance, 'month', floatingPointResult);
};

DateTime.prototype.diffYear = function diffYear(dateTime, floatingPointResult = false) {
    return this.instance.diff(dateTime.instance, 'year', floatingPointResult);
};

// return: full year such as 2019
DateTime.prototype.getFullYear = function getFullYear() {
    return this.instance.year();
};

// return: Month index (zero-based)
DateTime.prototype.getMonth = function getMonth() {
    return this.instance.month();
};

DateTime.prototype.toMillis = function toMillis() {
    return this.instance.valueOf();
};

DateTime.prototype.toUTCMillis = function toUTCMillis() {
    return this.instance.utc(true).valueOf();
};

// return: `string` of ISO8061 format
DateTime.prototype.toISOString = function toISOString() {
    return this.instance.toISOString();
};

// (formatStr): one of the `CreateDateTime.FORMATS`.
// return: formatted `string`
DateTime.prototype.format = function format(formatStr, { formatUtc } = {}) {
    const time = this.instance.locale(dayjs.locale());
    return (formatUtc ? dayjs.utc(time.valueOf()) : time).format(formatStr);
};

// return: `DateTime` of first hour of day
DateTime.prototype.startOfDay = function startOfDay() {
    const dayjsInstance = this.instance.locale(dayjs.locale()).startOf('day');
    return DateTime(dayjsInstance);
};

// return: `DateTime` of start minute
DateTime.prototype.startOfMinute = function startOfMinute() {
    const dayjsInstance = this.instance.locale(dayjs.locale()).startOf('minute');
    return DateTime(dayjsInstance);
};

// return: `DateTime` of start hour
DateTime.prototype.startOfHour = function startOfHour() {
    const dayjsInstance = this.instance.locale(dayjs.locale()).startOf('hour');
    return DateTime(dayjsInstance);
};

// return: `DateTime` of first day of week (Depends on `weekStart` in locale)
DateTime.prototype.startOfWeek = function startOfWeek() {
    const dayjsInstance = this.instance.locale(dayjs.locale()).startOf('week');
    return DateTime(dayjsInstance);
};

// return: `DateTime` of first day of month
DateTime.prototype.startOfMonth = function startOfMonth() {
    const dayjsInstance = this.instance.locale(dayjs.locale()).startOf('month');
    return DateTime(dayjsInstance);
};
// return: `DateTime` of first day of year
DateTime.prototype.startOfYear = function startOfYear() {
    const dayjsInstance = this.instance.locale(dayjs.locale()).startOf('year');
    return DateTime(dayjsInstance);
};

// return: `DateTime` of last day of year
DateTime.prototype.endOfYear = function endOfYear() {
    const dayjsInstance = this.instance.locale(dayjs.locale()).endOf('year');
    return DateTime(dayjsInstance);
};

// return: `DateTime` of last hour of day
DateTime.prototype.endOfDay = function endOfDay() {
    const dayjsInstance = this.instance.locale(dayjs.locale()).endOf('day');
    return DateTime(dayjsInstance);
};

// return: `DateTime` of last day of week (Depends on `weekStart` in locale)
DateTime.prototype.endOfWeek = function endOfWeek() {
    const dayjsInstance = this.instance.locale(dayjs.locale()).endOf('week');
    return DateTime(dayjsInstance);
};

// return: `DateTime` of last day of month
DateTime.prototype.endOfMonth = function endOfMonth() {
    const dayjsInstance = this.instance.locale(dayjs.locale()).endOf('month');
    return DateTime(dayjsInstance);
};

// return: new `DateTime` of old DateTime + 'monthCount'
DateTime.prototype.addMonth = function addMonth(monthCount) {
    const dayjsInstance = this.instance.locale(dayjs.locale()).add(monthCount, 'month');
    return DateTime(dayjsInstance);
};

// return: new `DateTime` of old DateTime + 'yearCount'
DateTime.prototype.addYear = function addYear(yearCount) {
    const dayjsInstance = this.instance.locale(dayjs.locale()).add(yearCount, 'year');
    return DateTime(dayjsInstance);
};

// return: new `DateTime` of old DateTime + 'weekCount'
DateTime.prototype.addWeek = function addWeek(weekCount) {
    const dayjsInstance = this.instance.locale(dayjs.locale()).add(weekCount, 'week');
    return DateTime(dayjsInstance);
};

// return: new `DateTime` of old DateTime + `dayCount`
DateTime.prototype.addDay = function addDay(dayCount) {
    const dayjsInstance = this.instance.locale(dayjs.locale()).add(dayCount, 'day');
    return DateTime(dayjsInstance);
};

// return: new `DateTime` of old DateTime + `hour`
DateTime.prototype.addHour = function addHour(hour) {
    const dayjsInstance = this.instance.locale(dayjs.locale()).add(hour, 'hour');
    return DateTime(dayjsInstance);
};

// return: new `DateTime` of old DateTime - `dayCount`
DateTime.prototype.subtractDay = function subtractDay(dayCount) {
    const dayjsInstance = this.instance.locale(dayjs.locale()).subtract(dayCount, 'day');
    return DateTime(dayjsInstance);
};

// return: new `DateTime` of old DateTime - `monthCount`
DateTime.prototype.subtractMonth = function subtractMonth(monthCount) {
    const dayjsInstance = this.instance.locale(dayjs.locale()).subtract(monthCount, 'month');
    return DateTime(dayjsInstance);
};

// return: new `DateTime` of old DateTime - `weekCount`
DateTime.prototype.subtractWeek = function subtractWeek(weekCount) {
    const dayjsInstance = this.instance.locale(dayjs.locale()).subtract(weekCount, 'week');
    return DateTime(dayjsInstance);
};

// return: new `DateTime` of old DateTime - `hour`
DateTime.prototype.subtractHour = function subtractHour(hour) {
    const dayjsInstance = this.instance.locale(dayjs.locale()).subtract(hour, 'hour');
    return DateTime(dayjsInstance);
};

// return: number of day of week
DateTime.prototype.getWeekDay = function getWeekDay() {
    return this.instance.day();
};

// return: number of day of month
DateTime.prototype.getDayOfMonth = function getDayOfMonth() {
    return this.instance.date();
};

DateTime.prototype.getHour = function getHour() {
    return this.instance.hour();
};

DateTime.prototype.getMinute = function getMinute() {
    return this.instance.minute();
};

DateTime.prototype.setSecond = function setSecond(s) {
    const dayjsInstance = this.instance.locale(dayjs.locale()).second(s);
    return DateTime(dayjsInstance);
};

DateTime.prototype.setMinute = function setMinute(m) {
    const dayjsInstance = this.instance.locale(dayjs.locale()).minute(m);
    return DateTime(dayjsInstance);
};

DateTime.prototype.setHour = function setHour(h) {
    const dayjsInstance = this.instance.locale(dayjs.locale()).hour(h);
    return DateTime(dayjsInstance);
};

DateTime.prototype.setMonth = function setMonth(m) {
    const dayjsInstance = this.instance.locale(dayjs.locale()).set('month', m);
    return DateTime(dayjsInstance);
};

DateTime.prototype.setYear = function setYear(y) {
    const dayjsInstance = this.instance.locale(dayjs.locale()).set('year', y);
    return DateTime(dayjsInstance);
};

DateTime.prototype.setMonthDay = function setMonthDay(d) {
    const dayjsInstance = this.instance.locale(dayjs.locale()).set('date', d);
    return DateTime(dayjsInstance);
};

DateTime.prototype.getSecond = function getSecond() {
    return this.instance.second();
};

// return: Get the entire weekdays of a `DateTime`
DateTime.prototype.getAllWeekdaysOfDate = function getAllWeekdaysOfDate() {
    const dayjsInstance = this.startOfWeek();
    return Array.from({ length: 7 }, (_, index) => dayjsInstance.addDay(index));
};

DateTime.prototype.getUTCOffsetInMinutes = function getUTCOffsetInMinutes() {
    return this.instance.utcOffset();
};

// Relative time
DateTime.prototype.fromNow = function fromNow(withoutSuffix) {
    return this.instance.fromNow(withoutSuffix);
};

DateTime.prototype.from = function from(compared, withoutSuffix) {
    return this.instance.from(compared, withoutSuffix);
};

DateTime.prototype.toNow = function toNow(withoutSuffix) {
    return this.instance.toNow(withoutSuffix);
};

DateTime.prototype.to = function to(compared, withoutSuffix) {
    return this.instance.to(compared, withoutSuffix);
};

DateTime.getMonthsFullName = function getMonthsFullName() {
    return [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
};
DateTime.getMonthsShortName = function getMonthsShortName() {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
};

// return: An array of normalized "DateTime"s
DateTime.normalizeDates = function normalizeDates(dates) {
    return dates.map((date) => DateTime(date));
};

// return: string array of week day name (Depends on `weekStart` in locale)
DateTime.weekDaysFullName = function weekDaysFullName() {
    const day = DateTime().startOfWeek().instance.day();
    const daysOfWeekNumber = [];

    Array.from({ length: 7 }, (_, index) => daysOfWeekNumber.push(index), day);
    Array.from({ length: day }, (_, index) => daysOfWeekNumber.push(index));

    return daysOfWeekNumber.map((i) => DateTime().instance.day(i).format('dddd')); // is accessible from `dayjs().$locale()` too
};

// return: string array of week day name (Depends on `weekStart` in locale)
DateTime.weekDaysShortName = function weekDaysShortName() {
    const weekDay = DateTime().startOfWeek().getWeekDay();
    const daysOfWeekNumber = [];

    Array.from({ length: 7 }, (_, index) => daysOfWeekNumber.push(index), weekDay);
    Array.from({ length: weekDay }, (_, index) => daysOfWeekNumber.push(index));

    return daysOfWeekNumber.map((i) => DateTime().instance.day(i).format('ddd'));
};

DateTime.humanizeDuration = function humanizeDuration(totalDurationInSecond) {
    // TODO: Replace it with dayjs.duration
    const hour = Math.floor(totalDurationInSecond / 3600);
    const minute = Math.floor((totalDurationInSecond - hour * 3600) / 60);
    const second = Math.round(totalDurationInSecond % 60);

    const hourWithPrefix = (hour < 10 ? '0' : '') + hour;

    return `${hour ? `${hourWithPrefix}:` : ''}${minute < 10 ? '0' : ''}${minute}:${second < 10 ? '0' : ''}${second}`;
};

DateTime.timezone = {
    getCurrent() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    parse(dateTime, timezoneName) {
        if (dateTime instanceof DateTime) {
            return DateTime(dayjs.tz(dateTime.format(DateTime.FORMATS.DATE_TIME), timezoneName));
        }
        return DateTime(dayjs.tz(dateTime, timezoneName));
    },
    getTimezoneOffset(timezone) {
        const offsetInMinutes = this.parse(DateTime(), timezone).getUTCOffsetInMinutes();
        const offsetInHours = offsetInMinutes / 60;
        const isPositive = offsetInHours > 0;

        const hours = isPositive ? Math.floor(offsetInHours) : Math.ceil(offsetInHours);
        const minutes = offsetInMinutes % 60;
        return `${isPositive ? '+' : ''}${hours}${minutes > 0 ? `:${minutes}` : ''}`;
    },
};

// If we deprecated ordinal day formats, we can deprecate the advancedFormat dayjs plugin too.
DateTime.FORMATS = {
    // for instances, supposed it's `Thursday, January 16, 2020 8:21:08 PM (01/16/2020)`
    DATE_MED: 'll', // 'Jan 16, 2020'
    MEDIA_DATETIME: 'YYYYMMDDHHmmssSSS', // '20200116202108984'
    DATE_TIME: 'YYYY-MM-DD HH:mm:ss', // 2020-01-16 20:21:08
    // TODO-MAYBE: according to the new designs, use 'MMM D, YYYY, HH:mm' instead.
    DATE_TIME_HUMAN_READABLE: 'MMM. D, YYYY HH:mm', // Jan. 6, 2020 20:21
    DATE_TIME_NO_SEC: 'YYYY-MM-DD HH:mm', // 2020-01-16 20:21
    DATE: 'YYYY-MM-DD', // 2020-01-16
    DATE_HUMAN_READABLE: 'MMMM DD, YYYY', // January 16, 2020
    DATE_HUMAN_READABLE_SHORT_MONTH: 'MMM DD, YYYY', // Jan 16, 2020
    TIME: 'HH:mm:ss', // 20:21:08
    HOUR_MINUTE: 'HH:mm', // 20:21
    WEEK_DAY_SHORT_NAME: 'ddd', // Thu
    MONTH_NAME_WITH_YEAR: 'MMMM YYYY', // January 2020
    MONTH_SHORT_NAME_WITH_YEAR: 'MMM. YYYY', // Jan. 2020
    MONTH_SHORT_NAME_WITH_DAY: 'MMM DD', // Jan 16
    MONTH_SHORT_NAME_WITH_ORDINAL_DAY: 'MMM. Do', // Jan. 16th
    MONTH_NAME_WITH_DAY: 'MMMM DD', // January 16
    MONTH_FULL_NAME: 'MMMM', // January
    FULL_YEAR: 'YYYY', // 2020
    DAY: 'DD', // 03
    DAY_WEEK: 'DD ddd', // 16 Thu
    WEEK_DAY_WITH_DATE: 'dddd MMM DD', // Thursday Jan 16
    WEEK_DAY_SHORT_WITH_DATE: 'ddd MMM DD', // Thu Jan 16
    WEEK_DAY_WITH_ORDINAL_DAY: 'ddd. Do', // Thu. 16th
    WEEK_DAY_WITH_ORDINAL_DAY_WITH_COMMA: 'ddd, Do', // Thu. 16th
    DATE_IN_LOCALE: 'L', // 01/16/2020 (localized format)
    TIME_IN_LOCALE: 'LT', // 8:21 PM (localized format)
    MERIDIEM: 'A', // PM
    HOUR_MINUTE_WITH_SEPARATOR: 'h|mm', // 8|21
    FULL_DATE_TIME_LOCALE: 'LLLL', // Thursday, January 16, 2020 8:21 PM
    // TODO: This should be merged into other formats.
    FULL_DATE_TIME_LOCALE_ACTIVITY_SESSIONS: 'MMM DD, YYYY HH:mm', // Jan 16, 2020 08:21
    ADHERENCE_REPORT_API_MODIFIED_DATE_FORMAT: 'MMM-DD-YYYY HH:mm', // Jan-16-2020 08:21
    HOUR_MINUTE_MERIDIEM_WITH_SEPARATOR: 'hh:mm|A', // 08:21|PM
    DATE_HUMAN_READABLE_WITH_SEPARATOR: 'MMMM|D|YYYY', // January 16, 2020
    HOUR_MINUTE_MERIDIEM: 'hh:mm A', // 08:21 PM
    DATE_HOUR_MINUTE_MERIDIEM: 'YYYY-MM-DD hh:mm A', // 2020-01-16 08:21 PM
    YEAR_MONTH: 'YYYY-MM', // 2020-01
    ISO_8601: 'YYYY-MM-DDTHH:mm:ss.SSSZ', // 2020-01-16T20:21:08.000+03:30,
    MONTH_DAY: 'M/D', // 1/16
};

DateTime.timezones = {
    EtcUTC: 'Etc/UTC',
};

export default DateTime;
