import { ROLE_IDS, ROLES } from '$app-web/constants';

export function isParticipant(role: string) {
    return role === ROLES.PARTICIPANT;
}

export function isResearcher(role: string) {
    return role === ROLES.RESEARCHER;
}

export function getRoleById(roleId: number) {
    switch (roleId) {
        case ROLE_IDS.PARTICIPANT:
            return ROLES.PARTICIPANT;
        case ROLE_IDS.RESEARCHER:
            return ROLES.RESEARCHER;
        default:
            throw new Error(`Role by ID of ${roleId} not found!`);
    }
}

export function getIdOfRole(role: string) {
    switch (role) {
        case ROLES.PARTICIPANT:
            return ROLE_IDS.PARTICIPANT;
        case ROLES.RESEARCHER:
            return ROLE_IDS.RESEARCHER;
        default:
            throw new Error(`Role ID of ${role} not found!`);
    }
}
