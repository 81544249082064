import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        '& audio': {
            width: '100%',
        },
    },
    paragraph: {
        margin: '1em 0',
    },
    blockquote: {
        '&::before': {
            content: '"“"',
            fontWeight: 700,
            fontSize: '36px',
            color: theme.palette.secondary.main,
            fontFamily: 'Arial',
            position: 'relative',
            left: '-6px',
        },
    },
    imageWrapper: {
        textAlign: 'center',
        margin: theme.spacing(2, 0),
        minHeight: 40,
        cursor: 'pointer',
    },
    image: {
        display: 'inline-block',
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.secondary.dark,
        },
    },
}));
