import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';

import styles from './styles';

const ButtonContained = (props) => {
    const { className, classes, text, handleClick, color, ...rest } = props;
    const rootClassName = clsx(classes.root, {
        [classes.rootError]: color === 'error',
        [classes.rootSuccess]: color === 'success',
        [classes.rootInfo]: color === 'info',
    });
    return (
        <Button
            className={className}
            variant="contained"
            color={color === 'secondary' ? 'secondary' : 'primary'}
            onClick={handleClick}
            classes={{
                root: rootClassName,
                label: classes.label,
            }}
            {...rest}
        >
            {text}
        </Button>
    );
};

ButtonContained.defaultProps = {
    handleClick: () => {},
    color: '',
    className: undefined,
};

ButtonContained.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    text: PropTypes.node.isRequired,
    handleClick: PropTypes.func,
    color: PropTypes.string,
};

export default withStyles(styles)(ButtonContained);
