import React, { useMemo } from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';

import classes from './styles.module.scss';

const Badge = (props) => {
    const { border, side, color, variant, content, invisible, children, max, className } = props;

    const badgeContent = useMemo(() => {
        if (typeof content === 'string') {
            return content;
        }
        return content > max ? `+${max}` : content;
    }, [content, max]);

    return (
        <span className={clsx(classes.root, { [classes.standAlone]: children === undefined }, className)}>
            {children}
            {!invisible && (
                <span
                    className={clsx(classes.badge, 'background-color', color, {
                        [classes.border]: border,
                        [classes.dot]: variant === 'dot',
                        [classes.left]: side === 'left',
                        [classes.right]: side === 'right',
                        [classes.top]: side === 'top',
                        [classes.bottom]: side === 'bottom',
                        [classes.topRight]: side === 'top-right',
                        [classes.topLeft]: side === 'top-left',
                        [classes.bottomRight]: side === 'bottom-right',
                        [classes.bottomLeft]: side === 'bottom-left',
                    })}
                >
                    {variant === 'large' && badgeContent}
                </span>
            )}
        </span>
    );
};

Badge.defaultProps = {
    border: false,
    children: undefined,
    content: undefined,
    color: 'ocean',
    side: 'top-right',
    variant: 'large',
    invisible: false,
    max: 9,
    className: '',
};

Badge.propTypes = {
    border: PropTypes.bool,
    children: PropTypes.element,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.oneOf([
        'ocean',
        'ocean-light',
        'green',
        'green-light',
        'red',
        'red-light',
        'orange',
        'orange-light',
        'space',
        'space-light',
        'gray-light',
        'violet',
        'violet-light',
        'yellow',
        'yellow-light',
    ]),
    side: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'top-left', 'top-right', 'bottom-left', 'bottom-right']),
    variant: PropTypes.oneOf(['large', 'dot']),
    invisible: PropTypes.bool,
    max: PropTypes.number,
    className: PropTypes.string,
};

export default Badge;
