import React, { forwardRef, Fragment, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import ButtonGroup from '$app-web/ui-kit/components/button-group';
import Card from '$app-web/ui-kit/components/card';
import Loading from '$app-web/ui-kit/components/loading';
import useLazyLoadingList from '$app-web/ui-kit/hooks/use-lazy-loading-list';

import Item from './item';
import classes from './styles.module.scss';

const SELECT_ALL_VALUE = '__SELECT_ALL__';

const initialDraggingItemInfo = { draggableId: null, initialScrollTop: 0 };

export const GROUP_MULTIPLIER = 100;

export const mapOptionGroupsToOptions = (optionGroupsToMap) =>
    optionGroupsToMap.flatMap((optionGroup) => {
        if (!optionGroup.header) {
            return optionGroup.items;
        }
        return optionGroup;
    });

export const mapOptionsToOptionGroups = (optionsToMap) =>
    optionsToMap.reduce((state, currentItem) => {
        const isGroup = currentItem.header !== undefined && currentItem.items?.length > 0;
        const lastItem = state[state.length - 1];
        const isLastItemManualGroup = lastItem?.header === undefined && lastItem?.items?.length > 0;
        if (isGroup) {
            return [...state, currentItem];
        }
        if (isLastItemManualGroup) {
            lastItem.items = [...lastItem.items, currentItem];
            return state;
        }
        return [...state, { items: [currentItem] }];
    }, []);

const handleGroupOptionClick = ({ groupSelectedStatus, groupItems, value, onChange }) => {
    const groupItemValues = groupItems.map((item) => item.value);
    if (groupSelectedStatus === true) {
        onChange(value.filter((item) => !groupItemValues.includes(item)));
    } else {
        onChange(Array.from(new Set([...value, ...groupItemValues])));
    }
};
const getAllSelected = (selectedOptionsLength, totalOptionsLength) => {
    if (selectedOptionsLength === totalOptionsLength && totalOptionsLength > 0) {
        return true;
    }
    if (selectedOptionsLength) {
        return 'indeterminate';
    }
    return false;
};

const DroppableSection = ({ droppableId, children }) => (
    <Droppable droppableId={droppableId} type={droppableId}>
        {(dropProvided) => {
            return (
                <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
                    {children}
                    {dropProvided.placeholder}
                </div>
            );
        }}
    </Droppable>
);

DroppableSection.propTypes = {
    children: PropTypes.node.isRequired,
    droppableId: PropTypes.string.isRequired,
};

const OptionItems = forwardRef((props, ref) => {
    const {
        actions,
        fetchingNextPage,
        fetchNextPage,
        noOptionsText,
        freeInput,
        hasNextPage,
        lazy,
        total,
        loading,
        loadingText,
        multiple,
        onChange,
        onOptionClick,
        onReorder,
        options,
        searchInputValue,
        selectableGroups,
        selectAll,
        selectAllLabel,
        value,
        button,
        optionsTooltipMaxWidth,
        optionsTooltipSide,
        draggable,
        withoutCard,
        fullHeight,
        className,
    } = props;

    const { t } = useTranslation();
    const [draggingItemInfo, setDraggingItemInfo] = useState(initialDraggingItemInfo);

    const itemsWrapperRef = useRef(null);

    const calculateSelected = useCallback(
        (option) => (multiple ? value.includes(option.value) : option.value === value),
        [multiple, value]
    );

    const getStyle = useCallback((transform, initialScrollTop) => {
        return transform?.replace(
            /translate\((-?\d*px),\s*(-?\d*px)\)/g,
            (_, __, g2) => `translateY(${parseFloat(g2) - initialScrollTop}px)`
        );
    }, []);

    const optionGroups = useMemo(() => mapOptionsToOptionGroups(options), [options]);

    const handleBeforeDragStart = useCallback((eventInfo) => {
        setDraggingItemInfo({ draggableId: eventInfo.draggableId, initialScrollTop: itemsWrapperRef.current?.scrollTop || 0 });
    }, []);

    const handleReorder = useCallback(
        ({ source, destination }) => {
            setDraggingItemInfo(initialDraggingItemInfo);

            if (source === null || destination === null || source.droppableId !== destination.droppableId) {
                return;
            }

            const groupIndex = Math.floor(source.index / GROUP_MULTIPLIER);
            const sourceIndex = source.index % GROUP_MULTIPLIER;
            const destinationIndex = destination.index % GROUP_MULTIPLIER;

            const newOptionGroups = [...optionGroups];
            const { items } = newOptionGroups[groupIndex];

            const [removedItem] = items.splice(sourceIndex, 1);
            items.splice(destinationIndex, 0, removedItem);

            onReorder?.(mapOptionGroupsToOptions(newOptionGroups), newOptionGroups);
        },
        [onReorder, optionGroups]
    );

    const itemsLength = useMemo(() => optionGroups.flatMap((option) => option.items).length, [optionGroups]);
    const nonDisabledItems = useMemo(
        () => optionGroups.flatMap((option) => option.items).filter((option) => !option.disabled),
        [optionGroups]
    );
    const totalItemsLength = lazy ? total : itemsLength;
    const nonDisabledItemsLength = nonDisabledItems.length;
    const allSelected = multiple && getAllSelected(value.length, totalItemsLength);

    useImperativeHandle(
        ref,
        () => ({
            allSelected,
        }),
        [allSelected]
    );

    const handleSelectAll = () => {
        const isAllLoadedItemsSelected = value.length === nonDisabledItemsLength;
        if (allSelected === true || (allSelected === 'indeterminate' && isAllLoadedItemsSelected)) {
            onChange([]);
        } else {
            // Select all (except disabled ones)
            onChange(nonDisabledItems.map((option) => option.value));
        }
    };

    const handleOptionClick = useCallback(
        (option, { isCustom = false } = {}) => {
            const { value: optionValue } = option;

            if (!multiple) {
                onChange?.(optionValue, { isCustom });
            } else {
                let selectedItemValues;
                if (value.includes(optionValue)) {
                    // option is deselected
                    selectedItemValues = value.filter((valueItem) => valueItem !== optionValue);
                } else {
                    // option is selected/added
                    selectedItemValues = [...value, optionValue];
                }
                onChange?.(selectedItemValues, { isCustom });
            }

            onOptionClick?.(option, { isCustom });
        },
        [multiple, onChange, onOptionClick, value]
    );

    const { getLazyLoadingListProps } = useLazyLoadingList({
        enabled: lazy,
        fetchingNextPage,
        fetchNextPage,
        hasNextPage,
        loading,
        listElementRef: itemsWrapperRef,
    });

    const renderLoading = (fetchMode = false) => {
        return (
            <div
                className={clsx('flex items-center', classes.loading, {
                    [classes.narrowPadding]: fetchMode,
                })}
            >
                {!fetchMode && <div className={classes.loadingText}>{loadingText || t('rdash:loading')}</div>}
                <Loading color="space" />
            </div>
        );
    };

    const renderSelectAll = () =>
        selectAll ? (
            <>
                <Item
                    multiple={multiple}
                    option={{
                        endLabel: `${value.length} / ${totalItemsLength}`,
                        label: selectAllLabel,
                        value: SELECT_ALL_VALUE,
                        disabled: nonDisabledItemsLength === 0,
                    }}
                    selected={allSelected}
                    onClick={handleSelectAll}
                    button={button}
                />
                <div className={classes.horizontalSeparator} />
            </>
        ) : null;

    const renderItem = (option, { dragHandleProps, key, selected, onClick } = {}) => {
        const handleItemClick = onClick !== undefined ? onClick : () => handleOptionClick(option);

        return (
            <React.Fragment key={key}>
                <Item
                    multiple={multiple}
                    option={option}
                    selected={selected !== undefined ? selected : calculateSelected(option)}
                    onClick={handleItemClick}
                    button={button}
                    tooltipMaxWidth={optionsTooltipMaxWidth}
                    tooltipSide={optionsTooltipSide}
                    customTooltipContent={option.customTooltipContent}
                    customTooltipOpen={option.customTooltipOpen}
                    {...(draggable &&
                        dragHandleProps !== undefined && {
                            draggable: true,
                            dragHandleProps,
                        })}
                />
                {option.divider && <div className={classes.horizontalSeparator} />}
            </React.Fragment>
        );
    };

    const renderOption = (option, index) => {
        const id = option.value ? `${option.value}` : option.label;

        if (draggable) {
            return (
                <Draggable key={id} draggableId={id} index={index}>
                    {(provided) => {
                        const { draggableProps, dragHandleProps } = provided;
                        const { style } = draggableProps;

                        const patchedStyle = {
                            ...style,
                            top: 'auto',
                            left: 'auto',
                            transform:
                                id === draggingItemInfo.draggableId
                                    ? getStyle(style.transform || 'translate(0px, 0px)', draggingItemInfo.initialScrollTop)
                                    : style.transform,
                        };

                        return (
                            <div ref={provided.innerRef} {...draggableProps} style={patchedStyle}>
                                {renderItem(option, { dragHandleProps, key: id })}
                            </div>
                        );
                    }}
                </Draggable>
            );
        }

        return renderItem(option, { key: id });
    };

    const renderOptions = (items, groupIndex) => {
        const content = items
            .filter((optionItem) => !optionItem.hidden)
            .map((optionItem, optionIndex) => renderOption(optionItem, GROUP_MULTIPLIER * groupIndex + optionIndex));
        return draggable ? (
            <DroppableSection droppableId={`droppableSection-${groupIndex}`}>{content}</DroppableSection>
        ) : (
            content
        );
    };

    const renderList = () => {
        let shouldShowSelectAll = false;
        let content;

        const isSomeOptionsVisible = optionGroups.flatMap((optionGroup) => optionGroup.items).some((option) => !option.hidden);

        if (optionGroups.length > 0 && isSomeOptionsVisible) {
            shouldShowSelectAll = true;
            content = optionGroups
                .filter((optionGroup) => !optionGroup.hidden)
                .map((optionGroup, groupIndex) => {
                    const id = optionGroup.items[0].value;
                    const optionsElement = renderOptions(optionGroup.items, groupIndex);
                    const isSelectableGroup = selectableGroups && optionGroup.header;
                    const groupSelectedStatus =
                        isSelectableGroup &&
                        getAllSelected(
                            optionGroup.items.filter((item) => value.includes(item.value)).length,
                            optionGroup.items.length
                        );

                    return (
                        <Fragment key={`group-${id}`}>
                            {optionGroup.header && !selectableGroups && (
                                <span className={classes.optionGroupHeader}>{optionGroup.header}</span>
                            )}
                            {isSelectableGroup &&
                                renderItem(
                                    {
                                        label: optionGroup.header,
                                        value: `header-${optionGroup.header}`,
                                        disabled: optionGroup.disabled,
                                    },
                                    {
                                        selected: groupSelectedStatus,
                                        onClick: () =>
                                            handleGroupOptionClick({
                                                groupSelectedStatus,
                                                groupItems: optionGroup.items,
                                                value,
                                                onChange,
                                            }),
                                    }
                                )}
                            {isSelectableGroup ? <div className={classes.indented}>{optionsElement}</div> : optionsElement}
                        </Fragment>
                    );
                });
        } else if (freeInput && searchInputValue) {
            const customOption = { label: `${t('rdash:use')} "${searchInputValue}"`, value: searchInputValue };
            content = <Item option={customOption} onClick={() => handleOptionClick(customOption, { isCustom: true })} />;
        } else {
            content = (
                <div className={classes.noOptionsFoundText}>
                    <div>{noOptionsText || t('rdash:no_options_found')}</div>
                    {freeInput && <div>{t('rdash:you_can_type_your_own_value')}</div>}
                </div>
            );
        }

        return (
            <div
                className={clsx(classes.itemsWrapper, { [classes.fullHeight]: fullHeight })}
                ref={itemsWrapperRef}
                {...getLazyLoadingListProps()}
            >
                {shouldShowSelectAll && renderSelectAll()}
                {content}
                {fetchingNextPage && renderLoading(true)}
            </div>
        );
    };

    let mainContent;
    if (loading) {
        mainContent = renderLoading();
    } else if (draggable) {
        mainContent = (
            <DragDropContext onBeforeDragStart={handleBeforeDragStart} onDragEnd={handleReorder}>
                {renderList()}
            </DragDropContext>
        );
    } else {
        mainContent = renderList();
    }

    const Wrapper = withoutCard ? 'div' : Card;
    return (
        <Wrapper className={clsx(classes.root, className)}>
            {mainContent}
            {actions && actions.length > 0 && (
                <div className={classes.actionsWrapper}>
                    <ButtonGroup
                        dense
                        items={actions.map((action) => ({
                            ...action,
                            small: true,
                        }))}
                    />
                </div>
            )}
        </Wrapper>
    );
});

const getOptionPropTypes = (isRequired = false) => {
    const isRequiredType = (type) => (isRequired ? type.isRequired : type);

    return {
        value: isRequiredType(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        label: isRequiredType(PropTypes.node),
        disabled: PropTypes.bool,
        divider: PropTypes.bool,
        icon: PropTypes.arrayOf(PropTypes.string),
        statusColor: PropTypes.string,
        badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        customTooltipContent: PropTypes.string,
        customTooltipOpen: PropTypes.bool,
        warning: PropTypes.bool,
    };
};

OptionItems.defaultProps = {
    actions: undefined,
    fetchingNextPage: false,
    fetchNextPage: undefined,
    freeInput: false,
    hasNextPage: false,
    lazy: false,
    total: undefined,
    loading: false,
    multiple: false,
    onChange: undefined,
    searchInputValue: '',
    selectableGroups: false,
    selectAll: false,
    selectAllLabel: undefined,
    value: undefined,
    button: false,
    optionsTooltipMaxWidth: undefined,
    optionsTooltipSide: 'top',
    draggable: false,
    onReorder: undefined,
    onOptionClick: undefined,
    withoutCard: false,
    fullHeight: false,
    className: undefined,
    loadingText: undefined,
    noOptionsText: undefined,
};

OptionItems.propTypes = {
    onOptionClick: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            ...getOptionPropTypes(),
            header: PropTypes.string,
            icon: PropTypes.arrayOf(PropTypes.string),
            items: PropTypes.arrayOf(PropTypes.shape(getOptionPropTypes(true))),
        })
    ).isRequired,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            color: PropTypes.string,
            disabled: PropTypes.bool,
            label: PropTypes.string,
            onClick: PropTypes.func,
        })
    ),
    fetchingNextPage: PropTypes.bool,
    fetchNextPage: PropTypes.func,
    freeInput: PropTypes.bool,
    hasNextPage: PropTypes.bool,
    lazy: PropTypes.bool,
    total: PropTypes.number,
    loading: PropTypes.bool,
    multiple: PropTypes.bool,
    draggable: PropTypes.bool,
    onChange: PropTypes.func,
    onReorder: PropTypes.func,
    searchInputValue: PropTypes.string,
    selectableGroups: PropTypes.bool,
    selectAll: PropTypes.bool,
    selectAllLabel: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])),
    ]),
    button: PropTypes.bool,
    optionsTooltipMaxWidth: PropTypes.number,
    optionsTooltipSide: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
    withoutCard: PropTypes.bool,
    fullHeight: PropTypes.bool,
    className: PropTypes.string,
    loadingText: PropTypes.string,
    noOptionsText: PropTypes.string,
};

export default OptionItems;
