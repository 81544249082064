import { SELECTABLE_QUESTION_TYPES } from '$app-web/constants';
import type { ObjectType, Section } from '$app-web/types';
import { SuperArray, SuperObject } from '$app-web/utils';

import { StoreQuestion } from '../store/types';

export function getQuestionEnablerOptions(sections: Array<Section>) {
    const choiceBasedQuestions = sections
        .flatMap((section) => section.questions)
        .filter((question) => SELECTABLE_QUESTION_TYPES.includes(question.questionType));
    const questionEnablerOptions = choiceBasedQuestions.flatMap((question) =>
        question
            .answers!.filter((item) => item.enableQuestion !== '')
            .map((item) => ({
                questionId: question.questionId,
                answerIds: [item.answerId],
                enableQuestion: Number(item.enableQuestion),
            }))
    );
    const reducedQuestionEnablerOptions = questionEnablerOptions.reduce(
        (allOptions, currentOption) => {
            const existingOption = allOptions.find(
                (item) => item.questionId === currentOption.questionId && item.enableQuestion === currentOption.enableQuestion
            );
            if (existingOption == null) {
                return [...allOptions, currentOption];
            }
            return [
                ...allOptions.filter((item) => item !== existingOption),
                { ...existingOption, answerIds: [...existingOption.answerIds, ...currentOption.answerIds] },
            ];
        },
        [] as Array<{ questionId: number; answerIds: Array<number>; enableQuestion: number }>
    );

    return reducedQuestionEnablerOptions;
}

export function getDisabledQuestions(
    questionEnablerOptions: Array<{
        questionId: number;
        answerIds: Array<number>;
        enableQuestion: number;
    }>,
    newAnswers: Array<{ questionId: number; iteration: number | null; value: unknown }>
) {
    const disabledQuestions = newAnswers.flatMap((answer) => {
        const enablerQuestions = questionEnablerOptions.filter((item) => item.questionId === answer.questionId);

        if (enablerQuestions.length === 0) {
            return null;
        }

        const answerIds = Array.isArray(answer.value) ? answer.value : [answer.value];
        const numericAnswerIds = answerIds.map((id) => Number(id));
        const filteredEnablerQuestions = enablerQuestions.filter(
            (question) => !question.answerIds.some((id) => numericAnswerIds.includes(id))
        );

        return filteredEnablerQuestions.map((item) => ({ questionId: item.enableQuestion, iteration: answer.iteration }));
    });

    const validDisabledQuestions = SuperArray.removeNullAndUndefined(disabledQuestions);
    const uniqueDisabledQuestions = SuperArray.removeDuplicates(validDisabledQuestions);

    return uniqueDisabledQuestions;
}

export function isAnswerValueEmpty(value: unknown) {
    if (SuperObject.isObject(value) && SuperObject.hasKey(value as ObjectType, 'text', 'audioUrl')) {
        return (value as ObjectType).text === '' && (value as ObjectType).audioUrl == null;
    }
    if (Array.isArray(value)) {
        const filteredArray = SuperArray.removeNullAndUndefined(value);
        return filteredArray.length === 0;
    }
    if (typeof value === 'string') {
        return value === '';
    }
    return value == null;
}

export function shouldPresentQuestion(question: StoreQuestion) {
    if (
        question.isHiddenByNoSectionLoop ||
        question.isHiddenBySectionCriteria ||
        question.isHiddenByCriteria ||
        question.isHiddenBySectionsRandomization ||
        question.isHiddenByRandomization ||
        question.isHiddenByFlow ||
        (question.isHiddenBySectionsFlow && question.isHiddenByFlow != false) ||
        (!question.enabled && !question.isEnabledByAnswers)
    ) {
        return false;
    }
    return true;
}
