import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';

import styles from './styles';

import 'inobounce';

const LayoutBase = ({ classes, children }) => {
    return (
        <Grid container className={classes.root}>
            {children}
        </Grid>
    );
};

LayoutBase.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType]).isRequired,
};

export default withStyles(styles)(LayoutBase);
