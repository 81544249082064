// TODO: some constants defined here belong to the survey and should be moved there.
import { invert } from 'lodash-es';
import { ReactComponent as AudioTextIcon } from '@assets/images/survey-editor/audio-text-icon.svg';
import { ReactComponent as VasIcon } from '@assets/images/survey-editor/vas-icon.svg';

import appDateTime from '@edf-pkg/app-date-time';

import { EMAIL_ADDRESS_REGEX, NUMBER_REGEX, PHONE_NUMBER_REGEX, TRANSLATION_BASE_ID, URL_REGEX } from '$app-web/constants';
import { TRIGGERING_LOGICS } from '$app-web/modules/rdash/constants';

import { fal } from '$common/fontawesome-pro/light';

export const SECTION = 'section';

export const QUESTOIN_LABEL_MAX_LENGTH = 250;

export const SECTION_LABEL_MAX_LENGTH = 250;

// copied from @edf-pkg/app-pdash/src/constants
export const QUESTION_TYPES = {
    INFORMATION: 'information',
    SINGLE_CHOICE: 'single_choice',
    MULTIPLE_CHOICE: 'multiple_choice',
    FREE_FORM_TEXT: 'free_form_text',
    NUMBER: 'number',
    MASS: 'mass',
    LENGTH: 'length',
    VAS: 'vas',
    CALENDAR: 'calendar',
    IMAGE: 'image',
    VIDEO: 'video',
    AUDIO: 'audio',
    AUDIO_TEXT: 'audio_text',
    BARCODE: 'barcode',
};

// FIXME: This should be removed
export const ALERT_TYPES = {
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
};

export const TYPE_ICONS = {
    // FIXME: General and alert should be removed
    general: {
        language: ['fal', 'globe'],
        duplicate: ['fas', 'copy'],
        move: ['fas', 'arrows'],
        trash: ['fas', 'trash'],
        edit: ['fas', 'pen'],
        save: ['fas', 'save'],
        discard: ['fas', 'eraser'],
    },
    alert: {
        [ALERT_TYPES.INFO]: ['far', 'info-circle'],
        [ALERT_TYPES.WARNING]: ['far', 'exclamation-triangle'],
        [ALERT_TYPES.ERROR]: ['far', 'exclamation-circle'],
    },
    question: {
        [QUESTION_TYPES.INFORMATION]: ['far', 'info-square'],
        [QUESTION_TYPES.SINGLE_CHOICE]: ['far', 'dot-circle'],
        [QUESTION_TYPES.MULTIPLE_CHOICE]: ['far', 'check-square'],
        [QUESTION_TYPES.FREE_FORM_TEXT]: ['far', 'text'],
        [QUESTION_TYPES.NUMBER]: ['far', 'sort-circle'],
        [QUESTION_TYPES.MASS]: ['far', 'weight'],
        [QUESTION_TYPES.LENGTH]: ['far', 'ruler'],
        [QUESTION_TYPES.VAS]: VasIcon,
        [QUESTION_TYPES.CALENDAR]: ['far', 'calendar-alt'],
        [QUESTION_TYPES.IMAGE]: ['far', 'image-polaroid'],
        [QUESTION_TYPES.VIDEO]: ['far', 'camera-home'],
        [QUESTION_TYPES.AUDIO]: ['far', 'microphone'],
        [QUESTION_TYPES.AUDIO_TEXT]: AudioTextIcon,
        [QUESTION_TYPES.BARCODE]: ['far', 'barcode-read'],
    },
    triggeringLogic: {
        [TRIGGERING_LOGICS.ELIGIBILITY.name]: ['fas', 'user-check'],
        [TRIGGERING_LOGICS.TIME.name]: ['fas', 'hourglass-half'],
        [TRIGGERING_LOGICS.USER.name]: ['fas', 'user-check'],
        [TRIGGERING_LOGICS.PROXIMITY.name]: ['fas', 'people-arrows'],
        [TRIGGERING_LOGICS.DROPOUT.name]: ['fas', 'user-times'],
        [TRIGGERING_LOGICS.PUBLIC.name]: ['fas', 'door-open'],
        [TRIGGERING_LOGICS.GEOFENCING.name]: ['fas', 'map-marker-alt'],
    },
    setting: {
        general: ['fas', 'sliders-h-square'],
        message: ['fas', 'comment-alt-lines'],
        layout: ['fas', 'game-board-alt'],
    },
};

export const TYPE_TEXTS = {
    general: {
        [SECTION]: (t) => t('rdash:question_section'),
    },
    question: {
        [QUESTION_TYPES.INFORMATION]: (t) => t('rdash:question_type_information'),
        [QUESTION_TYPES.SINGLE_CHOICE]: (t) => t('rdash:question_type_single_choice'),
        [QUESTION_TYPES.MULTIPLE_CHOICE]: (t) => t('rdash:question_type_multiple_choice'),
        [QUESTION_TYPES.FREE_FORM_TEXT]: (t) => t('rdash:question_type_free_form_text'),
        [QUESTION_TYPES.NUMBER]: (t) => t('rdash:question_type_number'),
        [QUESTION_TYPES.MASS]: (t) => t('rdash:question_type_mass'),
        [QUESTION_TYPES.LENGTH]: (t) => t('rdash:question_type_length'),
        [QUESTION_TYPES.VAS]: (t) => t('rdash:question_type_vas'),
        [QUESTION_TYPES.CALENDAR]: (t) => t('rdash:question_type_calendar'),
        [QUESTION_TYPES.IMAGE]: (t) => t('rdash:question_type_image'),
        [QUESTION_TYPES.VIDEO]: (t) => t('rdash:question_type_video'),
        [QUESTION_TYPES.AUDIO]: (t) => t('rdash:question_type_audio'),
        [QUESTION_TYPES.AUDIO_TEXT]: (t) => t('rdash:question_type_audio_text'),
        [QUESTION_TYPES.BARCODE]: (t) => t('rdash:question_type_barcode'),
    },
    triggeringLogic: {
        [TRIGGERING_LOGICS.ELIGIBILITY.name]: (t) => t('rdash:tl_eligibility'),
        [TRIGGERING_LOGICS.TIME.name]: (t) => t('rdash:tl_time'),
        [TRIGGERING_LOGICS.USER.name]: (t) => t('rdash:tl_user'),
        [TRIGGERING_LOGICS.PROXIMITY.name]: (t) => t('rdash:tl_proximity'),
        [TRIGGERING_LOGICS.DROPOUT.name]: (t) => t('rdash:tl_dropout'),
        [TRIGGERING_LOGICS.PUBLIC.name]: (t) => t('rdash:tl_public'),
        [TRIGGERING_LOGICS.GEOFENCING.name]: (t) => t('rdash:tl_geofencing'),
    },
    setting: {
        general: (t) => t('rdash:general_settings'),
        message: (t) => t('rdash:game_messages'),
        layout: (t) => t('rdash:game_layout'),
    },
};

export const TYPE_DESCRIPTIONS = {
    general: {
        [SECTION]: (t) => t('rdash:question_section_description'),
    },
    question: {
        [QUESTION_TYPES.INFORMATION]: (t) => t('rdash:question_type_information_description'),
        [QUESTION_TYPES.SINGLE_CHOICE]: (t) => t('rdash:question_type_single_choice_description'),
        [QUESTION_TYPES.MULTIPLE_CHOICE]: (t) => t('rdash:question_type_multiple_choice_description'),
        [QUESTION_TYPES.FREE_FORM_TEXT]: (t) => t('rdash:question_type_free_form_text_description'),
        [QUESTION_TYPES.NUMBER]: (t) => t('rdash:question_type_number_description'),
        [QUESTION_TYPES.MASS]: (t) => t('rdash:question_type_mass_description'),
        [QUESTION_TYPES.LENGTH]: (t) => t('rdash:question_type_length_description'),
        [QUESTION_TYPES.VAS]: (t) => t('rdash:question_type_vas_description'),
        [QUESTION_TYPES.CALENDAR]: (t) => t('rdash:question_type_calendar_description'),
        [QUESTION_TYPES.IMAGE]: (t) => t('rdash:question_type_image_description'),
        [QUESTION_TYPES.VIDEO]: (t) => t('rdash:question_type_video_description'),
        [QUESTION_TYPES.AUDIO]: (t) => t('rdash:question_type_audio_description'),
        [QUESTION_TYPES.AUDIO_TEXT]: (t) => t('rdash:question_type_audio_text_description'),
        [QUESTION_TYPES.BARCODE]: (t) => t('rdash:question_type_barcode_description'),
    },
};

export const ACTIVITY_EXPIRY_TIME_TYPES = {
    NEVER: 'never',
    AFTER_MINUTES: 'after_minutes',
};

export const QUESTION_FREE_FORM_TEXT_LINE_TYPES = {
    SINGLE: 'SINGLE',
    MULTI: 'MULTI',
};

export const QUESTION_MASS_UNITS = {
    KG: 'kg',
    LBS: 'lbs',
};

export const QUESTION_LENGTH_UNITS = {
    CM: 'cm',
    FT_INCH: 'ft_in',
};

export const QUESTION_CALENDAR_SELECTOR_TYPES = {
    DATE_TIME: 'date-time',
    DATE: 'date',
    TIME: 'time',
};

export const QUESTION_CHOICE_BASED_LAYOUT_TYPES = {
    RADIO_BUTTON: 'radio_button',
    CHECKBOX: 'checkbox',
    BUTTON: 'button',
    DROPDOWN: 'dropdown',
};

export const QUESTION_CHOICE_BASED_ANSWER_TYPES = {
    TEXT: 'text',
    IMAGE: 'image',
};

export const QUESTION_VAS_LAYOUT_TYPES = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
};

export const QUESTION_VAS_SELECTION_LABEL_TYPES = {
    TEXT: 'text',
    IMAGE: 'image',
};

export const QUESTION_VAS_ANCHOR_TYPES = {
    TEXT: 'text',
    IMAGE: 'url',
};

export const BASE_QUESTION_SETTINGS = {
    id: '',
    type: QUESTION_TYPES.INFORMATION,
    criteria: '',
    isMandatory: false,
    isEnabled: true,
    content: {
        [TRANSLATION_BASE_ID]: '',
    },
    label: {
        [TRANSLATION_BASE_ID]: '',
    },
    translationId: TRANSLATION_BASE_ID,
};

export const QUESTION_VAS_BASE_ANCHOR_SETTINGS = {
    type: QUESTION_VAS_ANCHOR_TYPES.TEXT,
    text: {
        [TRANSLATION_BASE_ID]: '',
    },
    url: {
        [TRANSLATION_BASE_ID]: '',
    },
};

export const SURVEY_TYPES = {
    GENERIC: 0,
    TIME_USE: 1,
};

export const ELEMENT_TYPES = {
    SECTION: 'section',
    QUESTION: 'question',
    ANSWER: 'answer',
};

export const SETTING_TYPES = {
    EMPTY: 'empty',
    SURVEY: 'survey',
    ACTIVITY_SHARED: 'activity-shared',
    SECTION: 'section',
    QUESTION_INFORMATION: 'question_information',
    QUESTION_BARCODE: 'question_barcode',
    QUESTION_IMAGE: 'question_image',
    QUESTION_VIDEO: 'question_video',
    QUESTION_AUDIO: 'question_audio',
    QUESTION_AUDIO_TEXT: 'question_audio_text',
    QUESTION_NUMBER: 'question_number',
    QUESTION_MASS: 'question_mass',
    QUESTION_LENGTH: 'question_length',
    QUESTION_CALENDAR: 'question_calendar',
    QUESTION_FREE_FORM_TEXT: 'question_free_form_text',
    QUESTION_SINGLE_CHOICE: 'question_single_choice',
    QUESTION_MULTIPLE_CHOICE: 'question_multiple_choice',
    QUESTION_VAS: 'question_vas',
};

const settingsTypesInvert = invert(SETTING_TYPES);
export const QUESTION_TYPE_TO_SETTINGS_TYPE = {
    [QUESTION_TYPES.INFORMATION]: settingsTypesInvert.question_information,
    [QUESTION_TYPES.SINGLE_CHOICE]: settingsTypesInvert.question_single_choice,
    [QUESTION_TYPES.MULTIPLE_CHOICE]: settingsTypesInvert.question_multiple_choice,
    [QUESTION_TYPES.FREE_FORM_TEXT]: settingsTypesInvert.question_free_form_text,
    [QUESTION_TYPES.NUMBER]: settingsTypesInvert.question_number,
    [QUESTION_TYPES.MASS]: settingsTypesInvert.question_mass,
    [QUESTION_TYPES.LENGTH]: settingsTypesInvert.question_length,
    [QUESTION_TYPES.VAS]: settingsTypesInvert.question_vas,
    [QUESTION_TYPES.CALENDAR]: settingsTypesInvert.question_calendar,
    [QUESTION_TYPES.IMAGE]: settingsTypesInvert.question_image,
    [QUESTION_TYPES.VIDEO]: settingsTypesInvert.question_video,
    [QUESTION_TYPES.AUDIO]: settingsTypesInvert.question_audio,
    [QUESTION_TYPES.AUDIO_TEXT]: settingsTypesInvert.question_audio_text,
    [QUESTION_TYPES.BARCODE]: settingsTypesInvert.question_barcode,
};

export const SECTION_NEXT_NORMAL_FLOW_ID = '-2';
export const SECTION_END_SECTION_ID = '-1';
export const QUESTION_CHOICE_NEUTRAL_NEXT_SECTION_ID = '-2';
export const QUESTION_CHOICE_NO_NEXT_SECTION_ID = '-1';

export const BASE_SETTINGS = {
    [SETTING_TYPES.SURVEY]: {
        id: '',
        version: 0,
        title: {
            [TRANSLATION_BASE_ID]: '',
        },
        description: {
            [TRANSLATION_BASE_ID]: '',
        },
        criteria: '',
        buttonIcon: '',
        buttonCaption: {
            [TRANSLATION_BASE_ID]: '',
        },
        expiryTimeType: ACTIVITY_EXPIRY_TIME_TYPES.NEVER,
        expiryAfterMinutes: 0,
        captureLocationEnabled: false,
        showProgressEnabled: false,
        sectionsRandomizationEnabled: false,
        randomizationIncludedSectionIds: [],
        randomizationSelectionCount: 1,
        randomSectionOrderEnabled: false,
        previousButtonEnabled: true,
    },
    [SETTING_TYPES.SECTION]: {
        id: '',
        criteria: '',
        label: {
            [TRANSLATION_BASE_ID]: '',
        },
        translationId: TRANSLATION_BASE_ID,
        loopEnabled: false,
        loopSourceQuestionId: null,
        questionRandomizationEnabled: false,
        randomizationIncludedQuestionIds: [],
        randomizationSelectionCount: 1,
        randomQuestionOrderEnabled: false,
        nextSectionId: SECTION_NEXT_NORMAL_FLOW_ID,
    },
    [SETTING_TYPES.QUESTION_INFORMATION]: { ...BASE_QUESTION_SETTINGS },
    [SETTING_TYPES.QUESTION_BARCODE]: { ...BASE_QUESTION_SETTINGS, type: QUESTION_TYPES.BARCODE },
    [SETTING_TYPES.QUESTION_IMAGE]: { ...BASE_QUESTION_SETTINGS, type: QUESTION_TYPES.IMAGE },
    [SETTING_TYPES.QUESTION_VIDEO]: { ...BASE_QUESTION_SETTINGS, type: QUESTION_TYPES.VIDEO },
    [SETTING_TYPES.QUESTION_AUDIO]: { ...BASE_QUESTION_SETTINGS, type: QUESTION_TYPES.AUDIO },
    [SETTING_TYPES.QUESTION_NUMBER]: {
        ...BASE_QUESTION_SETTINGS,
        type: QUESTION_TYPES.NUMBER,
        unit: {
            [TRANSLATION_BASE_ID]: '',
        },
        step: 1,
        defaultEnabled: false,
        default: 0,
        minimumEnabled: false,
        minimum: 0,
        maximumEnabled: false,
        maximum: 100,
    },
    [SETTING_TYPES.QUESTION_MASS]: {
        ...BASE_QUESTION_SETTINGS,
        type: QUESTION_TYPES.MASS,
        unit: QUESTION_MASS_UNITS.KG,
        step: 1,
        defaultEnabled: false,
        default: 0,
        minimumEnabled: false,
        minimum: 0,
        maximumEnabled: false,
        maximum: 100,
    },
    [SETTING_TYPES.QUESTION_LENGTH]: {
        ...BASE_QUESTION_SETTINGS,
        type: QUESTION_TYPES.LENGTH,
        unit: QUESTION_LENGTH_UNITS.CM,
        step: 1,
        defaultEnabled: false,
        default: 0,
        minimumEnabled: false,
        minimum: 0,
        maximumEnabled: false,
        maximum: 100,
    },
    [SETTING_TYPES.QUESTION_CALENDAR]: {
        ...BASE_QUESTION_SETTINGS,
        type: QUESTION_TYPES.CALENDAR,
        periodEnabled: false,
        selectorType: QUESTION_CALENDAR_SELECTOR_TYPES.DATE_TIME,
        maximum: null,
        maximumParticipantTodayChecked: false,
        minimum: null,
        minimumParticipantTodayChecked: false,
        minuteInterval: '1',
    },
    [SETTING_TYPES.QUESTION_FREE_FORM_TEXT]: {
        ...BASE_QUESTION_SETTINGS,
        type: QUESTION_TYPES.FREE_FORM_TEXT,
        format: {
            [TRANSLATION_BASE_ID]: '',
        },
        hint: {
            [TRANSLATION_BASE_ID]: '',
        },
        autocompleteOptionsEnabled: false,
        autoCompleteOptions: '',
        includePastResponsesEnabled: false,
        lineType: QUESTION_FREE_FORM_TEXT_LINE_TYPES.MULTI,
    },
    [SETTING_TYPES.QUESTION_AUDIO_TEXT]: {
        ...BASE_QUESTION_SETTINGS,
        type: QUESTION_TYPES.AUDIO_TEXT,
        hint: {
            [TRANSLATION_BASE_ID]: '',
        },
        lineType: QUESTION_FREE_FORM_TEXT_LINE_TYPES.MULTI,
    },
    [SETTING_TYPES.QUESTION_SINGLE_CHOICE]: {
        ...BASE_QUESTION_SETTINGS,
        type: QUESTION_TYPES.SINGLE_CHOICE,
        displayLayoutType: QUESTION_CHOICE_BASED_LAYOUT_TYPES.RADIO_BUTTON,
        answerRandomizationEnabled: false,
        randomizationIncludedAnswers: [],
        randomizationSelectionCount: 1,
        randomAnswerOrderEnabled: false,
        answersType: QUESTION_CHOICE_BASED_ANSWER_TYPES.TEXT,
    },
    [SETTING_TYPES.QUESTION_MULTIPLE_CHOICE]: {
        ...BASE_QUESTION_SETTINGS,
        type: QUESTION_TYPES.MULTIPLE_CHOICE,
        displayLayoutType: QUESTION_CHOICE_BASED_LAYOUT_TYPES.CHECKBOX,
        answerRandomizationEnabled: false,
        randomizationIncludedAnswers: [],
        randomizationSelectionCount: 1,
        randomAnswerOrderEnabled: false,
        answersType: QUESTION_CHOICE_BASED_ANSWER_TYPES.TEXT,
        maxChoices: 0,
    },
    [SETTING_TYPES.QUESTION_VAS]: {
        ...BASE_QUESTION_SETTINGS,
        type: QUESTION_TYPES.VAS,
        layoutType: QUESTION_VAS_LAYOUT_TYPES.HORIZONTAL,
        maximum: 100,
        minimum: 0,
        step: 1,
        anchors: [QUESTION_VAS_BASE_ANCHOR_SETTINGS, QUESTION_VAS_BASE_ANCHOR_SETTINGS],
        showSelectionValueEnabled: true,
        showSelectionLabelEnabled: true,
        selectionLabelType: QUESTION_VAS_SELECTION_LABEL_TYPES.TEXT,
        selectionLabels: [],
    },
};

export const QUESTION_ANSWER_BASE_SETTING = {
    id: '',
    textId: '',
    content: { [TRANSLATION_BASE_ID]: '' },
    url: { [TRANSLATION_BASE_ID]: '' },
    enabledQuestionIds: [],
    nextSectionId: QUESTION_CHOICE_NEUTRAL_NEXT_SECTION_ID,
};

export const QUESTION_VAS_BASE_LABEL_SETTING = {
    id: '',
    text: {
        [TRANSLATION_BASE_ID]: '',
    },
    url: {
        [TRANSLATION_BASE_ID]: '',
    },
    lowerBound: 0,
};

export const ICON_PACK = Object.values(fal);

export const SAMPLE_DATE_TIME = appDateTime().format(appDateTime.FORMATS.DATE_TIME);

export const SAMPLE_DAY_TIME = '0 00:00:00';

export const SCHEDULE_TIME_FORMAT_ENUM = {
    ABSOLUTE: 'absolute',
    RELATIVE: 'relative',
};

export const SCHEDULE_BASE_ENUM = {
    REGISTRATION_TIME: 'registration_time',
    REGISTRATION_DATE: 'registration_date',
};

export const SCHEDULE_PERIOD_RANDOMIZATION_ENUM = {
    UNIFORM: 'uniform',
    NORMAL: 'normal',
};

export const SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM = {
    DAILY: 'day',
    WEEKLY: 'week',
    MONTHLY: 'month',
    YEARLY: 'year',
};

export const SCHEDULE_REPEAT_OCCURRENCE_UNIT_TRANSLATIONS = {
    [SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM.DAILY]: (t) => t('rdash:daily'),
    [SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM.WEEKLY]: (t) => t('rdash:weekly'),
    [SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM.MONTHLY]: (t) => t('rdash:monthly'),
    [SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM.YEARLY]: (t) => t('rdash:yearly'),
};

export const SCHEDULE_REPEAT_OCCURRENCE_UNIT_NOUN_TRANSLATIONS = {
    [SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM.DAILY]: (t) => t('rdash:day'),
    [SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM.WEEKLY]: (t) => t('rdash:week'),
    [SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM.MONTHLY]: (t) => t('rdash:month'),
    [SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM.YEARLY]: (t) => t('rdash:year'),
};

export const SCHEDULE_REPEAT_OCCURRENCE_UNIT_PLURAL_NOUN_TRANSLATIONS = {
    [SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM.DAILY]: (t) => t('rdash:days'),
    [SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM.WEEKLY]: (t) => t('rdash:weeks'),
    [SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM.MONTHLY]: (t) => t('rdash:months'),
    [SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM.YEARLY]: (t) => t('rdash:years'),
};

export const SCHEDULE_REPEAT_WEEK_DAYS_ENUM = {
    MON: 'mon',
    TUE: 'tue',
    WED: 'wed',
    THU: 'thu',
    FRI: 'fri',
    SAT: 'sat',
    SUN: 'sun',
};

export const SCHEDULE_END_CRITERIA_ENUM = {
    NEVER: 'never',
    ON_COUNT: 'on_count',
    ON_DATE: 'on_date',
};

export const SCHEDULE_REPEAT_ENUM = {
    REPETITIVE: 'repetitive',
    NO_REPEAT: 'no_repeat',
};

export const SCHEDULE_REPEAT_DEFAULTS = {
    [SCHEDULE_REPEAT_ENUM.REPETITIVE]: {
        repeat: {
            occurrenceUnit: SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM.DAILY,
            occurEvery: 1,
        },
        end: {
            criteria: SCHEDULE_END_CRITERIA_ENUM.NEVER,
        },
    },
    [SCHEDULE_REPEAT_ENUM.NO_REPEAT]: {
        repeat: undefined,
        end: undefined,
    },
};

// TODO: use dayjs locale to get the week day names
export const SCHEDULE_REPEAT_WEEK_DAYS_TRANSLATIONS = {
    [SCHEDULE_REPEAT_WEEK_DAYS_ENUM.MON]: (t) => t('rdash:mon'),
    [SCHEDULE_REPEAT_WEEK_DAYS_ENUM.TUE]: (t) => t('rdash:tue'),
    [SCHEDULE_REPEAT_WEEK_DAYS_ENUM.WED]: (t) => t('rdash:wed'),
    [SCHEDULE_REPEAT_WEEK_DAYS_ENUM.THU]: (t) => t('rdash:thu'),
    [SCHEDULE_REPEAT_WEEK_DAYS_ENUM.FRI]: (t) => t('rdash:fri'),
    [SCHEDULE_REPEAT_WEEK_DAYS_ENUM.SAT]: (t) => t('rdash:sat'),
    [SCHEDULE_REPEAT_WEEK_DAYS_ENUM.SUN]: (t) => t('rdash:sun'),
};

export const PROXIMITY_TRIGGERING_LOGIC_BASE_ENUM = {
    DEPARTURE: 'departure',
    ARRIVAL: 'arrival',
};

export const MINUTE_IN_MS = 60 * 1000;

export const GEOFENCING_DEFAULTS = {
    RADIUS_METERS: 100,
    STAY_DURATION_SECONDS: 600,
    MIN_GAP_BETWEEN_TRIGGERS_SECONDS: 600,
    SCHEDULING_OFFSET_SECONDS: 0,
};

export const GEOFENCE_TYPES = {
    CIRCLE: 'circle',
    POLYGON: 'polygon',
};

export const GEOFENCING_EVENT_TYPES = {
    ENTER: 'Enter',
    EXIT: 'Exit',
    STAY: 'Stay',
};

export const TRIGGERING_LOGIC_DEFAULTS = {
    [TRIGGERING_LOGICS.ELIGIBILITY.name]: {
        type: TRIGGERING_LOGICS.ELIGIBILITY.name,
        eligibilityCriteria: '',
    },
    [TRIGGERING_LOGICS.DROPOUT.name]: {
        type: TRIGGERING_LOGICS.DROPOUT.name,
        criteria: undefined,
    },
    [TRIGGERING_LOGICS.USER.name]: {
        type: TRIGGERING_LOGICS.USER.name,
        triggerTitle: '',
        triggerTitleTranslation: undefined,
        criteria: undefined,
        buttonIcon: undefined,
    },
    [TRIGGERING_LOGICS.PUBLIC.name]: {
        type: TRIGGERING_LOGICS.PUBLIC.name,
    },
    [TRIGGERING_LOGICS.TIME.name]: {
        type: TRIGGERING_LOGICS.TIME.name,
        criteria: undefined,
        buttonCaption: undefined,
        buttonCaptionTranslation: undefined,
        buttonIcon: undefined,
        schedule: {
            timeFormat: SCHEDULE_TIME_FORMAT_ENUM.RELATIVE,
            base: SCHEDULE_BASE_ENUM.REGISTRATION_DATE,
            repeat: undefined,
            period: {
                start: SAMPLE_DAY_TIME,
                end: SAMPLE_DAY_TIME,
                randomization: {
                    function: SCHEDULE_PERIOD_RANDOMIZATION_ENUM.UNIFORM,
                },
            },
            end: undefined,
        },
    },
    [TRIGGERING_LOGICS.PROXIMITY.name]: {
        type: TRIGGERING_LOGICS.PROXIMITY.name,
        buttonCaption: undefined,
        buttonCaptionTranslation: undefined,
        teamId: -1,
        roles: [],
        base: PROXIMITY_TRIGGERING_LOGIC_BASE_ENUM.ARRIVAL,
        offsetMs: 0,
        minContactLengthMs: 0,
        minConsecutivePromptIntervalMs: 0,
        minimumRssi: -512,
    },
    [TRIGGERING_LOGICS.GEOFENCING.name]: {
        type: TRIGGERING_LOGICS.GEOFENCING.name,
        shapeType: GEOFENCE_TYPES.CIRCLE,
        center: { latitude: 0, longitude: 0 },
        radiusMeters: GEOFENCING_DEFAULTS.RADIUS_METERS,
        points: [],
        eventType: GEOFENCING_EVENT_TYPES.ENTER,
        stayDurationSeconds: GEOFENCING_DEFAULTS.STAY_DURATION_SECONDS,
        schedulingOffsetSeconds: GEOFENCING_DEFAULTS.SCHEDULING_OFFSET_SECONDS,
        minGapBetweenTriggersSeconds: GEOFENCING_DEFAULTS.MIN_GAP_BETWEEN_TRIGGERS_SECONDS,
        expireOnExit: false,
        triggerOnInitialLocation: false,
    },
};

export const TIME_TRIGGERING_LOGIC_SCHEDULE_REPEAT_DEFAULT = {
    occurrenceUnit: SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM.DAILY,
    occurEvery: 1,
    weekDays: undefined,
    skipStartingWeek: undefined,
};

export const TIME_TRIGGERING_LOGIC_SCHEDULE_WEEKLY_REPEAT_DEFAULT = {
    ...TIME_TRIGGERING_LOGIC_SCHEDULE_REPEAT_DEFAULT,
    occurrenceUnit: SCHEDULE_REPEAT_OCCURRENCE_UNIT_ENUM.WEEKLY,
    weekDays: [],
    skipStartingWeek: false,
};

export const TIME_TRIGGERING_LOGIC_SCHEDULE_END_DEFAULTS = {
    [SCHEDULE_END_CRITERIA_ENUM.NEVER]: () => ({
        criteria: SCHEDULE_END_CRITERIA_ENUM.NEVER,
        lastCount: undefined,
        lastDate: undefined,
    }),
    [SCHEDULE_END_CRITERIA_ENUM.ON_COUNT]: () => ({
        criteria: SCHEDULE_END_CRITERIA_ENUM.ON_COUNT,
        lastCount: 15,
        lastDate: undefined,
    }),
    [SCHEDULE_END_CRITERIA_ENUM.ON_DATE]: (isRelative) => ({
        criteria: SCHEDULE_END_CRITERIA_ENUM.ON_DATE,
        lastCount: undefined,
        lastDate: isRelative ? SAMPLE_DAY_TIME : SAMPLE_DATE_TIME,
    }),
};

export const INNER_FIELD_GROUP_SPACING = 2;

export const OUTER_FIELD_SPACING = 3;

export const TRIGGERING_LOGICS_INCLUDING_TRANSLATION = {
    [TRIGGERING_LOGICS.DROPOUT.name]: false,
    [TRIGGERING_LOGICS.ELIGIBILITY.name]: false,
    [TRIGGERING_LOGICS.USER.name]: true,
    [TRIGGERING_LOGICS.TIME.name]: true,
    [TRIGGERING_LOGICS.PROXIMITY.name]: true,
    [TRIGGERING_LOGICS.GEOFENCING.name]: true,
};

export const PROXIMITY_TRIGGERING_LOGIC_TEAM_TYPE_ENUM = {
    ALL: 'all',
    SAME_ID: 'same_id',
    SPECIFIC_ID: 'specific_id',
};

export const PROXIMITY_TRIGGERING_LOGIC_TEAM_TYPE_ID_ENUM = {
    ALL: -2,
    SAME_ID: -1,
};

export const PROXIMITY_TRIGGERING_LOGIC_ROLES_ENUM = {
    ANY: 'any',
    SPECIFIC: 'specific',
};

export const PROXIMITY_TRIGGERING_LOGIC_ROLE_DEFAULT = {
    from: 0,
    to: 0,
};

export const TIME_INTERVALS_UNITS_ENUM = {
    SECOND: 'second',
    MINUTE: 'minute',
    HOUR: 'hour',
    DAY: 'day',
};

export const TIME_INTERVALS_UNITS_ORDER = [
    TIME_INTERVALS_UNITS_ENUM.SECOND,
    TIME_INTERVALS_UNITS_ENUM.MINUTE,
    TIME_INTERVALS_UNITS_ENUM.HOUR,
    TIME_INTERVALS_UNITS_ENUM.DAY,
];

export const TIME_INTERVAL_UNIT_DETAILS = {
    [TIME_INTERVALS_UNITS_ENUM.SECOND]: {
        ms: 1000,
        translation: (t) => t('rdash:second'),
        translationPlural: (t) => t('rdash:seconds'),
    },
    [TIME_INTERVALS_UNITS_ENUM.MINUTE]: {
        ms: 1000 * 60,
        translation: (t) => t('rdash:minute'),
        translationPlural: (t) => t('rdash:minutes'),
    },
    [TIME_INTERVALS_UNITS_ENUM.HOUR]: {
        ms: 1000 * 60 * 60,
        translation: (t) => t('rdash:hour'),
        translationPlural: (t) => t('rdash:hours'),
    },
    [TIME_INTERVALS_UNITS_ENUM.DAY]: {
        ms: 1000 * 60 * 60 * 24,
        translation: (t) => t('rdash:day'),
        translationPlural: (t) => t('rdash:days'),
    },
};

export const DIALOG_IDS = {
    CONTENT_SECTION_DELETE: 'content_section_delete',
    CONTENT_SECTION_MOVE: 'content_section_move',
    CONTENT_SECTION_DUPLICATE: 'content_section_duplicate',
    CONTENT_QUESTION_DELETE: 'content_question_delete',
    CONTENT_QUESTION_MOVE: 'content_question_move',
    CONTENT_QUESTION_DUPLICATE: 'content_question_duplicate',
    CONTENT_QUESTION_FREE_FORM_TEXT_AUTOCOMPLETE_OPTIONS_EDIT: 'content_question_free_form_text_autocomplete_options_edit',
    CONTENT_SELECT_IMAGE: 'content_select_image',
    CONTENT_SELECT_AUDIO: 'content_select_audio',
    SURVEY_IMPORT: 'survey_import',
    SURVEY_LOADING_CONFIRMATION: 'survey_loading_confirmation',
    SUGGESTED_ANSWERS_CONFIRMATION: 'suggested_answers_confirmation',
    SAVING_ERROR: 'saving_error',
    DISCARD_CONFIRMATION: 'discard_confirmation',
};

export const ACTION_POSITIONS = {
    AFTER: 'after',
    BEFORE: 'before',
};

export const DROPPABLE_IDS = {
    SECTIONS: 'sections',
    NEW_ITEM_SECTION: 'new_item_section',
    NEW_ITEM_QUESTIONS: 'new_item_questions',
    SECTION_QUESTIONS: (sectionId) => `section_questions_${sectionId}`,
    SECTION_QUESTION_ANSWERS: (mixedId) => `section_question_answers_${mixedId}`,
};

export const HIERARCHY_BASE_ELEMENTS = {
    [ELEMENT_TYPES.SECTION]: {
        id: '',
        questions: [],
    },
    [ELEMENT_TYPES.QUESTION]: {
        id: '',
        type: '',
    },
};

export const EXTERNAL_QUESTIONS_ERRORS = {
    UNAUTHORIZED_SURVEY: 'UNAUTHORIZED_SURVEY',
    MISSING_QUESTION: 'MISSING_QUESTION',
};

export const SURVEY_JSON = {
    MAIN_REQUIRED: {
        version: 0,
        name: '',
        expiryDurationMs: 0,
        triggeringLogics: [],
        subSurveys: [
            {
                subSurveyId: 1,
                subSurveyContent: [],
            },
        ],
    },
    MAIN_EXTRA: {
        nameTranslation: {},
        description: '',
        descriptionTranslation: {},
        buttonCaption: '',
        buttonCaptionTranslation: {},
        criteria: '',
        captureLocation: false,
        showProgress: true,
    },
    SUB_SURVEYS_EXTRA: {
        sectionRandomSelection: {
            sectionIds: [],
            selectionCount: 1,
        },
        sectionRandomOrder: false,
    },
    SECTION_REQUIRED: {
        nextSection: -1,
        questions: [],
    },
    SECTION_EXTRA: {
        loopSourceQid: -1,
        criteria: '',
        questionRandomSelection: {
            questionIds: [],
            selectionCount: 1,
        },
        questionRandomOrder: false,
    },
    QUESTION_REQUIRED: {
        orderId: -1,
        questionId: -1,
        questionType: QUESTION_TYPES.INFORMATION,
        isMandatory: false,
        enabled: true,
        questionContent: '',
        hasPlaceholder: false,
    },
    QUESTION_EXTRA: {
        criteria: '',
        questionContentTranslation: {},
        answerRandomSelection: {
            answerIds: [],
            selectionCount: 1,
        },
    },
};

export const EXPIRY_TIME_OPTIONS = (t) => [
    {
        value: ACTIVITY_EXPIRY_TIME_TYPES.NEVER,
        label: t('rdash:never'),
    },
    {
        value: ACTIVITY_EXPIRY_TIME_TYPES.AFTER_MINUTES,
        label: t('rdash:after'),
    },
];

export const DEVICES = {
    MOBILE: {
        width: 360,
        height: 640,
        title: (t) => t('rdash:mobile'),
    },
    DESKTOP: {
        width: 760,
        height: 550,
        title: (t) => t('rdash:desktop'),
    },
};

export const FREE_FORM_TEXT_PREDEFINED_FORMAT = {
    EMAIL_ADDRESS: { title: (t) => t('rdash:email_address'), regex: EMAIL_ADDRESS_REGEX.source, hint: 'name@example.com' },
    URL: { title: (t) => t('rdash:url'), regex: URL_REGEX.source, hint: 'https://example.com' },
    NUMBER: { title: (t) => t('rdash:question_type_number'), regex: NUMBER_REGEX.source, hint: '0123456789' },
    PHONE_NUMBER: { title: (t) => t('rdash:phone_number'), regex: PHONE_NUMBER_REGEX.source, hint: '+12345678910' },
};

export const CHOICE_BASED_PASTE_MODES = {
    CREATE_NEW_ANSWERS: 'CREATE_NEW_ANSWERS',
    OVERRIDE_ANSWERS: 'OVERRIDE_ANSWERS',
    OVERRIDE_EMPTY_ANSWERS: 'OVERRIDE_EMPTY_ANSWERS',
};

export const DURATION_TYPES = {
    UNKNOWN: { id: 0, label: 'Unknown', value: 'unknown' },
    TIMED: { id: 1, label: 'Timed', value: 'timed' },
    ROUND: { id: 2, label: 'Round', value: 'round' },
};

export const DURATION_TYPES_BY_ID = Object.fromEntries(Object.values(DURATION_TYPES).map((item) => [item.id, item.value]));

export const SELECTORS = {
    UNKNOWN: { id: 0, label: 'Unknown', value: 'unknown' },
    COLORED_BOX: { id: 1, label: 'Colored Box', value: 'colored_box' },
    COLORED_TEXT: { id: 2, label: 'Colored Text', value: 'colored_text' },
    YES_NO: { id: 3, label: 'Yes or No', value: 'yes_no' },
};

export const SELECTORS_BY_ID = Object.fromEntries(Object.values(SELECTORS).map((item) => [item.id, item.value]));
