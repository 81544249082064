import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    mainWrapper: {
        height: '100%',
        padding: theme.spacing(2),
        outline: 'none',
    },
    playerWrapper: {
        textAlign: 'center',
        overflow: 'hidden',
    },
    image: {
        width: '100%',
        height: '100%',
    },
}));
