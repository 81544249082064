import React, { cloneElement, isValidElement, useMemo, useState } from 'react';
import clsx from 'clsx';
import { uniqueId } from 'lodash-es';
import * as PropTypes from 'prop-types';

import Icon from '$app-web/ui-kit/components/icon';
import InputAdornment from '$app-web/ui-kit/components/input-adornment';
import Label from '$app-web/ui-kit/components/label';

import classes from './styles.module.scss';

const TextField = (props) => {
    const {
        value,
        onChange,
        type,
        id,
        name,
        placeholder,
        label,
        labelHelperText,
        multiline,
        error,
        errorMessage,
        textCenter,
        fullWidth,
        small,
        required,
        disabled,
        readOnly,
        maxLength,
        ellipsis,
        autoComplete,
        startAdornment,
        endAdornment,
        inputRef,
        onClick,
        onFocus,
        onBlur,
        focused,
        className,
        header,
        warning,
        onClear,
        noMinWidth,
    } = props;

    const [isFocused, setIsFocused] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    const textFieldUniqueId = useMemo(() => id || uniqueId('text_field-'), [id]);

    const remainingLength = maxLength - (value?.length || 0);

    const StartAdornmentElement =
        isValidElement(startAdornment) && disabled ? cloneElement(startAdornment, { disabled }) : startAdornment;
    const EndAdornmentElement =
        isValidElement(endAdornment) && disabled ? cloneElement(endAdornment, { disabled }) : endAdornment;

    const inputProps = {
        ...(value !== undefined ? { value } : {}),
        ...(onChange ? { onChange: (event) => onChange(event.target.value) } : {}),
        id: textFieldUniqueId,
        name,
        type,
        placeholder,
        disabled,
        maxLength,
        readOnly,
        autoComplete,
        ...(required ? { 'aria-required': required } : {}),
        ...(inputRef ? { ref: inputRef } : {}),
        onClick: (e) => {
            setIsFocused(true);
            onClick?.(e);
        },
        onFocus: (e) => {
            setIsFocused(true);
            onFocus?.(e);
        },
        onBlur: (e) => {
            setIsTouched(true);
            setIsFocused(false);
            onBlur?.(e);
        },
    };

    const isErrorShown = error && isTouched;
    const isClearDisabled = value === undefined || value === '';

    return (
        <div
            className={clsx(
                classes.root,
                { [classes.fullWidth]: fullWidth, [classes.error]: isErrorShown, [classes.disabled]: disabled },
                className
            )}
        >
            <div>
                {label && (
                    <Label
                        disabled={disabled}
                        htmlFor={textFieldUniqueId}
                        label={label}
                        helperText={labelHelperText}
                        required={required}
                    />
                )}
                {header != null && <header className={classes.headerWrapper}>{header}</header>}
                <div
                    className={clsx(classes.inputWrapper, 'flex items-center', {
                        [classes.focused]: isFocused || focused,
                        [classes.textAreaWrapper]: multiline,
                        [classes.small]: small,
                        [classes.noMinWidth]: noMinWidth,
                        [classes.hasHeader]: header != null,
                    })}
                >
                    {!multiline && StartAdornmentElement}
                    {multiline ? (
                        <>
                            <textarea className={clsx(classes.textInput, classes.textArea)} {...inputProps} />
                            {maxLength && (
                                <span className={classes.lengthCounter}>
                                    {remainingLength}/{maxLength}
                                </span>
                            )}
                        </>
                    ) : (
                        <input
                            className={clsx(classes.textInput, 'item xs', {
                                [classes.textCenter]: textCenter,
                                truncate: ellipsis,
                            })}
                            {...inputProps}
                        />
                    )}
                    {warning && <Icon icon={['fas', 'exclamation-triangle']} size="sm" color="yellow-400" />}
                    {onClear && (
                        <InputAdornment tabIndex={-1} onClick={isClearDisabled ? undefined : () => onClear?.()}>
                            <Icon
                                className={clsx({ [classes.clearHover]: !isClearDisabled })}
                                color={isClearDisabled ? 'space-100' : 'space-200'}
                                icon={['fal', 'times']}
                            />
                        </InputAdornment>
                    )}
                    {!multiline && EndAdornmentElement}
                </div>
            </div>
            {isErrorShown && errorMessage && !disabled && (
                <div className={clsx(classes.errorMessageWrapper)}>
                    {/* TODO: Update this Icon to use "hexagon-exclamation" (according to design) when we updated FontAwesome to v6.  */}
                    <Icon size="sm" icon={['fas', 'exclamation-circle']} color="red-400" />
                    <span className={clsx(classes.errorMessage)}>{errorMessage}</span>
                </div>
            )}
        </div>
    );
};

TextField.defaultProps = {
    id: undefined,
    name: undefined,
    type: 'text',
    placeholder: undefined,
    label: undefined,
    labelHelperText: undefined,
    multiline: false,
    error: false,
    errorMessage: undefined,
    textCenter: false,
    fullWidth: false,
    small: false,
    required: false,
    disabled: false,
    maxLength: undefined,
    readOnly: false,
    ellipsis: false,
    autoComplete: 'on',
    startAdornment: undefined,
    endAdornment: undefined,
    inputRef: undefined,
    onClick: undefined,
    onFocus: undefined,
    onBlur: undefined,
    className: undefined,
    onChange: undefined,
    focused: false,
    value: undefined,
    header: undefined,
    warning: false,
    onClear: undefined,
    noMinWidth: false,
};

TextField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.oneOf(['text', 'number', 'password']),
    placeholder: PropTypes.string,
    label: PropTypes.string,
    labelHelperText: PropTypes.string,
    multiline: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    textCenter: PropTypes.bool,
    fullWidth: PropTypes.bool,
    small: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    readOnly: PropTypes.bool,
    ellipsis: PropTypes.bool,
    autoComplete: PropTypes.oneOf(['on', 'off']),
    startAdornment: PropTypes.element,
    endAdornment: PropTypes.element,
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    focused: PropTypes.bool,
    className: PropTypes.string,
    header: PropTypes.element,
    warning: PropTypes.bool,
    onClear: PropTypes.func,
    noMinWidth: PropTypes.bool,
};

export default TextField;
