const SIZE_UNITS = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

function getMatchedSizeUnit(size: number, options: { baseOnDecimal: boolean }) {
    const { baseOnDecimal = false } = options;
    const conversionBase = baseOnDecimal ? 1000 : 1024;
    const unitsWithValue = SIZE_UNITS.map((symbol, index) => ({ symbol, value: conversionBase ** index }));
    const baseUnit = unitsWithValue[0];
    const reversedUnits = unitsWithValue.reverse();
    const matchedUnit = reversedUnits.find((unit) => unit.value <= size) ?? baseUnit;

    return matchedUnit;
}

export function getReadableFileSize(fileSizeInBytes = 0, options: { baseOnDecimal?: boolean; showInBits?: boolean } = {}) {
    const { baseOnDecimal = false, showInBits = false } = options;
    const sizeToConvert = showInBits ? fileSizeInBytes * 8 : fileSizeInBytes;
    const { symbol, value } = getMatchedSizeUnit(sizeToConvert, { baseOnDecimal });
    const roundedSize = Math.round((sizeToConvert / value) * 100) / 100;
    const normalizedSize = parseFloat(roundedSize.toFixed(2));

    return `${normalizedSize} ${symbol}${showInBits ? 'b' : 'B'}`;
}
