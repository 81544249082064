import { mixed, number, object, string } from 'yup';

import { core as appClientCore, utils as appClientUtils } from '@edf-pkg/app-client';

const requestSchema = object().shape({
    payload: object().shape({
        timestamp: number().required(),
        level: string().required(),
        message: string().required(),
        os_version: string().required(),
        source: string().required(),
        extra_info: mixed(),
        stack_trace: string().required(),
        device_id: string().required(),
        app_version: number().required(),
        user_id: number().required(),
    }),
});

export default async function createLogEndpoint(payload) {
    try {
        const data = await requestSchema.validate({ payload });
        await appClientCore.axiosClientsManager
            .getClient('api')
            .post('/study/log/create/', appClientUtils.object.toFormData(data));
        return true;
    } catch (error) {
        appClientCore.errorMapper(error);
    }
    return null;
}
