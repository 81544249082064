const styles = (theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    errorPaper: {
        padding: theme.spacing(2, 2, 0, 2),
        textAlign: 'center',
    },
    errorMessage: {
        color: '#EB4A6F',
        paddingBottom: theme.spacing(4),
    },
});

export default styles;
