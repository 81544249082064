import { fade, lighten } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

const buttonTheme = (theme) =>
    createMuiTheme(theme, {
        props: {
            MuiButtonBase: {
                disableRipple: true,
            },
            MuiButton: {
                disableRipple: true,
            },
            MuiToggleButtonGroup: {
                size: 'small',
                exclusive: true,
            },
            MuiToggleButton: {
                disableRipple: true,
            },
        },
        overrides: {
            MuiButton: {
                root: {
                    borderRadius: 0,
                    padding: theme.spacing(1.75, 3),
                },
                textPrimary: {
                    color: theme.palette.primary.main,
                    '&:hover': {
                        color: theme.palette.primary.dark,
                        backgroundColor: 'transparent',
                        '@media (hover: none)': {
                            backgroundColor: 'transparent',
                        },
                    },
                    '&$disabled': {
                        color: theme.palette.action.disabled,
                    },
                },
                contained: {
                    color: theme.palette.default.contrastText,
                    backgroundColor: theme.palette.default.main,
                    boxShadow: theme.shadows.custom[1],
                    '&:hover': {
                        backgroundColor: theme.palette.default.dark,
                        boxShadow: theme.shadows.custom[1],
                        '@media (hover: none)': {
                            backgroundColor: theme.palette.default.dark,
                            boxShadow: theme.shadows.custom[1],
                        },
                        '&$disabled': {
                            backgroundColor: theme.palette.default.main,
                        },
                    },
                    '&$focusVisible': {
                        boxShadow: theme.shadows.custom[1],
                    },
                    '&:active': {
                        boxShadow: theme.shadows.custom[1],
                    },
                    '&$disabled': {
                        color: fade(theme.palette.default.contrastText, 0.3),
                        backgroundColor: theme.palette.default.main,
                        boxShadow: theme.shadows.custom[1],
                    },
                },
                startIcon: {
                    display: 'inherit',
                    marginRight: 8,
                    marginLeft: -4,
                    fontSize: theme.typography.pxToRem(24),
                },
                containedSizeSmall: {
                    padding: theme.spacing(1, 2),
                    fontSize: theme.typography.pxToRem(14),
                    '&$disabled': {
                        backgroundColor: fade(theme.palette.primary.main, 0.2),
                        color: fade(theme.palette.primary.contrastText, 0.5),
                    },
                },
                focusVisible: {},
                disabled: {},
            },
            MuiIconButton: {
                root: {
                    fontSize: theme.typography.pxToRem(22),
                    color: theme.palette.default.main,
                    '&:hover': {
                        color: theme.palette.default.light,
                        backgroundColor: 'transparent',
                        '@media (hover: none)': {
                            backgroundColor: 'transparent',
                        },
                    },
                    '&$disabled': {
                        color: theme.palette.action.disabled,
                    },
                },
                colorPrimary: {
                    color: theme.palette.primary.dark,
                    '&:hover': {
                        color: theme.palette.primary.main,
                        backgroundColor: 'transparent',
                        '@media (hover: none)': {
                            backgroundColor: 'transparent',
                        },
                    },
                    '&$disabled': {
                        color: theme.palette.action.disabled,
                    },
                },
            },
            MuiToggleButton: {
                root: {
                    color: theme.palette.default.dark,
                    backgroundColor: lighten(theme.palette.default.dark, 0.9),
                    borderColor: lighten(theme.palette.default.dark, 0.5),
                    '&$selected': {
                        backgroundColor: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                        color: theme.palette.default.light,
                        position: 'relative',
                        zIndex: 1,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        },
                    },
                },
                sizeSmall: {
                    height: 25,
                },
                selected: {},
            },
            MuiToggleButtonGroup: {
                grouped: {
                    '&:not(:first-child)': {
                        marginLeft: -4,
                        borderBottomLeftRadius: undefined,
                        borderTopLeftRadius: undefined,
                        borderLeft: undefined,
                    },
                    '&:not(:last-child)': {
                        borderBottomRightRadius: undefined,
                        borderTopRightRadius: undefined,
                    },
                },
            },
        },
    });

export default buttonTheme;
