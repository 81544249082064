import Language from '../core/language';

const pdashNamespace = import('../namespaces/es-co.pdash.namespace');
const webActivitiesNamespace = import('../namespaces/es-co.web-activities.namespace');
const rdashTranslatablesNamespace = import('../namespaces/es-co.rdash-translatables.namespace');

class EsCOLanguage extends Language {
    code = 'es-CO';

    locale = 'es-CO';

    localName = 'Colombiano';

    englishName = 'Colombian';

    territory = 'CO';

    notLoadedNamespaces = {
        pdash: pdashNamespace,
        webActivities: webActivitiesNamespace,
        rdashTranslatables: rdashTranslatablesNamespace,
    };
}

const instance = new EsCOLanguage();
export default instance;
