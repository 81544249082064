import Language from '../core/language';

const pdashNamespace = import('../namespaces/zu-zu.pdash.namespace');
const webActivitiesNamespace = import('../namespaces/zu-zu.web-activities.namespace');
const rdashTranslatablesNamespace = import('../namespaces/zu-zu.rdash-translatables.namespace');

class ZuZULanguage extends Language {
    code = 'zu-ZU';

    locale = 'zu';

    localName = 'IsiZulu';

    englishName = 'Zulu';

    territory = 'ZU';

    notLoadedNamespaces = {
        pdash: pdashNamespace,
        webActivities: webActivitiesNamespace,
        rdashTranslatables: rdashTranslatablesNamespace,
    };
}

const instance = new ZuZULanguage();
export default instance;
