import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    image: {
        boxShadow: theme.shadows.custom[2],
        width: '100%',
        height: '100%',
    },
    imageDisabled: {
        opacity: 0.6,
    },
}));
