import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import { useDialog } from '@edf-pkg/app-main';

import Dialog from '$app-web/components/dialog';
import { DIALOG_IDS } from '$app-web/constants';

const AccountDeletionRevokedDialog = () => {
    const { t } = useTranslation();
    const accountDeletionRevokedDialog = useDialog(DIALOG_IDS.ACCOUNT_DELETION_REVOKED);

    return (
        <Dialog
            title={t('pdash:delete_account_revoked_title')}
            content={<Trans t={t} i18nKey="pdash:delete_account_revoked_description" />}
            id={DIALOG_IDS.ACCOUNT_DELETION_REVOKED}
            actions={() => (
                <Button color="secondary" variant="contained" onClick={accountDeletionRevokedDialog.close}>
                    {t('ok')}
                </Button>
            )}
        />
    );
};

export default AccountDeletionRevokedDialog;
