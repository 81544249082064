import { memo } from 'react';

import LazyImage from '$app-web/components/lazy-image';

import useStyles from './styles';

interface Props {
    value: string;
    alt?: string;
}

const Image = ({ value, alt }: Props) => {
    const classes = useStyles();

    return <LazyImage className={classes.image} src={value} alt={alt} shadow={false} />;
};

export default memo(Image);
