import firebase from 'firebase/app';

import 'firebase/messaging';

const firebaseConfig = {
    apiKey: 'AIzaSyD8JkCvrUJGJCmZFEvC6OxY670QgT7i66Y',
    authDomain: 'ethica-d8624.firebaseapp.com',
    databaseURL: 'https://ethica-d8624.firebaseio.com',
    projectId: 'ethica-d8624',
    storageBucket: 'ethica-d8624.appspot.com',
    messagingSenderId: '88923320064',
    appId: '1:88923320064:web:b8f139a6bfe5c2b0db4de5',
};

firebase.initializeApp(firebaseConfig);

export const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
const publicKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;

export async function getToken(registration: ServiceWorkerRegistration) {
    if (messaging) {
        return messaging.getToken({ vapidKey: publicKey, serviceWorkerRegistration: registration });
    } else {
        return '';
    }
}
