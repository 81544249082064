import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => {
    const toolbarBackground = theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.background.default;
    const finalTBBackground =
        (theme.overrides.MuiPickersToolbar &&
            theme.overrides.MuiPickersToolbar.toolbar &&
            theme.overrides.MuiPickersToolbar.toolbar.backgroundColor) ||
        toolbarBackground;
    return {
        toolbar: {
            backgroundColor: finalTBBackground,
            color: theme.palette.getContrastText(finalTBBackground),
            height: 100,
        },
        toolbarLandscape: {
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        listSubheader: {
            backgroundColor: theme.palette.background.default,
        },
        timeSelectorWrapper: { height: '100%' },
        timeListWrapper: { height: '100%', width: '100%' },
        root: { width: '100%', height: 305 },
        timeSelectorContainer: { height: '100%', overflow: 'hidden' },
    };
});
