import { memo } from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';

import useStyles from './styles';
import { createDisplaySections } from './utils';

interface Props {
    value: number | string | Date;
    size?: 'small' | 'medium';
    variant?: 'date' | 'time' | 'date-time';
}

const Text = ({ value, variant = 'date-time', size = 'medium' }: Props) => {
    const classes = useStyles();
    const [top, middle, bottom] = createDisplaySections(variant, value);
    return (
        <div className={clsx({ [classes.smallSize]: size === 'small' })}>
            <Typography data-testid="top" align="center" className={classes.top}>
                {top}
                {/* space mocking if top is empty */}
                {!top && <span style={{ height: 14, display: 'block' }} />}
            </Typography>
            <Typography data-testid="middle" align="center" className={classes.middle}>
                {middle}
            </Typography>
            <Typography data-testid="bottom" align="center" className={classes.bottom}>
                {bottom}
            </Typography>
        </div>
    );
};

export default memo(Text);
