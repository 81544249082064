import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import { core as appClientCore, utils as appClientUtils } from '../../';
import { AUTHENTICATION_CLIENT_IDS, AUTHENTICATION_GRANT_TYPES, AUTHENTICATION_SCOPES } from '../../constants';

export interface ReturnValue {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    revokedDeletion?: boolean;
}

export interface Parameters {
    username?: string;
    password?: string;
    refreshToken?: string | null;
}

export async function fetchAuthenticationTokens({ username, password, refreshToken }: Parameters) {
    try {
        const normalizedPayload = snakeCaseKeys({
            clientId: AUTHENTICATION_CLIENT_IDS.WEB,
            scope: AUTHENTICATION_SCOPES.ALL,
            ...(username && { username, password, grantType: AUTHENTICATION_GRANT_TYPES.PASSWORD }),
            ...(refreshToken && { refreshToken, grantType: AUTHENTICATION_GRANT_TYPES.REFRESH_TOKEN }),
        });

        const response = await appClientCore.axiosClientsManager
            .getClient('api')
            .post('/v1/auth/token/', appClientUtils.object.toFormData(normalizedPayload));

        const normalizedData = camelcaseKeys(response.data);

        return normalizedData as unknown as ReturnValue;
    } catch (error) {
        appClientCore.errorMapper(error as Error);
        throw new Error('Failed to fetch authentication tokens');
    }
}
