import { memo } from 'react';

import VAS from '$app-web/components/vas';
import { useTranslator } from '$app-web/hooks/use-translator';

import classes from './styles.module.scss';

interface Props {
    onChange: (value: number) => void;
    anchors: Array<{
        index: number;
        text: string;
        type: string;
        url: string;
    }>;
    showSelectionValue?: boolean;
    showSelectionLabel?: boolean;
    isVertical?: boolean;
    labels?: {
        type: string;
        map: Array<unknown>;
    };
    step?: number;
    max?: number;
    min?: number;
    value?: number;
}

const TypeVAS = ({
    showSelectionValue = true,
    showSelectionLabel = true,
    isVertical = false,
    min = 0,
    max = 100,
    value = 0,
    step = 1,
    labels,
    onChange,
    anchors,
}: Props) => {
    const t = useTranslator();

    return (
        <>
            <VAS
                showSelectionLabel={showSelectionLabel}
                showSelectionValue={showSelectionValue}
                isVertical={isVertical}
                labels={labels}
                anchors={anchors}
                step={step}
                max={max}
                min={min}
                value={value}
                onChange={onChange}
            />
            {value == null ? <p className={classes.hintText}>{t('webActivities:time_pref_question_slider_tap_notice')}</p> : null}
        </>
    );
};

export default memo(TypeVAS);
