import { makeStyles } from '@material-ui/styles';

const generateIconSizeStyles = (theme, sizeInPixels) => ({
    fontSize: [theme.typography.pxToRem(sizeInPixels), '!important'],
    width: [sizeInPixels, '!important'],
    maxWidth: [sizeInPixels, '!important'],
    height: [sizeInPixels, '!important'],
    maxHeight: [sizeInPixels, '!important'],
});

export default makeStyles((theme) => ({
    contained: {
        background: theme.palette.background.gray.main,
        boxSizing: 'content-box',
    },
    extraSmall: {
        ...generateIconSizeStyles(theme, 8),
    },
    extraSmallContained: {
        padding: theme.spacing(0.5),
    },
    small: {
        ...generateIconSizeStyles(theme, 12),
    },
    smallContained: {
        padding: theme.spacing(0.75),
    },
    medium: {
        ...generateIconSizeStyles(theme, 16),
    },
    mediumContained: {
        padding: theme.spacing(1.25),
    },
    large: {
        ...generateIconSizeStyles(theme, 24),
    },
    largeContained: {
        padding: theme.spacing(1.5),
    },
    extraLarge: {
        ...generateIconSizeStyles(theme, 60),
    },
    extraLargeContained: {
        padding: theme.spacing(2.5),
    },
}));
