import readDataSourceOptions from './data-source-options.endpoint.api';
import userLogout from './user-logout.endpoint.api';
import userRead from './user-read.endpoint.api';

const client = {
    api: {
        userLogout,
        userRead,
        readDataSourceOptions,
    },
};

export default client;
