import { QUESTION_TYPES as Q_TYPES, SELECTABLE_QUESTION_TYPES } from '$app-web/constants';
import { SuperArray } from '$app-web/utils';

import { QUESTION_CHOICE_NEUTRAL_NEXT_SECTION_ID, QUESTION_CHOICE_NO_NEXT_SECTION_ID } from '../constants';
import { StoreQuestion } from '../store/types';
import { isAnswerValueEmpty, shouldPresentQuestion } from './helpers';

export function applySurveyFlow(questions: Array<StoreQuestion>): Array<StoreQuestion> {
    const questionsOfInitialSection = questions.filter((question) => question.sectionId === questions[0].sectionId);
    const visibleQuestions: Array<StoreQuestion> = [...questionsOfInitialSection];

    while (visibleQuestions.length < questions.length) {
        const lastQuestion = visibleQuestions[visibleQuestions.length - 1];
        const relatedQuestions = questions.filter(({ sectionId }) => sectionId === lastQuestion.sectionNextSection);

        if (relatedQuestions.length === 0 || visibleQuestions.includes(relatedQuestions[0])) {
            break;
        } else {
            visibleQuestions.push(...relatedQuestions);
        }
    }

    const finalQuestions = questions.map((question) => ({
        ...question,
        isHiddenBySectionsFlow: !visibleQuestions.includes(question),
    }));

    return finalQuestions;
}

function getNextSectionOfAnswer(question: StoreQuestion) {
    const { answers = [], answer, questionType } = question;

    if (!SELECTABLE_QUESTION_TYPES.includes(questionType)) {
        return QUESTION_CHOICE_NEUTRAL_NEXT_SECTION_ID;
    }

    const targetAnswer = Array.isArray(answer) ? (answer as Array<string>)[answer.length - 1] : (answer as string | null);
    const relatedAnswer = answers.find((item) => `${item.answerId}` === targetAnswer);

    return relatedAnswer?.nextSection ?? QUESTION_CHOICE_NEUTRAL_NEXT_SECTION_ID;
}

export function applyNextSectionsOfAnswer(questions: Array<StoreQuestion>) {
    let normalizedQuestions: Array<StoreQuestion> = questions.map((question) => ({ ...question, isHiddenByFlow: null }));

    for (const stringifiedIndex in normalizedQuestions) {
        const index = parseInt(stringifiedIndex);
        const currentQuestion = questions[index];
        const nextSection = getNextSectionOfAnswer(currentQuestion);
        const indexOfFirstQuestionOfNextSection = questions.findIndex((question) => question.sectionId === nextSection);
        const indexOfLastQuestionOfCurrentSection = questions.findLastIndex(
            ({ sectionId }) => currentQuestion.sectionId === sectionId
        );
        // QUESTION_CHOICE_NEUTRAL_NEXT_SECTION_ID refers to normal flow
        if (nextSection === QUESTION_CHOICE_NEUTRAL_NEXT_SECTION_ID) {
            continue;
        }
        // QUESTION_CHOICE_NO_NEXT_SECTION_ID refers to end of survey
        else if (nextSection === QUESTION_CHOICE_NO_NEXT_SECTION_ID) {
            for (let counter = indexOfLastQuestionOfCurrentSection + 1; counter < questions.length; counter++) {
                normalizedQuestions[counter].isHiddenByFlow = true;
            }
        } else if (indexOfFirstQuestionOfNextSection < index) {
            break; // This is wrong scenario and researcher should be aware of this
        } else {
            for (let counter = indexOfLastQuestionOfCurrentSection + 1; counter < indexOfFirstQuestionOfNextSection; counter++) {
                normalizedQuestions[counter].isHiddenByFlow = true;
            }

            let isFinishSurvey = false;
            normalizedQuestions = normalizedQuestions.map((question) => {
                if (question.sectionNextSection === QUESTION_CHOICE_NO_NEXT_SECTION_ID && shouldPresentQuestion(question)) {
                    isFinishSurvey = true;
                }

                if (!isFinishSurvey && question.isHiddenBySectionsFlow && question.sectionId >= nextSection) {
                    return { ...question, isHiddenByFlow: false };
                } else {
                    return question;
                }
            });
        }
    }

    return normalizedQuestions;
}

export function applyEnablersAnswers(questions: Array<StoreQuestion>): Array<StoreQuestion> {
    const validSelectableQuestions = questions.filter(
        (question) => SELECTABLE_QUESTION_TYPES.includes(question.questionType) && !isAnswerValueEmpty(question.answer)
    );
    const questionIdsShouldBeEnabled = validSelectableQuestions.reduce((acc, question) => {
        const { questionType, answer, answers = [] } = question;
        const normalizedAnswer = questionType === Q_TYPES.SINGLE_CHOICE ? [answer as string] : (answer as Array<string>);
        const matchedAnswers = answers.filter(
            ({ answerId, enableQuestion }) => normalizedAnswer.includes(`${answerId}`) && enableQuestion !== ''
        );
        const enableQuestionIds = matchedAnswers.map(({ enableQuestion }) => parseInt(enableQuestion));

        return [...acc, ...enableQuestionIds];
    }, [] as Array<number>);
    const uniqueQuestionIdsShouldBeEnabled = SuperArray.removeDuplicates(questionIdsShouldBeEnabled);

    return questions.map((question) => ({
        ...question,
        isEnabledByAnswers: uniqueQuestionIdsShouldBeEnabled.includes(question.questionId),
    }));
}
