import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from '$app-web/components/snackbar';

const SnackbarHandler = (props) => {
    const { toShowSnackbar, shouldOpen, snackbarOpened, snackbarClosed, snackbarExited } = props;
    return (
        <Snackbar
            message={toShowSnackbar.message}
            id={toShowSnackbar.id}
            variant={toShowSnackbar.variant}
            open={shouldOpen}
            onOpen={snackbarOpened}
            onClose={snackbarClosed}
            onExited={snackbarExited}
        />
    );
};

SnackbarHandler.propTypes = {
    toShowSnackbar: PropTypes.objectOf(PropTypes.string).isRequired,
    shouldOpen: PropTypes.bool.isRequired,
    snackbarOpened: PropTypes.func.isRequired,
    snackbarClosed: PropTypes.func.isRequired,
    snackbarExited: PropTypes.func.isRequired,
};

export default SnackbarHandler;
