import React from 'react';
import PropTypes from 'prop-types';

import LayoutBase from '$app-web/layouts/base';

const LayoutFull = ({ wrapper, content, wrapperProps }) => {
    const Wrapper = wrapper;
    return <LayoutBase>{wrapper ? <Wrapper {...wrapperProps}>{content}</Wrapper> : <>{content}</>}</LayoutBase>;
};

LayoutFull.defaultProps = {
    wrapper: null,
    wrapperProps: undefined,
};

LayoutFull.propTypes = {
    wrapper: PropTypes.elementType,
    content: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
    wrapperProps: PropTypes.objectOf(PropTypes.any),
};

export default LayoutFull;
