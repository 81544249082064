import { SuperArray } from './array';

export abstract class SuperString {
    private static readonly DIGITS = '0123456789';

    private static readonly LETTERS = 'abcdefghijklmnopqrstuvwxyz';

    static toCamelCase(str: string) {
        return str.replace(/[-_]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
    }

    static toSnakeCase(str: string) {
        return str.replace(/([A-Z])/g, '_$1').toLowerCase();
    }

    static getRandomChars(length = 16, options: { onlyLetters?: boolean; onlyDigits?: boolean } = {}) {
        const { onlyLetters = false, onlyDigits = false } = options;

        const selectableChars: Array<string> = [];

        if (onlyLetters && onlyDigits) {
            throw new Error('Only one option can be true!');
        } else if (onlyLetters) {
            selectableChars.push(...SuperString.LETTERS);
        } else if (onlyDigits) {
            selectableChars.push(...SuperString.DIGITS);
        } else {
            selectableChars.push(...SuperString.LETTERS, ...SuperString.DIGITS);
        }

        const selectedChars = Array.from({ length }, () => SuperArray.getRandomElement(selectableChars));

        return selectedChars.join('');
    }

    static generateUniqueId() {
        const timeId = new Date().getTime().toString(36);
        const randomChars = SuperString.getRandomChars();
        const microTimeId = performance.now().toString(36).replace('.', '').padStart(8, '0').slice(0, 8);

        return `${timeId}-${randomChars}-${microTimeId}`;
    }
}
