class TimeoutError extends Error {
    constructor() {
        super('Timeout');
        this.name = 'TimeoutError';
    }
}

async function wait(delayTime) {
    await new Promise((resolve) => {
        setTimeout(resolve, delayTime);
    });
}

export async function getDelayedPromise(promise, delayTime) {
    wait(delayTime);
    return promise;
}

export async function callPromiseWithTimeout(promise, timeoutTime) {
    let timeoutId;

    try {
        const result = await Promise.race([
            promise,
            new Promise((_, reject) => {
                timeoutId = setTimeout(() => {
                    reject(new TimeoutError());
                }, timeoutTime);
            }),
        ]);

        return result;
    } finally {
        clearTimeout(timeoutId);
    }
}
