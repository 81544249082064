import { fade, lighten } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

const selectTheme = (theme) =>
    createMuiTheme(theme, {
        props: {
            MuiSelect: {
                disableUnderline: true,
                IconComponent: (props) => <FontAwesomeIcon {...props} icon={['fal', 'angle-down']} />,
                autoWidth: false,
                MenuProps: {
                    variant: 'menu',
                    elevation: 0,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: -2,
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    MenuListProps: {
                        disablePadding: true,
                    },
                    PaperProps: {
                        // A hack in conjunction with autoWith false to make the menu with as same as parent select width
                        style: { maxWidth: 0 },
                    },
                },
            },
        },
        overrides: {
            MuiSelect: {
                root: {
                    backgroundColor: fade(theme.palette.default.main, 0.1),
                },
                select: {
                    boxShadow: theme.shadows.custom[1],
                    padding: theme.spacing(1.25),
                    fontSize: theme.typography.pxToRem(12),
                },
                selectMenu: {
                    fontSize: theme.typography.pxToRem(12),
                },
                icon: {
                    top: undefined,
                    color: undefined,
                    right: 16,
                },
            },
            /*
             TODO: The following changes on Menu affects whole menus, so move it to a separate Select component.
             */
            MuiMenuItem: {
                root: {
                    // TODO: this adds an extra divider to the first item
                    '&::before': {
                        content: '""',
                        width: 'calc(100% - 32px)',
                        position: 'absolute',
                        height: 1,
                        backgroundColor: fade(theme.palette.default.dark, 0.05),
                        top: 0,
                        left: 16,
                    },
                    fontSize: theme.typography.pxToRem(12),
                    whiteSpace: undefined,
                    padding: theme.spacing(1.25, 2),
                    paddingTop: undefined,
                    paddingBottom: undefined,
                },
            },
            MuiMenu: {
                paper: {
                    backgroundColor: lighten(theme.palette.default.main, 0.9),
                    boxShadow: theme.shadows.custom[1],
                    borderRadius: 0,
                },
            },
        },
    });

export default selectTheme;
