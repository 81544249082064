import { memo } from 'react';
import { FilledInput, Typography } from '@material-ui/core';

import { useTranslator } from '$app-web/hooks/use-translator';

import RoundFrameBase from '../round-frame-base';
import classes from './styles.module.scss';

interface Props {
    value: string;
    onChange: (value: string) => void;
}

const TypeBarcode = ({ value, onChange }: Props) => {
    const t = useTranslator();

    return (
        <RoundFrameBase
            roundFrameProps={{ variant: 'barcode' }}
            mainSlot={
                <>
                    <Typography variant="subtitle2" className={classes.inputLabel}>
                        {t('webActivities:barcode_description')}
                    </Typography>
                    <FilledInput value={value} onChange={(event) => onChange(event.target.value)} />
                </>
            }
            secondSlotButtonProps={
                value !== '' ? { children: t('webActivities:delete'), onClick: () => onChange('') } : undefined
            }
        />
    );
};

export default memo(TypeBarcode);
