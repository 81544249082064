import { put, select, takeLatest } from 'redux-saga/effects';

import {
    duckActionCreators as dialogActionCreators,
    duckActions as dialogActions,
    duckSelectors as dialogSelectors,
} from './dialog.duck';

export const SAGA_NAME = 'DIALOG';

function* handleCloseDialog(action) {
    const dialogs = yield select((state) => dialogSelectors.dialogsSelector(state));
    const { dialogId } = action.payload;
    if (dialogs[dialogId] && dialogs[dialogId].opened === true) {
        yield put(dialogActionCreators.setOpenStatusClosed(dialogId));
    }
}

function* handleOpenDialog(action) {
    const dialogs = yield select((state) => dialogSelectors.dialogsSelector(state));
    const { dialogId } = action.payload;
    if (dialogs[dialogId] && dialogs[dialogId].opened === false) {
        yield put(dialogActionCreators.setOpenStatusOpened(dialogId));
    }
}

function* handleUnregisterDialog(action) {
    const { dialogId } = action.payload;
    yield put(dialogActionCreators.remove(dialogId));
}

function* handleRegisterDialog(action) {
    const { dialogId } = action.payload;
    yield put(dialogActionCreators.add(dialogId));
}

export default function* dialogSaga() {
    yield takeLatest(dialogActions.REGISTER, handleRegisterDialog);
    yield takeLatest(dialogActions.UNREGISTER, handleUnregisterDialog);
    yield takeLatest(dialogActions.OPEN, handleOpenDialog);
    yield takeLatest(dialogActions.CLOSE, handleCloseDialog);
}
