import { memo, useMemo, useState } from 'react';

import DateTimePicker from '$app-web/components/date-time-picker';
import { useTranslator } from '$app-web/hooks/use-translator';

import RoundFrameBase from '../round-frame-base';

type Period = [Date | null, Date | null];

function normalizeDate(dateTime: null | Date) {
    if (dateTime == null) {
        return null;
    }
    return dateTime.toString();
}

interface Props {
    onChange: (value: Period) => void;
    value?: Period;
    variant?: 'date-time' | 'date' | 'time';
    requirePeriod?: boolean;
    minDate?: string;
    maxDate?: string;
    minuteInterval?: number;
}

const TypeCalendar = ({
    onChange,
    value = [null, null],
    minDate,
    maxDate,
    minuteInterval = 1,
    variant = 'date-time',
    requirePeriod = false,
}: Props) => {
    const t = useTranslator();

    const [open, setOpen] = useState(false);
    const [openFrom, setOpenFrom] = useState(false);
    const [openTo, setOpenTo] = useState(false);

    const texts = useMemo(
        () => ({
            date: t('webActivities:survey_question_calendar_select_date'),
            time: t('webActivities:survey_question_calendar_select_time'),
            'date-time': t('webActivities:survey_question_calendar_select_datetime'),
        }),
        [t]
    );
    const mainButtonText = texts[variant];
    const isEmpty = value[0] == null && value[1] == null;

    const roundFrameBaseComponent = (
        <RoundFrameBase
            roundFrameProps={
                requirePeriod ? undefined : { type: value[0] == null ? 'icon' : 'text', variant, value: normalizeDate(value[0]) }
            }
            periodProps={
                requirePeriod
                    ? {
                          variant,
                          items: value.map((item) => ({
                              type: item == null ? 'icon' : 'text',
                              value: item == null ? null : item.toString(),
                          })),
                      }
                    : undefined
            }
            mainSlotButtonProps={
                requirePeriod
                    ? {
                          children: isEmpty
                              ? t('webActivities:survey_question_calendar_select_period')
                              : t('webActivities:change'),
                          onClick: () => setOpenFrom(true),
                      }
                    : {
                          children: isEmpty ? mainButtonText : t('webActivities:change'),
                          onClick: () => setOpen(true),
                      }
            }
            secondSlotButtonProps={
                !isEmpty ? { children: t('webActivities:delete'), onClick: () => onChange([null, null]) } : undefined
            }
        />
    );

    if (requirePeriod) {
        return (
            <>
                <DateTimePicker
                    variant={variant}
                    onChange={(dateString: string | null) => {
                        onChange([dateString == null ? null : new Date(dateString), value[1]]);
                    }}
                    open={openFrom}
                    minDate={minDate}
                    maxDate={normalizeDate(value[1])}
                    minuteInterval={minuteInterval}
                    close={() => {
                        setOpenFrom(false);
                        setOpenTo(true);
                    }}
                    value={normalizeDate(value[0])}
                    clearable
                />
                <DateTimePicker
                    variant={variant}
                    minDate={normalizeDate(value[0])}
                    maxDate={maxDate}
                    onChange={(dateString: string | null) => {
                        onChange([value[0], dateString == null ? null : new Date(dateString)]);
                    }}
                    open={openTo}
                    minuteInterval={minuteInterval}
                    close={() => setOpenTo(false)}
                    value={normalizeDate(value[1])}
                    clearable
                />
                {roundFrameBaseComponent}
            </>
        );
    }

    return (
        <>
            <DateTimePicker
                variant={variant}
                onChange={(dateString: string | null) => {
                    onChange([dateString == null ? null : new Date(dateString), null]);
                }}
                open={open}
                close={() => setOpen(false)}
                minDate={minDate}
                maxDate={maxDate}
                minuteInterval={minuteInterval}
                value={normalizeDate(value[0])}
                clearable
            />
            {roundFrameBaseComponent}
        </>
    );
};

export default memo(TypeCalendar);
