import { SELECTABLE_QUESTION_TYPES } from '$app-web/constants';
import type { Answer, Section, Survey, SurveyHierarchy } from '$app-web/types';
import { SuperArray } from '$app-web/utils';

import { StoreQuestion } from '../store/types';

export function generateSurveyHierarchy({
    sections,
    sectionRandomOrder,
    sectionRandomSelection = {
        sectionIds: [],
        selectionCount: 0,
    },
}: {
    sections: Array<Section>;
    sectionRandomOrder: boolean;
    sectionRandomSelection: Survey['subSurveys'][0]['sectionRandomSelection'];
}): SurveyHierarchy {
    const { sectionIds: randomSectionIds = [], selectionCount: randomSectionsCount = 0 } = sectionRandomSelection;

    const allSectionIds = sections.map(({ id }) => id);
    const normalSectionIds = SuperArray.getDifference(allSectionIds, randomSectionIds);
    const allowedRandomSectionIds = SuperArray.getRandomElements(randomSectionIds, randomSectionsCount);
    const allowedSectionIds = [...allowedRandomSectionIds, ...normalSectionIds];
    const allowedSections = sections.filter(({ id }) => allowedSectionIds.includes(id));
    const shuffledSections = sectionRandomOrder ? SuperArray.shuffle(allowedSections) : allowedSections;

    const updatedSections = shuffledSections.map((section) => {
        const { questions, questionRandomOrder, questionRandomSelection } = section;
        const { questionIds: randomQuestionIds = [], selectionCount: randomQuestionsCount = 0 } = questionRandomSelection ?? {};
        const allQuestionIds = questions.map((question) => question.questionId);
        const normalQuestionIds = SuperArray.getDifference(allQuestionIds, randomQuestionIds);
        const allowedRandomQuestionIds = SuperArray.getRandomElements(randomQuestionIds, randomQuestionsCount);
        const allowedQuestionIds = [...allowedRandomQuestionIds, ...normalQuestionIds];
        const allowedQuestions = questions.filter((question) => allowedQuestionIds.includes(question.questionId));
        const shuffledQuestions = questionRandomOrder ? SuperArray.shuffle(allowedQuestions) : allowedQuestions;

        const updatedQuestions = shuffledQuestions.map((question) => {
            const { answers, answerRandomOrder, answerRandomSelection = { selectionCount: 0, answerIds: [] } } = question;
            const { answerIds: randomAnswerIds = [], selectionCount: randomAnswersCount = 0 } = answerRandomSelection;

            if (answers == null || answers.length === 0) {
                return question;
            }

            const allAnswerIds = answers.map((answer) => answer.answerId);
            const normalAnswerIds = SuperArray.getDifference(allAnswerIds, randomAnswerIds);
            const allowedRandomAnswerIds = SuperArray.getRandomElements(randomAnswerIds, randomAnswersCount);
            const allowedAnswerIds = [...allowedRandomAnswerIds, ...normalAnswerIds];
            const allowedAnswers = answers.filter((answer) => allowedAnswerIds.includes(answer.answerId));
            const shuffledAnswers = answerRandomOrder ? SuperArray.shuffle(allowedAnswers) : allowedAnswers;

            return { ...question, answers: shuffledAnswers };
        });

        return { ...section, questions: updatedQuestions };
    });

    return updatedSections.map((section) => {
        return {
            index: section.id,
            questions: section.questions.map((question) => {
                const answers = question.answers ?? [];
                const answerIds = answers.map(({ answerId }) => answerId);

                return { id: question.questionId, ...(answerIds.length === 0 ? {} : { answerIds }) };
            }),
        };
    });
}

export function applySurveyRandomization(
    questions: Array<StoreQuestion>,
    initialSurveyHierarchy: SurveyHierarchy = []
): Array<StoreQuestion> {
    if (initialSurveyHierarchy == null || initialSurveyHierarchy.length === 0) {
        return questions;
    }

    const questionsHierarchy = initialSurveyHierarchy.flatMap(({ questions }) => questions);

    const normalizedQuestions = questions.map((question) => {
        const isHiddenBySectionRandomization = initialSurveyHierarchy.every(({ index }) => index !== question.sectionId);
        const isHiddenByQuestionRandomization = questionsHierarchy.every(({ id }) => id !== question.questionId);

        return {
            ...question,
            isHiddenBySectionsRandomization: isHiddenBySectionRandomization,
            isHiddenByRandomization: isHiddenBySectionRandomization ? false : isHiddenByQuestionRandomization,
        };
    });

    const visibleQuestions = normalizedQuestions.filter(
        (question) => !question.isHiddenBySectionsRandomization && !question.isHiddenByRandomization
    );
    const reorderedVisibleQuestions = questionsHierarchy.map(
        ({ id }) => visibleQuestions.find((item) => item.questionId === id)!
    );
    const hiddenQuestions = normalizedQuestions.filter(
        (question) => question.isHiddenBySectionsRandomization || question.isHiddenByRandomization
    );
    const reorderedQuestions = [...reorderedVisibleQuestions, ...hiddenQuestions];

    const updatedQuestions = reorderedQuestions.map((question) => {
        if (SELECTABLE_QUESTION_TYPES.includes(question.questionType)) {
            const questionHierarchyState = questionsHierarchy.find((item) => item.id === question.questionId);

            if (
                questionHierarchyState == null ||
                questionHierarchyState.answerIds == null ||
                questionHierarchyState.answerIds.length === 0 ||
                question.answers == null
            ) {
                return question;
            }

            const answerIds = questionHierarchyState.answerIds ?? [];
            const filteredAnswers = question.answers.filter((answer) => answerIds.includes(answer.answerId));
            const reorderedAnswers = answerIds.map((id) => filteredAnswers.find((item) => item.answerId === id)) as Array<Answer>;

            return { ...question, answers: reorderedAnswers };
        }
        return question;
    });

    return updatedQuestions;
}
