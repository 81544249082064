import { memo } from 'react';
import { ReactComponent as DateTimeIcon } from '@assets/images/survey/date-time-icon.svg';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

const DATE_TIME_ICON_HEIGHT_BY_SIZES = {
    medium: 43,
    large: 56,
};

const sizes = {
    small: '2x',
    medium: '3x',
    large: '5x',
};

const variantIcons = {
    camera: ['fad', 'camera-alt'],
    microphone: ['fad', 'microphone-alt'],
    'audio-recording': ['fad', 'microphone-alt'],
    'audio-playing': ['fas', 'waveform'],
    video: ['fad', 'video'],
    'video-playing': ['fad', 'video'],
    barcode: ['fad', 'barcode-read'],
    date: ['fad', 'calendar-alt'],
    time: ['fad', 'clock'],
};

interface Props {
    iconSize?: 'small' | 'medium' | 'large';
    variant:
        | 'camera'
        | 'microphone'
        | 'video'
        | 'barcode'
        | 'date-time'
        | 'date'
        | 'time'
        | 'audio-recording'
        | 'audio-playing'
        | 'video-playing';
}

const Icon = ({ iconSize = 'large', variant }: Props) => {
    if (variant === 'date-time') {
        return <DateTimeIcon style={{ height: DATE_TIME_ICON_HEIGHT_BY_SIZES[iconSize !== 'small' ? iconSize : 'medium'] }} />;
    }

    if (variant === 'audio-playing') {
        return (
            <>
                <FontAwesomeIcon size={sizes[iconSize]} icon={variantIcons[variant]} />
                <FontAwesomeIcon size={sizes[iconSize]} icon={variantIcons[variant]} />
                <FontAwesomeIcon size={sizes[iconSize]} icon={variantIcons[variant]} />
            </>
        );
    }

    return <FontAwesomeIcon size={sizes[iconSize]} icon={variantIcons[variant]} swapOpacity={variant === 'time'} />;
};

export default memo(Icon);
