import { memo, useCallback, useState } from 'react';

import { useTranslator } from '$app-web/hooks/use-translator';
import { SuperNumber } from '$app-web/utils';

import TypeNumber from '../number';
import classes from './style.module.scss';

interface Props {
    value: number | null;
    min?: number;
    max?: number;
    step?: number;
    defaultUnit?: 'cm' | 'ft_in';
    prefUnit?: 'cm' | 'ft_in';
    defaultValue?: number;
    onChange: (value: number | null) => void;
}

const TypeLength = ({
    min = 0,
    max = Number.MAX_VALUE,
    step = 1,
    value,
    defaultUnit = 'cm',
    prefUnit,
    defaultValue = Math.max(0, min),
    onChange,
}: Props) => {
    const t = useTranslator();
    const [selectedUnit, setSelectedUnit] = useState(prefUnit ?? defaultUnit);
    const switchOnCentimeters = useCallback(() => setSelectedUnit('cm'), []);
    const switchOnFeetAndInches = useCallback(() => setSelectedUnit('ft_in'), []);

    const { feet, inches } = SuperNumber.centimetersToFeetAndInches(value || 0);
    const { feet: minFeet, inches: minInches } = SuperNumber.centimetersToFeetAndInches(min);
    const { feet: maxFeet, inches: maxInches } = SuperNumber.centimetersToFeetAndInches(max);
    const { feet: defaultValueFeet, inches: defaultValueInches } = SuperNumber.centimetersToFeetAndInches(defaultValue);

    const changeFeet = useCallback(
        (feetValue: number | null) => {
            if (feetValue == null && inches === 0) {
                onChange(null);
            } else if (feetValue === minFeet && inches < minInches) {
                const centimeters = SuperNumber.feetAndInchesToCentimeters(feetValue!, minInches);
                onChange(centimeters);
            } else if (feetValue === maxFeet && inches > maxInches) {
                const centimeters = SuperNumber.feetAndInchesToCentimeters(feetValue!, maxInches);
                onChange(centimeters);
            } else {
                const centimeters = SuperNumber.feetAndInchesToCentimeters(feetValue!, inches);
                onChange(centimeters);
            }
        },
        [inches, maxFeet, maxInches, minFeet, minInches, onChange]
    );

    const changeInches = useCallback(
        (inchesValue: number | null) => {
            const feetOfInches = inchesValue == null ? 0 : Math.floor(inchesValue / 12);
            const validInches = inchesValue == null ? 0 : inchesValue % 12;

            if (inchesValue == null && feet === 0) {
                onChange(null);
            } else {
                const centimeters = SuperNumber.feetAndInchesToCentimeters(feet + feetOfInches, validInches);
                onChange(centimeters);
            }
        },
        [feet, onChange]
    );

    return (
        <div className={classes.container}>
            {selectedUnit === 'cm' ? (
                <TypeNumber min={min} max={max} step={step} value={value} defaultValue={defaultValue} onChange={onChange} />
            ) : (
                <div className={classes.inputsWrapper}>
                    <div>
                        <TypeNumber
                            min={minFeet}
                            max={maxFeet}
                            step={step}
                            value={value == null ? null : feet}
                            defaultValue={defaultValueFeet}
                            onChange={changeFeet}
                        />
                        <span>{t('webActivities:foot_label')}</span>
                    </div>
                    <div>
                        <TypeNumber
                            key={`${feet === minFeet}-${feet === maxFeet}`}
                            min={feet === minFeet ? minInches : 0}
                            max={feet === maxFeet ? maxInches : 11}
                            step={1}
                            value={value == null ? null : inches}
                            defaultValue={defaultValueInches}
                            onChange={changeInches}
                        />
                        <span>{t('webActivities:inch_label')}</span>
                    </div>
                </div>
            )}
            <div className={classes.unitsWrapper}>
                <button type="button" onClick={switchOnCentimeters} disabled={selectedUnit === 'cm'}>
                    {t('webActivities:centimeters')}
                </button>
                <button type="button" onClick={switchOnFeetAndInches} disabled={selectedUnit === 'ft_in'}>
                    {t('webActivities:foot_label')} {t('webActivities:inch_label')}
                </button>
            </div>
        </div>
    );
};

export default memo(TypeLength);
