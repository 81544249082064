import Language from '../core/language';

const pdashNamespace = import('../namespaces/cy-gb.pdash.namespace');
const webActivitiesNamespace = import('../namespaces/cy-gb.web-activities.namespace');
const rdashTranslatablesNamespace = import('../namespaces/cy-gb.rdash-translatables.namespace');

class CyGBLanguage extends Language {
    code = 'cy-GB';

    locale = 'cy';

    localName = 'Welsh';

    englishName = 'Welsh';

    territory = 'GB';

    notLoadedNamespaces = {
        pdash: pdashNamespace,
        webActivities: webActivitiesNamespace,
        rdashTranslatables: rdashTranslatablesNamespace,
    };
}

const instance = new CyGBLanguage();
export default instance;
