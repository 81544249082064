import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import uuid from 'uuid';

import { EDFError, errorHandle } from '@edf-pkg/app-error';

import Activity from '$app-web/components/activity';
import { BackHandlerProvider } from '$app-web/context/back-handler.context';

import { webActivitiesDuck } from './store';
import webActivityHelper from './utils';

class WebActivitiesContainer extends Component {
    constructor(props) {
        super(props);
        this.backHandlerEventTarget = new EventTarget();
        this.state = {
            activityUUID: '',
        };
    }

    componentDidMount() {
        const { setCurrentUrl, callInjectedOnLoadingCompleted, location } = this.props;
        webActivityHelper.jsToNativeHelper.registerFunctionHandler('onUrlChanged', async function onUrlChangedHandler(rawData) {
            // eslint-disable-next-line no-console
            console.log('onUrlChanged function handler called.');
            try {
                const data = JSON.parse(rawData);
                setCurrentUrl(data.new_url);
            } catch (error) {
                errorHandle.anError(new EDFError('An error occurred during parsing data from onUrlChanged.', error));
            }
        });
        const goBack = this.handleGoBack;
        webActivityHelper.jsToNativeHelper.registerFunctionHandler(
            'onBackPressed',
            async function appContainerOnBackPressedHandler() {
                // eslint-disable-next-line no-console
                console.log('onBackPressed function handler called.');
                const activityId = location.pathname.replace(process.env.PUBLIC_URL, '').replace('/web-activities/', '');
                if (activityId !== 'asa24') {
                    goBack();
                }
            }
        );
        this.setState({ activityUUID: uuid() });
        callInjectedOnLoadingCompleted();
    }

    handleOnClose = (finished) => {
        const { callInjectedBack, callInjectedExit } = this.props;
        if (finished) {
            callInjectedExit();
        } else {
            callInjectedBack();
        }
    };

    handleGoBack = () => {
        this.backHandlerEventTarget.dispatchEvent(new Event('onBackPressed'));
    };

    render() {
        const { sessionInfo, options, location } = this.props;

        const { activityUUID } = this.state;
        const activityType = location.pathname.replace(process.env.PUBLIC_URL, '').replace('/web-activities/', '');

        return (
            activityUUID !== '' && (
                <BackHandlerProvider backHandlerEventTarget={this.backHandlerEventTarget}>
                    <Activity
                        sessionInfo={sessionInfo}
                        options={options}
                        type={activityType}
                        activityUUID={activityUUID}
                        onClose={this.handleOnClose}
                        ensurePHRSent
                    />
                </BackHandlerProvider>
            )
        );
    }
}

WebActivitiesContainer.propTypes = {
    setCurrentUrl: PropTypes.func.isRequired,
    callInjectedBack: PropTypes.func.isRequired,
    callInjectedExit: PropTypes.func.isRequired,
    callInjectedOnLoadingCompleted: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    sessionInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
    options: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapStateToProps = function mapDispatchToProps(state) {
    return {
        sessionInfo: webActivitiesDuck.duckSelectors.sessionInfoSelector(state),
        options: webActivitiesDuck.duckSelectors.optionsSelector(state),
    };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
    return {
        setCurrentUrl: (currentUrl) => dispatch(webActivitiesDuck.duckActionCreators.setCurrentURL(currentUrl)),
        callInjectedBack: () => dispatch(webActivitiesDuck.duckActionCreators.callInjectedBack()),
        callInjectedExit: () => dispatch(webActivitiesDuck.duckActionCreators.callInjectedExit()),
        callInjectedOnLoadingCompleted: () => dispatch(webActivitiesDuck.duckActionCreators.callInjectedOnLoadingCompleted()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WebActivitiesContainer)));
