/* eslint-disable */
// Generated from criteria.g4 by ANTLR 4.8
var antlr4 = require('antlr4/index');

// This class defines a complete listener for a parse tree produced by criteriaParser.
function criteriaListener() {
    antlr4.tree.ParseTreeListener.call(this);
    return this;
}

criteriaListener.prototype = Object.create(antlr4.tree.ParseTreeListener.prototype);
criteriaListener.prototype.constructor = criteriaListener;

// Enter a parse tree produced by criteriaParser#mainRule.
criteriaListener.prototype.enterMainRule = function (ctx) {};

// Exit a parse tree produced by criteriaParser#mainRule.
criteriaListener.prototype.exitMainRule = function (ctx) {};

// Enter a parse tree produced by criteriaParser#opLogicExpr.
criteriaListener.prototype.enterOpLogicExpr = function (ctx) {};

// Exit a parse tree produced by criteriaParser#opLogicExpr.
criteriaListener.prototype.exitOpLogicExpr = function (ctx) {};

// Enter a parse tree produced by criteriaParser#atomExpr.
criteriaListener.prototype.enterAtomExpr = function (ctx) {};

// Exit a parse tree produced by criteriaParser#atomExpr.
criteriaListener.prototype.exitAtomExpr = function (ctx) {};

// Enter a parse tree produced by criteriaParser#parenExpr.
criteriaListener.prototype.enterParenExpr = function (ctx) {};

// Exit a parse tree produced by criteriaParser#parenExpr.
criteriaListener.prototype.exitParenExpr = function (ctx) {};

// Enter a parse tree produced by criteriaParser#opCompareExpr.
criteriaListener.prototype.enterOpCompareExpr = function (ctx) {};

// Exit a parse tree produced by criteriaParser#opCompareExpr.
criteriaListener.prototype.exitOpCompareExpr = function (ctx) {};

// Enter a parse tree produced by criteriaParser#atom.
criteriaListener.prototype.enterAtom = function (ctx) {};

// Exit a parse tree produced by criteriaParser#atom.
criteriaListener.prototype.exitAtom = function (ctx) {};

exports.criteriaListener = criteriaListener;
