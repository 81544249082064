import React, { useCallback } from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';

import Icon from '$app-web/ui-kit/components/icon';

import classes from './styles.module.scss';

const Chip = (props) => {
    const { label, onClick, size, onDelete, color, margin, disabled, className } = props;

    const clickable = onClick && !disabled;

    const handleDelete = useCallback(
        (e) => {
            if (!disabled) {
                e.stopPropagation();
                onDelete(e);
            }
        },
        [disabled, onDelete]
    );

    return (
        <div
            className={clsx(
                classes.root,
                {
                    // TODO: Refactor this and make use of the new background-color util instead
                    [classes.green]: color === 'green',
                    [classes.red]: color === 'red',
                    [classes.small]: size === 'small',
                    [classes.margin]: margin === true,
                    [classes.topMargin]: margin === 'top',
                    [classes.bottomMargin]: margin === 'bottom',
                    [classes.leftMargin]: margin === 'left',
                    [classes.rightMargin]: margin === 'right',
                    [classes.verticalMargin]: margin === 'vertical',
                    [classes.horizontalMargin]: margin === 'horizontal',
                    [classes.disabled]: disabled,
                    [classes.clickable]: clickable,
                },
                className
            )}
            {...(clickable ? { onClick } : {})}
        >
            {onDelete && (
                // TODO: We should be using the IconButton component. Currently our IconButton doesn't support this circular shape.
                <button
                    className={clsx(classes.iconWrapperButton, 'flex justify-center items-center')}
                    onClick={handleDelete}
                    onKeyPress={handleDelete}
                    type="button"
                    tabIndex={disabled ? -1 : 0}
                >
                    <Icon icon={['fal', 'times']} className={classes.icon} size="xs" />
                </button>
            )}
            <span className={classes.label}>{label}</span>
        </div>
    );
};

Chip.defaultProps = {
    className: undefined,
    onClick: undefined,
    onDelete: undefined,
    color: 'gray',
    size: 'medium',
    disabled: false,
    margin: undefined,
};

Chip.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
    color: PropTypes.oneOf(['gray', 'green', 'red']),
    size: PropTypes.oneOf(['small', 'medium']),
    margin: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.oneOf(['right', 'bottom', 'left', 'right', 'vertical', 'horizontal']),
    ]),
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

export default Chip;
