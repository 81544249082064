const styles = (theme) => ({
    default: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.secondary.contrastText,
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.secondary.contrastText,
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.secondary.contrastText,
    },
    info: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.secondary.contrastText,
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.secondary.contrastText,
    },
    icon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

export default styles;
