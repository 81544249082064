import { array, bool, boolean, mixed, number, object, string } from 'yup';

import { core as appClientCore } from '@edf-pkg/app-client';
import { languages } from '@edf-pkg/app-i18n';

const responseSchema = object().shape({
    device: object().shape({
        app_updated_at: number().nullable(),
        bt_mac: string().nullable(),
        cur_app_version: number().nullable(),
        cur_os_version: string().nullable(),
        date_created: number().nullable(),
        device_id: string().nullable(),
        last_app_version: number().nullable(),
        last_os_version: string().nullable(),
        last_used: number().nullable(),
        manufacturer: string().nullable(),
        model: string().nullable(),
        os_updated_at: number().nullable(),
        owner: number().nullable(),
        wifi_mac: string().nullable(),
    }),
    user: object().shape({
        username: string().required(),
        role_id: number().required(),
        id: number().required(),
        avatar: string().ensure(),
        first_name: string().ensure(),
        last_name: string().ensure(),
        language: string().ensure(),
        org_name: string().ensure(),
        is_email_verified: bool().default(false),
        is_phone_verified: bool().default(false),
        mfa_enabled: bool().default(false),
        phone: string().ensure(),
        matrix_password: string().ensure(),
        subscription_configs: mixed().required(),
        preferred_notification_medium_ids: array().of(number()).required(),
        has_auto_logout: boolean().required().default(false),
        has_password_expiry: boolean().required().default(false),
        // TODO: Below fields are deprecate. Remove them after adjusting the UI in PDash.
        gender_id: number().required(),
        age_id: number().required(),
        education_id: number().required(),
        marital_status_id: number().required(),
        occupation_id: number().required(),
        salary_id: number().required(),
    }),
});

export default async function userReadEndpoint(accessToken) {
    try {
        const { data } = await appClientCore.axiosClientsManager.getClient('api').get('/v1/user/', {
            headers: { authorization: `Bearer ${accessToken}` },
        });

        const validatedData = await responseSchema.validate(data);

        const userData = validatedData.user;
        const deviceInfo = validatedData.device;

        let normalizedUserData = languages.camelCaseNonTranslationKeys(userData);

        normalizedUserData = {
            ...normalizedUserData,
            // FIXME: We have a util for this in app-web directory called 'getRoleById'. Make sure to replace this below line when we've moved this file into app-web dir.
            role: normalizedUserData.roleId === 1 ? 'researcher' : 'participant',
            apiKey: accessToken,
            deviceId: deviceInfo.device_id,
        };

        return normalizedUserData;
    } catch (error) {
        appClientCore.errorMapper(error);
    }
    return null;
}
