import { CommonTokenStream, InputStream } from 'antlr4';

import { errorHandle } from '@edf-pkg/app-error';

import { criteriaLexer as CriteriaLexer } from '$app-web/utils/criteria-evaluator/antlr/criteriaLexer';
import { criteriaParser as CriteriaParser } from '$app-web/utils/criteria-evaluator/antlr/criteriaParser';
import CriteriaVisitor from '$app-web/utils/criteria-evaluator/visitor';

function criteriaEvaluator(expression, getData) {
    try {
        if (!expression) {
            return true;
        }

        const lexer = new CriteriaLexer(new InputStream(expression));
        const parser = new CriteriaParser(new CommonTokenStream(lexer));
        const tree = parser.exp();
        const result = new CriteriaVisitor(getData).visit(tree);
        if (Array.isArray(result) && result.length === 0) {
            return false;
        }
        return Boolean(result);
    } catch (error) {
        errorHandle.anError(error);
        return false;
    }
}

export function areParenthesesBalanced(expression = '') {
    const stack = [];

    for (const char of expression) {
        if (char === '(') {
            stack.push(char);
        } else if (char === ')') {
            if (stack.length === 0) {
                return false;
            }
            stack.pop();
        }
    }

    return stack.length === 0;
}

function validateCriteria(expression, onErrorCallback) {
    if (!expression) {
        return;
    }

    if (!areParenthesesBalanced(expression)) {
        onErrorCallback(1);
        return;
    }

    let numberOfErrors = 0;

    const lexer = new CriteriaLexer(new InputStream(expression));
    lexer.removeErrorListeners();
    lexer.addErrorListener({
        syntaxError: () => {
            if (numberOfErrors === 0) {
                onErrorCallback(numberOfErrors + 1);
                numberOfErrors += 1;
            }
        },
    });
    const parser = new CriteriaParser(new CommonTokenStream(lexer));
    parser.removeErrorListeners();
    parser.addErrorListener({
        syntaxError: () => {
            if (numberOfErrors === 0) {
                onErrorCallback(numberOfErrors + 1);
                numberOfErrors += 1;
            }
        },
    });
    parser.exp();
}

function isExpressionValid(expression = '') {
    const normalizedExpression = expression.trim().replace(/\s\s+/g, ' ');
    const expressionParts = normalizedExpression.split(' ');
    const SINGLE_OPERAND_CRITERIA_REGEX = /(NOT\s+)?Q\d+(_\d+)?$/g;

    if (normalizedExpression === '') {
        return true;
    }
    if (SINGLE_OPERAND_CRITERIA_REGEX.test(normalizedExpression)) {
        return true;
    }
    if (expressionParts.length < 3) {
        return false;
    }

    let hasError = !areParenthesesBalanced(normalizedExpression);

    const lexer = new CriteriaLexer(new InputStream(normalizedExpression));
    lexer.removeErrorListeners();
    lexer.addErrorListener({
        syntaxError: () => {
            hasError = true;
        },
    });
    const parser = new CriteriaParser(new CommonTokenStream(lexer));
    parser.removeErrorListeners();
    parser.addErrorListener({
        syntaxError: () => {
            hasError = true;
        },
    });
    parser.exp();

    return !hasError;
}

export { criteriaEvaluator, validateCriteria, isExpressionValid };
