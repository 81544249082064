import { object, string } from 'yup';

import { core as appClientCore } from '@edf-pkg/app-client';

const requestSchema = object().shape({
    session_uuid: string().required(),
    activity_type: string().required(),
});

export default async function getActivitySession(sessionUUId, activityType) {
    try {
        const params = await requestSchema.validate({
            session_uuid: sessionUUId,
            activity_type: activityType,
        });
        const response = await appClientCore.axiosClientsManager.getClient('api').get('/study/activity/session/', {
            params,
        });

        return response.data;
    } catch (error) {
        appClientCore.errorMapper(error);
    }
    return null;
}
