import { memo } from 'react';
import type { ButtonBaseProps } from '@material-ui/core';
import { ButtonBase } from '@material-ui/core';

import useStyles from './styles';

interface Props extends ButtonBaseProps {
    text: string;
}

const OverlayButton = ({ children, text, ...restProps }: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {children}
            <ButtonBase className={classes.button} {...restProps}>
                {text}
            </ButtonBase>
        </div>
    );
};

export default memo(OverlayButton);
