import PropTypes from 'prop-types';
import { Backdrop, Grid, Modal } from '@material-ui/core';

import LazyImage from '$app-web/components/lazy-image';

import Header from './header';
import Player from './player';
import useStyles from './styles';

const MediaViewer = ({ className, type, open, autoPlay, url, onCloseClick, alt, alignCloseStart, icon, ...restProps }) => {
    const classes = useStyles();
    return (
        <Modal className={className} open={open} BackdropComponent={Backdrop} onEscapeKeyDown={onCloseClick} {...restProps}>
            <Grid
                data-testid="mediaViewer"
                className={classes.mainWrapper}
                container
                alignItems="stretch"
                direction="column"
                spacing={2}
                wrap="nowrap"
            >
                <Grid item>
                    <Header onCloseClick={onCloseClick} alignCloseStart={alignCloseStart} />
                </Grid>
                <Grid item xs className={classes.playerWrapper}>
                    {type !== 'image' && <Player url={url} autoPlay={autoPlay} type={type} icon={icon} />}
                    {type === 'image' && <LazyImage className={classes.image} src={url} alt={alt} />}
                </Grid>
            </Grid>
        </Modal>
    );
};

MediaViewer.defaultProps = {
    className: undefined,
    autoPlay: false,
    type: 'video',
    alt: '',
    alignCloseStart: false,
    icon: undefined,
};

MediaViewer.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool.isRequired,
    autoPlay: PropTypes.bool,
    url: PropTypes.string.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['audio', 'video', 'image']),
    alt: PropTypes.string,
    alignCloseStart: PropTypes.bool,
    icon: PropTypes.node,
};

export default MediaViewer;
