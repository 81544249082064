import { ObjectType } from '$app-web/types/helpers';

import { NativeToJavaScriptFunctionHelper } from './native-to-js-function';
import PromiseWithTimeout from './promise-with-timeout';

export default class NativeToJsFunctionHelperWeb extends NativeToJavaScriptFunctionHelper {
    back(): Promise<void> {
        super.logBack();

        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.edWeb.back();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    exit(): Promise<void> {
        super.logExit();

        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.edWeb.exit();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    onLoadingCompleted(): Promise<void> {
        super.logOnLoadingCompleted();

        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.edWeb.onLoadingCompleted();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    onLoadingFailed(): Promise<void> {
        super.logOnLoadingFailed();

        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.edWeb.onLoadingFailed();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    getCurrentUserCredentials(): Promise<ObjectType> {
        super.logGetCurrentUserCredentials();

        return PromiseWithTimeout((resolve, reject) => {
            (window as unknown as ObjectType).getCurrentUserCredentialsCallback = (data: unknown) => {
                (window as unknown as ObjectType).getCurrentUserCredentialsCallback = null;

                if (data && typeof data === 'string') {
                    resolve(JSON.parse(data));
                } else {
                    reject(new Error('Returned value not supported.'));
                }
            };

            try {
                window.edWeb.getCurrentUserCredentials();
            } catch (error) {
                reject(error);
            }
        });
    }

    getOptions(): Promise<ObjectType> {
        super.logGetOptions();

        return PromiseWithTimeout((resolve, reject) => {
            (window as unknown as ObjectType).getOptionsCallback = (data: unknown) => {
                (window as unknown as ObjectType).getOptionsCallback = null;

                if (data && typeof data === 'string') {
                    resolve(JSON.parse(data));
                } else {
                    reject(new Error('Returned value not supported.'));
                }
            };

            try {
                window.edWeb.getOptions();
            } catch (error) {
                reject(error);
            }
        });
    }

    getSessionInfo(): Promise<ObjectType> {
        super.logGetSessionInfo();

        return PromiseWithTimeout((resolve, reject) => {
            (window as unknown as ObjectType).getSessionInfoCallback = (data: unknown) => {
                (window as unknown as ObjectType).getSessionInfoCallback = null;

                if (data && typeof data === 'string') {
                    resolve(JSON.parse(data));
                } else {
                    reject(new Error('Returned value not supported.'));
                }
            };

            try {
                window.edWeb.getSessionInfo();
            } catch (error) {
                reject(error);
            }
        });
    }

    shareImage(imageBase64: string): Promise<void> {
        super.logShareImage();

        return PromiseWithTimeout((resolve, reject) => {
            try {
                window.edWeb.shareImage(imageBase64);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }
}
