/* eslint-disable */
// Generated from criteria.g4 by ANTLR 4.8
var antlr4 = require('antlr4/index');
var criteriaListener = require('./criteriaListener').criteriaListener;
var grammarFileName = 'criteria.g4';

var serializedATN = [
    '\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964',
    '\u0003\r&\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004\u0004\t\u0004',
    '\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0003\u0003\u0003\u0003\u0003',
    '\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0005\u0003',
    '\u0014\n\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003',
    '\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0007\u0003\u001f',
    '\n\u0003\f\u0003\u000e\u0003"\u000b\u0003\u0003\u0004\u0003\u0004\u0003',
    '\u0004\u0002\u0003\u0004\u0005\u0002\u0004\u0006\u0002\u0003\u0003\u0002',
    "\u0003\u0006\u0002'\u0002\b\u0003\u0002\u0002\u0002\u0004\u0013\u0003",
    '\u0002\u0002\u0002\u0006#\u0003\u0002\u0002\u0002\b\t\u0005\u0004\u0003',
    '\u0002\t\n\u0007\u0002\u0002\u0003\n\u0003\u0003\u0002\u0002\u0002\u000b',
    '\f\b\u0003\u0001\u0002\f\r\u0007\u000b\u0002\u0002\r\u000e\u0005\u0004',
    '\u0003\u0002\u000e\u000f\u0007\f\u0002\u0002\u000f\u0014\u0003\u0002',
    '\u0002\u0002\u0010\u0011\u0007\u0007\u0002\u0002\u0011\u0014\u0005\u0004',
    '\u0003\u0007\u0012\u0014\u0005\u0006\u0004\u0002\u0013\u000b\u0003\u0002',
    '\u0002\u0002\u0013\u0010\u0003\u0002\u0002\u0002\u0013\u0012\u0003\u0002',
    '\u0002\u0002\u0014 \u0003\u0002\u0002\u0002\u0015\u0016\f\u0006\u0002',
    '\u0002\u0016\u0017\u0007\n\u0002\u0002\u0017\u001f\u0005\u0004\u0003',
    '\u0007\u0018\u0019\f\u0005\u0002\u0002\u0019\u001a\u0007\b\u0002\u0002',
    '\u001a\u001f\u0005\u0004\u0003\u0006\u001b\u001c\f\u0004\u0002\u0002',
    '\u001c\u001d\u0007\t\u0002\u0002\u001d\u001f\u0005\u0004\u0003\u0005',
    '\u001e\u0015\u0003\u0002\u0002\u0002\u001e\u0018\u0003\u0002\u0002\u0002',
    '\u001e\u001b\u0003\u0002\u0002\u0002\u001f"\u0003\u0002\u0002\u0002',
    ' \u001e\u0003\u0002\u0002\u0002 !\u0003\u0002\u0002\u0002!\u0005\u0003',
    '\u0002\u0002\u0002" \u0003\u0002\u0002\u0002#$\t\u0002\u0002\u0002',
    '$\u0007\u0003\u0002\u0002\u0002\u0005\u0013\u001e ',
].join('');

var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map(function (ds, index) {
    return new antlr4.dfa.DFA(ds, index);
});

var sharedContextCache = new antlr4.PredictionContextCache();

var literalNames = [null, null, null, null, null, "'NOT'", "'AND'", "'OR'", null, "'('", "')'"];

var symbolicNames = [null, 'REG_DT', 'QID', 'INT', 'DOUBLE', 'NOT', 'AND', 'OR', 'COMPARATOR', 'LPAREN', 'RPAREN', 'WS'];

var ruleNames = ['parse_all', 'exp', 'atom'];

function criteriaParser(input) {
    antlr4.Parser.call(this, input);
    this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
    this.ruleNames = ruleNames;
    this.literalNames = literalNames;
    this.symbolicNames = symbolicNames;
    return this;
}

criteriaParser.prototype = Object.create(antlr4.Parser.prototype);
criteriaParser.prototype.constructor = criteriaParser;

Object.defineProperty(criteriaParser.prototype, 'atn', {
    get: function () {
        return atn;
    },
});

criteriaParser.EOF = antlr4.Token.EOF;
criteriaParser.REG_DT = 1;
criteriaParser.QID = 2;
criteriaParser.INT = 3;
criteriaParser.DOUBLE = 4;
criteriaParser.NOT = 5;
criteriaParser.AND = 6;
criteriaParser.OR = 7;
criteriaParser.COMPARATOR = 8;
criteriaParser.LPAREN = 9;
criteriaParser.RPAREN = 10;
criteriaParser.WS = 11;

criteriaParser.RULE_parse_all = 0;
criteriaParser.RULE_exp = 1;
criteriaParser.RULE_atom = 2;

function Parse_allContext(parser, parent, invokingState) {
    if (parent === undefined) {
        parent = null;
    }
    if (invokingState === undefined || invokingState === null) {
        invokingState = -1;
    }
    antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = criteriaParser.RULE_parse_all;
    return this;
}

Parse_allContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Parse_allContext.prototype.constructor = Parse_allContext;

Parse_allContext.prototype.copyFrom = function (ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};

function MainRuleContext(parser, ctx) {
    Parse_allContext.call(this, parser);
    this.expression = null; // ExpContext;
    Parse_allContext.prototype.copyFrom.call(this, ctx);
    return this;
}

MainRuleContext.prototype = Object.create(Parse_allContext.prototype);
MainRuleContext.prototype.constructor = MainRuleContext;

criteriaParser.MainRuleContext = MainRuleContext;

MainRuleContext.prototype.EOF = function () {
    return this.getToken(criteriaParser.EOF, 0);
};

MainRuleContext.prototype.exp = function () {
    return this.getTypedRuleContext(ExpContext, 0);
};
MainRuleContext.prototype.enterRule = function (listener) {
    if (listener instanceof criteriaListener) {
        listener.enterMainRule(this);
    }
};

MainRuleContext.prototype.exitRule = function (listener) {
    if (listener instanceof criteriaListener) {
        listener.exitMainRule(this);
    }
};

criteriaParser.Parse_allContext = Parse_allContext;

criteriaParser.prototype.parse_all = function () {
    var localctx = new Parse_allContext(this, this._ctx, this.state);
    this.enterRule(localctx, 0, criteriaParser.RULE_parse_all);
    try {
        localctx = new MainRuleContext(this, localctx);
        this.enterOuterAlt(localctx, 1);
        this.state = 6;
        localctx.expression = this.exp(0);
        this.state = 7;
        this.match(criteriaParser.EOF);
    } catch (re) {
        if (re instanceof antlr4.error.RecognitionException) {
            localctx.exception = re;
            this._errHandler.reportError(this, re);
            this._errHandler.recover(this, re);
        } else {
            throw re;
        }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ExpContext(parser, parent, invokingState) {
    if (parent === undefined) {
        parent = null;
    }
    if (invokingState === undefined || invokingState === null) {
        invokingState = -1;
    }
    antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = criteriaParser.RULE_exp;
    return this;
}

ExpContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ExpContext.prototype.constructor = ExpContext;

ExpContext.prototype.copyFrom = function (ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};

function OpLogicExprContext(parser, ctx) {
    ExpContext.call(this, parser);
    this.left = null; // ExpContext;
    this.op = null; // Token;
    this.right = null; // ExpContext;
    ExpContext.prototype.copyFrom.call(this, ctx);
    return this;
}

OpLogicExprContext.prototype = Object.create(ExpContext.prototype);
OpLogicExprContext.prototype.constructor = OpLogicExprContext;

criteriaParser.OpLogicExprContext = OpLogicExprContext;

OpLogicExprContext.prototype.NOT = function () {
    return this.getToken(criteriaParser.NOT, 0);
};

OpLogicExprContext.prototype.exp = function (i) {
    if (i === undefined) {
        i = null;
    }
    if (i === null) {
        return this.getTypedRuleContexts(ExpContext);
    } else {
        return this.getTypedRuleContext(ExpContext, i);
    }
};

OpLogicExprContext.prototype.AND = function () {
    return this.getToken(criteriaParser.AND, 0);
};

OpLogicExprContext.prototype.OR = function () {
    return this.getToken(criteriaParser.OR, 0);
};
OpLogicExprContext.prototype.enterRule = function (listener) {
    if (listener instanceof criteriaListener) {
        listener.enterOpLogicExpr(this);
    }
};

OpLogicExprContext.prototype.exitRule = function (listener) {
    if (listener instanceof criteriaListener) {
        listener.exitOpLogicExpr(this);
    }
};

function AtomExprContext(parser, ctx) {
    ExpContext.call(this, parser);
    ExpContext.prototype.copyFrom.call(this, ctx);
    return this;
}

AtomExprContext.prototype = Object.create(ExpContext.prototype);
AtomExprContext.prototype.constructor = AtomExprContext;

criteriaParser.AtomExprContext = AtomExprContext;

AtomExprContext.prototype.atom = function () {
    return this.getTypedRuleContext(AtomContext, 0);
};
AtomExprContext.prototype.enterRule = function (listener) {
    if (listener instanceof criteriaListener) {
        listener.enterAtomExpr(this);
    }
};

AtomExprContext.prototype.exitRule = function (listener) {
    if (listener instanceof criteriaListener) {
        listener.exitAtomExpr(this);
    }
};

function ParenExprContext(parser, ctx) {
    ExpContext.call(this, parser);
    this.inner = null; // ExpContext;
    ExpContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ParenExprContext.prototype = Object.create(ExpContext.prototype);
ParenExprContext.prototype.constructor = ParenExprContext;

criteriaParser.ParenExprContext = ParenExprContext;

ParenExprContext.prototype.LPAREN = function () {
    return this.getToken(criteriaParser.LPAREN, 0);
};

ParenExprContext.prototype.RPAREN = function () {
    return this.getToken(criteriaParser.RPAREN, 0);
};

ParenExprContext.prototype.exp = function () {
    return this.getTypedRuleContext(ExpContext, 0);
};
ParenExprContext.prototype.enterRule = function (listener) {
    if (listener instanceof criteriaListener) {
        listener.enterParenExpr(this);
    }
};

ParenExprContext.prototype.exitRule = function (listener) {
    if (listener instanceof criteriaListener) {
        listener.exitParenExpr(this);
    }
};

function OpCompareExprContext(parser, ctx) {
    ExpContext.call(this, parser);
    this.left = null; // ExpContext;
    this.op = null; // Token;
    this.right = null; // ExpContext;
    ExpContext.prototype.copyFrom.call(this, ctx);
    return this;
}

OpCompareExprContext.prototype = Object.create(ExpContext.prototype);
OpCompareExprContext.prototype.constructor = OpCompareExprContext;

criteriaParser.OpCompareExprContext = OpCompareExprContext;

OpCompareExprContext.prototype.exp = function (i) {
    if (i === undefined) {
        i = null;
    }
    if (i === null) {
        return this.getTypedRuleContexts(ExpContext);
    } else {
        return this.getTypedRuleContext(ExpContext, i);
    }
};

OpCompareExprContext.prototype.COMPARATOR = function () {
    return this.getToken(criteriaParser.COMPARATOR, 0);
};
OpCompareExprContext.prototype.enterRule = function (listener) {
    if (listener instanceof criteriaListener) {
        listener.enterOpCompareExpr(this);
    }
};

OpCompareExprContext.prototype.exitRule = function (listener) {
    if (listener instanceof criteriaListener) {
        listener.exitOpCompareExpr(this);
    }
};

criteriaParser.prototype.exp = function (_p) {
    if (_p === undefined) {
        _p = 0;
    }
    var _parentctx = this._ctx;
    var _parentState = this.state;
    var localctx = new ExpContext(this, this._ctx, _parentState);
    var _prevctx = localctx;
    var _startState = 2;
    this.enterRecursionRule(localctx, 2, criteriaParser.RULE_exp, _p);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 17;
        this._errHandler.sync(this);
        switch (this._input.LA(1)) {
            case criteriaParser.LPAREN:
                localctx = new ParenExprContext(this, localctx);
                this._ctx = localctx;
                _prevctx = localctx;

                this.state = 10;
                this.match(criteriaParser.LPAREN);
                this.state = 11;
                localctx.inner = this.exp(0);
                this.state = 12;
                this.match(criteriaParser.RPAREN);
                break;
            case criteriaParser.NOT:
                localctx = new OpLogicExprContext(this, localctx);
                this._ctx = localctx;
                _prevctx = localctx;
                this.state = 14;
                localctx.op = this.match(criteriaParser.NOT);
                this.state = 15;
                localctx.right = this.exp(5);
                break;
            case criteriaParser.REG_DT:
            case criteriaParser.QID:
            case criteriaParser.INT:
            case criteriaParser.DOUBLE:
                localctx = new AtomExprContext(this, localctx);
                this._ctx = localctx;
                _prevctx = localctx;
                this.state = 16;
                this.atom();
                break;
            default:
                throw new antlr4.error.NoViableAltException(this);
        }
        this._ctx.stop = this._input.LT(-1);
        this.state = 30;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input, 2, this._ctx);
        while (_alt != 2 && _alt != antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if (_alt === 1) {
                if (this._parseListeners !== null) {
                    this.triggerExitRuleEvent();
                }
                _prevctx = localctx;
                this.state = 28;
                this._errHandler.sync(this);
                var la_ = this._interp.adaptivePredict(this._input, 1, this._ctx);
                switch (la_) {
                    case 1:
                        localctx = new OpCompareExprContext(this, new ExpContext(this, _parentctx, _parentState));
                        localctx.left = _prevctx;
                        this.pushNewRecursionContext(localctx, _startState, criteriaParser.RULE_exp);
                        this.state = 19;
                        if (!this.precpred(this._ctx, 4)) {
                            throw new antlr4.error.FailedPredicateException(this, 'this.precpred(this._ctx, 4)');
                        }
                        this.state = 20;
                        localctx.op = this.match(criteriaParser.COMPARATOR);
                        this.state = 21;
                        localctx.right = this.exp(5);
                        break;

                    case 2:
                        localctx = new OpLogicExprContext(this, new ExpContext(this, _parentctx, _parentState));
                        localctx.left = _prevctx;
                        this.pushNewRecursionContext(localctx, _startState, criteriaParser.RULE_exp);
                        this.state = 22;
                        if (!this.precpred(this._ctx, 3)) {
                            throw new antlr4.error.FailedPredicateException(this, 'this.precpred(this._ctx, 3)');
                        }
                        this.state = 23;
                        localctx.op = this.match(criteriaParser.AND);
                        this.state = 24;
                        localctx.right = this.exp(4);
                        break;

                    case 3:
                        localctx = new OpLogicExprContext(this, new ExpContext(this, _parentctx, _parentState));
                        localctx.left = _prevctx;
                        this.pushNewRecursionContext(localctx, _startState, criteriaParser.RULE_exp);
                        this.state = 25;
                        if (!this.precpred(this._ctx, 2)) {
                            throw new antlr4.error.FailedPredicateException(this, 'this.precpred(this._ctx, 2)');
                        }
                        this.state = 26;
                        localctx.op = this.match(criteriaParser.OR);
                        this.state = 27;
                        localctx.right = this.exp(3);
                        break;
                }
            }
            this.state = 32;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input, 2, this._ctx);
        }
    } catch (error) {
        if (error instanceof antlr4.error.RecognitionException) {
            localctx.exception = error;
            this._errHandler.reportError(this, error);
            this._errHandler.recover(this, error);
        } else {
            throw error;
        }
    } finally {
        this.unrollRecursionContexts(_parentctx);
    }
    return localctx;
};

function AtomContext(parser, parent, invokingState) {
    if (parent === undefined) {
        parent = null;
    }
    if (invokingState === undefined || invokingState === null) {
        invokingState = -1;
    }
    antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = criteriaParser.RULE_atom;
    this.token = null; // Token
    return this;
}

AtomContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AtomContext.prototype.constructor = AtomContext;

AtomContext.prototype.QID = function () {
    return this.getToken(criteriaParser.QID, 0);
};

AtomContext.prototype.INT = function () {
    return this.getToken(criteriaParser.INT, 0);
};

AtomContext.prototype.DOUBLE = function () {
    return this.getToken(criteriaParser.DOUBLE, 0);
};

AtomContext.prototype.REG_DT = function () {
    return this.getToken(criteriaParser.REG_DT, 0);
};

AtomContext.prototype.enterRule = function (listener) {
    if (listener instanceof criteriaListener) {
        listener.enterAtom(this);
    }
};

AtomContext.prototype.exitRule = function (listener) {
    if (listener instanceof criteriaListener) {
        listener.exitAtom(this);
    }
};

criteriaParser.AtomContext = AtomContext;

criteriaParser.prototype.atom = function () {
    var localctx = new AtomContext(this, this._ctx, this.state);
    this.enterRule(localctx, 4, criteriaParser.RULE_atom);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 33;
        localctx.token = this._input.LT(1);
        _la = this._input.LA(1);
        if (
            !(
                (_la & ~0x1f) == 0 &&
                ((1 << _la) &
                    ((1 << criteriaParser.REG_DT) |
                        (1 << criteriaParser.QID) |
                        (1 << criteriaParser.INT) |
                        (1 << criteriaParser.DOUBLE))) !==
                    0
            )
        ) {
            localctx.token = this._errHandler.recoverInline(this);
        } else {
            this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
        if (re instanceof antlr4.error.RecognitionException) {
            localctx.exception = re;
            this._errHandler.reportError(this, re);
            this._errHandler.recover(this, re);
        } else {
            throw re;
        }
    } finally {
        this.exitRule();
    }
    return localctx;
};

criteriaParser.prototype.sempred = function (localctx, ruleIndex, predIndex) {
    switch (ruleIndex) {
        case 1:
            return this.exp_sempred(localctx, predIndex);
        default:
            throw 'No predicate with index:' + ruleIndex;
    }
};

criteriaParser.prototype.exp_sempred = function (localctx, predIndex) {
    switch (predIndex) {
        case 0:
            return this.precpred(this._ctx, 4);
        case 1:
            return this.precpred(this._ctx, 3);
        case 2:
            return this.precpred(this._ctx, 2);
        default:
            throw 'No predicate with index:' + predIndex;
    }
};

exports.criteriaParser = criteriaParser;
