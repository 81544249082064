import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';

import SurveyContainer from '$app-web/activities/survey/components/page-survey/container';
import { TRIGGERING_LOGIC_TYPES } from '$app-web/constants';
import { SaveSessionRequestParams } from '$app-web/endpoints';
import { Session } from '$app-web/types';

import { StoreProvider } from './store';
import theme from './theme';

interface Props {
    sessionUUID?: string;
    surveyVersion?: number;
    isTimeUse?: boolean;
    isEligibilitySurvey?: boolean;
    translationId: string;
    sessionInfo?: Session;
    participantRegistrationTime?: number;
    onSubmitSurveySession?: (backendData: SaveSessionRequestParams) => void;
    onClose?: (finished: boolean) => void;
}

const ActivitySurvey = ({
    sessionUUID,
    surveyVersion,
    isTimeUse,
    isEligibilitySurvey,
    sessionInfo,
    participantRegistrationTime,
    onSubmitSurveySession,
    onClose,
}: Props) => {
    const location = useLocation();
    const isPublicSurvey = location.pathname.includes('/public') || sessionInfo?.tlTypeId === TRIGGERING_LOGIC_TYPES.PUBLIC;
    const isSandboxMode = !isPublicSurvey && !location.pathname.includes('/pdash');

    return (
        <MuiThemeProvider theme={theme}>
            <StoreProvider
                isSandboxMode={isSandboxMode}
                sessionUUID={sessionUUID}
                surveyVersion={surveyVersion ?? sessionInfo?.version}
                isTimeUse={isTimeUse}
                isEligibilitySurvey={isEligibilitySurvey}
                sessionInfo={sessionInfo}
                isPublicSurvey={isPublicSurvey}
                participantRegistrationTime={participantRegistrationTime}
                onClose={onClose}
                onSubmitSurveySession={onSubmitSurveySession}
            >
                <SurveyContainer />
            </StoreProvider>
        </MuiThemeProvider>
    );
};

export default memo(ActivitySurvey);
