/* eslint-disable no-console */
import { ObjectType } from '$app-web/types';

import { MobileInjectedFunctions } from '../types';

export abstract class NativeToJavaScriptFunctionHelper implements MobileInjectedFunctions {
    abstract back(): Promise<void>;
    abstract exit(): Promise<void>;
    abstract onLoadingCompleted(): Promise<void>;
    abstract onLoadingFailed(): Promise<void>;
    abstract getCurrentUserCredentials(): Promise<ObjectType>;
    abstract getOptions(): Promise<ObjectType>;
    abstract getSessionInfo(): Promise<ObjectType>;
    abstract shareImage(imageBase64: string): Promise<void>;

    logBack() {
        console.log('Calling injected back');
    }

    logExit() {
        console.log('Calling injected exit');
    }

    logOnLoadingCompleted() {
        console.log('Calling injected onLoadingCompleted');
    }

    logOnLoadingFailed() {
        console.log('Calling injected onLoadingFailed');
    }

    logGetCurrentUserCredentials() {
        console.log('Calling injected getCurrentUserCredentials');
    }

    logGetOptions() {
        console.log('Calling injected getOptions');
    }

    logGetSessionInfo() {
        console.log('Calling injected getSessionInfo');
    }

    logShareImage() {
        console.log('Calling injected shareImage');
    }
}
