import { store as appMainStore, utils as appMainUtils } from '@edf-pkg/app-main';
import { getStore } from '@edf-pkg/app-store';
import appUtils from '@edf-pkg/app-utils';

import { auth as appClientAuth, utils as appClientUtils } from '../';
import axiosClientsManager from './axios-clients-manager';

// TODO: We should replace it with the below file: ** import { URLsManager } from '$app-web/utils' **
function getInitialBaseUrl() {
    if (appUtils.env.isDev()) {
        return process.env.REACT_APP_SERVER_BASE_URL || '';
    }
    if (appUtils.env.shouldLoadBaseUrlFromLocalStorage() && localStorage.getItem('baseURL')) {
        return localStorage.getItem('baseURL') || '';
    }
    if (typeof window !== 'undefined' && window.location) {
        const parsedURL = new URL(window.location.href);
        return parsedURL.origin;
    }
    return process.env.REACT_APP_SERVER_BASE_URL || '';
}

const baseUrl = getInitialBaseUrl();

const defaultAxiosClients = {
    api: { baseURL: `${baseUrl}/api` },
    media: { baseURL: `${baseUrl}/media` },
    gql: { baseURL: `${baseUrl}/graphql` },
    file: { baseURL: process.env.REACT_APP_FILE_SERVER_BASE_URL },
};

Object.keys(defaultAxiosClients).forEach((axiosClientId) => {
    const axiosInstance = axiosClientsManager.createClient(axiosClientId, defaultAxiosClients[axiosClientId]);

    axiosInstance.interceptors.request.use(
        (config) => {
            const requestConfig = { ...config };
            const { accessToken } = appClientAuth.useAuthenticationStore.getState();

            if (
                !Object.keys(requestConfig.headers)
                    .map((key) => key.toLowerCase())
                    .includes('authorization') &&
                !appUtils.object.hasKey(requestConfig, 'auth') &&
                accessToken
            ) {
                requestConfig.headers.authorization = `Bearer ${accessToken}`;
            }

            // TODO: For some reason `accessToken` coming via the zustand store is null in web activities on MO (only when viewed on MO). The below line is a workaround, We need to investigate this issue.
            if (
                !Object.keys(requestConfig.headers)
                    .map((key) => key.toLowerCase())
                    .includes('authorization')
            ) {
                const userAuthData = appMainUtils.user.getAuthData();

                if (userAuthData.apiKey === 'anonymous') {
                    requestConfig.headers.authorization = `Bearer anonymous`;
                } else {
                    requestConfig.headers.authorization = `ApiKey USER:${userAuthData.apiKey}`;
                }
            }

            if (typeof window !== 'undefined' && window.location) {
                requestConfig.headers['x-ethica-path'] = window.location.pathname;
            }

            return requestConfig;
        },
        (error) => Promise.reject(error)
    );

    let isRefreshingToken = false;
    let requestsQueue = [];
    function processQueue(token = null, error = null) {
        requestsQueue.forEach((prom) => {
            if (error) {
                prom.reject(error);
            } else if (token) {
                prom.resolve(token);
            }
        });
        requestsQueue = [];
    }

    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const store = getStore();
            const { refreshToken } = appClientAuth.useAuthenticationStore.getState();
            const previousConfig = error?.config;
            // TODO: There is an internal issue with headers in the current installed axios version which the below is a workaround to fix that. Remove it later when updated Axios to latest version.
            previousConfig.headers = JSON.parse(JSON.stringify(previousConfig.headers || {}));

            if (refreshToken) {
                if (error?.response?.status === 401 && !appClientUtils.auth.isPublicEndpoint(previousConfig.url)) {
                    if (!isRefreshingToken) {
                        isRefreshingToken = true;

                        try {
                            const responseData = await appClientAuth.fetchAuthenticationTokens({ refreshToken });

                            appClientAuth.useAuthenticationStore.getState().updateState({
                                accessToken: responseData.accessToken,
                                refreshToken: responseData.refreshToken,
                                expiresIn: responseData.expiresIn,
                                isAccountDeletionRevoked: responseData.revokedDeletion,
                            });

                            store.dispatch(
                                appMainStore.userDuck.duckActionCreators.setUserDataExternal({
                                    userData: { apiKey: responseData.accessToken },
                                    actionSource: '',
                                    shouldPersistUserData: true,
                                    shouldLoadFromAPI: false,
                                    shouldSetInitializationStatus: false,
                                })
                            );

                            processQueue(responseData.accessToken);
                            isRefreshingToken = false;

                            const retryConfig = {
                                ...previousConfig,
                                headers: {
                                    ...previousConfig.headers,
                                    authorization: `Bearer ${responseData.accessToken}`,
                                },
                            };

                            return axiosInstance(retryConfig);
                        } catch (refreshError) {
                            // Logout user if refresh token is invalid
                            if (refreshError.status === 401) {
                                appClientAuth.useAuthenticationStore.getState().resetState();
                                return store.dispatch(appMainStore.userDuck.duckActionCreators.logout());
                            }

                            processQueue(null, refreshError);
                            isRefreshingToken = false;
                            return Promise.reject(refreshError);
                        }
                    } else {
                        return new Promise((resolve, reject) => {
                            requestsQueue.push({
                                resolve: (token) => {
                                    const retryConfig = {
                                        ...previousConfig,
                                        headers: {
                                            ...previousConfig.headers,
                                            authorization: `Bearer ${token}`,
                                        },
                                    };

                                    resolve(axiosInstance(retryConfig));
                                },
                                reject,
                            });
                        });
                    }
                }
            }

            return Promise.reject(error);
        }
    );
});
