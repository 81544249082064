import React, { cloneElement, isValidElement } from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';

import classes from './styles.module.scss';

// TODO-MAYBE: The position prop should not be here probably. Any logic/style related to that prop should be taken care of under the TextField itself.
const InputAdornment = (props) => {
    const { children, position, disabled, className, onClick, tabIndex } = props;

    const childrenElement = isValidElement(children) && disabled ? cloneElement(children, { disabled }) : children;

    return (
        <button
            className={clsx(
                classes.root,
                {
                    [classes.startAdornment]: position === 'start',
                    [classes.endAdornment]: position === 'end',
                    [classes.disabled]: disabled,
                    [classes.clickable]: onClick && !disabled,
                },
                className
            )}
            disabled={disabled}
            tabIndex={tabIndex}
            type="button"
            onClick={onClick}
        >
            {childrenElement}
        </button>
    );
};

InputAdornment.defaultProps = {
    children: undefined,
    position: undefined,
    onClick: undefined,
    disabled: false,
    className: undefined,
    tabIndex: undefined,
};

InputAdornment.propTypes = {
    children: PropTypes.element,
    onClick: PropTypes.func,
    position: PropTypes.oneOf(['start', 'end']),
    disabled: PropTypes.bool,
    className: PropTypes.string,
    tabIndex: PropTypes.number,
};

export default InputAdornment;
