import { memo, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDialog } from '@edf-pkg/app-main';

import Button from '$app-web/activities/survey/components/button';
import { DIALOG_IDS, TRIGGERING_LOGIC_TYPE_IDS as TL_TYPE_IDS } from '$app-web/activities/survey/constants';
import Dialog from '$app-web/components/dialog';
import Loading from '$app-web/components/loading';
import { useTranslator } from '$app-web/hooks/use-translator';
import { getIsACTMaternityStudy, URLsManager } from '$app-web/utils';

interface Props {
    studyId: number;
    triggeringLogicTypeId: number;
    onCancelSession: () => Promise<void>;
    onDelete: () => Promise<void>;
}

const Dialogs = ({ studyId, triggeringLogicTypeId, onCancelSession, onDelete }: Props) => {
    const t = useTranslator();
    const [isLoading, setIsLoading] = useState(false);
    const closeDialog = useDialog(DIALOG_IDS.CLOSE);
    const history = useHistory();
    const isACTMaternityStudy = useMemo(() => getIsACTMaternityStudy(studyId), [studyId]);

    const goToHome = useCallback(() => {
        history.push(URLsManager.to('dashboard:pdash:HOME'));
    }, [history]);

    const cancelSession = useCallback(async () => {
        setIsLoading(true);
        await onCancelSession();
        setIsLoading(false);
    }, [onCancelSession]);

    const deleteSession = useCallback(async () => {
        setIsLoading(true);
        await onDelete();
        setIsLoading(false);
    }, [onDelete]);

    const defaultDialogActions = useMemo(
        () => ({
            no: { text: t('webActivities:no'), autoClose: true },
            yes: {
                text: t('webActivities:yes'),
                autoClose: true,
                onClick: goToHome,
            },
        }),
        [goToHome, t]
    );

    const { dialogContent, dialogActions } = useMemo(() => {
        switch (triggeringLogicTypeId) {
            case TL_TYPE_IDS.ELIGIBILITY:
                return {
                    dialogContent: t('webActivities:survey_discard_answering_eligibility_survey'),
                    dialogActions: defaultDialogActions,
                };
            case TL_TYPE_IDS.USER:
            case TL_TYPE_IDS.DROPOUT:
            case TL_TYPE_IDS.PUBLIC:
                return {
                    dialogContent: t('webActivities:survey_discard_answering_user_survey'),
                    dialogActions: { ...defaultDialogActions, yes: { ...defaultDialogActions.yes, onClick: cancelSession } },
                };
            case TL_TYPE_IDS.TIME:
            case TL_TYPE_IDS.TIME_USER_DEF:
            case TL_TYPE_IDS.PROXIMITY:
            case TL_TYPE_IDS.RESEARCHER:
                return {
                    dialogContent: (
                        <>
                            <p>{t('webActivities:survey_cancel_dialog_message')}</p>
                            <br />
                            <Button color="secondary" onClick={goToHome}>
                                {t('webActivities:survey_continue_answering_later')}
                            </Button>
                            <br />
                            {!isACTMaternityStudy && (
                                <>
                                    <Button color="secondary" onClick={cancelSession}>
                                        {t('webActivities:survey_discard_answering')}
                                    </Button>
                                    <br />
                                </>
                            )}
                            <Button color="secondary" onClick={closeDialog.close}>
                                {t('webActivities:close_dialog')}
                            </Button>
                        </>
                    ),
                    dialogActions: undefined,
                };
            default:
                return {
                    dialogContent: t('webActivities:survey_discard_answering_user_survey'),
                    dialogActions: defaultDialogActions,
                };
        }
    }, [cancelSession, closeDialog.close, defaultDialogActions, goToHome, isACTMaternityStudy, t, triggeringLogicTypeId]);

    return (
        <>
            <Loading
                variant="parent-overlay"
                loading={isLoading}
                showErrorText
                errorHasButton
                errorButtonText={t('back')}
                onErrorButtonClick={() => {
                    goToHome();
                }}
            />
            <Dialog
                id={DIALOG_IDS.DELETE}
                title={t('webActivities:delete')}
                content={t('webActivities:activity_delete_survey_confirm_body')}
                disableOutClickClose
                actions={{
                    no: { text: t('webActivities:no'), autoClose: true },
                    yes: { text: t('webActivities:yes'), autoClose: true, onClick: deleteSession },
                }}
            />
            <Dialog id={DIALOG_IDS.CLOSE} content={dialogContent} actions={dialogActions} />
        </>
    );
};

export default memo(Dialogs);
