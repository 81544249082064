import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import useStyles from './styles';

const TextAnchor = ({ text, vertical, variant, dataTestId }) => {
    const classes = useStyles();

    return (
        <Grid
            item
            className={clsx({
                [classes.secondaryHorizontalWrapper]: variant === 'secondary' && !vertical,
                [classes.secondaryVerticalWrapper]: variant === 'secondary' && vertical,
                [classes.horizontalAnchorWrapper]: variant === 'primary' && !vertical,
                [classes.verticalAnchorWrapper]: variant === 'primary' && vertical,
            })}
        >
            {text && (
                <div
                    className={clsx({
                        [classes.secondaryInnerWrapper]: variant === 'secondary',
                    })}
                >
                    <Typography
                        align="center"
                        className={clsx(classes.text, {
                            [classes.smallerText]: variant === 'secondary',
                        })}
                        data-testid={dataTestId}
                    >
                        {text}
                    </Typography>
                </div>
            )}
        </Grid>
    );
};

TextAnchor.defaultProps = {
    variant: 'primary',
    dataTestId: 'anchor-text',
};

TextAnchor.propTypes = {
    text: PropTypes.string.isRequired,
    dataTestId: PropTypes.string,
    vertical: PropTypes.bool.isRequired,
    variant: PropTypes.oneOf(['primary', 'secondary']),
};

export default TextAnchor;
