import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialValues = {
    accessToken: null,
    refreshToken: null,
    expiresIn: null,
    isAccountDeletionRevoked: false,
    username: null,
    password: null,
    otpToken: null,
    mfaEnabled: false,
    modifyEmailAuthToken: null,
    modifyPhoneNumberAuthToken: null,
};

// TODO: When using TypeScript for this zustand stores, There is a issue where the store is not being updated from a different files.
// I've used JS for now, but we should switch to TS and fix this issue later.
export const useAuthenticationStore = create(
    persist(
        (set) => ({
            ...initialValues,
            updateState: ({
                accessToken,
                refreshToken,
                expiresIn,
                isAccountDeletionRevoked,
                username,
                password,
                otpToken,
                mfaEnabled,
                modifyEmailAuthToken,
                modifyPhoneNumberAuthToken,
            }) => {
                const newState = {
                    ...(accessToken && { accessToken }),
                    ...(refreshToken && { refreshToken }),
                    ...(expiresIn && { expiresIn }),
                    ...(isAccountDeletionRevoked && { isAccountDeletionRevoked }),
                    ...(username && { username }),
                    ...(password && { password }),
                    ...(otpToken && { otpToken }),
                    ...(mfaEnabled && { mfaEnabled }),
                    ...(modifyEmailAuthToken && { modifyEmailAuthToken }),
                    ...(modifyPhoneNumberAuthToken && { modifyPhoneNumberAuthToken }),
                };
                set(newState);
            },
            resetState: () => {
                set(initialValues);
            },
        }),
        {
            name: process.env.REACT_APP_LOCAL_STORAGE_KEY_AUTH_STORE,
            partialize: (state) => ({
                refreshToken: state.refreshToken,
                accessToken: state.accessToken,
                isAccountDeletionRevoked: state.isAccountDeletionRevoked,
                expiresIn: state.expiresIn,
            }),
        }
    )
);
