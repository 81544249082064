import { CallType } from 'matrix-js-sdk/lib/webrtc/call';
import { Device } from 'mediasoup-client';
import { AppData, MediaKind, RtpCapabilities, RtpParameters, Transport, TransportOptions } from 'mediasoup-client/lib/types';

import { auth as appClientAuth } from '@edf-pkg/app-client';
import { errorHandle } from '@edf-pkg/app-error';

interface ServerInit {
    response: true;
    ok: boolean;
    id: number;
    method: 'init';
    data: {
        roomId: string;
        consumerTransportOptions: TransportOptions;
        producerTransportOptions: TransportOptions;
        routerRtpCapabilities: RtpCapabilities;
    };
}

interface ServerConnectedProducerTransport {
    response: true;
    ok: boolean;
    id: number;
    method: 'connectedProducerTransport';
}

interface ServerConnectedConsumerTransport {
    response: true;
    ok: boolean;
    id: number;
    method: 'connectedConsumerTransport';
}

interface ServerConsumerAdded {
    response: true;
    ok: boolean;
    id: number;
    method: 'consumerAdded';
    data: {
        producerId: string;
        consumerId: string;
        kind: MediaKind;
        source: 'screen' | 'device';
        rtpParameters: RtpParameters;
    };
}

interface ServerConsumerRemoved {
    response: true;
    ok: boolean;
    id: number;
    method: 'consumerRemoved';
    data: {
        producerId: string;
        consumerId: string;
    };
}

interface ServerProduced {
    response: true;
    ok: boolean;
    id: number;
    method: 'produced';
    data: {
        producerId: string;
    };
}

interface ServerConsumerResumed {
    response: true;
    ok: boolean;
    id: number;
    method: 'consumerResumed';
}

interface ServerStartedRecording {
    response: true;
    ok: boolean;
    id: number;
    method: 'startedRecording';
}

interface ServerStoppedRecording {
    response: true;
    ok: boolean;
    id: number;
    method: 'stoppedRecording';
}

interface ServerParticipantAdded {
    response: true;
    ok: boolean;
    id: number;
    method: 'participantAdded';
    data: {
        participantId: string;
        userId: number;
    };
}

interface ServerParticipantRemoved {
    response: true;
    ok: boolean;
    id: number;
    method: 'participantRemoved';
    data: {
        participantId: string;
        userId: number;
    };
}

type ServerMessage =
    | ServerInit
    | ServerConsumerAdded
    | ServerConsumerRemoved
    | ServerConnectedProducerTransport
    | ServerProduced
    | ServerConnectedConsumerTransport
    | ServerConsumerResumed
    | ServerStartedRecording
    | ServerStoppedRecording
    | ServerParticipantAdded
    | ServerParticipantRemoved;

interface MediasoupState {
    ws: WebSocket;
    device: Device;
    eventTarget: EventTarget;
    // Initialize a media stream (will be used to collect consumer tracks)
    mediaStream: MediaStream;
    screenShareMediaStream: MediaStream;
    screenShareConsumerData: ServerConsumerAdded['data'] | null;
    producerTransport: Transport<AppData> | null;
    consumerTransport: Transport<AppData> | null;
    // false -> not recording, true -> recording, undefined -> unknown
    isRecording: boolean;

    // Indicated is mediasoup initialized
    initializedPromise: Promise<void>;
    consumersReceivedCount: number;
    consumersReceivedPromise: Promise<void>;
}
class WebsocketEvent extends Event {
    message: ServerMessage;
    constructor(event: string, message: ServerMessage) {
        super(event);
        this.message = message;
    }
}

function generateMessageId() {
    return Math.floor(Math.random() * 1_000_000_000);
}

const initializeProducerTransport = (
    state: MediasoupState,
    producerTransportOptions: TransportOptions<AppData>
): Promise<MediasoupState> => {
    return new Promise((resolve) => {
        const transport = state.device.createSendTransport(producerTransportOptions);

        // eslint-disable-next-line no-param-reassign
        state.producerTransport = transport
            .on('connect', ({ dtlsParameters }, success) => {
                const id = generateMessageId();
                state.ws.send(
                    JSON.stringify({
                        request: true,
                        id,
                        method: 'connectProducerTransport',
                        data: { dtlsParameters },
                    })
                );
                state.eventTarget.addEventListener(
                    `event-${id}`,
                    () => {
                        success();
                    },
                    { once: true }
                );
            })
            .on('produce', ({ kind, rtpParameters }, success) => {
                const id = generateMessageId();
                state.ws.send(
                    JSON.stringify({
                        request: true,
                        id,
                        method: 'produce',
                        data: {
                            kind,
                            rtpParameters,
                        },
                    })
                );
                state.eventTarget.addEventListener(
                    `event-${id}`,
                    (event) => {
                        success({ id: ((event as WebsocketEvent).message as ServerProduced).data.producerId });
                    },
                    { once: true }
                );
            });
        resolve(state);
    });
};

const initializeConsumerTransport = (
    state: MediasoupState,
    consumerTransportOptions: TransportOptions<AppData>
): Promise<MediasoupState> => {
    return new Promise((resolve) => {
        const transport = state.device.createRecvTransport(consumerTransportOptions);

        // eslint-disable-next-line no-param-reassign
        state.consumerTransport = transport.on('connect', ({ dtlsParameters }, success) => {
            const id = generateMessageId();
            state.ws.send(
                JSON.stringify({
                    request: true,
                    id,
                    method: 'connectConsumerTransport',
                    data: {
                        dtlsParameters,
                    },
                })
            );
            state.eventTarget.addEventListener(
                `event-${id}`,
                () => {
                    success();
                },
                { once: true }
            );
        });
        resolve(state);
    });
};

export function initializeMediasoup({
    roomId,
    studyId,
    callType,
    onScreenShareStart,
    onScreenShareStop,
    onRecordingStop,
}: {
    roomId: string;
    studyId: number;
    callType: CallType;
    onScreenShareStart: (mediaStream: MediaStream) => void;
    onScreenShareStop: () => void;
    onRecordingStop: () => void;
}) {
    const { accessToken } = appClientAuth.useAuthenticationStore.getState();

    const webSocketData = { roomId, studyId: `${studyId}`, jwtToken: accessToken || '' };

    const wsUrl = `${process.env.REACT_APP_LOCAL_CHAT_MEDIASOUP_SERVER_URL}?${new URLSearchParams(webSocketData).toString()}`;

    const mediasoupStateEventTarget = new EventTarget();
    const mediasoupState: MediasoupState = {
        ws: new WebSocket(wsUrl),
        device: new Device(),
        mediaStream: new MediaStream(),
        screenShareMediaStream: new MediaStream(),
        screenShareConsumerData: null,
        producerTransport: null,
        consumerTransport: null,
        isRecording: false,
        initializedPromise: new Promise<void>((resolve) => {
            mediasoupStateEventTarget.addEventListener('initialized', () => resolve(), { once: true });
        }),
        consumersReceivedCount: 0,
        consumersReceivedPromise: new Promise<void>((resolve) => {
            mediasoupStateEventTarget.addEventListener('consumersAdded', () => resolve(), { once: true });
        }),
        eventTarget: mediasoupStateEventTarget,
    };

    async function websocketMessageHandler(message: MessageEvent) {
        const decodedMessage: ServerMessage = JSON.parse(message.data);
        switch (decodedMessage.method) {
            case 'init':
                await mediasoupState.device.load({
                    routerRtpCapabilities: decodedMessage.data.routerRtpCapabilities,
                });
                mediasoupState.ws.send(
                    JSON.stringify({
                        request: true,
                        id: decodedMessage.id,
                        method: 'init',
                        data: { rtpCapabilities: mediasoupState.device.rtpCapabilities },
                    })
                );
                await initializeProducerTransport(mediasoupState, decodedMessage.data.producerTransportOptions);
                await initializeConsumerTransport(mediasoupState, decodedMessage.data.consumerTransportOptions);

                mediasoupState.eventTarget.dispatchEvent(new Event('initialized'));
                break;
            case 'consumerAdded': {
                mediasoupState.consumersReceivedCount += 1;
                await mediasoupState.initializedPromise;
                const consumer = await mediasoupState.consumerTransport!.consume({
                    id: decodedMessage.data.consumerId,
                    producerId: decodedMessage.data.producerId,
                    kind: decodedMessage.data.kind,
                    rtpParameters: decodedMessage.data.rtpParameters,
                });

                if (decodedMessage.data.source === 'screen') {
                    mediasoupState.screenShareConsumerData = decodedMessage.data;
                    mediasoupState.screenShareMediaStream.addTrack(consumer.track);
                    onScreenShareStart(mediasoupState.screenShareMediaStream);
                } else {
                    mediasoupState.mediaStream.addTrack(consumer.track);
                }

                mediasoupState.ws.send(
                    JSON.stringify({
                        request: true,
                        id: decodedMessage.id,
                        method: 'consumerResume',
                        data: { consumerId: decodedMessage.data.consumerId },
                    })
                );
                if (
                    (callType === CallType.Video && mediasoupState.consumersReceivedCount === 2) ||
                    (callType === CallType.Voice && mediasoupState.consumersReceivedCount === 1)
                ) {
                    mediasoupState.eventTarget.dispatchEvent(new WebsocketEvent('consumersAdded', decodedMessage));
                }
                break;
            }
            case 'consumerRemoved': {
                mediasoupState.consumersReceivedCount -= 1;

                const screenShareConsumerData = mediasoupState.screenShareConsumerData;
                if (
                    screenShareConsumerData !== null &&
                    screenShareConsumerData.producerId === decodedMessage.data.producerId &&
                    screenShareConsumerData.consumerId === decodedMessage.data.consumerId
                ) {
                    onScreenShareStop();
                    mediasoupState.screenShareMediaStream.getTracks().forEach((track) => {
                        mediasoupState.screenShareMediaStream.removeTrack(track);
                    });
                    mediasoupState.screenShareConsumerData = null;
                }

                break;
            }
            case 'participantAdded': {
                // TBD
                break;
            }
            case 'participantRemoved': {
                // TBD
                break;
            }
            default:
                mediasoupState.eventTarget.dispatchEvent(new WebsocketEvent(`event-${decodedMessage.id}`, decodedMessage));
                break;
        }
    }

    const close = () => {
        mediasoupState.ws.removeEventListener('message', websocketMessageHandler);
        mediasoupState.ws.removeEventListener('error', errorHandle.anError);
        mediasoupState.ws.removeEventListener('close', close);

        mediasoupState.producerTransport?.close();
        mediasoupState.consumerTransport?.close();
        mediasoupState.ws.close();
    };

    mediasoupState.ws.addEventListener('message', websocketMessageHandler);
    mediasoupState.ws.addEventListener('error', errorHandle.anError);
    mediasoupState.ws.addEventListener('close', close);

    return {
        async startRecording() {
            if (mediasoupState.isRecording) {
                return;
            }

            await mediasoupState.consumersReceivedPromise;

            const id = generateMessageId();
            mediasoupState.ws.send(
                JSON.stringify({
                    request: true,
                    id,
                    method: 'startRecording',
                    data: null,
                })
            );
            mediasoupState.eventTarget.addEventListener(
                `event-${id}`,
                () => {
                    mediasoupState.isRecording = true;
                },
                { once: true }
            );
        },
        stopRecording() {
            if (!mediasoupState.isRecording) {
                return;
            }

            const id = generateMessageId();
            mediasoupState.ws.send(
                JSON.stringify({
                    request: true,
                    id,
                    method: 'stopRecording',
                })
            );
            mediasoupState.eventTarget.addEventListener(
                `event-${id}`,
                () => {
                    mediasoupState.isRecording = false;
                    onRecordingStop();
                },
                { once: true }
            );
        },
        async waitInitialized() {
            await mediasoupState.initializedPromise;
        },
        async waitConsumersAdded() {
            await mediasoupState.consumersReceivedPromise;
        },
        isRecording() {
            return mediasoupState.isRecording;
        },
        setIsRecording(isRecording: boolean) {
            mediasoupState.isRecording = isRecording;
        },
        async addMediaStream(stream: MediaStream) {
            const tracks = stream.getTracks().map((track) => mediasoupState.producerTransport!.produce({ track }));
            await Promise.all(tracks);
        },
        getMediaStreams() {
            return { cameraStream: mediasoupState.mediaStream, screenStream: mediasoupState.screenShareMediaStream };
        },
        close,
    };
}
