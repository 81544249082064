import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { lighten, TextField } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

const autocompleteTheme = (theme) =>
    createMuiTheme(theme, {
        props: {
            MuiAutocomplete: {
                forcePopupIcon: false,
                renderInput: (params) => <TextField {...params} fullWidth variant="filled" />,
                getOptionLabel: (option) => option.title,
                autoHighlight: true,
                filterSelectedOptions: true,
                blurOnSelect: true,
                disableClearable: true,
                renderOption: (option, { inputValue }) => {
                    const { title } = option;
                    const matches = match(title, inputValue);
                    const parts = parse(title, matches);
                    return parts.map((part, index) => (
                        <span
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${part.text}${index}`}
                            style={{ fontWeight: part.highlight ? 'bolder' : 'normal' }}
                        >
                            {part.text}
                        </span>
                    ));
                },
            },
        },
        overrides: {
            MuiAutocomplete: {
                inputRoot: {
                    '&[class*="MuiFilledInput-root"]': undefined,
                    border: 'none',
                    '&:hover': {
                        border: 'none',
                        outline: 'none',
                    },
                },
                clearIndicator: {
                    marginRight: theme.spacing(2),
                },
                paper: {
                    backgroundColor: lighten(theme.palette.default.main, 0.9),
                    borderRadius: 0,
                    boxShadow: 'none',
                    marginTop: 1,
                    width: '100%',
                },
                option: {
                    display: undefined,
                },
            },
        },
    });

export default autocompleteTheme;
