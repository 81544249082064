export const VERIFICATION_CODE_LENGTH = 6;

export const END_OF_TIME_MS = 31570041600000; // 2970-06-01 00:00:00 UTC

export const STUDY_DEFAULT_BACKGROUND_IMAGE = '/media/study_bg/default_bg.jpg';

export const TRANSLATION_BASE_ID = 'base';

export const CONSENT_TYPES = Object.freeze({
    PARENTS: 1,
    PARENTS_OF_MINOR: 2,
    MINOR_CHILD: 3,
    ADULT_CHILD: 4,
});

export const ROLES = Object.freeze({
    PARTICIPANT: 'participant',
    RESEARCHER: 'researcher',
});

export const ROLE_IDS = Object.freeze({
    PARTICIPANT: 0,
    RESEARCHER: 1,
});
