import asModule from '$app-web/hoces/as-module';
import { URLsManager } from '$app-web/utils';
import lazyLoad, { withTimeoutAndDelay } from '$app-web/utils/lazy-load';

URLsManager.space('dashboard').addModule('pdash').setURLs({
    HOME: '/',
    JOIN_STUDY_CHECK: '/join-study/check',
    JOIN_STUDY_INFO: '/join-study/info/:studyId',
    JOIN_STUDY_CHECK_ELIGIBILITY: '/join-study/eligibility/:studyId',
    STUDY: '/study/:studyId',
    STUDY_SECTION: '/study/:studyId/:section',
    STUDY_ACTIVITY: '/study/:studyId/activity/:activityId/:tlId',
    STUDY_ACTIVITY_PENDING_SESSION: '/study/:studyId/session/:sessionUuid',
    MY_STUDIES: '/my-studies',
    SETTINGS: '/settings',
    SETTINGS_JOIN_STUDY_CHECK: '/settings/join-study/check',
    SETTINGS_MY_STUDIES: '/settings/my-studies',
    SETTINGS_MY_STUDIES_STUDY: '/settings/my-studies/:studyId/:section',
    SETTINGS_LANGUAGE: '/settings/language',
    SETTINGS_USER_PROFILE: '/settings/user/profile',
    SETTINGS_ACCOUNT_INFORMATION: '/settings/account-information',
    SETTINGS_USER_PASSWORD: '/settings/user/password',
    SETTINGS_NOTIFICATIONS: '/settings/user/notifications',
    SETTINGS_USER_DELETE_ACCOUNT: '/settings/user/delete-account',
    STUDY_SETUP: '/study-setup',
    STUDY_SETUP_VERIFY_EMAIL: '/study-setup/verify-email',
    STUDY_SETUP_VERIFY_PHONE: '/study-setup/verify-phone',
    INELIGIBILITY: '/ineligibility',
});

const PdashModule = lazyLoad(() => withTimeoutAndDelay(() => import('./routes')));

function shouldRender({ user }) {
    return user.isAuthenticated && user.role === 'participant' && user.initializationStatusSucceeded;
}

export default asModule(shouldRender, 'dashboard.pdash')(PdashModule);
