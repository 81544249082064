import { KeysToCamelCase, KeysToSnakeCase, ObjectType } from '$app-web/types';

export abstract class SuperObject {
    static isObject(value: unknown) {
        if (
            typeof value === 'object' &&
            value !== null &&
            !Array.isArray(value) &&
            !(value instanceof RegExp) &&
            !(value instanceof Error) &&
            !(value instanceof Date) &&
            !(value instanceof Map) &&
            !(value instanceof Set) &&
            !(value instanceof File)
        ) {
            return true;
        }
        return false;
    }

    static toCamelCase<T extends object>(data: T, options?: { excludeKeys: Array<string> }): KeysToCamelCase<T> {
        const { excludeKeys = [] } = options ?? {};

        if (SuperObject.isObject(data)) {
            return Object.entries(data as T).reduce((newObject, [key, value]) => {
                const convertedInnerObject = SuperObject.toCamelCase(value as T, options);
                if (excludeKeys.includes(key)) {
                    return { ...newObject, [key]: convertedInnerObject };
                }
                const camelCaseKey = key.replace(/[-_]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
                return { ...newObject, [camelCaseKey]: convertedInnerObject };
            }, {}) as KeysToCamelCase<T>;
        }
        if (Array.isArray(data)) {
            return (data as Array<T>).map((item) => SuperObject.toCamelCase(item, options)) as KeysToCamelCase<T>;
        }
        return data as KeysToCamelCase<T>;
    }

    static toSnakeCase<T extends object>(data: T, options?: { excludeKeys: Array<string> }): KeysToSnakeCase<T> {
        const { excludeKeys = [] } = options ?? {};

        if (SuperObject.isObject(data)) {
            return Object.entries(data as ObjectType).reduce((newObject, [key, value]) => {
                const convertedInnerObject = SuperObject.toSnakeCase(value as T, options);
                if (excludeKeys.includes(key)) {
                    return { ...newObject, [key]: convertedInnerObject };
                }
                const snakeCaseKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
                return { ...newObject, [snakeCaseKey]: convertedInnerObject };
            }, {}) as KeysToSnakeCase<T>;
        }
        if (Array.isArray(data)) {
            return (data as Array<T>).map((item) => SuperObject.toSnakeCase(item as T, options)) as KeysToSnakeCase<T>;
        }
        return data as KeysToSnakeCase<T>;
    }

    static isSuperset(superset: unknown, subset: unknown): boolean {
        if (typeof superset !== typeof subset) {
            return false;
        }
        if (SuperObject.isObject(superset) && SuperObject.isObject(subset)) {
            return Object.entries(subset as ObjectType).every(([key, value]) => {
                if (key in (superset as ObjectType)) {
                    return SuperObject.isSuperset((superset as ObjectType)[key], value);
                }
                return false;
            });
        }
        if (Array.isArray(superset) && Array.isArray(subset)) {
            return (subset as Array<unknown>).every((value) =>
                (superset as Array<unknown>).some((item) => SuperObject.isSuperset(item, value))
            );
        }

        return superset === subset;
    }

    static deepClone<T>(data: T): T {
        if (SuperObject.isObject(data)) {
            return Object.entries(data as ObjectType).reduce((newObject, [key, value]) => {
                return { ...newObject, [key]: SuperObject.deepClone(value) };
            }, {}) as T;
        }
        if (Array.isArray(data)) {
            return (data as Array<unknown>).map(SuperObject.deepClone) as unknown as T;
        }
        return data;
    }

    static pick<T extends object, Key extends keyof T>(data: T, keys: Array<Key>): Pick<T, Key> {
        return keys.reduce(
            (newObject, key) => {
                if (Object.hasOwn(data, key) && data[key] != null) {
                    return { ...newObject, [key]: data[key] };
                }
                return newObject;
            },
            {} as Pick<T, Key>
        );
    }

    static omit<T extends object, Key extends keyof T>(data: T, keys: Array<Key>): Omit<T, Key> {
        return Object.entries(data).reduce(
            (newObject, [key, value]) => {
                if (!keys.includes(key as Key)) {
                    return { ...newObject, [key]: value };
                }
                return newObject;
            },
            {} as Omit<T, Key>
        );
    }

    static hasKey<T extends ObjectType>(data: T, ...keys: Array<string | number>): boolean {
        return keys.every((key) => key in data);
    }

    static getLength(data: ObjectType): number {
        return Object.keys(data).length;
    }

    static isEqual<T>(first: T, second: T): boolean {
        if (SuperObject.isObject(first) && SuperObject.isObject(second)) {
            if (SuperObject.getLength(first as ObjectType) !== SuperObject.getLength(second as ObjectType)) {
                return false;
            }
            return Object.entries(first as ObjectType).every(([key, value]) => {
                if (key in (second as ObjectType)) {
                    return SuperObject.isEqual((second as ObjectType)[key], value);
                }
                return false;
            });
        }
        if (Array.isArray(first) && Array.isArray(second)) {
            if (first.length !== second.length) {
                return false;
            }
            return (first as Array<unknown>).every((value) =>
                (second as Array<unknown>).some((item) => SuperObject.isEqual(item, value))
            );
        }

        return first === second;
    }

    static groupBy<
        T extends ObjectType,
        Key extends {
            [K in keyof T]: T[K] extends string | number ? K : never;
        }[keyof T],
        Result extends ObjectType<Array<T>>,
    >(data: Array<T>, key: Key): Result {
        return data.reduce((newObject, item) => {
            const groupKey = item[key] as string | number;
            if (SuperObject.hasKey(newObject, groupKey)) {
                const prevValue = newObject[groupKey];
                return { ...newObject, [groupKey]: [...prevValue, item] } as Result;
            }
            return { ...newObject, [groupKey]: [item] } as Result;
        }, {} as Result);
    }

    static getValueOfKey(object: ObjectType, key: string, defaultValue: unknown) {
        if (SuperObject.hasKey(object, key)) {
            return object[key];
        } else {
            return defaultValue;
        }
    }
}
