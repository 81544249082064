import { ChangeEvent, useEffect, useRef } from 'react';
import clsx from 'clsx';

import classes from './styles.module.scss';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

interface Props {
    checked: boolean;
    onChange?: (checked: boolean) => void;
    onChangeNative?: (event: ChangeEvent<HTMLInputElement>) => void;
    label?: string | number;
    value?: string | number;
    error?: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
    required?: boolean;
    className?: string;
}

const Checkbox = (props: Props) => {
    const { value, label, checked, onChange, onChangeNative, indeterminate, required, disabled, error, className } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const checkboxInputProps = {
        checked,
        onChange: (event: ChangeEvent<HTMLInputElement>) => {
            onChange?.(event.target.checked);
            onChangeNative?.(event);
        },
        ...(value ? { value } : {}),
        ...(disabled ? { disabled } : {}),
        ...(required ? { 'aria-required': required } : {}),
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.indeterminate = indeterminate || false;
        }
    }, [indeterminate]);

    return (
        <label className={clsx(classes.root, { [classes.disabled]: disabled }, className)}>
            <input ref={inputRef} type="checkbox" className={classes.input} {...checkboxInputProps} />
            <div className={clsx(classes.iconWrapper, { [classes.error]: error })}>
                <FontAwesomeIcon className={classes.icon} icon={['fas', indeterminate ? 'minus' : 'check']} />
            </div>
            {label && <span className={classes.labelText}>{label}</span>}
            {label && required && <span className={classes.requiredIndicator}>*</span>}
        </label>
    );
};

export default Checkbox;
