import PropTypes from 'prop-types';
import { Slider as MUISlider } from '@material-ui/core';

import useStyles from './styles';

const Slider = ({ value, ...restProps }) => {
    const classes = useStyles();
    const { min, max, step } = restProps;
    // Researchers can set any value for `step`. However, if the `step` value exceeds the
    // range defined by `max - min`, it prevents the slider from functioning correctly.
    const normalizedStep = step > max - min ? max : step;

    return (
        <MUISlider
            value={value === null ? -Infinity : value}
            classes={{ thumb: value === null ? classes.hiddenThumb : undefined }}
            valueLabelFormat={(value) => <span data-testid="selection-value">{value}</span>}
            {...restProps}
            step={normalizedStep}
        />
    );
};

Slider.defaultProps = {
    value: null,
};

Slider.propTypes = {
    value: PropTypes.number,
};

export default Slider;
