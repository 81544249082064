import produce from 'immer';
import { createSelector } from 'reselect';
import { getSelector, namespaceReducerActions } from 'retrack';

export const DUCK_NAME = 'space-web-activities';

const initialState = {
    userCredentials: {},
    sessionInfo: {},
    options: {},
    currentURL: '',
};

export const duckActions = {
    INITIALIZE_ON_HOLD: 'INITIALIZE_ON_HOLD',
    SET_USER_CREDENTIALS: 'SET_USER_CREDENTIALS',
    SET_SESSION_INFO: 'SET_SESSION_INFO',
    SET_OPTIONS: 'SET_OPTIONS',
    SET_CURRENT_URL: 'SET_CURRENT_URL',
    CALL_INJECTED_BACK: 'CALL_INJECTED_BACK',
    CALL_INJECTED_EXIT: 'CALL_INJECTED_EXIT',
    CALL_INJECTED_ON_LOADING_COMPLETED: 'CALL_INJECTED_ON_LOADING_COMPLETED',
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case duckActions.SET_USER_CREDENTIALS:
            draft.userCredentials = action.payload.userCredentials;
            break;
        case duckActions.SET_SESSION_INFO:
            draft.sessionInfo = action.payload.sessionInfo;
            break;
        case duckActions.SET_OPTIONS:
            draft.options = action.payload.options;
            break;
        case duckActions.SET_CURRENT_URL:
            draft.currentURL = action.payload.currentURL;
            break;
        // no default
    }
}, initialState);

namespaceReducerActions(reducer, duckActions);

export default reducer;

export const duckActionCreators = {
    initializeOnHold() {
        return {
            type: duckActions.INITIALIZE_ON_HOLD,
        };
    },
    setUserCredentials: function setUserCredentials(userCredentials) {
        return {
            type: duckActions.SET_USER_CREDENTIALS,
            payload: { userCredentials },
        };
    },
    setSessionInfo: function setSessionInfo(sessionInfo) {
        return {
            type: duckActions.SET_SESSION_INFO,
            payload: { sessionInfo },
        };
    },
    setOptions: function setSessionInfo(options) {
        return {
            type: duckActions.SET_OPTIONS,
            payload: { options },
        };
    },
    setCurrentURL: function setCurrentURL(currentURL) {
        return {
            type: duckActions.SET_CURRENT_URL,
            payload: { currentURL },
        };
    },
    callInjectedBack: function callInjectedBack() {
        return { type: duckActions.CALL_INJECTED_BACK };
    },
    callInjectedExit: function callInjectedBack() {
        return { type: duckActions.CALL_INJECTED_EXIT };
    },
    callInjectedOnLoadingCompleted: function callInjectedBack() {
        return { type: duckActions.CALL_INJECTED_ON_LOADING_COMPLETED };
    },
};

const webActivitiesSelector = getSelector(reducer);

const userCredentialsSelector = createSelector(webActivitiesSelector, (webActivitiesState) => webActivitiesState.userCredentials);

const sessionInfoSelector = createSelector(webActivitiesSelector, (webActivitiesState) => webActivitiesState.sessionInfo);

const optionsSelector = createSelector(webActivitiesSelector, (webActivitiesState) => webActivitiesState.options);

const currentURLSelector = createSelector(webActivitiesSelector, (webActivitiesState) => webActivitiesState.currentURL);

export const duckSelectors = {
    webActivitiesSelector,
    userCredentialsSelector,
    sessionInfoSelector,
    optionsSelector,
    currentURLSelector,
};
