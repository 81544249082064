import { cloneDeepWith, has } from 'lodash-es';

function getNodeIdFromBase64Id(base64String, typeName) {
    return atob(base64String).replace(`${typeName}:`, '');
}

function getBase64IdFromNodeId(nodeId, typeName) {
    return btoa(`${typeName}:${nodeId}`);
}

function normalize(raw) {
    return cloneDeepWith(raw, (deepData) => {
        // parse .edges
        if (has(deepData, 'edges')) {
            const { edges, __typename, ...restData } = deepData;
            if (Object.keys(restData).length > 0) {
                return {
                    data: normalize(deepData.edges),
                    ...restData,
                };
            }
            return normalize(deepData.edges);
        }

        // parse .node
        if (has(deepData, 'node')) {
            return normalize(deepData.node);
        }

        // parse .__typename
        if (has(deepData, '__typename') && !has(deepData, 'gqlData')) {
            const { id, __typename, ...restData } = deepData;
            return normalize({
                id: id && getNodeIdFromBase64Id(id, __typename),
                gqlData: {
                    id,
                    typeName: __typename,
                },
                ...restData,
            });
        }

        // continue deep clone
        return undefined;
    });
}

const gqlUtils = {
    node: {
        getNodeIdFromBase64Id,
        getBase64IdFromNodeId,
    },
    response: {
        normalize,
    },
};

export default gqlUtils;
