export const HTTP_CALL_STATUS = {
    UNKNOWN: 'UNKNOWN',
    LOADING: 'LOADING',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
};

export const USER_SIGN_ACTION_SOURCES = {
    SIGN_IN: 'sign-in',
    SIGN_UP: 'sign-up',
    RESET_PASSWORD: 'reset-password',
};

export const PHR_EVENT_TYPES = {
    USER_SIGN_UP: 1,
    USER_SIGN_IN: 2,
    USER_LOGOUT: 3,
    APPLICATION_OPENED: 100,
    // There is no easy way to send a request to backend after user tries to close the browser window and before actual close.
    // APPLICATION_CLOSED: 101
    // Not available in web
    // TIMEZONE_CHANGED: 108
    ACTIVITY_RESPONSES_UPLOAD_STARTED: 112,
    ACTIVITY_RESPONSES_UPLOAD_FINISHED: 113,
    ACTIVITY_RESPONSES_UPLOAD_FAILED: 114,
    ACTIVITY_OPENED: 200,
    ACTIVITY_CLOSED: 201,
    ACTIVITY_FINISHED: 202,
    AUDIT_TRAIL_PAGE_OPENED: 700,
    ACTIVITIES_PAGE_OPENED: 701,
    DATA_SOURCES_PAGE_OPENED: 702,
    STUDY_BASICS_PAGE_OPENED: 703,
    DATA_SOURCES_BEACONS_PAGE_OPENED: 704,
    NOTIFICATION_TEMPLATES_PAGE_OPENED: 705,
    NOTIFICATIONS_PAGE_OPENED: 706,
    PARTICIPATION_PAGE_OPENED: 707,
    PARTICIPATION_PENDING_INVITATION_PAGE_OPENED: 708,
    ACTIVITY_SESSIONS_PAGE_OPENED: 709,
    SURVEY_RESPONSES_PAGE_OPENED: 710,
    DATA_EXPORT_PAGE_OPENED: 711,
    RESEARCHERS_PAGE_OPENED: 718,
    EDEN_INTRODUCTION_PAGE_OPENED: 712,
    EDEN_DATA_CONFIRMATION_PAGE_OPENED: 713,
    EDEN_CONSENT_PAGE_OPENED: 714,
    EDEN_PARENTS_OF_MINOR_CONSENT_PAGE_OPENED: 715,
    EDEN_PARTICIPANT_CONSENTED: 716,
    EDEN_PARTICIPANT_DATA_CONFIRMED: 717,
};
