import client from '$app-web/core/client';
import useErrorHandler from '$app-web/hooks/use-error-handler';
import type { ActivitySession, KeysToSnakeCase } from '$app-web/types';
import { SuperObject } from '$app-web/utils';

interface Params {
    sessionUUID: string;
    shouldCall?: boolean;
    onSuccess?: (response: ActivitySession) => void;
    onError?: (error: Error) => void;
}

interface ReturnValue {
    data: ActivitySession;
    isLoading: boolean;
    isFetching: boolean;
    isSuccess: boolean;
    isIdle: boolean;
    isError: boolean;
    refetch: () => void;
}

export const useFetchActivitySession = ({ sessionUUID, onSuccess, onError, shouldCall }: Params) => {
    const { handleError } = useErrorHandler();

    const result = client.registerEndpoint(
        'GET api/read-activity-session',
        `v1/activity/session/`,
        {}
    )(
        { sessionUuid: sessionUUID },
        {
            enabled: shouldCall,
            onError: (error) => {
                onError?.(error as Error);
                handleError(error);
            },
            onSuccess: (response) => {
                const normalizedResponse = SuperObject.toCamelCase(
                    response as KeysToSnakeCase<ActivitySession>
                ) as unknown as ActivitySession;
                onSuccess?.(normalizedResponse);
            },
        }
    ) as ReturnValue;

    return result;
};
