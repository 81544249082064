import Language from '../core/language';

const pdashNamespace = import('../namespaces/fa-ir.pdash.namespace');
const webActivitiesNamespace = import('../namespaces/fa-ir.web-activities.namespace');
const rdashTranslatablesNamespace = import('../namespaces/fa-ir.rdash-translatables.namespace');

class FaIrLanguage extends Language {
    code = 'fa-IR';

    locale = 'fa';

    localName = 'فارسی';

    englishName = 'Farsi';

    territory = 'IR';

    timezones = ['Asia/Tehran'];

    notLoadedNamespaces = {
        pdash: pdashNamespace,
        webActivities: webActivitiesNamespace,
        rdashTranslatables: rdashTranslatablesNamespace,
    };
}

const instance = new FaIrLanguage();
export default instance;
