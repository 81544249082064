import { memo } from 'react';
import { Typography } from '@material-ui/core';

import appDateTime from '@edf-pkg/app-date-time';

import Icon from '../icon';
import useStyles from './styles';

interface Props {
    variant: 'audio-recording' | 'audio-playing' | 'video-playing';
    value?: number;
}

const IconText = ({ variant, value }: Props) => {
    const classes = useStyles();

    return (
        <>
            {variant === 'audio-playing' && <div style={{ height: 13 }} />}
            <Icon variant={variant} iconSize={variant === 'audio-playing' ? 'small' : 'medium'} />
            <Typography className={classes.time} variant="button" display="block" align="center">
                {value && appDateTime.humanizeDuration(value)}
            </Typography>
        </>
    );
};

export default memo(IconText);
