const styles = {
    root: {
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        '-webkit-overflow-scrolling': 'touch',
    },
    xHidden: {
        overflowX: 'hidden',
    },
    yHidden: {
        overflowY: 'hidden',
    },
};

export default styles;
