import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import FullLoadingIndicator from '$app-web/components/full-loading-indicator';

class AppErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        const { name } = this.props;
        Sentry.withScope((scope) => {
            scope.setTag('catch_in', 'AppErrorBoundary');
            scope.setExtras({ errorInfo, name });
            Sentry.captureException(error);
        });
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            return <FullLoadingIndicator hasError />;
        }

        return children;
    }
}

AppErrorBoundary.defaultProps = {
    children: null,
};

AppErrorBoundary.propTypes = {
    children: PropTypes.node,
    name: PropTypes.string.isRequired,
};

export default AppErrorBoundary;
