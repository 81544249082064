import { ChangeEvent, memo, Ref, useCallback, useMemo } from 'react';
import { FilledInput } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import DynamicSearchSelect from './dynamic-search-select';

const generateFilterOption = createFilterOptions();

const SEARCHING_INDICATOR_KEYWORD = 'refset';

interface Props {
    hint?: string;
    autocompleteOptions?: Array<string>;
    inputRef?: Ref<HTMLInputElement>;
    multiLine?: boolean;
    value?: string;
    onChange: (value: string) => void;
    forcePickAutocompleteOption?: boolean;
}

const TypeFreeFormText = ({
    hint,
    autocompleteOptions = [],
    inputRef,
    multiLine = true,
    value = '',
    forcePickAutocompleteOption = false,
    onChange,
}: Props) => {
    const ref = useMemo(() => ({ ref: inputRef }), [inputRef]);
    const valueAsTitle = useMemo(() => ({ title: value }), [value]);
    const options = useMemo(() => autocompleteOptions.map((option) => ({ title: option })), [autocompleteOptions]);

    const filterOptions = useCallback(
        () =>
            generateFilterOption(options, {
                inputValue: value,
                getOptionLabel: (option) => (option as { title: string }).title,
            }),
        [options, value]
    );

    const handleInputChange = useCallback(
        (_event: ChangeEvent<Record<string, unknown>>, newValue: string) => {
            onChange(newValue);
        },
        [onChange]
    );

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            onChange(event.target.value);
        },
        [onChange]
    );

    const referenceSetKey = useMemo(() => {
        const referenceSet = autocompleteOptions.find((option) => option.includes(SEARCHING_INDICATOR_KEYWORD));

        if (referenceSet !== undefined) {
            const [, keyword] = referenceSet.split(':');
            return keyword;
        }

        return null;
    }, [autocompleteOptions]);

    if (autocompleteOptions.length > 0 && referenceSetKey == null) {
        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            <Autocomplete
                freeSolo
                options={options}
                placeholder={hint}
                value={valueAsTitle}
                onInputChange={handleInputChange}
                filterOptions={filterOptions}
            />
        );
    }

    if (referenceSetKey != null) {
        return (
            <DynamicSearchSelect
                value={value}
                onChange={onChange}
                referenceSetKey={referenceSetKey}
                freeInput={!forcePickAutocompleteOption}
            />
        );
    }

    return (
        <FilledInput
            fullWidth
            placeholder={hint}
            multiline={multiLine}
            rows={3}
            rowsMax={Number.MAX_SAFE_INTEGER}
            value={value}
            inputProps={ref}
            onChange={handleChange}
        />
    );
};

export default memo(TypeFreeFormText);
