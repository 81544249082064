import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        minHeight: '100%',
    },
    sliderWrapper: {
        height: '100%',
    },
    sliderPadding: {
        padding: theme.spacing(0, 3),
    },
    verticalLabelWrapper: {
        // slider width is 24px, so 14px is reduced to make it center
        width: 'calc(50% - 14px)',
    },
    verticalControlWrapper: {
        minHeight: 300,
    },
    topMargin: {
        marginTop: theme.spacing(8),
    },
}));
