export enum SESSION_STATUSES {
    UNKNOWN = 0,
    SUBMITTED = 1,
    CANCELED = 2,
    EXPIRED = 3,
    BLOCKED = 4,
    INVALID_CRITERIA = 5,
    IN_PROGRESS = 6,
}

export const UNANSWERED_QUESTION_REASONS = Object.freeze({
    UNKNOWN: -1,
    SKIPPED: 1,
    QUESTION_CRITERIA: 2,
    SECTION_CRITERIA: 3,
    QUESTION_RANDOMIZATION: 4,
    SECTION_RANDOMIZATION: 5,
    DISABLED_QUESTION: 6,
    NO_SECTION_LOOP: 7,
    SECTIONS_FLOW: 8,
    SESSION_STATUS: 9,
});

export const SURVEY_FLOW_STATUS = Object.freeze({
    REACHABLE: 'REACHABLE',
    UNREACHABLE_DUE_TO_SINCE_REG_TOKENS: 'UNREACHABLE_DUE_TO_REG_DATE_TOKENS',
    UNREACHABLE_DUE_TO_EMPTY_SURVEY_HIERARCHY: 'UNREACHABLE_DUE_TO_EMPTY_SURVEY_HIERARCHY',
    UNREACHABLE_DUE_TO_INVALID_CRITERIA: 'UNREACHABLE_DUE_TO_INVALID_CRITERIA',
});

export const TRIGGERING_LOGIC_TYPES = Object.freeze({
    UNKNOWN: -1,
    RESEARCHER: 0,
    ELIGIBILITY: 1,
    TIME: 2,
    PROXIMITY: 3,
    USER: 4,
    DROPOUT: 5,
    TIME_USER_DEF: 6,
    GEOFENCING: 7,
    PUBLIC: 9,
});
