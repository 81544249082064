/* eslint-disable @typescript-eslint/ban-types */
import { useCallback } from 'react';
import axios, { AxiosInstance, AxiosRequestConfig, Method, ResponseType } from 'axios';
import { jwtDecode } from 'jwt-decode';
import {
    GetNextPageParamFunction,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    useMutation,
    UseMutationOptions,
    useQuery,
    UseQueryOptions,
} from 'react-query';
import type { SchemaOf } from 'yup';

import {
    auth as appClientAuth,
    constants as appClientConstants,
    core as appClientCore,
    utils as appClientUtils,
} from '@edf-pkg/app-client';
import { ClientError } from '@edf-pkg/app-error';
import { languages } from '@edf-pkg/app-i18n';
import { store as appMainStore, utils as appMainUtils } from '@edf-pkg/app-main';
import { getStore } from '@edf-pkg/app-store';
import appUtils from '@edf-pkg/app-utils';

import { ObjectType } from '$app-web/types';
import { SuperObject, SuperString, URLsManager } from '$app-web/utils';

interface Endpoint {
    method: Method;
    serviceId: string;
    options: {
        requestSchema?: SchemaOf<unknown>;
        responseSchema?: SchemaOf<unknown>;
        responseType?: ResponseType;
        headers?: ObjectType<string>;
        customErrorsOrMapper?: unknown;
        requestSchemaStripUnknown?: boolean;
        responseSchemaStripUnknown?: boolean;
        shouldCamelCaseResponseKeys?: boolean;
        shouldSnakeCaseRequestKeys?: boolean;
        keepPayloadRaw?: boolean;
        mutate?: boolean;
        infinite?: boolean;
        getNextPageParam?: GetNextPageParamFunction<unknown>;
        json?: boolean;
    };
    responseParser?: Function;
    url?: string | ((data: unknown) => string);
    queryAndVariables?: Function | string;
}

class Client {
    defaultServices: ObjectType<ObjectType> = {
        api: {
            baseURL: URLsManager.getEndpointURL('api'),
        },
        media: {
            baseURL: URLsManager.getEndpointURL('media'),
        },
        gql: {
            baseURL: URLsManager.getEndpointURL('graphql'),
        },
        file: {
            baseURL: URLsManager.filesBaseURL,
        },
    };

    services: ObjectType<AxiosInstance> = {};

    endpoints: ObjectType<Endpoint> = {};

    ERROR_CODES = appClientCore.ERROR_CODES;

    constructor() {
        this.initializeDefaultClients();
    }

    initializeDefaultClients() {
        Object.keys(this.defaultServices).forEach((defaultServiceId) => {
            const axiosInstance = this.createService(defaultServiceId, this.defaultServices[defaultServiceId]);

            let isRefreshingToken = false;
            let requestsQueue: Array<{ resolve: (token: string) => void; reject: (reason?: string | Error) => void }> = [];

            function processQueue(token: string | null, error: Error | null = null) {
                requestsQueue.forEach((prom) => {
                    if (error) {
                        prom.reject(error);
                    } else if (token) {
                        prom.resolve(token);
                    }
                });
                requestsQueue = [];
            }

            axiosInstance.interceptors.request.use(
                async (config) => {
                    const store = getStore();

                    const { accessToken, refreshToken } = appClientAuth.useAuthenticationStore.getState();
                    const currentConfig = { ...config };

                    if (
                        !Object.keys(currentConfig.headers)
                            .map((key) => key.toLowerCase())
                            .includes('authorization') &&
                        !appUtils.object.hasKey(currentConfig, 'auth') &&
                        accessToken
                    ) {
                        currentConfig.headers.authorization = `Bearer ${accessToken}`;
                    }

                    // TODO: For some reason `accessToken` coming via the zustand store is null in web activities on MO (only when viewed on MO). The below line is a workaround, We need to investigate this issue.
                    if (
                        !Object.keys(currentConfig.headers)
                            .map((key) => key.toLowerCase())
                            .includes('authorization')
                    ) {
                        const userAuthData = appMainUtils.user.getAuthData();

                        if (userAuthData.apiKey === 'anonymous') {
                            currentConfig.headers.authorization = `Bearer anonymous`;
                        } else {
                            currentConfig.headers.authorization = `ApiKey USER:${userAuthData.apiKey}`;
                        }
                    }

                    if (typeof window !== 'undefined' && window.location) {
                        currentConfig.headers['x-ethica-path'] = window.location.pathname;
                    }

                    const isScopedPasswordVerification = (formData: FormData) => {
                        if (formData instanceof FormData) {
                            const scopeValue = formData.get('scope');
                            return (
                                scopeValue === appClientConstants.AUTHENTICATION_SCOPES.MODIFY_EMAIL ||
                                scopeValue === appClientConstants.AUTHENTICATION_SCOPES.MODIFY_PHONE_NUMBER
                            );
                        }
                        return false;
                    };

                    // The request proceeds with the 'refresh token' flow only if the target URL is not a public endpoint (such as sign-in, sign-up, etc.).
                    // The only exception is for certain operations like email verification, where we may bypass the public endpoint check if the request payload contains
                    // specific scope values (e.g., 'modify_email' or 'modify_phone') indicating a password verification process.
                    if (
                        accessToken &&
                        (!appClientUtils.auth.isPublicEndpoint(config.url) || isScopedPasswordVerification(config.data))
                    ) {
                        const { exp } = jwtDecode(accessToken);
                        const nowInSeconds = Math.floor(Date.now() / 1000);
                        // Check if the token has expired or will expire within the next minute and a half (90 seconds), This "90 seconds" is to consider the timeout time configured in FE.
                        if (exp && exp - nowInSeconds <= 90) {
                            if (!isRefreshingToken) {
                                isRefreshingToken = true;
                                try {
                                    const responseData = await appClientAuth.fetchAuthenticationTokens({ refreshToken });

                                    if (responseData != null) {
                                        appClientAuth.useAuthenticationStore.getState().updateState({
                                            accessToken: responseData.accessToken,
                                            refreshToken: responseData.refreshToken,
                                            expiresIn: responseData.expiresIn,
                                            isAccountDeletionRevoked: responseData.revokedDeletion,
                                        });

                                        store.dispatch(
                                            appMainStore.userDuck.duckActionCreators.setUserDataExternal({
                                                userData: { apiKey: responseData.accessToken },
                                                actionSource: '',
                                                shouldPersistUserData: true,
                                                shouldLoadFromAPI: false,
                                                shouldSetInitializationStatus: false,
                                            })
                                        );

                                        processQueue(responseData.accessToken, null);
                                        isRefreshingToken = false;

                                        const retryConfig = {
                                            ...currentConfig,
                                            headers: {
                                                ...currentConfig.headers,
                                                authorization: `Bearer ${responseData.accessToken}`,
                                            },
                                        };

                                        return retryConfig;
                                    }
                                } catch (errorObject) {
                                    const refreshError = errorObject as Error & { status?: number };

                                    if (refreshError.status === 401) {
                                        // Logout user if refresh token is invalid
                                        appClientAuth.useAuthenticationStore.getState().resetState();
                                        return store.dispatch(appMainStore.userDuck.duckActionCreators.logout());
                                    }

                                    processQueue(
                                        null,
                                        refreshError instanceof Error ? refreshError : new Error('Token refresh failed')
                                    );

                                    isRefreshingToken = false;
                                    return Promise.reject(refreshError);
                                }
                            } else {
                                return new Promise((resolve, reject) => {
                                    requestsQueue.push({
                                        resolve: (token) => {
                                            const retryConfig = {
                                                ...currentConfig,
                                                headers: {
                                                    ...currentConfig.headers,
                                                    authorization: `Bearer ${token}`,
                                                },
                                            };
                                            resolve(retryConfig);
                                        },
                                        reject,
                                    });
                                });
                            }
                        }
                    }

                    return currentConfig;
                },
                (error) => {
                    throw Promise.reject(error);
                }
            );
        });
    }

    // Service manager
    createService(serviceId: string, axiosConfig: AxiosRequestConfig) {
        this.services[serviceId] = axios.create(axiosConfig);
        return this.services[serviceId];
    }

    getService(serviceId: string) {
        if (SuperObject.hasKey(this.services, serviceId)) {
            return this.services[serviceId];
        }
        throw new ClientError(`Trying to get missing service. Please call createService first. serviceId: ${serviceId}`);
    }

    // Endpoint manager
    static parseEndpoint(endpointDescriptor: string) {
        if (typeof endpointDescriptor !== 'string') {
            throw new ClientError(
                `Provided endpoint descriptor is not valid. It should be string. endpointDescriptor: ${endpointDescriptor}`
            );
        }

        const endpointDescriptorSplit = endpointDescriptor.split(' ');

        const parsedEndpoint = {
            descriptor: endpointDescriptor,
            method: 'get' as Method,
            endpointId: endpointDescriptorSplit[0],
            serviceId: '',
            endpointSubId: '',
        };

        if (endpointDescriptorSplit.length === 2) {
            parsedEndpoint.method = endpointDescriptorSplit[0].toLowerCase() as Method;
            [, parsedEndpoint.endpointId] = endpointDescriptorSplit;
        }

        const endpointSplit = parsedEndpoint.endpointId.split('/');
        if (endpointSplit.length !== 2) {
            throw new ClientError(
                `Provided endpoint part is not valid. It should be like SERVICE/ENDPOINT_ID. endpoint: ${parsedEndpoint.descriptor}`
            );
        }
        const [serviceId, endpointSubId] = endpointSplit;
        parsedEndpoint.serviceId = serviceId;
        parsedEndpoint.endpointSubId = endpointSubId;

        return parsedEndpoint;
    }

    setResponseParser(newResponseParser: Function | undefined, { endpointId }: { endpointId: string }) {
        if (newResponseParser != null) {
            this.endpoints[endpointId].responseParser = newResponseParser;
        }
    }

    registerEndpoint(
        endpointDescriptor: string,
        URLOrGQLQueryAndVariables: Function | string,
        options: Endpoint['options'],
        responseParser?: Function
    ) {
        if (!URLOrGQLQueryAndVariables) {
            throw new ClientError(
                `Endpoint URL or GQL query and variables is missing. It should be provided. URLOrGQLQueryAndVariables: ${URLOrGQLQueryAndVariables}`
            );
        }

        const { method, serviceId, endpointId, endpointSubId } = Client.parseEndpoint(endpointDescriptor);

        const endpoint: Endpoint = {
            method,
            serviceId,
            options,
            responseParser,
        };

        if (serviceId === 'gql') {
            endpoint.queryAndVariables = URLOrGQLQueryAndVariables;
        } else {
            endpoint.url = URLOrGQLQueryAndVariables as (data: unknown) => string;
        }

        this.endpoints[endpointId] = endpoint;

        function getResult<T extends Function>(useAny: T): T {
            Object.defineProperty(useAny, 'name', { value: SuperString.toCamelCase(`use-${endpointSubId}`) });
            Object.defineProperty(useAny, 'key', { value: endpointId });

            return useAny;
        }

        if (options.mutate) {
            const useCustomMutation = (queryConfig: UseMutationOptions = {}, clientQueryOptions: ObjectType = {}) => {
                const { mutate: mainMutate, mutateAsync: mainMutateAsync, ...rest } = useMutation(queryConfig);

                this.setResponseParser(clientQueryOptions.responseParser as Function, { endpointId });

                const mutate = useCallback(
                    (data, extraQueryConfig = {}) => mainMutate({ ...data, queryKey: endpointId }, extraQueryConfig),
                    [mainMutate]
                );

                const mutateAsync = useCallback(
                    (data, extraQueryConfig = {}) => mainMutateAsync({ ...data, queryKey: endpointId }, extraQueryConfig),
                    [mainMutateAsync]
                );

                return { mutate, mutateAsync, ...rest } as ReturnType<typeof useMutation>;
            };

            return getResult(useCustomMutation);
        }

        if (options.infinite) {
            const useCustomInfiniteQuery = (
                data: ObjectType,
                queryConfig: UseInfiniteQueryOptions = {},
                clientQueryOptions: ObjectType = {}
            ) => {
                this.setResponseParser(clientQueryOptions.responseParser as Function, { endpointId });

                return useInfiniteQuery([endpointId, data], {
                    ...queryConfig,
                    getNextPageParam: options.getNextPageParam,
                });
            };

            return getResult(useCustomInfiniteQuery);
        }

        const useCustomQuery = (data: ObjectType, queryConfig: UseQueryOptions = {}, clientQueryOptions: ObjectType = {}) => {
            this.setResponseParser(clientQueryOptions.responseParser as Function, { endpointId });
            return useQuery([endpointId, data], queryConfig);
        };
        return getResult(useCustomQuery);
    }

    getEndpoint(endpointId: string) {
        if (!SuperObject.hasKey(this.endpoints, endpointId)) {
            throw new ClientError(`Trying to get missing endpoint. endpointId: ${endpointId}`);
        }
        return this.endpoints[endpointId];
    }

    static getVersion(url: string) {
        const hasVersion = url.match(/(^[v][1-9]+)/);
        if (hasVersion) {
            return hasVersion[0];
        }
        return 'unknown';
    }

    async callEndpoint(
        endpointId: string,
        data: ObjectType,
        pageParam: unknown,
        axiosConfig: AxiosRequestConfig
    ): Promise<unknown> {
        const {
            serviceId,
            method,
            url,
            queryAndVariables,
            options: {
                requestSchema,
                responseSchema,
                responseType,
                headers,
                customErrorsOrMapper,
                requestSchemaStripUnknown = true,
                responseSchemaStripUnknown = true,
                shouldCamelCaseResponseKeys = true,
                shouldSnakeCaseRequestKeys = true,
                json = false,
            },
            responseParser,
        } = this.getEndpoint(endpointId);

        try {
            const requestConfig: AxiosRequestConfig = { method, responseType, headers: headers || {}, ...axiosConfig };
            if (serviceId === 'gql') {
                requestConfig.url = '';
                requestConfig.data = (queryAndVariables as Function)(
                    data,
                    appClientUtils.gql.node.getBase64IdFromNodeId,
                    pageParam
                );
            } else {
                let finalData: ObjectType = {
                    ...SuperObject.deepClone(data),
                    // BE should always depend on scrollId
                    ...(pageParam !== undefined ? { scrollId: pageParam } : {}),
                };

                if (shouldSnakeCaseRequestKeys) {
                    finalData = languages.snakeCaseNonTranslationKeys(finalData, ['image', 'OR', 'AND']);
                }

                requestConfig.url = typeof url === 'function' ? url(data) : url;

                if (Object.keys(finalData).length > 0) {
                    // Normalize file instances
                    Object.keys(data).forEach((key) => {
                        if (data[key] instanceof File) {
                            finalData[SuperString.toSnakeCase(key)] = data[key];
                        }
                    });

                    if (requestSchema) {
                        finalData = (await requestSchema.validate(finalData, {
                            stripUnknown: requestSchemaStripUnknown,
                        })) as ObjectType;
                    }

                    if (method === 'get') {
                        requestConfig.params = { ...finalData };
                    } else if (method === 'post' && Client.getVersion(url as string) === 'unknown' && !json) {
                        requestConfig.data = appClientUtils.object.toFormData(finalData);
                    } else if (['post', 'put', 'patch', 'delete'].includes(method)) {
                        requestConfig.data = finalData;
                        requestConfig.headers['Content-Type'] = 'application/json';
                    }
                }
            }

            const response = await this.getService(serviceId).request(requestConfig);

            let finalResponse = response.data;

            if (serviceId === 'gql') {
                finalResponse = appClientUtils.gql.response.normalize(response.data.data);
            }

            if (responseSchema) {
                finalResponse = await responseSchema.validate(finalResponse, {
                    stripUnknown: responseSchemaStripUnknown,
                });
            }

            if (shouldCamelCaseResponseKeys) {
                finalResponse = languages.camelCaseNonTranslationKeys(finalResponse);
            }

            if (responseParser) {
                return responseParser(finalResponse, data);
            }

            return finalResponse;
        } catch (error) {
            appClientCore.errorMapper(error as Error, customErrorsOrMapper);
            return null;
        }
    }
}

const instance = new Client();
export default instance;
