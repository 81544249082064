import { ducksManager, sagasManager } from '@edf-pkg/app-store';

import PHRReducer, * as PHRDuck from './phr.duck';
import PHRSaga, { SAGA_NAME as PHRSagaName } from './phr.saga';

export function inject() {
    ducksManager.add(PHRDuck.DUCK_NAME, PHRReducer);
    sagasManager.add(PHRSagaName, PHRSaga);
}

export { PHRDuck };
