import Language from '../core/language';

const pdashNamespace = import('../namespaces/de-de.pdash.namespace');
const webActivitiesNamespace = import('../namespaces/de-de.web-activities.namespace');
const rdashTranslatablesNamespace = import('../namespaces/de-de.rdash-translatables.namespace');

class DeDELanguage extends Language {
    code = 'de-DE';

    locale = 'de';

    localName = 'Deutsch';

    englishName = 'German';

    territory = 'DE';

    timezones = ['Europe/Berlin', 'Europe/Busingen'];

    notLoadedNamespaces = {
        pdash: pdashNamespace,
        webActivities: webActivitiesNamespace,
        rdashTranslatables: rdashTranslatablesNamespace,
    };
}

const instance = new DeDELanguage();
export default instance;
