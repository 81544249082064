import { cloneDeep } from 'lodash-es';
import { Filter as MatrixSDKFilter } from 'matrix-js-sdk';

import {
    AVATAR,
    AVATAR_COLORS,
    MATRIX_TIMELINE_EVENT_TYPES,
    MATRIX_TIMELINE_MESSAGE_CONTENT_TYPES,
    MATRIX_TIMELINE_PAGINATE_EVENTS_LIMIT,
    ROOM,
    ROOM_ALIAS_REGEX,
    ROOM_TYPES,
} from '$app-web/components/chat/constants';
import { URLsManager } from '$app-web/utils';

const MATRIX_SERVER_NAME = process.env.REACT_APP_LOCAL_CHAT_MATRIX_SERVER_NAME;
/*
    Create default filter for rooms timeline. It's created in the matrix server for each user and will be used each time.
 */
async function createRoomsTimelineFilter(client, userData) {
    const { userId } = userData;
    const filter = new MatrixSDKFilter(userId);
    filter.setDefinition({
        room: {
            timeline: {
                types: Object.values(MATRIX_TIMELINE_EVENT_TYPES),
                limit: MATRIX_TIMELINE_PAGINATE_EVENTS_LIMIT,
            },
        },
    });
    filter.filterId = await client.getOrCreateFilter(`FILTER_USER_ROOMS:${userId}`, filter);
    return filter;
}

/*
    Normalize the matrix room into a room ready for the chat client.
 */
function normalizeMatrixRoom(matrixRoom, currentUserId, checkStudyExistence) {
    const roomAlias = matrixRoom.getCanonicalAlias();
    if (roomAlias !== null) {
        const [, studyId, type, userIdOneString, userIdTwoString] = roomAlias.match(ROOM_ALIAS_REGEX);
        // We don't want to keep the rooms that belong to studies the user isn't part of anymore.
        if (!checkStudyExistence(studyId)) {
            return null;
        }

        const normalizedRoom = {
            ...cloneDeep(ROOM),
            matrixRoom,
            type,
            studyId,
            id: matrixRoom.roomId,
            alias: roomAlias,
            unreadMessagesCount: matrixRoom.getUnreadNotificationCount(),
            modifiedTimeMs: matrixRoom.currentState.getLastModifiedTime(),
            isArchived: matrixRoom.getMember(`@u${currentUserId}:${MATRIX_SERVER_NAME}`).powerLevel < 0,
        };

        // Get other member data
        const userIds = [Number(userIdOneString), Number(userIdTwoString)];
        if (type === ROOM_TYPES.PARTICIPANT_TO_RESEARCHERS) {
            normalizedRoom.otherMemberId = userIds[0];
        } else if (type === ROOM_TYPES.PARTICIPANT_TO_RESEARCHER || type === ROOM_TYPES.RESEARCHER_TO_RESEARCHER) {
            const currentUserIdIndex = userIds.indexOf(currentUserId);
            if (currentUserIdIndex >= 0) {
                normalizedRoom.otherMemberId = userIds[1 - currentUserIdIndex];
                // TODO: This is a quick fix for incoming calls when we haven't loaded the Chat page yet, and will be refactored later.
                const otherMember = matrixRoom.getMember(`@u${normalizedRoom.otherMemberId}:${MATRIX_SERVER_NAME}`);
                if (otherMember) {
                    normalizedRoom.title = otherMember.user?.displayName || otherMember.name;
                    normalizedRoom.avatar.label = normalizedRoom.title;
                    if (otherMember.user?.avatarUrl && !otherMember.user?.avatarUrl.endsWith('default_avatar.png')) {
                        normalizedRoom.avatar.url = URLsManager.getURLTo(otherMember.user?.avatarUrl);
                    } else {
                        normalizedRoom.avatar.color = getAvatarColorById(normalizedRoom.otherMemberId);
                    }
                }
            }
        }

        // Set is direct or not
        if (type === ROOM_TYPES.PARTICIPANT_TO_RESEARCHER || type === ROOM_TYPES.RESEARCHER_TO_RESEARCHER) {
            normalizedRoom.direct = true;
        }

        // Set room names
        if (type === ROOM_TYPES.RESEARCHER_TO_RESEARCHERS || type === ROOM_TYPES.PARTICIPANT_TO_RESEARCHERS) {
            normalizedRoom.title = matrixRoom.name;
        }

        /*
            Set the last message, if any, as the first timeline event so the last message will be shown correctly. Note
            that when the user opens the room, the timeline will be reset with the real timeline. It's added to remove
            the need to have a last message key to store it, as it'll add duplication and can lead to bugs.
        */
        const lastTimelineMessage = matrixRoom.timeline[matrixRoom.timeline.length - 1];
        if (lastTimelineMessage && lastTimelineMessage.getType() === MATRIX_TIMELINE_EVENT_TYPES.MESSAGE) {
            normalizedRoom.timeline.events.push(lastTimelineMessage);
        }

        return normalizedRoom;
    }
    return null;
}

/*
    Sort study rooms based on the last message time and get the sorted Ids
    TODO: this should be done on sync instead of on each "send" to handle multi-device situations
 */
function getSortedStudyRoomsOrder(studyRooms) {
    return Object.keys(studyRooms).sort((roomOneId, roomTwoId) => {
        const roomOne = studyRooms[roomOneId];
        const roomTwo = studyRooms[roomTwoId];
        const roomOneLastMessage = roomOne.timeline.events[roomOne.timeline.events.length - 1];
        const roomTwoLastMessage = roomTwo.timeline.events[roomTwo.timeline.events.length - 1];
        // If both have last message, sort based on the last message timestamp
        if (roomOneLastMessage && roomTwoLastMessage) {
            return roomTwoLastMessage.getTs() - roomOneLastMessage.getTs();
        }
        // If room one has last message, sort it before room two
        if (roomOneLastMessage) {
            return -1;
        }
        // If room two has last message, sort it before room one
        if (roomTwoLastMessage) {
            return 1;
        }
        // Finally if no last message, sort based on modified time
        return roomOne.modifiedTimeMs - roomTwo.modifiedTimeMs;
    });
}

function getAvatarColorById(userOrStudyId) {
    const numberId = Number(userOrStudyId) || 0;
    const colorIndex = numberId % AVATAR_COLORS.length;

    return AVATAR_COLORS[colorIndex];
}

/*
    Load user data of study and normalize it
 */
async function loadStudyUsersData(studyUsersDataLoader, rooms) {
    const studyUsersData = {};
    const loadedStudyUsersData = await studyUsersDataLoader();
    Object.keys(loadedStudyUsersData).forEach((userId) => {
        const userData = {
            ...loadedStudyUsersData[userId],
            id: userId,
            matrixId: `@u${userId}:${MATRIX_SERVER_NAME}`,
            avatar: {
                url: undefined,
                color: undefined,
                label: undefined,
            },
            isOnline: false,
        };

        if (!userData.avatarUrl || userData.avatarUrl.endsWith('default_avatar.png')) {
            userData.avatar.color = getAvatarColorById(userId);
            if (userData.role === 'participant' && userData.label.includes('AID')) {
                userData.avatar.label = 'PR';
            } else {
                userData.avatar.label = userData.label;
            }
        } else {
            userData.avatar.url = userData.avatarUrl ? URLsManager.getURLTo(userData.avatarUrl) : '';
        }

        delete userData.avatarUrl;

        studyUsersData[userId] = userData;
    });

    // This is a quick fix for the cases in which the user isn't in the study anymore, but we have their rooms on Chat.
    rooms.forEach((room) => {
        const members = room.matrixRoom.getMembers();
        members.forEach((member) => {
            if (member.userId === `@admin:${MATRIX_SERVER_NAME}`) {
                return;
            }

            const [, userId] = member.userId.match(/@u([0-9]+):/);
            const userIdNumber = +userId;
            if (!studyUsersData[userIdNumber]) {
                studyUsersData[userIdNumber] = {
                    // We won't have the `role` here.
                    id: userIdNumber,
                    label: member.name,
                    matrixId: member.userId,
                    avatar: {
                        url: member.user?.avatarUrl ? URLsManager.getURLTo(member.user.avatarUrl) : '',
                        color: getAvatarColorById(userId),
                        label: member.name,
                    },
                    isOnline: false,
                };
            }
        });
    });

    return studyUsersData;
}
/*
    Get room title based on type and user data
 */
function getChatRoomTitle(room, studyUsersData, currentUserId, t, newTitle) {
    const otherUserData = studyUsersData?.[room.otherMemberId];
    if (otherUserData) {
        if (room.type === ROOM_TYPES.PARTICIPANT_TO_RESEARCHERS && room.otherMemberId !== currentUserId) {
            return `${otherUserData.label} - ${t('group')}`;
        }
        if (room.type === ROOM_TYPES.RESEARCHER_TO_RESEARCHER || room.type === ROOM_TYPES.PARTICIPANT_TO_RESEARCHER) {
            return otherUserData.label;
        }
    }
    return newTitle || room.title;
}

/*
    Get room avatar based on type and user data
 */
function getChatRoomAvatar(room, studyUsers) {
    const { type, title, studyId, otherMemberId } = room;

    if (type === ROOM_TYPES.RESEARCHER_TO_RESEARCHERS) {
        return { ...AVATAR, color: getAvatarColorById(studyId), label: title };
    }

    const otherUser = studyUsers[otherMemberId];

    if (otherUser) {
        if (type === ROOM_TYPES.PARTICIPANT_TO_RESEARCHERS) {
            return { ...AVATAR, color: getAvatarColorById(room.otherMemberId), label: title };
        }
        if ([ROOM_TYPES.RESEARCHER_TO_RESEARCHER, ROOM_TYPES.PARTICIPANT_TO_RESEARCHER].includes(room.type)) {
            const avatar = { ...AVATAR };
            if (otherUser.avatar.url) {
                avatar.url = URLsManager.getURLTo(otherUser.avatar.url);
            } else {
                avatar.color = otherUser.avatar.color;
                avatar.label = otherUser.avatar.label;
            }
            return avatar;
        }
        return AVATAR;
    }
    return AVATAR;
}

function getEventMessage(event, t) {
    const eventType = event.getType();
    const { msgtype: type, body } = event.getContent();

    if (type === MATRIX_TIMELINE_MESSAGE_CONTENT_TYPES.TEXT) {
        return body;
    }

    switch (eventType) {
        case MATRIX_TIMELINE_EVENT_TYPES.CALL_ANSWER:
            return t('call_answered');
        case MATRIX_TIMELINE_EVENT_TYPES.CALL_REJECT:
            return t('call_rejected');
        case MATRIX_TIMELINE_EVENT_TYPES.CALL_HANGUP:
            return t('call_ended');
        case MATRIX_TIMELINE_EVENT_TYPES.CALL_INVITE:
            return t('call_started');
        default:
            return `This type of message is not supported. Type: ${type}`;
    }
}

export {
    createRoomsTimelineFilter,
    normalizeMatrixRoom,
    getSortedStudyRoomsOrder,
    loadStudyUsersData,
    getChatRoomTitle,
    getChatRoomAvatar,
    getEventMessage,
    getAvatarColorById,
};
