import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
        minHeight: 40,
        objectFit: 'contain',
        backgroundColor: theme.palette.common.white,
    },
    shadow: {
        boxShadow: theme.shadows.custom[1],
    },
    translucentImage: {
        opacity: 0.2,
    },
}));
