export function getImageOriginalAndThumbUrl(url) {
    if (typeof url === 'string' && url !== '') {
        if (url.startsWith('blob')) {
            return { original: url, thumb: url };
        }
        if (url.includes('thumb')) {
            return { original: url.replace('thumb/', ''), thumb: url };
        }

        const lashSlashIndex = url.lastIndexOf('/');
        const thumbUrls = `${url.slice(0, lashSlashIndex)}/thumb/${url.slice(lashSlashIndex + 1)}`;

        return { original: url, thumb: thumbUrls };
    }

    return { original: '', thumb: '' };
}
